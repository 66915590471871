var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "saveButton" }, [
    _vm.$store.state.nodeform.formStatus === 2
      ? _c("button", { on: { click: _vm.cancelHandle } }, [_vm._v("撤销")])
      : _vm._e(),
    _c(
      "button",
      { attrs: { disabled: _vm.num }, on: { click: _vm.saveHandel } },
      [_vm._v("保存")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }