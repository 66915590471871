<template>
  <div v-if="item.beginTime">
    <div class="flow-item-mini"
         :style="flowMiniStyle"
         v-if="item.isInvisible !== 'YES'"
         v-show="this.isLeft || this.isRight">
      <div class="left"
           v-show="isLeft">
        <i class="iconfont icon-slide-left"></i>
      </div>
      <div class="name"
           @click="adjustTLRange">{{item.simpleName}}</div>
      <div class="right"
           v-show="isRight">
        <i class="iconfont icon-slide-right"></i>
      </div>
    </div>
    <div v-show="!(this.isLeft || this.isRight)"
         class="div">
      <div v-if="isSlider"
           class="flow-item"
           :style="itemStyle(item)"
           v-show="isVisible(item)">
        <time-picker :timeRange="item"
                     :expand="expand"
                     @sliderChange="handleSliderChange"
                     @toggleChildren="handleToggleChildren">
        </time-picker>
      </div>
      <div v-else
           class="point-item"
           :style="itemStyle(item)"
           v-show="true">
        <div class="sname">{{item.simpleName}}</div>
        <div class="has-child-icon"
             v-if="item.children"
             @click="handleToggleChildren">
          <i :class="expandIcon"></i>
        </div>
        <div class="fname"
             @click.stop="openForm">{{item.nodeName}}</div>
      </div>
    </div>
    <div class="flow-item-children"
         v-if="item.children"
         v-show="expand">
      <linked-item :itemList="children"
                   :parentItem="item"
                   :parentAnchor="anchor"
                   @toggleChild="handleToggleChild">
      </linked-item>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import visibilityMixin from '~mixins/visibility'

import LinkedItem from '../linkedItem'
import TimePicker from './timePicker'

export default {
  mixins: [visibilityMixin],
  name: 'flow-item',
  props: {
    item: Object
  },
  components: {
    LinkedItem,
    TimePicker
  },
  data () {
    return {
      expand: false,
      anchor: 0,
      isLeft: false,
      isRight: false,
      children: []
    }
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching,
      backToTodayTranslateX: state => state.backToTodayTranslateX
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber', 'getBackToTodayTranslateX']),
    itemCopy () {
      const itemCopy = this.item
      if (itemCopy.children) {
        const _children = this.makeExpandToBoolean(itemCopy.children)
        itemCopy.children = _children
      }
      return itemCopy
    },
    isSlider () {
      const { beginTime, endTime } = this.item
      if (beginTime !== endTime) {
        return true
      } else {
        return false
      }
    },
    expandIcon () {
      if (this.expand) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    flowMiniStyle () {
      if (this.isLeft) {
        return {
          justifyContent: 'flex-start'
        }
      } else if (this.isRight) {
        return {
          justifyContent: 'flex-end'
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    makeExpandToBoolean (arr) {
      const newArr = []
      arr.forEach(item => {
        const tempItem = item
        tempItem.expand = item === 'YES'
        newArr.push(tempItem)
      })
      return newArr
    },
    // 为子级节点加上层级
    getLeveled (itemsArr) {
      const getLevel = (item, level = 0) => {
        const pItem = itemsArr.filter(obj => {
          return obj.nodeId === item.parentId
        })
        if (pItem && pItem.length === 0) {
          return level
        } else {
          level += 1
          return getLevel(pItem[0], level)
        }
      }
      const leveledArr = []
      itemsArr.forEach(item => {
        const level = getLevel(item)
        item.level = level
        item.anchor = item.beginTime
        leveledArr.push(item)
      })
      return leveledArr
    },
    itemStyle (item) {
      const { start, end } = this.getTLRangeStamp
      const _start = this.getTimeStamp(item.beginTime)
      const _end = this.getTimeStamp(item.endTime)
      const timeCross = _end - _start
      const pr = timeCross / this.getTLRangeCrossNumber
      const width = _start === _end ? 'auto' : this.timelineWidth * pr
      const left =
        (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
      // 不在可视范围
      const isLeft = start - _end
      const isRight = _start - end
      if (isLeft > 0) {
        this.isLeft = true
        this.isRight = false
      } else if (isRight > 0) {
        this.isLeft = false
        this.isRight = true
      } else {
        this.isLeft = false
        this.isRight = false
      }
      const styleCode = item.style + ''
      const colorSheet = {
        default: '#666',
        gray: '#555'
      }
      const translateY = 0
      let style = {}
      if (width === 'auto') {
        style = {
          width: 'auto',
          transform: `translate3d(${left}px,${translateY},0)`,
          color: colorSheet[styleCode]
        }
      } else {
        style = {
          width: `${width}px`,
          transform: `translate3d(${left}px,${translateY},0)`,
          color: colorSheet[styleCode]
        }
      }
      if (item.isInvisible === 'YES') {
        style.opacity = 0
        style.height = '0px'
      }

      return style
    },
    toggleExpandChildren () {
      this.expand = !this.expand
    },
    handleToggleChildren () {
      this.expand = !this.expand
    },
    handleToggleChild (tItem) {
      const { caseNodeId, level } = tItem
      const newLinkedItems = []
      this.children.forEach(item => {
        if (item.level === level + 1) {
          item.expand = false
        }
        const _caseNodeId = item.caseNodeId
        if (_caseNodeId === caseNodeId) {
          // 如果当前项未展开
          if (!item.expand) {
            const level = item.level
            this.children.forEach(element => {
              if (element.level === level && element.caseNodeId !== _caseNodeId) {
                element.expand = false
              }
            })
          }
          item.expand = !item.expand
        } else {

        }
        newLinkedItems.push(item)
      })
      Object.assign(this.$data.children, newLinkedItems)
      // this.children = newLinkedItems
    },
    handleSliderChange (payload) {
      // console.log(payload)
      this.anchor = payload.days
    },
    // 点击缩略节点调整时间轴视窗
    adjustTLRange () {
      const itemStart = this.getTimeStamp(this.item.beginTime)
      const { start, end } = this.getTLRangeStamp
      const newStart = itemStart - this.getBackToTodayTranslateX / this.timelineWidth * (end - start)
      const newEnd = newStart + end - start
      window.TLDetail.setWindow(newStart, newEnd)
    },
    openForm (e) {
      const caseId = this.$route.params.caseId
      const { beginTime: startDate, nodeId, nodeFormType, nodeName: title } = this.item
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    }
  },
  created () {
    if (this.itemCopy.children) {
      this.children = this.getLeveled(this.itemCopy.children)
    }
    if (this.item.isInvisible === 'YES' && this.item.children) {
      this.handleToggleChildren()
    }
  }
}
</script>

<style lang="stylus">
.flow-item-mini
  display flex
  height 28px
  align-items center
  cursor pointer
  box-sizing border-box
  .left, .right
    color #55c3fe
    i
      color #acdbf4
      font-size 18px
  .name
    border-radius 50%
    background #acdbf4
    color #ffffff
    border 1px dashed #55c3fe
    height 24px
    width 24px
    line-height 24px
    text-align center
    font-size 14px
.flow-item
  background transparent
  color #fff
  height 28px
  line-height 28px
  box-sizing border-box
  display flex
  box-sizing border-box
  &.active
    background #f6b536
  .has-child-item-icon
    i
      font-size 20px
  .flow-item-fname
    padding 0 0 0 6px
    white-space nowrap
    font-size 14px
    word-break keep-all
    overflow visible
.point-item
  height 28px
  display flex
  max-width 120px
  align-items center
  .sname
    width 28px
    height 28px
    background #5E88FF
    color #ffffff
    text-align center
    line-height 28px
    border-radius 50%
    margin-left -14px
    z-index 2
  .has-child-icon
    padding 0 2px
  .fname
    color #999999
    word-break keep-all
    overflow visible
    padding 0 2px
</style>
