<template>
  <div class="document-tab">
    <div class="document-header" v-show="showCell">
      <div class="header-left">
        <span class="iconfont icon-sousuo"></span>
        <van-field
          v-model="inputdata"
          class="input"
          @focus="focu"
          @blur="gogo"
          left-icon="iconfont iconfont iconsousuo"
          placeholder="请输入文书"
        >
        </van-field>
      </div>
      <!--  <div class="header-right">
        <span class="quanbu" @click="selectAll">{{ button }}</span>
      </div> -->
    </div>
    <ul>
      <div class="document-loading-wrapper" v-show="showLoading">
        <van-loading />
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <!-- 文书模式 -->
        <cell
          v-for="item in DocumentList"
          :key="item.id"
          :item="item"
          ref="cell"
          @DeleteandBuilt="showDeleteandBuilt"
          @showHistoryBottomBar="showHistoryBottomBar"
          @closeHistoryBottomBar="closeHistoryBottomBar"
          v-show="showCell"
          @establish="establish"
        ></cell>
        <!-- 节点模式 -->
        <node
          v-for="val in NodeList"
          :key="val.nodeId"
          :val="val"
          @getNodeList="getNodeList"
          v-show="showNode"
          ref="node"
          @shownode="shownode"
          @nonenode="nonenode"
          @nodefunct="nodefunct"
        ></node>
      </van-list>
      <div class="document-kongbai"></div>
      <!-- 删除 创建 -->
      <van-popup
        v-model="show"
        position="bottom"
        :overlay="false"
        class="deleteandbuilt"
        :get-container="container"
      >
        <ul class="documentwrap">
          <li @click="cancle">
            <span class="iconfont icon-quxiao"></span>
            <span class="text">取消</span>
          </li>
          <li @click="deleteFn">
            <span class="iconfont icon-shanchu"></span>
            <span class="text">删除</span>
          </li>
          <!-- 创建待开发 -->
          <!-- <li>
            <span class="iconfont icon-chuangjian"></span>
            <span class="text">创建</span>
          </li> -->
        </ul>
      </van-popup>
    </ul>
  </div>
</template>
<script>
import cell from './components/cell'
import node from './components/node'
import { caseBase } from '~api-config'
export default {
  name: 'document-tab',
  components: {
    cell,
    node,
  },

  data() {
    return {
      showhead: false,
      menuHeight: false, // 判断页面高度是否发生变化
      menuHeightT: '', // 存储窗口大小
      button: '常用',
      inputdata: '',
      DocumentList: [],
      NodeList: [],
      loading: false, // 加载列表数据
      finished: false, // 结束加载列表数据
      showLoading: false, // 加载页面
      datasLength: 0, // 接口请求数据的length
      show: false,
      isShow: true,
      showCell: true,
      showNode: false,
      container: () => document.body,
      changeBtn: null,
      content: null,
      myDocumentId: '', // 删除文书用的id
      nodemyDocumentId: '', // 删除节点文书id
      mydocumentarr: [], // 文书模式-删除文书的id数组
      nodemydocumentarr: [], // 节点模式-删除文书的id数组
      arr: [],
      nodearr: [],
    }
  },
  computed: {
    chekedDoc() {
      return this.$refs.cell.filter((item, index) => {
        return item.checked
      })
    },
    checkDoclength() {
      return this.DocumentList.filter((item, index) => {
        return this.$refs.cell[index].checked
      }).length
    },
    checkHistorylength() {
      return this.DocumentList.filter((item, index) => {
        return this.$refs.cell[index].historyCheck
      }).length
    },
    isCellHistory() {
      return this.$refs.cell
        .filter((item, index) => {
          return item.bgColor
        })
        .map((item) => {
          return item.$refs.history
            .filter((item) => {
              return item.active
            })
            .map((item) => {
              return item.historyData.val
            })
        })
    },
    isCellHistoryActive() {
      return this.$refs.cell
        .filter((item, index) => {
          return item.bgColor
        })
        .map((item) => {
          return item.$refs.history
            .filter((item) => {
              return item.active
            })
            .map((item) => {
              return item
            })
        })
    },
    isCell() {
      return this.$refs.cell
        .filter((item, index) => {
          return item.bgColor
        })
        .map((node) => {
          return node.item
        })
    },
    checkNodelength() {
      if (!Array.isArray(this.NodeList)) {
        return []
      }
      return this.NodeList.filter((item, index) => {
        // console.log(this.$refs.node[index].check)
        return this.$refs.node[index].check
      }).length
    },
    checkedNode() {
      return this.$refs.node
        .filter((item) => {
          return item.check
        })
        .map((item) => {
          return item.$refs.sonnode.filter((item) => {
            return item.active
          })
        })
    },
    isNodeHistory() {
      return this.$refs.node
        .filter((item) => {
          return item.check
        })
        .map((item) => {
          return item.$refs.sonnode
            .filter((item) => {
              return item.Bgcolor
            })
            .map((item) => {
              return item.$refs.history.filter((item) => {
                return item.active
              })
            })
        })
    },
    isNode() {
      return this.$refs.node
        .filter((item) => {
          return item.check
        })
        .map((item) => {
          return item.$refs.sonnode
            .filter((item) => {
              return item.Bgcolor
            })
            .map((node) => {
              return node.item
            })
        })
    },
    isNodegetList() {
      return this.$refs.node.filter((item) => {
        return item.check
      })
    },
    changeButton() {
      if (
        this.$store.state.caseDetail.currrentTabName === 'DocumentTab' &&
        this.$store.state.caseDetail.bottomTabMode !== 'MIN'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    nodefunct(val) {
      if (val.length > 0) {
        this.show = true
        this.nodearr = val
      } else {
        this.show = false
      }
    },
    establish(val) {
      if (val.length > 0) {
        this.show = true
        this.arr = val
      } else {
        this.show = false
      }
    },
    // 搜索框聚焦和失焦事件
    focu() {
      console.log('聚焦')
      // window.onresize = () => {
      // if (!this.menuHeight) {
      //   // this.menuHeightT = document.querySelector('#scroll-menu').style.height
      //   // document.querySelector('#scroll-menu').style.height = '280px'
      // }
      // // 测试数据
    },
    gogo() {
      console.log('失焦')
      // this.menuHeight = false
    },
    selectAll() {
      if (this.inputdata === '') {
        if (this.button === '全部') {
          this.DocumentList = this.allDocumentList
          this.button = '默认'
        } else {
          this.DocumentList = this.laterDocumentList
          this.button = '全部'
        }
      }
    },
    getDocList() {
      // 获取文书接口
      this.showLoading = true
      const caseId = this.$route.params.caseId
      this.DocumentList = ''
      this.$axios
        .get(`${caseBase}/document/basicDocuments/getDocumentsByCaseId2?caseId=${caseId}`)
        .then((res) => {
          this.showLoading = false
          if (res.status === 200 && res.data.data) {
            // 常用数据
            this.laterDocumentList = res.data.data.oftenUsedBasicDocument
            // 全部数据
            this.allDocumentList = res.data.data.basicDocument
            this.DocumentList = res.data.data.oftenUsedBasicDocument
            this.DocumentList.forEach((item) => {
              setTimeout(() => {
                Array.from(
                  document.querySelectorAll('.van-list .list-right .name')
                ).forEach((itemlist) => {
                  if (item.editor && itemlist.innerText === item.editor) {
                    itemlist.style.fontWeight = '700'
                    itemlist.style.color = '#000000'
                    itemlist.parentElement.children[1].style.color = '#000000'
                    itemlist.parentElement.children[1].style.fontWeight = '700'
                    itemlist.parentElement.previousSibling.children[0].style.fontWeight =
                      '700'
                    itemlist.parentElement.previousSibling.children[0].style.color =
                      '#000000'
                  }
                })
              })
            })
            this.datasLength = res.data.data.length
          }
        })
        .catch((err) => {
          this.showLoading = false
          Promise.reject(err)
        })
    },
    getNodeList() {
      // 获取节点接口--立案登记
      this.NodeList = ''
      const caseId = this.$route.params.caseId
      this.$axios
        .get(`${caseBase}/lts/case/caseNodeVos?caseId=${caseId}`)
        .then((res) => {
          // console.log(res.data.data)
          if (res.status === 200 && res.data.data) {
            this.NodeList = res.data.data
          }
        })
        .catch((err) => {
          Promise.reject(err)
        })
    },
    // deleteFn () {
    //   // 删除文书接口
    //   this.resetCheked()
    //   if (this.showCell) {
    //     this.isCellHistory.forEach(item => {
    //       item.forEach(element => {
    //         element.status = 0
    //         const myDocumentId = element.id
    //         console.log(myDocumentId)
    //         // return false
    //         // this.$axios.delete(`${caseBase}/new-design/catalog/deleteMyDocument/${cellhistoryid}`)
    //         this.$axios.delete(`${caseBase}/document/basicCatalog/deleteMyDocument/${myDocumentId}`)
    //           .then(res => {
    //             if (res.status === 200) {
    //               this.isCellHistoryActive.forEach(item => {
    //                 item.forEach(item => {
    //                   item.change()
    //                 })
    //               })
    //               this.getDocList()
    //             }
    //           })
    //       })
    //     })
    //   } else if (this.showNode) {
    //     const NodeHistoryArray = []
    //     this.isNodeHistory.forEach(item => {
    //       item.forEach(element => {
    //         NodeHistoryArray.push(...element)
    //       })
    //     })
    //     NodeHistoryArray.forEach(item => {
    //       const nodehistoryid = item.historyData.val.id
    //       item.historyData.val.status = 0
    //       this.$axios.delete(`${caseBase}/new-design/catalog/deleteMyDocument/${nodehistoryid}`).then(res => {
    //         if (res.status === 200) {
    //           this.isNodegetList.forEach(element => {
    //             element.getSonNodeList()
    //           })
    //           item.change()
    //         }
    //       })
    //     })
    //   }
    // },
    deleteFn() {
      // console.log(this.mydocumentarr)
      // // 删除文书接口--支持多条记类删除，采用mydocumentarr管理id支持多条记类删除，采用mydocumentarr管理id
      // if (this.showCell) {
      //   if (this.mydocumentarr.length !== 0) {
      //     this.$axios.delete(`${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${this.mydocumentarr}`).then(res => {
      //       if (res.status === 200) {
      //         this.resetCheked()
      //         this.getDocList()
      //       }
      //     })
      //   } else {
      //     this.$notify('此文书没有历史版本，无法删除')
      //     this.resetCheked()
      //   }
      // } else if (this.showNode) {
      //   if (this.nodemydocumentarr.length !== 0) {
      //     this.$axios.delete(`${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${this.nodemydocumentarr}`).then(res => {
      //       if (res.status === 200) {
      //         this.getNodeList()
      //         this.$refs.node.forEach((item) => {
      //           item.getSonNodeList()
      //         })
      //         this.resetCheked()
      //       }
      //     })
      //   } else {
      //     this.$notify('此文书没有历史版本，无法删除')
      //     this.resetCheked()
      //   }
      // }
      if (this.showNode) {
        // 如果为节点状态
        if (this.nodearr.length > 0) {
          // 先删除历史版本
          this.$axios
            .post(
              `${caseBase}/document/basicCatalog/deleteMyDocument?documentRecordIdList=${this.nodearr.join(
                ','
              )}`
            )
            .then((res) => {
              if (res.data.code === '200') {
                this.$toast('删除历史版本成功')
                this.show = false
                this.nodearr = []
                this.getNodeList()
              } else {
                this.$toast(res.data.message)
              }
            })
        } else {
          // 只删除文书
          this.$axios
            .post(
              `${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${this.nodemydocumentarr.join(
                ','
              )}`
            )
            .then((res) => {
              this.show = false
              if (res.data.code === '200') {
                this.$toast('删除文书成功')
                this.show = false
                this.getNodeList()
              } else {
                this.$toast(res.data.message)
              }
            })
        }
      } else {
        // 如果为非节点状态
        // console.log(this.arr.length, '如果为非节点状态')
        if (this.arr.length > 0) {
          // 先删除历史版本
          this.$axios
            .post(
              `${caseBase}/document/basicCatalog/deleteMyDocument?documentRecordIdList=${this.arr.join(
                ','
              )}`
            )
            .then((res) => {
              if (res.data.code === '200') {
                this.$toast('删除历史版本成功')
                this.show = false
                this.arr = []
                this.getDocList()
              } else {
                this.$toast(res.data.message)
              }
            })
        } else {
          // 只删除文书
          this.$axios
            .post(
              `${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${this.mydocumentarr.join(
                ','
              )}`
            )
            .then((res) => {
              this.show = false
              if (res.data.code === '200') {
                this.$toast('删除文书成功')
                this.show = false
                this.getDocList()
              } else {
                this.$toast(res.data.message)
              }
            })
        }
      }
    },
    cancle() {
      // 取消选中的节点
      this.resetCheked()
    },
    Idselect(id, e) {
      // 用于过滤全局文书的id,提供删除文书接口的id数组
      // if (id !== null && id !== undefined && e !== false) {
      //   this.mydocumentarr.push(id)
      // } else if (id !== null && e === false) {
      //   this.mydocumentarr.pop(id)
      // }
      // console.log('全局id' + this.mydocumentarr)
      if (e && id) {
        this.mydocumentarr.push(id)
      }
    },
    NodeIdselect(id, e) {
      // 用于过滤节点文书的id,提供删除节点文书接口的id数组
      // if (id !== null && id !== undefined && e !== false) {
      //   this.nodemydocumentarr.push(id)
      // } else if (id !== null && e === false) {
      //   this.nodemydocumentarr.pop(id)
      // }
      // console.log('节点id' + this.nodemydocumentarr)
      if (e && id) {
        this.nodemydocumentarr.push(id)
      }
    },
    onLoad() {
      setTimeout(() => {
        this.loading = false
        if (this.DocumentList.length >= this.datasLength) {
          this.finished = true
        }
      }, 500)
    },
    showDeleteandBuilt(id, e) {
      this.Idselect(id, e)
      // 文书模式下 根据选中图标 切换bottombar的显示或隐
      if (this.checkDoclength) {
        // console.log(this.checkDoclength)
        this.show = true
      } else {
        this.show = false
      }
    },
    shownode(id, e) {
      // 在节点模式下 选中图标时
      this.NodeIdselect(id, e)
      if (this.checkNodelength) {
        this.show = true
      }
    },
    nonenode(id, e) {
      // 在节点模式下 未选中图标时 隐藏bottombar
      this.NodeIdselect(id, e)
      if (!this.checkNodelength) {
        this.show = false
      }
    },
    closeBottomBar() {
      // 不在documentTab时 隐藏bottombar
      this.show = false
    },
    showHistoryBottomBar(id, e) {
      this.Idselect(id, e)
      if (this.checkHistorylength) {
        this.show = true
      }
    },
    closeHistoryBottomBar(id, e) {
      this.Idselect(id, e)
      this.show = false
    },
    createMenu() {
      this.changeBtn = document.createElement('div')
      this.changeBtn.innerHTML = '切换'
      this.changeBtn.classList.add('documentTabButton')
      this.changeBtn.addEventListener('click', this.showCellOrNode)
      this.content.appendChild(this.changeBtn)
    },
    mounteMenu() {
      // this.createMenu()
    },
    destroyMenu() {
      if (this.changeBtn) {
        this.content.removeChild(this.changeBtn)
        this.changeBtn = null
      }
    },
    showCellOrNode() {
      this.showCell = !this.showCell
      this.showNode = !this.showNode
      if (this.showCell && this.checkDoclength) {
        this.show = true
      } else if (this.showNode && this.checkNodelength) {
        this.show = true
      } else if (this.showCell && this.checkHistorylength) {
        this.show = true
      } else {
        this.show = false
      }
    },
    resetCheked() {
      // 用于取消所有选择的文书
      if (this.showCell) {
        this.chekedDoc.forEach((item) => {
          item.checked = false
        })
        this.isCellHistoryActive.forEach((item) => {
          item.forEach((item) => {
            item.active = false
          })
        })
      } else if (this.showNode) {
        this.checkedNode.forEach((array) => {
          array.forEach((item) => {
            item.active = false
          })
        })

        this.isNodeHistory.forEach((item) => {
          item.forEach((item) => {
            item.forEach((item) => {
              item.active = false
            })
          })
        })
        this.$refs.node.forEach((item) => {
          item.check = false
        })
      }

      this.mydocumentarr = []
      this.nodemydocumentarr = []
      this.show = false
    },
  },
  created() {
    // this.box = document.querySelector('#scroll-menu')
    // alert(this.box.getBoundingClientRect().top)
    this.content = document.querySelector('.fb-page')
  },
  mounted() {
    setTimeout(() => {
      this.content = document.querySelector('.fb-page')
      this.getDocList()
      this.getNodeList()
      // this.createMenu()
    }, 500)
  },
  destroyed() {
    this.destroyMenu()
  },
  watch: {
    changeButton(value) {
      // console.log(value, 'changeBtn---watch')
      if (value) {
        this.mounteMenu()
        this.showDeleteandBuilt()
        this.shownode()
        this.showHistoryBottomBar()
      } else {
        this.destroyMenu()
        this.closeBottomBar()
      }
    },
    // 数据监听过滤
    inputdata(value) {
      this.DocumentList = []
      for (var i = 0; i < this.allDocumentList.length; i++) {
        if (this.allDocumentList[i].documentName.indexOf(value) !== -1) {
          this.DocumentList.push(this.allDocumentList[i])
        }
      }
    },
  },
}
</script>

<style lang="stylus">
.document-tab
  // margin-top 150px
.document-loading-wrapper
  display flex
  justify-content center
  // align-items center
.document-header
  display flex
  align-items center
  margin-top 8px
  .header-left
    width 301px
    height 54px
    border-radius 27px
    margin-left 15px
    .icon-sousuo
      position absolute
      margin-top 8px
      margin-left 10px
      font-size 20px
      z-index 1000
    .input
      padding 2px 30px
      font-size 15px
      margin-top 5px
      width 98%
      height 27px
      border-radius 27px
      background rgba(242, 242, 242, 1)
  .header-right
    // width 25%
    margin-left 12px
    height 36px
  .quanbu
    height 25px
    font-size 15px
    font-family PingFangSC
    color rgb(75, 153, 252)
.document-kongbai
  height 48px
.deleteandbuilt
  width 100%
  height 52px
  background-color #fff
  border-top 1px solid rgba(238, 238, 238, 1)
  position fixed
  .documentwrap
    display flex
    justify-content space-between
    margin 10px 83px 0 81px
    & li
      display flex
      flex-direction column
      align-items center
      color #333
      .text
        font-size 11px
      .iconfont
        font-size 20px
.documentTabButton
  width 50px
  height 30px
  background #4899FC
  z-index 105
  position fixed
  bottom 40px
  right 0
  border-top-left-radius 15px
  border-bottom-left-radius 15px
  text-align center
  padding-top 5px
  font-size 14px
  font-family PingFangSC
  color #fff
  box-sizing border-box
</style>
