var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nodeform-search" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "检索法院", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.clickBack },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nodeformsearch-body" },
        [
          _c("div", { key: "norepead", staticClass: "nodeform-search-cell" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "nodeformsearch",
              attrs: { type: "text", placeholder: "输入法院名称" },
              domProps: { value: _vm.name },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.name = $event.target.value
                  },
                  _vm.inputHandle,
                ],
              },
            }),
          ]),
          _vm._l(_vm.array, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "nodeform-search-cell",
                on: {
                  click: function ($event) {
                    return _vm.chooseHandle(item)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }