var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible(_vm.stage),
          expression: "isVisible(stage)",
        },
      ],
      class: _vm.stageClass,
      style: _vm.stageStyle,
      attrs: { title: _vm.stage.nodeName },
    },
    [
      _c("div", { staticClass: "stage-name" }, [
        _vm._v(_vm._s(_vm.stage.nodeName)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }