var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible(_vm.item),
          expression: "isVisible(item)",
        },
      ],
      ref: "tearwp",
      class: { "svg-wrapper": true, active: _vm.item.showInfoCard },
      style: _vm.itemStyle,
      on: { mouseover: _vm.showHintLine, mouseout: _vm.hideHintLine },
    },
    [
      _c(
        "svg",
        {
          ref: "item",
          class: _vm.tearClass,
          attrs: { viewBox: "0 0 30 42" },
          on: {
            click: function ($event) {
              return _vm.toggleTearCard(_vm.item)
            },
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M15 3\n              Q16.5 6.8 25 18\n              A12.8 12.8 0 1 1 5 18\n              Q13.5 6.8 15 3z",
            },
          }),
          _c("text", { attrs: { x: "15", y: "26", fill: "white" } }, [
            _vm._v(_vm._s(_vm.item.simpleName)),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.item.showInfoCard,
              expression: "item.showInfoCard",
            },
          ],
          class: _vm.itemInfoCardClass,
        },
        [
          _vm.item.children
            ? _c(
                "div",
                {
                  staticClass: "has-child-item-icon",
                  on: {
                    click: function ($event) {
                      return _vm.toggleChildItem(_vm.item)
                    },
                  },
                },
                [_c("i", { class: _vm.icon })]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tear-item-fname", on: { click: _vm.openForm } },
            [_vm._v(_vm._s(_vm.item.nodeName))]
          ),
          _vm.item.avatar
            ? _c("div", { staticClass: "tear-item-avatar" }, [
                _c("img", { attrs: { src: _vm.item.avatar } }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }