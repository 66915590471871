var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "proofPeriod" },
    [
      _vm._m(0),
      _c("div", { staticClass: "inputBox border-bottom" }, [
        _c(
          "span",
          {
            staticClass: "center",
            class: { isCkecked: _vm.checkedFif },
            on: {
              click: function ($event) {
                return _vm.add(15, _vm.checkedFif)
              },
            },
          },
          [_vm._v("15")]
        ),
        _c(
          "span",
          {
            staticClass: "center",
            class: { isCkecked: _vm.checkedThi },
            on: {
              click: function ($event) {
                return _vm.add(30, _vm.checkedThi)
              },
            },
          },
          [_vm._v("30")]
        ),
        _c(
          "div",
          {
            staticClass: "time",
            on: {
              click: function ($event) {
                _vm.showTimer = true
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.calcDate))]),
            _c("i", { staticClass: "iconfont icon-calendar" }),
          ]
        ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showTimer,
            callback: function ($$v) {
              _vm.showTimer = $$v
            },
            expression: "showTimer",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: _vm.$store.state.nodeform.dateKind,
              "item-height": _vm.pickCellheight,
              "min-date": _vm.minDate,
              "cancel-button-text":
                _vm.$store.state.nodeform.dateKind == "date"
                  ? "切换为时间"
                  : "切换为日期",
            },
            on: {
              confirm: _vm.confirmDateHandle,
              cancel: _vm.cancelDateHandle,
            },
            model: {
              value: _vm.$store.state.nodeform.juZhengEndDate,
              callback: function ($$v) {
                _vm.$set(_vm.$store.state.nodeform, "juZhengEndDate", $$v)
              },
              expression: "$store.state.nodeform.juZhengEndDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _c("span", [_vm._v("举证期")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }