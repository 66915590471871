<template>
  <div>
    <div class="child-group-wrapper"
         v-for="(group, level) in leveledData"
         :key="level"
         v-show="checkGroupState(group)"
         :style="getGroupWPStyle(level)">
      <div class="connect-parent-item"
           v-if="parentItem.isInvisible !== 'YES'"
           :style="getConnectToParentStyle(parseInt(level))"></div>
      <!-- 节点组 -->
      <div class="slider-wrapper"
           :style="groupStyle(level)">
        <div class="slider-item">
          <range-input :ref="getRefName(level)"
                       :timeRange="useSelectedItem(level)"
                       :outterTranslateX="getOutterTranslateX(level)"
                       :groupLevel="parseInt(level)"
                       :sliderDateToAdd="getSliderDateToAdd(level)"
                       @sliderChange="handleSliderChange"></range-input>
        </div>
        <div :class="{'tear-child-items-wrapper':true, 'no-left-line': checkGroupHasExpandItem(group) }"
             :style="getSliderTranslateX(level)">
          <div :class="{'tear-child-item': true, active: isActive(item), selected: isSelected(item)}"
               v-for="(item, index) in group"
               :key="index"
               @click.stop="selectItem(item)"
               v-show="checkParentState(item)">
            <!-- 子节点 -->
            <!-- 节点全名 -->
            <div class="tear-child-item-fname">
              <div v-if="hasChildren(item)"
                   class="has-child-item-icon"
                   @click.stop="toggleChildItem(item)">
                <i :class="expandIcon(item)"></i>
              </div>{{item.nodeName}}
              <span class="probability">{{probability(item)}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import visibilityMixin from '~mixins/visibility'
import getNodePosTimeMixin from '~mixins/getNodePosTime'

import RangeInput from '../rangeInput'

export default {
  name: 'linked-item',
  mixins: [visibilityMixin, getNodePosTimeMixin],
  components: {
    RangeInput
  },
  props: {
    itemList: Array,
    parentItem: Object,
    parentAnchor: {
      default: 0
    }
  },
  data: function () {
    return {
      translateX: 0,
      sliderVal: {},
      selectedItemDict: {}
    }
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    leveledData () {
      const leveledDict = {}
      this.itemList.forEach(element => {
        const key = element.level
        if (!leveledDict[key]) {
          leveledDict[key] = []
        }
        leveledDict[key].push(element)
      })
      return leveledDict
    },
    oneDayWidth () {
      return (
        this.timelineWidth /
        (this.getTLRangeCrossNumber / (3600 * 1000 * 24))
      )
    },
    openChildren () {
      const expandDict = {}
      this.itemList.forEach(element => {
        const key = element.nodeId
        expandDict[key] = element.expand || false
      })
      return expandDict
    },
    selectedDict () {
      const dict = {}
      this.itemList.forEach(element => {
        const key = element.nodeId
        dict[key] = element.selected || false
      })
      return dict
    }
  },
  methods: {
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    getOffsetLeft (item) {
      const { start } = this.getTLRangeStamp
      const { nodeStatus, completeDateDisType } = item
      let _start = 0
      if (completeDateDisType === 'BEGIN_AND_END') {
        _start = item.beginTime
      } else {
        if (nodeStatus === 'COMPLETE') {
          _start = item.completeTime
        } else if (nodeStatus === 'DOWN') {
          _start = item.downTime
        } else if (nodeStatus === 'FLOW') {
          _start = item.beginTime
        }
      }
      const left =
        (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
      return left
    },
    probability (item) {
      const probability = item.probability || 0
      const pr = parseFloat(probability * 100).toFixed(2)
      const prString = `${pr}%`
      return prString
    },
    expandIcon (item) {
      const state = item.expand
      if (state) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    getSliderDateToAdd (groupLevel) {
      const daysToAdd = this.getDaysToAdd(groupLevel)
      return daysToAdd
    },
    isActive (item) {
      const state = item.expand
      return state
    },
    isSelected (item) {
      const level = item.level
      const _item = this.selectedItemDict[level]
      let isSelected = false
      if (_item && _item.nodeId === item.nodeId) {
        isSelected = true
      }
      return isSelected
    },
    hasChildren (item) {
      const childItems = this.itemList.filter(obj => {
        return obj.parentId === item.nodeId
      })
      if (childItems && childItems.length > 0) {
        return true
      } else {
        return false
      }
    },
    checkGroupHasExpandItem (group) {
      let state = true
      group.forEach(element => {
        if (element.expand) {
          state = false
        }
      })
      return state
    },
    // 检查父级是否展开
    checkGroupState (group) {
      let state = false
      group.forEach(item => {
        const pState = this.checkParentState(item)
        if (pState) {
          state = true
        }
      })
      return state
    },
    checkParentState (item) {
      // 递归检查父级节点状态
      // root节点状态为undefined
      const getParentOpenState = item => {
        const pid = item.parentId
        const pItemArr = this.itemList.filter(element => {
          return element.nodeId === pid
        })
        if (pItemArr.length > 0) {
          const parentItem = pItemArr[0]
          const parentId = parentItem.nodeId
          const openState = this.openChildren[parentId]
          if (openState === undefined) {
            return true
          } else if (openState) {
            return getParentOpenState(parentItem)
          } else {
            return false
          }
        } else {
          const openState = this.openChildren[pid]
          if (openState === undefined || openState) {
            return true
          } else {
            return false
          }
        }
      }
      const state = getParentOpenState(item)
      return state
    },
    groupStyle (level) {
      const seletedItem = this.selectedItemDict[level] || this.leveledData[level][0]
      const vItem = { nodeStatus: 'FLOW' }
      vItem.beginTime = seletedItem.endTime
      const left = this.getOffsetLeft(seletedItem)
      const vLeft = this.getOffsetLeft(vItem)
      const width = vLeft - left
      return {
        transform: `translate3d(${left}px, 0, 0)`,
        width: `${width}px`
      }
    },
    childItemStyle (item) {
      const _start = this.getTimeStamp(item.beginTime)
      const _end = this.getTimeStamp(item.endTime)
      const timeCross = _end - _start
      const width = this.timelineWidth * timeCross / this.getTLRangeCrossNumber
      const left = this.getOffsetLeft(item)
      this.translateX = left
      const translateY = '-50%'
      const style = {
        width: `${width}px`,
        transform: `translate3d(${left}px,${translateY},0)`
      }
      return style
    },
    getRefName (level) {
      return `rangeInput${level}`
    },
    getLeveledData (itemList) {
      const leveledDict = {}
      itemList.forEach(element => {
        const key = element.level
        if (!leveledDict[key]) {
          leveledDict[key] = []
        }
        leveledDict[key].push(element)
      })
      return leveledDict
    },
    getSliderTranslateX (groupLevel) {
      const sliderVal = this.sliderVal[groupLevel]
      if (sliderVal && sliderVal.pr) {
        const tranlateX = sliderVal.pr
        return {
          transform: `translate3d(${tranlateX}, 0, 0)`
        }
      } else {
        return ''
      }
    },
    getDaysToAdd (groupLevel) {
      let days = 0
      for (let i = 0; i < groupLevel; i++) {
        const sliderVal = this.sliderVal[i]
        if (sliderVal) {
          days += sliderVal.days
        }
      }
      days += this.parentAnchor
      return days
    },
    getGroupWPStyle (groupLevel) {
      // 第一个子级不偏移
      if (groupLevel === 0) {
        const days = this.parentAnchor
        if (days !== 0) {
          const left = days * this.oneDayWidth
          return {
            transform: `translate3d(${left}px, 0, 0)`
          }
        } else {
          return ''
        }
      } else {
        const days = this.getDaysToAdd(groupLevel)
        const left = days * this.oneDayWidth
        return {
          transform: `translate3d(${left}px, 0, 0)`
        }
      }
    },
    getOutterTranslateX (groupLevel) {
      if (groupLevel === 0) {
        return 0
      } else {
        const days = this.getDaysToAdd(groupLevel)
        const left = days * this.oneDayWidth
        return left
      }
    },
    getConnectToParentStyle (groupLevel) {
      let offsetLeft = 0
      let width = 0
      if (groupLevel === 0) {
        const parentItem = this.parentItem
        const childFirstItem = this.leveledData[groupLevel][0]
        const parentLeft = this.getOffsetLeft(parentItem)
        const childLeft = this.getOffsetLeft(childFirstItem)
        offsetLeft = parentLeft
        width = childLeft - parentLeft
      } else {
        const parentLevel = groupLevel - 1
        const parentFirstItem = this.leveledData[parentLevel][0]
        const childFirstItem = this.leveledData[groupLevel][0]
        const parentLeft = this.getOffsetLeft(parentFirstItem)
        const childLeft = this.getOffsetLeft(childFirstItem)
        offsetLeft = parentLeft
        width = childLeft - parentLeft
      }
      return {
        transform: `translate3d(${offsetLeft}px, 0, 0)`,
        width: `${width}px`
      }
    },
    handleSliderChange (payload) {
      // console.log(payload)
      this.$set(this.sliderVal, [payload.group], payload)
    },
    // 重置滑块偏移量
    resetDefaultSliderVal () {
      const defaultGroupSlider = {
        days: 0,
        pr: '0%'
      }
      const defaultSliderVal = {}
      const leveledData = this.getLeveledData(this.itemList)
      Object.keys(leveledData).forEach(key => {
        const tempSlider = defaultGroupSlider
        tempSlider.group = key
        defaultSliderVal[key] = tempSlider
      })
      this.sliderVal = defaultSliderVal
    },
    // 父级节点如果是FLOW则 不 打开表单 如果是DOWN或COMPLETE 则允许第一个子级打开表单
    passDataToForm (item) {
      const { nodeStatus, isInvisible } = this.parentItem
      if (nodeStatus !== 'FLOW' || isInvisible === 'YES') {
        const level = item.level
        if (level === 0) {
          // 传递给表单的日期数据是 父级和本身滑块值累加 + 节点的日期
          const daysToAdd = this.getDaysToAdd(level + 1)
          const formTime = daysToAdd * 3600 * 24 * 1000 + item.beginTime
          const caseId = this.$route.params.caseId
          const { nodeId, nodeFormType, nodeName: title } = item
          const formData = {
            type: nodeFormType,
            startDate: formTime,
            caseId,
            nodeId,
            title
          }
          this.showNodeForm(formData)
        }
      }
    },
    // 使用选中的节点数据 需要判断选中的是否存在数组中
    useSelectedItem (level) {
      const selectedItem = this.selectedItemDict[level]
      if (selectedItem) {
        const { caseNodeId } = selectedItem
        let inDataList = false
        this.leveledData[level].forEach(item => {
          if (item.caseNodeId === caseNodeId) {
            inDataList = true
          }
        })
        if (inDataList) {
          return selectedItem
        } else {
          this.selectedItemDict = {}
          return this.useExpandedItem(level)
        }
      } else {
        return this.useExpandedItem(level)
      }
    },
    useExpandedItem (level) {
      const expandedItem = this.leveledData[level].filter(item => {
        return item.expand
      })
      if (expandedItem.length === 1) {
        return expandedItem[0]
      } else {
        return this.leveledData[level][0]
      }
    },
    // 展开收缩子级
    toggleChildItem (item, autoSelect = true) {
      this.selectItem(item, false)
      this.$emit('toggleChild', item)
      this.autoSelectItem(item, autoSelect)
    },
    autoSelectItem (item, autoSelect) {
      const { level, nodeId } = item
      const newSelectedDitc = {}
      Object.keys(this.selectedItemDict).forEach(element => {
        if (element.level < level) {
          newSelectedDitc[level] = element
        }
      })
      if (autoSelect) {
        const childrenItems = this.leveledData[level + 1].filter(item => {
          return item.parentId === nodeId
        })
        if (childrenItems.length) {
          this.selectItem(childrenItems[0], false)
        }
      }
    },
    // 点击节点设为选中状态 如果已经选中则打开表单
    selectItem (item, openForm = true) {
      const { level, caseNodeId } = item
      const _item = this.selectedItemDict[level]
      const refName = `rangeInput${level}`
      this.$refs[refName][0].setDefaultVal()
      if (_item && _item.caseNodeId === caseNodeId) {
        // console.log('打开表单')
        if (openForm) this.passDataToForm(item)
      } else {
        this.hideSameGroupChildren(item)
        this.$set(this.selectedItemDict, level, item)
      }
    },
    // 关闭同级展开的节点
    hideSameGroupChildren (item) {
      const { caseNodeId, level } = item
      const itemArr = this.leveledData[level]
      itemArr.forEach(item => {
        if (item.caseNodeId !== caseNodeId && item.expand) {
          this.toggleChildItem(item, false)
        }
      })
    }
  },
  mounted () {
    this.resetDefaultSliderVal()
  }
}
</script>

<style lang="stylus" scpoped>
.child-group-wrapper
  position relative
  box-sizing border-box
  .connect-parent-item
    position absolute
    height 15px
    &:before
      height 0.026667rem
      top 0
      right 0
      width 100%
      position absolute
      content ''
      background-color #f6b536
    &:after
      width 0.026667rem
      left 0
      top -100%
      height 100%
      position absolute
      content ''
      background-color #f6b536
  .slider-wrapper
    // background rgba(38, 162, 255,0.5)
    .slider-item
      // height 20px
      // background #fff
.tear-child-items-wrapper
  border-left 1px solid #f6b536
  padding 0 0 20px 4px
  box-sizing border-box
.no-left-line
  border-left 1px solid transparent
.tear-child-item
  // background #c9c9c9
  color #fff
  height 28px
  line-height 28px
  box-sizing border-box
  margin 2px 0 0 0
  display flex
  max-width 4rem
  .tear-child-item-fname
    border 1px dashed #dedede
    box-sizing border-box
    padding 0 6px 0 6px
    color #666
    display flex
    white-space nowrap
    font-size 14px
    border-radius 2px
    word-break keep-all
    overflow visible
    cursor pointer
    .probability
      margin-left 10px
    .has-child-item-icon
      i
        font-size 20px
        color #666
  &.selected .tear-child-item-fname
    border 1px solid #f6b536
  &.active .tear-child-item-fname
    border 1px solid #f6b536
.group-connector
  position absolute
  box-sizing border-box
  height 100%
</style>
