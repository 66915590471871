var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-tab" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoading,
              expression: "showLoading",
            },
          ],
          staticClass: "task-loading-wrapper",
        },
        [_c("van-loading")],
        1
      ),
      _vm._l(_vm.jiedianList, function (val) {
        return _c("node", {
          key: val.nodeId,
          ref: "node",
          refInFor: true,
          attrs: {
            val: val,
            isshow: _vm.isshow,
            taskjiedian: _vm.taskJiedian,
            jiedianList: _vm.jiedianList,
          },
          on: {
            showDeleteOverBuilt: _vm.shownode,
            closeDeleteOverBuilt: _vm.closenode,
          },
        })
      }),
      _vm._l(_vm.task, function (item, index) {
        return _c("nonode", {
          key: index,
          ref: "nodelist",
          refInFor: true,
          attrs: { item: item, in: index },
          on: { DeleteOverBuilt: _vm.showDeleteOverBuilt },
        })
      }),
      _c("van-list", {
        attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
        on: { load: _vm.onLoad },
        model: {
          value: _vm.loading,
          callback: function ($$v) {
            _vm.loading = $$v
          },
          expression: "loading",
        },
      }),
      _c("div", { staticClass: "task-kongbai" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }