export default {
  methods: {
    isVisible (item) {
      const nodeType = item.nodeType
      const nodeStatus = item.nodeStatus
      let _start = 0
      let _end = 0
      if (nodeType === 'STAGE_NODE') {
        _start = item.beginTime
        _end = item.endTime ? item.endTime : _start
      } else {
        if (nodeStatus === 'COMPLETE') {
          _start = item.completeTime
          _end = _start
        } else if (nodeStatus === 'DOWN') {
          _start = item.downTime
          _end = _start
        } else if (nodeStatus === 'FLOW') {
          _start = item.beginTime
          _end = item.endTime ? item.endTime : _start
        }
      }

      const { start, end } = this.getTLRangeStamp
      // 起始时间在range的end之后 超出范围
      const isSAfterEnd = _start - end
      // 结束时间在range的start之前 超出范围
      const isEBeforeStart = start - _end
      if (isSAfterEnd >= 0 || isEBeforeStart >= 0) {
        return false
      } else {
        return true
      }
    }
  }
}
