<template>
  <div class="preservation-tab">
    <under-dev />
  </div>
</template>

<script>
export default {
  name: 'preservation-tab'
}
</script>

<style>
</style>
