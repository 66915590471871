<template>
  <div id="timeline">
    <scroll-menu ref="scrollmenu" :showLeftMenu="false" :showRightMenu="true" initialHeight="NORMAL" :resumeTab="true" :tid="tid" @addCoop="openAddCoopPopup" :style="{ width: '100%' }" />
    <fb-loading :loading="isLoading" />
    <van-popup v-model="showAddCoop" position="top" :overlay="false">
      <div class="top-notify-with-action">
        <div class="notice">
          该案件未添加协作人
          <span class="tap-text" @click="openAddCoopForm">点击添加</span>
        </div>
        <!-- <div class="close" @click="closeAddCoopPopup">
          <i class="iconfont icon-quxiao"></i>
        </div> -->
      </div>
    </van-popup>
    <!-- 案情概要 -->
    <ana-summary />
    <div v-show="timelineInitialized" id="backToTodayBtnDetail" :style="backToTodayBtnStyle" @click="backToToday">今天</div>
    <tool-bar v-show="timelineInitialized"></tool-bar>
    <div id="items-area" @touchstart.passive="onTouchStart" @touchmove.passive="onTouchMove" @touchend.passive="onTouchEnd">
      <div class="stages-wrapper">
        <div class="top-item-connector-top" v-if="showTopItemConnector" :style="topItemConnectorTopStyle"></div>
        <stage-item ref="stageItem" v-for="stage in stageNodeList" :key="stage.caseNodeId" :stage="stage"></stage-item>
        <section-item ref="sectionItem" v-for="item in sectionNodeList" @showChildItem="handleShowChildItem" @showInfoCard="handleShowInfoCard" :key="item.caseNodeId" :item="item"></section-item>
        <round-item ref="roundItem" v-for="item in roundNodeList" @showChildItem="handleShowChildItem" @showInfoCard="handleShowInfoCard" :key="item.nodeId" :item="item"></round-item>
        <tear-item @showChildItem="handleShowChildItem" @showInfoCard="handleShowInfoCard" v-for="tear in topTearNodeList" :key="tear.caseNodeId" :item="tear"> </tear-item>
        <remind-item v-for="(remind, i) in remindList" :key="i" @openRemind="handleOpenRemindForm" :data="remind" />
      </div>
      <div ref="itemsArea" class="scroll-wrapper">
        <div class="scroll-content">
          <div class="top-item-connector-bottom" v-if="showTopItemConnector" :style="topItemConnectorBottomStyle"></div>

          <div class="tears-wrapper" v-if="hasTearItem">
            <tear-item ref="tearItem" @showChildItem="handleShowChildItem" @showInfoCard="handleShowInfoCard" v-for="tear in tearNodeList" :key="tear.nodeId" :item="tear"> </tear-item>
          </div>
          <div class="children-wrapper">
            <linked-item ref="linkedItem" :itemList="topLinkedList" :parentItem="topParentItem" @toggleChild="handleToggleLinkedChild"> </linked-item>
          </div>
          <div class="flow-items-wrapper">
            <flow-item v-for="item in flowNodeList" :key="item.nodeId" :item="item"> </flow-item>
          </div>
        </div>
      </div>
    </div>
    <nodeform v-if="$store.state.nodeform.nodeform" />
    <remind-form v-if="$store.state.remindForm.remind" />
    <van-popup v-model="showAddCoopForm" class="add-coop-popup">
      <search-cooperation addCooperation="1" :propCaseId="caseId" @added="onAddCoopSuccess" @cancel="showAddCoopForm = false" />
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { caseBase } from '~api-config'

import BScroll from 'better-scroll'
import StageItem from './components/stageItem'
import SectionItem from './components/sectionItem'
import RoundItem from './components/roundItem'
import TearItem from './components/tearItem'
import ToolBar from './components/toolBar'
import LinkedItem from './components/linkedItem'
import FlowItem from './components/flowItem'
import RemindItem from './components/remindItem'

import Nodeform from '~components/nodeform'
import RemindForm from '~components/remindForm'

import AnaSummary from './components/anaSummary'
import ScrollMenu from '../../components/scrollMenu'

import SearchCooperation from '@/components/nodeform/searchCooperation'

export default {
  name: 'timeline-detail',
  components: {
    Nodeform,
    StageItem,
    TearItem,
    SectionItem,
    RoundItem,
    LinkedItem,
    FlowItem,
    RemindItem,
    ToolBar,
    RemindForm,
    AnaSummary,
    ScrollMenu,
    SearchCooperation,
  },
  data() {
    return {
      showAddCoop: false,
      showAddCoopForm: false,
      timelineInitialized: false,
      touch: {
        lastRange: {},
        startX: 0,
        isMove: false,
      },
      // 案件基本信息
      caseBasicInfo: {},
      // 阶段类型
      stageNodeList: [],
      // 顶部子级节点的父级数据
      topParentItem: {},
      // 顶部子级节点数据
      topLinkedList: [],
      // DOWN水滴类型 和 COMPLETE圆形 因为共用一个子级放到一个数组里
      topNodeList: [],
      // DOWN和COMPLETE子级
      topChildenNodeList: [],
      // FLOW漂浮类型
      flowNodeList: [],
      // 证据id
      evidenceId: 0,
      // 提醒节点
      remindList: [],
      // 案件阶段面板节点列表
      panelNodeList: [],
      // 正在加载数据
      isLoading: false,
      accidsAndTid: {},
    }
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: (state) => state.TLRange,
      timelineWidth: (state) => state.TLWidth,
      timelineHeight: (state) => state.TLHeight,
      timelineMode: (state) => state.TLMode,
      timelineTouching: (state) => state.TLTouching,
      timelineCanMove: (state) => state.TLCanMove,
      today: (state) => state.today,
      showTopItemConnector: (state) => state.showTopItemConnector,
      topItemConnnectorPosTime: (state) => state.topItemConnnectorPosTime,
    }),
    ...mapState('user', {
      userId: (s) => s.userInfo.id,
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getBackToTodayTranslateX', 'getTLRangeCrossNumber']),
    ...mapState('nodeform', ['dateNow']),
    ...mapState('remindForm', ['remindFormUpdata']),
    backToTodayTranslateX() {
      return this.getBackToTodayTranslateX
    },
    // 时间轴初始化配置
    options() {
      const options = {
        orientation: {
          axis: 'top',
          item: 'bottom',
        },
        zoomable: false,
        start: 1530403200000, // 2018-07-01
        end: 1533427200000, // 2018-08-05
        showCurrentTime: false,
        showMajorLabels: true,
        height: '100%',
        moment: function (date) {
          return window.vis.moment(date).utcOffset('00:00')
        },
      }
      return options
    },
    caseId() {
      return this.$route.params.caseId
    },
    // 水滴类型 今天之后
    tearNodeList() {
      // console.log(this.tearNodeList)

      if (this.topNodeList) {
        const tearArr = this.topNodeList.filter((item) => {
          const isAfterToday = item.completeTime && item.completeTime - this.today > 0
          return isAfterToday
        })
        return tearArr
      } else {
        return []
      }
    },
    // 圆形类型 今天之前
    roundNodeList() {
      if (this.topNodeList) {
        const roundArr = this.topNodeList.filter((item) => {
          const isbeforeToday = item.completeTime && item.completeTime - this.today <= 0
          return isbeforeToday
        })
        return roundArr
      } else {
        return []
      }
    },
    topTearNodeList() {
      if (this.topNodeList && this.topNodeList.length) {
        return this.topNodeList
          .filter((item) => {
            return item.nodeStatus === 'FLOW'
          })
          .sort(this.sortPanelNodeItem)
      } else {
        return []
      }
    },
    // 段类型
    sectionNodeList() {
      if (this.topNodeList) {
        const roundArr = this.topNodeList.filter((item) => {
          const isbeforeToday = item.completeDateDisType === 'BEGIN_AND_END'
          return isbeforeToday
        })
        return roundArr
      } else {
        return []
      }
    },
    // 返回今天按钮样式
    backToTodayBtnStyle() {
      return `transform: translate3d(${this.backToTodayTranslateX}px, 4px, 0)`
    },
    // 是否有水滴
    hasTearItem() {
      this.setitems(this.tearNodeList)
      // this.$store.commit.caseDetail.item = this.tearNodeList
      if (this.tearNodeList.length) {
        return true
      } else {
        return false
      }
    },
    // 最顶部节点 与子级的连线样式
    topItemConnectorBottomStyle() {
      const { start } = this.getTLRangeStamp
      const offsetLeft = ((this.topItemConnnectorPosTime - start) / this.getTLRangeCrossNumber) * this.timelineWidth
      if (offsetLeft <= 0 || offsetLeft >= this.timelineWidth || !this.hasTearItem) {
        return 'display: none'
      } else {
        return `transform: translate3d(${offsetLeft}px, 0px, 0)`
      }
    },
    topItemConnectorTopStyle() {
      const { start } = this.getTLRangeStamp
      const offsetLeft = ((this.topItemConnnectorPosTime - start) / this.getTLRangeCrossNumber) * this.timelineWidth
      if (offsetLeft <= 0 || offsetLeft >= this.timelineWidth) {
        return 'display: none'
      } else {
        return `transform: translate3d(${offsetLeft}px, 0.95rem, 0)`
      }
    },
    tid() {
      return this.accidsAndTid.tids ? this.accidsAndTid.tids : this.caseBasicInfo.tId
    },
  },
  methods: {
    ...mapMutations('caseDetail', [
      'setCaseTitle',
      'setTLRange',
      'setTLWidth',
      'setTLHeight',
      'setTLMode',
      'setTLTouching',
      'setToday',
      'setDocId',
      'setEvidenceId',
      'setAccidsAndTid',
      'setShowTopItemConnector',
      'setTabAnaSummary',
      'setitems',
    ]),
    ...mapMutations('remindForm', ['openRemindForm']),
    ...mapMutations('ana', ['SET_ANA_KV']),
    // 初始化容器
    initContainer() {
      this.$nextTick(() => {
        const container = document.getElementById('timeline')
        const offsetTop = container.offsetTop
        // 宽高单位： px
        const TL_W = document.documentElement.clientWidth
        const screenHeight = document.documentElement.clientHeight
        const TL_H = screenHeight - offsetTop
        this.options.height = TL_H + 'px'

        // 将宽高存到vuex
        this.setTLWidth(TL_W)
        this.setTLHeight(TL_H)

        // 用BS设置滚动
        const options = {
          click: true,
          bounce: false,
        }
        const itemsArea = this.$refs.itemsArea
        const itemsAreaOffsetTop = itemsArea.getBoundingClientRect().top
        const itemsAreaHeight = screenHeight - itemsAreaOffsetTop
        itemsArea.setAttribute('style', `height:${itemsAreaHeight}px`)
        setTimeout(() => {
          this.scroll = new BScroll(itemsArea, options)
        }, 20)
      })
    },
    // 获取案件列表基本信息
    fetchCaseData() {
      // 加载提示
      this.isLoading = true
      const caseId = this.caseId
      this.$axios
        .get(`${caseBase}/lts/case/${caseId}/caseNodesByCaseId`)
        .then((res) => {
          // console.log('==================')
          this.isLoading = false
          if (res && res.data) {
            const { message, code } = res.data
            if (Number(code) === 200) {
              this.getCaseInfoSucc(res)
            } else if (Number(res.status) === 500) {
              this.$notify('服务器错误')
            } else {
              this.$notify(message)
            }
          } else {
            this.$notify('接口未返回数据')
          }
          // if (res.status === 200 && res.data.code === '200') {
          //   this.$store.state.ana.caseName =
          //     res.data.data.caseBasicInfo.causeName
          //   this.$store.state.caseDetail.tabAnaSummaryChange = Date.now()
          //   this.getCaseInfoSucc(res)
          // } else {
          //   this.$toast.fail({
          //     message: '获取案件基本信息失败',
          //     duration: 1000
          //   })
          // }
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          // Promise.reject(err)
        })
    },
    getCaseInfoSucc(res) {
      // 1.将基本数据赋值给组件内
      const data = res.data ? res.data.data : null
      if (data) {
        window.rawCaseData = data
        const bi = data.caseBasicInfo
        this.caseBasicInfo = bi
        const { causeName } = bi
        this.SET_ANA_KV({ key: 'caseName', val: causeName })
        this.SET_ANA_KV({ key: 'tabAnaSummaryChange', val: Date.now() })

        // 2.将信息存到store
        const caseTitle = this.caseBasicInfo.title
        this.setCaseTitle(caseTitle)
        const eid = data.fileId['证据']
        const docId = data.fileId['文书']
        this.setToday(data.today)
        this.setDocId(docId)
        this.setEvidenceId(eid)
        this.evidenceId = eid
        this.accidsAndTid = data.accidsAndTid
        this.setAccidsAndTid(data.accidsAndTid)
        // 设置案情概要
        this.setTabAnaSummary(this.caseBasicInfo.abstract || '')

        // 对数据进行处理
        this.transformNodeListData(data.caseNodeList)
      }

      // 3.初始化时间轴
      this.initTimeline()
    },
    initTimeline() {
      // 重新获取节点数据后初会重新始化时间轴 所以先将上一次时间轴释放
      if (window.TLDetail) {
        window.TLDetail.destroy()
        window.TLDetail = null
      }
      const container = document.getElementById('timeline')
      const oneDayInMS = 3600 * 1000 * 24
      const start = this.getWholeDayInMS(this.today)
      let end = 0
      switch (this.timelineMode) {
        case 'a':
          end = start + 7 * oneDayInMS
          break
        case 'b':
          end = start + 30 * oneDayInMS
          break
        case 'c':
          end = start + 90 * oneDayInMS
          break
        case 'd':
          end = start + 365 * oneDayInMS
          break
      }
      const offset = parseInt((this.backToTodayTranslateX / this.timelineWidth) * (end - start))
      this.options.start = start - offset
      this.options.end = end - offset
      window.TLDetail = new window.vis.Timeline(container, [], this.options)
      this.timelineInitialized = true
      window.TLDetail.on('changed', () => {
        const windowRange = window.TLDetail.getWindow()
        this.syncWindowRange(windowRange)
      })
    },
    syncWindowRange(windowRange) {
      this.setTLRange(windowRange)
    },
    getTimeStr(timeStamp) {
      return window.moment(timeStamp).format('YYYY-MM-DD')
    },
    getTimeStamp(Str) {
      return new Date(Str).getTime()
    },
    onTouchStart(e) {
      this.touch.lastRange = this.timelineRange
      this.touch.startX = e.targetTouches[0].clientX
    },
    onTouchMove(e) {
      const diffX = e.targetTouches[0].clientX - this.touch.startX
      if (diffX !== 0) {
        // console.log('touching')
        this.setTLTouching(true)
        this.touch.isMove = true
      }
      const { start, end } = this.touch.lastRange
      const _start = this.getTimeStamp(start)
      const _end = this.getTimeStamp(end)
      const diffTime = Math.floor(((_end - _start) * diffX) / this.timelineWidth)
      const newStart = _start - diffTime
      const newEnd = _end - diffTime
      if (this.timelineCanMove && window.TLDetail) {
        window.TLDetail.setWindow(newStart, newEnd, { animation: false })
      }
    },
    // 时间轴触摸结束
    onTouchEnd(e) {
      if (this.touch.isMove) {
        setTimeout(() => {
          this.setTLTouching(false)
        }, 600)
      } else {
        this.setTLTouching(false)
      }
    },
    // 时间轴视窗范围缩小
    zoomIn() {
      if (window.TLDetail) {
        window.TLDetail.zoomIn(0.2)
      }
    },
    // 时间轴视窗范围放大
    zoomOut() {
      if (window.TLDetail) {
        window.TLDetail.zoomOut(0.2)
      }
    },
    // 去掉时分秒 只保留到 一天的开始（0点）
    getWholeDayInMS(time) {
      const timeSetter = { hour: 0, minute: 0, second: 0, millisecond: 0 }
      const todayOfWholeDay = new Date(window.moment(time).set(timeSetter)).getTime()
      return todayOfWholeDay
    },
    // 返回今天
    backToToday() {
      const { start, end } = window.TLDetail.getWindow()
      const windowCross = new Date(end).getTime() - new Date(start).getTime()
      const beforeToday = (this.backToTodayTranslateX / this.timelineWidth) * windowCross
      const todayOfWholeDay = this.getWholeDayInMS(this.today)
      const tlStart = todayOfWholeDay - beforeToday
      const tlEnd = windowCross + tlStart
      window.TLDetail.setWindow(tlStart, tlEnd)
    },
    // 根据 queue 字段对 漂浮节点进行排序
    sortFlowItem(obj1, obj2) {
      var val1 = obj1.queue || 999
      var val2 = obj2.queue || 999
      if (val1 < val2) {
        return -1
      } else if (val1 > val2) {
        return 1
      } else {
        return 0
      }
    },
    // 根据开始时间对阶段节点排序
    sortStageItem(obj1, obj2) {
      var val1 = obj1.beginTime || 999
      var val2 = obj2.beginTime || 999
      if (val1 < val2) {
        return -1
      } else if (val1 > val2) {
        return 1
      } else {
        return 0
      }
    },
    // 面板节点排序
    sortPanelNodeItem(obj1, obj2) {
      var val1 = obj1.completeTime || obj1.beginTime || 999
      var val2 = obj2.completeTime || obj2.beginTime || 999
      if (val1 < val2) {
        return -1
      } else if (val1 > val2) {
        return 1
      } else {
        return 0
      }
    },
    // 对获取到的数据进行分组和添加额外信息
    transformNodeListData(allNodeList) {
      // 1.刷选数据 -> 2.添加前端需要的状态字段
      // 普通节点
      const normalNodeList = allNodeList.filter((item) => {
        return (item.nodeType === 'NORMAL_NODE' || item.nodeType === 'SPECIAL_NODE') && item.defaultShowStatus === 'YES'
      })
      // 节点节点
      const stageNodeList = allNodeList.filter((item) => {
        return item.nodeType === 'STAGE_NODE' && item.defaultShowStatus === 'YES'
      })
      this.stageNodeList = stageNodeList.sort(this.sortStageItem)
      // console.log(this.stageNodeList)

      // topNodeList
      const rawTopNodeList = normalNodeList.filter((item) => {
        const isTopCompleteNode = (item.nodeStatus === 'DOWN' || item.nodeStatus === 'COMPLETE') && item.defaultShowStatus === 'YES'
        const isTopFlowNode = item.nodeStatus === 'FLOW' && item.backgroundDefault === 'YES' && item.defaultShowStatus === 'YES'
        return isTopCompleteNode || isTopFlowNode
      })
      const topNodeList = this.addExtraKey(rawTopNodeList)
      this.topNodeList = topNodeList

      // panelNodelist
      this.panelNodeList = this.topNodeList
        .filter((item) => {
          return item.nodeStatus === 'FLOW'
        })
        .sort(this.sortPanelNodeItem)
      // flowNodeList
      const rawFlowNodeList = normalNodeList.filter((item) => {
        return item.nodeStatus === 'FLOW' && item.defaultShowStatus === 'YES' && item.beginTime !== null && item.endTime !== null && item.backgroundDefault !== 'YES'
      })
      this.flowNodeList = this.addExtraKey(rawFlowNodeList).sort(this.sortFlowItem)

      // 默认不显示的节点数据
      const rawHiddenNodeList = allNodeList.filter((item) => {
        return item.defaultShowStatus === 'NO'
      })
      this.hiddenNodeList = rawHiddenNodeList
    },

    // 添加前端需要的状态字段
    addExtraKey(arr) {
      const newArr = []
      const a = JSON.parse(JSON.stringify(arr))
      a.forEach((item) => {
        const tempItem = item
        if (item.expand === 'YES' && item.children) {
          tempItem.showInfoCard = true
          tempItem.expand = true
        } else {
          tempItem.showInfoCard = false
          tempItem.expand = false
        }
        newArr.push(tempItem)
      })
      return newArr
    },
    // 给子节点分级
    getLeveled(itemsArr) {
      const getLevel = (item, level = 0) => {
        const pItem = itemsArr.filter((obj) => {
          return obj.nodeId === item.parentId
        })
        if (pItem && pItem.length === 0) {
          return level
        } else {
          level += 1
          return getLevel(pItem[0], level)
        }
      }
      const leveledArr = []
      itemsArr.forEach((item) => {
        const level = getLevel(item)
        item.level = level
        item.anchor = item.beginTime
        leveledArr.push(item)
      })
      return leveledArr
    },
    getShowSingleInfoCardData(dataList, itemId, action) {
      const oldDataList = [...dataList]
      const newData = []
      oldDataList.forEach((item) => {
        const tempItem = JSON.parse(JSON.stringify(item))
        tempItem.showInfoCard = false
        if (item.nodeId === itemId && action === 'show') {
          tempItem.showInfoCard = true
        }
        newData.push(tempItem)
      })
      return newData
    },
    // 处理 顶部节点显示 信息卡
    handleShowInfoCard(payload) {
      const newData = this.getShowSingleInfoCardData(this.topNodeList, payload.item.nodeId, payload.action)
      this.$set(this.$data, 'topNodeList', newData)
    },
    // 处理顶部节点显示子节点
    handleShowChildItem(payload) {
      this.$set(this.$data, 'topParentItem', payload.item)
      if (payload.action === 'show') {
        const { children } = payload.item
        if (children) {
          const leveled = this.getLeveled(children)
          const keyed = this.addExtraKey(leveled)
          this.topLinkedList = keyed
        }
      } else {
        // 隐藏子节点时 复位第一个分组的滑块偏移量
        const linkedItem = this.$refs.linkedItem
        linkedItem.resetDefaultSliderVal()
        // 清空子节点数据
        this.topLinkedList = []
      }
    },
    // 处理顶部节点的子节点 收缩子节点
    handleToggleLinkedChild(tItem) {
      const newLinkedItems = []
      const oldLinkedItems = JSON.parse(JSON.stringify(this.topLinkedList))
      const caseNodeId = tItem.caseNodeId
      oldLinkedItems.forEach((elem) => {
        const item = JSON.parse(JSON.stringify(elem))
        const _caseNodeId = item.caseNodeId
        if (_caseNodeId === caseNodeId) {
          // 如果当前项未展开
          if (!item.expand) {
            const level = item.level
            oldLinkedItems.forEach((element) => {
              if (element.level === level && element.caseNodeId !== _caseNodeId) {
                element.expand = false
              }
            })
          }
          item.expand = !item.expand
        }
        newLinkedItems.push(item)
      })
      this.topLinkedList = newLinkedItems
    },
    // 拍照
    addEvidenceResultCallback(data) {
      console.log(data)
      // TODO 根据返回状态执行响应操作
    },
    // 拍照
    takePhoto() {
      // 将当前路径传给安卓端，等安卓拍照上传完成之后再跳转回来
      const caseId = parseInt(this.caseId)
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showCamera(caseId, parseInt(this.evidenceId))
        } else if (u === 'fb-flutter-Webview') {
          window.showCamera.postMessage(
            JSON.stringify({
              caseId: caseId,
              pId: this.evidenceId,
            })
          )
        } else if (u === 'fb-iOS-Webview') {
          window.webkit.messageHandlers.showCamera.postMessage({
            caseId,
            pId: this.evidenceId,
          })
        } else {
          this.$toast('非原生环境 不支持打开相机')
        }
      } catch (err) {
        console.log('showCamera.postMessage', err)
      }
    },
    // 获取提醒数据
    getRemindData() {
      const caseId = this.caseId
      this.$axios
        .get(`${caseBase}/lts/remind/case/${caseId}`)
        .then((res) => {
          if (res.status === 200 && res.data.code === '200') {
            this.loading = false
            this.getRemindInfoSucc(res)
          } else if (res.status === '500') {
            this.loading = false
            this.$message.error('服务器错误')
          } else {
            this.loading = false
            this.getDataFailed('获取数据失败')
          }
        })
        .catch((err) => {
          this.loading = false
          Promise.reject(err)
        })
    },
    // 获取提醒数据回调
    getRemindInfoSucc(res) {
      this.remindList = res.data.data
    },
    // 处理打开提醒表单
    handleOpenRemindForm(payload) {
      // console.log(payload)
      const nodeData = window.rawCaseData
      this.openRemindForm({
        status: true,
        nodeData,
        remindData: payload,
      })
    },
    openAddCoopPopup() {
      this.showAddCoop = true
      setTimeout(() => {
        this.showAddCoop = false
      }, 2000)
    },
    closeAddCoopPopup() {
      this.showAddCoop = false
    },
    openAddCoopForm() {
      this.showAddCoopForm = true
    },
    // 成功添加写作人回调
    onAddCoopSuccess(payload) {
      console.log('add coop success：', payload)
      this.fetchCaseData()
    },
    // 清除
    clearAnaStoreData() {
      this.SET_ANA_KV({ key: 'caseName', val: '' })
      this.SET_ANA_KV({ key: 'tabAnaSummary', val: '' })
      this.SET_ANA_KV({ key: 'tabAnaSummaryChange', val: 0 })
      this.SET_ANA_KV({ key: 'tabAnaSummaryChange2', val: 0 })
    },
  },
  created() {
    // 隐藏顶部节点与子级的连线
    this.setShowTopItemConnector(false)
    // 拍照回调 2019.1.4 去掉拍照功能
    // window.addEvidenceResult = this.addEvidenceResultCallback
  },
  destroyed() {
    // console.log('111111111111111111111111111111111111生命周期销毁')
    this.setShowTopItemConnector(false)
    window.rawCaseData = {}
    if (window.TLDetail) {
      window.TLDetail = null
    }
    this.clearAnaStoreData()
  },
  mounted() {
    // 初始化时间轴容器并设置滚动区域
    this.initContainer()
    // 获取案件数据
    this.fetchCaseData()

    // 获取提醒数据
    this.getRemindData()
  },
  watch: {
    // 监听节点表单时间值 改变则重新请求数据
    dateNow(ov, nv) {
      // 保留原提醒数据
      const oldRemind = this.remindList
      Object.assign(this.$data, this.$options.data())
      this.setShowTopItemConnector(false)
      setTimeout(() => {
        this.fetchCaseData()
        this.remindList = oldRemind
      }, 300)
    },
    // 监听提醒表单变化 改变则重新获取提醒数据
    remindFormUpdata(ov, nv) {
      setTimeout(() => {
        this.getRemindData()
      }, 300)
    },
  },
}
</script>

<style lang="stylus">
#timeline
  overflow-y hidden
  .loading-wrapper
    display flex
    justify-content center
    align-items center
    height 80px
  #backToTodayBtnDetail
    background #399cf3
    display inline-block
    position absolute
    border-radius 2px
    color #fff
    z-index 3
  .take-photo
    position fixed
    bottom 5px
    left 50%
    transform translate3d(-50%, 0, 0)
    width 50px
    height 50px
    border-radius 50%
    background rgba(255, 255, 255, 0.9)
    box-shadow 0px 0px 10px rgba(163, 163, 163, 0.7)
    z-index 5
    text-align center
    line-height 50px
    i
      font-size 26px
      color #379DD3
  .cooperation
    position fixed
    bottom 5px
    left 10px
    z-index 5
  .vis-item.vis-range.BG-transparent
    background transparent
    top 3px !important
  .vis-item.vis-background.BG-brown
    background rgba(247, 150, 70, 0.5)
  .vis-item.vis-background.BG-pink
    background rgba(0, 110, 255, 1)
  .vis-item.vis-background.BG-gray
    background rgba(229, 229, 229, 1)
  .vis-timeline, .vis-panel.vis-top, .vis-panel.vis-center
    border none
  .vis-today
    background #ffb9b9
  .vis-grid.vis-saturday, .vis-grid.vis-sunday
    background #eee
  #items-area
    position absolute
    transform translate3d(0, 1.28rem, 0)
    width 100%
    // height 100%
    z-index 2
    overflow-y scroll
    .stages-wrapper
      height 46px
      position relative
      box-sizing border-box
      z-index 4
    .tears-wrapper
      height 42px
    .children-wrapper
      // background: rgba(38, 255, 160, 0.2);
    .flow-items-wrapper
      // background: rgba(246, 181, 54, 0.2);
#timeline
  *
    user-select none
.top-item-connector-top
  z-index 4
  border-top 0
  border-bottom 0
  height 10px
  width 0
  position absolute
  overflow hidden
  border-left 1px solid #f6b536
  box-sizing border-box
.top-item-connector-bottom
  z-index 4
  border-top 0
  border-bottom 0
  width 0
  height 37px
  position absolute
  overflow hidden
  border-left 1px solid #f6b536
  box-sizing border-box
.top-notify-with-action
  height 40px
  background #F7A750
  color #ffffff
  line-height 40px
  font-size 15px
  display flex
  justify-content center
  .notice
    padding-left 10px
    height 100%
    flex 1
    .tap-text
      font-weight bold
      letter-spacing 2px
      text-decoration underline
  .close
    width 34px
    height 100%
    i
      font-size 18px
.add-coop-popup
  background transparent
  border-radius 4px
  height 100vh
  width 100vw
</style>
