var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSection
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "remind-section-casedetail",
            style: _vm.remindScetionStyle,
          },
          [
            _c("div", { staticClass: "left", on: { click: _vm.handleClick } }, [
              _vm._v(_vm._s(_vm.simpleName)),
            ]),
            _c(
              "div",
              { staticClass: "right", on: { click: _vm.handleClick } },
              [_vm._v(_vm._s(_vm.simpleName))]
            ),
          ]
        ),
      ])
    : _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShow,
              expression: "isShow",
            },
          ],
          staticClass: "remind-container-casedetail",
          style: _vm.tearContainerStyle,
        },
        [
          _c(
            "div",
            {
              ref: "remindItem",
              class: _vm.remindItemClass,
              on: { click: _vm.handleClick },
            },
            [_vm._v(_vm._s(_vm.simpleName))]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }