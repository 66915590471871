var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "searchCooperation-box" }, [
    _c("div", { staticClass: "searchCooperation" }, [
      _c("div", { staticClass: "searchCooperation-title" }, [
        _vm._v(" 添加协作人 "),
      ]),
      _c("div", { staticClass: "searchCooperation-search" }, [
        _c("label", {
          staticClass: "iconfont icon-search",
          attrs: { for: "searchCooperation-search-input" },
          on: { click: _vm.search },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputValue,
              expression: "inputValue",
            },
          ],
          attrs: {
            type: "text",
            id: "searchCooperation-search-input",
            placeholder: "搜索",
          },
          domProps: { value: _vm.inputValue },
          on: {
            focus: _vm.getfocus,
            blur: _vm.blur,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.inputValue = $event.target.value
            },
          },
        }),
        _c("div", { staticClass: "searchCooperation-select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchType,
                  expression: "searchType",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.searchType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "1" } }, [_vm._v("律所")]),
              _c("option", { attrs: { value: "3" } }, [_vm._v("用户名")]),
              _c("option", { attrs: { value: "2" } }, [_vm._v("手机号")]),
            ]
          ),
        ]),
        _vm.numlost
          ? _c(
              "ul",
              { staticClass: "searchlist" },
              _vm._l(_vm.getlist, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.sea_per(item.name)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          ref: "scrollContainer",
          staticClass: "searchCooperation-container",
          on: { scroll: _vm.scroll },
        },
        _vm._l(_vm.array, function (item, index) {
          return _c(
            "label",
            {
              key: index,
              staticClass: "searchCooperation-cell",
              attrs: { for: item.id },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choosedArray,
                    expression: "choosedArray",
                  },
                ],
                attrs: { type: "checkbox", id: item.id },
                domProps: {
                  value: item,
                  checked: Array.isArray(_vm.choosedArray)
                    ? _vm._i(_vm.choosedArray, item) > -1
                    : _vm.choosedArray,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.choosedArray,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = item,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.choosedArray = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.choosedArray = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.choosedArray = $$c
                    }
                  },
                },
              }),
              _c("div", { staticClass: "searchCooperation-cell-left" }, [
                item.avatarUrl
                  ? _c("img", { attrs: { src: item.avatarUrl } })
                  : _c("i", { staticClass: "iconfont icon-user" }),
                _c("span", [_vm._v(_vm._s(item.nickName || item.lawyerName))]),
              ]),
              _c("span", { staticClass: "iconfont icon-check-2" }),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "searchCooperation-button-box" }, [
        _c("button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        _c(
          "button",
          { attrs: { disabled: _vm.num }, on: { click: _vm.confirm } },
          [_vm._v("确定")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }