var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "remindForm" }, on: { click: _vm.closeRemindForm } },
    [
      _c(
        "div",
        {
          staticClass: "formContainer",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("span", {
            staticClass: "iconfont icon-clear",
            on: { click: _vm.closeRemindForm },
          }),
          _vm._m(0),
          _c("div", { staticClass: "formcell content" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputdata,
                  expression: "inputdata",
                },
              ],
              attrs: { type: "text", placeholder: "请输入内容" },
              domProps: { value: _vm.inputdata },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.inputdata = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "formcell starttime" }, [
            _c("span", [_vm._v("开始时间:")]),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.showDatePicker = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.beginDate))]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "formcell starttime",
              on: {
                click: function ($event) {
                  _vm.endshowDatePicker = true
                },
              },
            },
            [
              _c("span", [_vm._v("结束时间:")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.endshowDatePicker = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.endDate))]
              ),
            ]
          ),
          _c("div", { staticClass: "formcell select-type" }, [
            _c("span", [_vm._v(" 设置提醒: ")]),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    _vm.timeState = true
                  },
                },
              },
              [
                _c("van-cell", {
                  model: {
                    value: _vm.time_t,
                    callback: function ($$v) {
                      _vm.time_t = $$v
                    },
                    expression: "time_t",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    _vm.timePopup = true
                  },
                },
              },
              [
                _c("van-cell", {
                  model: {
                    value: _vm.time_l,
                    callback: function ($$v) {
                      _vm.time_l = $$v
                    },
                    expression: "time_l",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "formcell select-node" }),
          _c("div", { staticClass: "formcell select-node" }, [
            !_vm.$store.state.remindForm.status
              ? _c("div", { staticClass: "button-box" }, [
                  _c(
                    "span",
                    {
                      staticClass: "cancel",
                      on: { click: _vm.closeRemindForm },
                    },
                    [_vm._v("取消")]
                  ),
                  _c("span", { staticClass: "save", on: { click: _vm.save } }, [
                    _vm._v("添加"),
                  ]),
                ])
              : _vm._e(),
            _vm.$store.state.remindForm.status
              ? _c("div", { staticClass: "button-box" }, [
                  _c(
                    "span",
                    { staticClass: "cancel", on: { click: _vm.deleteForm } },
                    [_vm._v("撤销")]
                  ),
                  _c(
                    "span",
                    { staticClass: "updata", on: { click: _vm.updata } },
                    [_vm._v("修改")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "p",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showDatePicker,
                callback: function ($$v) {
                  _vm.showDatePicker = $$v
                },
                expression: "showDatePicker",
              },
            },
            [
              _c("van-datetime-picker", {
                attrs: { type: "datetime" },
                on: {
                  confirm: _vm.confirmDateHandle,
                  cancel: _vm.cancelDateHandle,
                },
                model: {
                  value: _vm.pickerTime,
                  callback: function ($$v) {
                    _vm.pickerTime = $$v
                  },
                  expression: "pickerTime",
                },
              }),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.endshowDatePicker,
                callback: function ($$v) {
                  _vm.endshowDatePicker = $$v
                },
                expression: "endshowDatePicker",
              },
            },
            [
              _c("van-datetime-picker", {
                attrs: { type: "datetime" },
                on: {
                  confirm: _vm.confirmDateHandle2,
                  cancel: _vm.cancelDateHandle2,
                },
                model: {
                  value: _vm.endpickerTime,
                  callback: function ($$v) {
                    _vm.endpickerTime = $$v
                  },
                  expression: "endpickerTime",
                },
              }),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showPopup,
                callback: function ($$v) {
                  _vm.showPopup = $$v
                },
                expression: "showPopup",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  columns: _vm.caseNodeList,
                  "show-toolbar": true,
                  "value-key": "nodeName",
                },
                on: {
                  confirm: _vm.changeType,
                  cancel: function ($event) {
                    _vm.showPopup = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.statePopup,
                callback: function ($$v) {
                  _vm.statePopup = $$v
                },
                expression: "statePopup",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  columns: _vm.status,
                  "show-toolbar": true,
                  "value-key": "label",
                },
                on: {
                  confirm: _vm.changeType2,
                  cancel: function ($event) {
                    _vm.statePopup = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.timeState,
                callback: function ($$v) {
                  _vm.timeState = $$v
                },
                expression: "timeState",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  columns: _vm.timeType,
                  "show-toolbar": true,
                  "value-key": "label",
                },
                on: {
                  confirm: _vm.changeType3,
                  cancel: function ($event) {
                    _vm.timeState = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.timePopup,
                callback: function ($$v) {
                  _vm.timePopup = $$v
                },
                expression: "timePopup",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  columns: _vm.timeValue,
                  "show-toolbar": true,
                  "value-key": "label",
                },
                on: {
                  confirm: _vm.changeType4,
                  cancel: function ($event) {
                    _vm.timePopup = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-header" }, [
      _c("span", [_vm._v("提醒表单")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }