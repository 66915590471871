var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "moveEvidence" }, [
    _c("div", { staticClass: "movEvidenceTop" }, [
      _c("i", {
        staticClass: "iconfont icon-back",
        on: { click: _vm.backChoice },
      }),
      _c("span", { staticClass: "movEvidence-text" }, [_vm._v("移动")]),
      _c("br"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.choosedFileNew,
            expression: "choosedFileNew",
          },
        ],
        staticClass: "radio",
        attrs: {
          type: "radio",
          name: "moveSrc",
          id: "",
          value: "当前目录（预设）",
        },
        domProps: { checked: _vm._q(_vm.choosedFileNew, "当前目录（预设）") },
        on: {
          change: function ($event) {
            _vm.choosedFileNew = "当前目录（预设）"
          },
        },
      }),
      _c("span", { staticClass: "movEvidence-text" }, [_vm._v("当前目录")]),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticClass: "movEvidenceContent" },
        [
          _vm._m(0),
          _vm._l(_vm.randerArray, function (item, index) {
            return _c("p", { key: index, staticClass: "fileEach" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choosedFileNew,
                    expression: "choosedFileNew",
                  },
                ],
                staticClass: "radio",
                attrs: { type: "radio", name: "moveSrc", id: "" },
                domProps: {
                  value: item,
                  checked: _vm._q(_vm.choosedFileNew, item),
                },
                on: {
                  change: function ($event) {
                    _vm.choosedFileNew = item
                  },
                },
              }),
              _c(
                "span",
                {
                  staticClass: "movEvidence-text",
                  on: {
                    click: function ($event) {
                      return _vm.fileInit(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.fileName))]
              ),
            ])
          }),
        ],
        2
      ),
      _c("div", { staticClass: "line" }),
      _c("p", { staticClass: "select-btn" }, [
        _c(
          "span",
          {
            staticClass: "movEvidence-text",
            staticStyle: { color: "#478eee" },
            on: { click: _vm.clickBack },
          },
          [_vm._v("取消")]
        ),
        _c(
          "span",
          {
            staticClass: "movEvidence-text",
            staticStyle: { color: "#478eee" },
            on: { click: _vm.moveDown },
          },
          [_vm._v("确定")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "moEmpty hidden" }, [
      _c("img", {
        attrs: { src: require("./static/imgs/moempty.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }