<template>
  <div>
    <ul ref="nodelist">
      <li class='jiedianlist'>
        <span class="iconfont"
              :class="item.progress==='FINISHED'?'icon-xuanzhong' : 'icon-kongbai'"
              @click="change(item)"></span>
        <div class="list border-bottom">
          <div class="van-cell-group ">
            <van-field v-model="item.headline"
                       :class="item.progress==='FINISHED'?'delete-line':''"
                       type="textarea"
                       rows="1"
                       border
                       autosize
                       ref="input"
                       class="van-field__body"
                       @blur="saveHeadline(item)">
            </van-field>

            <div class="avatars">
              <div class="avatar"
                   :class="'avatar'+num"
                   v-for=" (j,index) in item.user"
                   :key="index"
                   @click="chooseImg(index,$event)">
                <img :src="j.avatarUrl"
                     alt="">
              </div>
            </div>
          </div>

        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { caseBase } from '~api-config'

export default {
  name: 'nonode',
  props: ['attr', 'item', 'in'],
  data () {
    return {
      num: null,
      url: [],
      NodeForms: {
        ids: [],
        progress: null
      },
      NodeForm: {
        nodeFormEntity: {},
        formContentEntity: []
      }
    }
  },
  mounted () {
    this.num = this.in
    this.ss()
    this.chimg(this.num)
  },
  methods: {
    // 头像去重
    ss () {
      console.log(this.item.user)
      const i = this.item.user
      if (i.length >= 2) {
        for (let j = 0; j < i.length; j++) {
          for (let k = j + 1; k < i.length; k++) {
            if (i[j].id === i[k].id) {
              i.splice(k, 1)
            }
          }
        }
      }

      this.$nextTick(() => {
        this.url.push(i)
      })
    },
    change (i) {
      if (i.progress === 'READY') {
        i.progress = 'FINISHED'
      } else if (i.progress === 'FINISHED') {
        i.progress = 'READY'
      }
      this.$nextTick(() => {
        this.NodeForms.ids = [i.id]
        this.NodeForms.progress = i.progress
        const userId = this.$store.state.user.userInfo.id
        // console.log(this.NodeForms)
        this.$axios.post(`${caseBase}/management/user/${userId}/task/progress`, this.NodeForms).then(res => {
          // console.log(res)
        })
      })

      // console.log(i)
    },
    // 保存headline
    saveHeadline (i) {
      // console.log('i===', i.headline)
      const userId = this.$store.state.user.userInfo.id
      this.NodeForms.ids = [i.id]
      this.NodeForms.progress = i.progress
      this.$axios.put(`${caseBase}/management/user/${userId}/task/${i.id}`, i).then(res => {
        if (res.status === 200) {
          console.log('保存成功', res)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 默认第一个头像显示
    chimg (i) {
      var imgs = document.getElementsByClassName('avatar' + i)
      this.$nextTick(() => {
        if (imgs.length) {
          imgs[0].classList.add('ava')
        }
      })
    },
    // 点击头像显示
    chooseImg (index, e) {
      var a = []
      var parent = e.target.parentNode.parentNode
      var children = parent.children
      for (var i = 0, pl = children.length; i < pl; i++) {
        if (children[i] !== e.target.parentNode) a.push(children[i])
      }
      e.target.parentNode.classList.add('ava')
      for (const j of a) {
        j.classList.remove('ava')
      }
    }
  }

}
</script>
<style lang="stylus" scoped>
.delete
  display none
.jiedianlist
  display flex
  align-items center
  .icon-kongbai
    font-size 20px
    margin 0 10px 0 15px
  .icon-xuanzhong
    font-size 20px
    margin 0px 10px 0 15px
    color #CCCCCC
  .border-bottom
    border-color #F3F4F7
  .list
    display flex
    flex 1
    justify-content space-between
    align-items center
    padding-bottom 11px
    margin 11px 19px 0 0
    font-family PingFangSC
    width 100%
    .van-cell-group
      width 100%
      display flex
      align-items center
      .van-field__body
        height 100%
        width 100%
      .avatars
        width 20%
        height 1rem
        white-space nowrap
        overflow hidden
        // overflow-x scroll
        margin-left 1rem
        position relative
        margin-right -0.2rem
        .avatar
          width 50%
          height 65%
          display inline-block
          position absolute
          // line-height 75px
          display flex
          align-items center
          border-radius 50%
          img
            width 100%
            height 100%
            border-radius 50%
        .avatar:nth-of-type(1)
          left 0
        .avatar:nth-of-type(2)
          left 23%
          z-index 200
        .avatar:nth-of-type(3)
          left 45%
        .ava
          z-index 800
          img
            width 100%
            height 100%
            border-radius 50%
    .delete-line
      text-decoration line-through
    #icon-more
      position relative
    .van-cell
      padding 0
    .color1
      color #ccc
      font-size 16px
      display none
    .color2
      color #ccc
      display block
      font-size 16px
</style>
