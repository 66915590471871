var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document-tab" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCell,
            expression: "showCell",
          },
        ],
        staticClass: "document-header",
      },
      [
        _c(
          "div",
          { staticClass: "header-left" },
          [
            _c("span", { staticClass: "iconfont icon-sousuo" }),
            _c("van-field", {
              staticClass: "input",
              attrs: {
                "left-icon": "iconfont iconfont iconsousuo",
                placeholder: "请输入文书",
              },
              on: { focus: _vm.focu, blur: _vm.gogo },
              model: {
                value: _vm.inputdata,
                callback: function ($$v) {
                  _vm.inputdata = $$v
                },
                expression: "inputdata",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "ul",
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            staticClass: "document-loading-wrapper",
          },
          [_c("van-loading")],
          1
        ),
        _c(
          "van-list",
          {
            attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
            on: { load: _vm.onLoad },
            model: {
              value: _vm.loading,
              callback: function ($$v) {
                _vm.loading = $$v
              },
              expression: "loading",
            },
          },
          [
            _vm._l(_vm.DocumentList, function (item) {
              return _c("cell", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCell,
                    expression: "showCell",
                  },
                ],
                key: item.id,
                ref: "cell",
                refInFor: true,
                attrs: { item: item },
                on: {
                  DeleteandBuilt: _vm.showDeleteandBuilt,
                  showHistoryBottomBar: _vm.showHistoryBottomBar,
                  closeHistoryBottomBar: _vm.closeHistoryBottomBar,
                  establish: _vm.establish,
                },
              })
            }),
            _vm._l(_vm.NodeList, function (val) {
              return _c("node", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showNode,
                    expression: "showNode",
                  },
                ],
                key: val.nodeId,
                ref: "node",
                refInFor: true,
                attrs: { val: val },
                on: {
                  getNodeList: _vm.getNodeList,
                  shownode: _vm.shownode,
                  nonenode: _vm.nonenode,
                  nodefunct: _vm.nodefunct,
                },
              })
            }),
          ],
          2
        ),
        _c("div", { staticClass: "document-kongbai" }),
        _c(
          "van-popup",
          {
            staticClass: "deleteandbuilt",
            attrs: {
              position: "bottom",
              overlay: false,
              "get-container": _vm.container,
            },
            model: {
              value: _vm.show,
              callback: function ($$v) {
                _vm.show = $$v
              },
              expression: "show",
            },
          },
          [
            _c("ul", { staticClass: "documentwrap" }, [
              _c("li", { on: { click: _vm.cancle } }, [
                _c("span", { staticClass: "iconfont icon-quxiao" }),
                _c("span", { staticClass: "text" }, [_vm._v("取消")]),
              ]),
              _c("li", { on: { click: _vm.deleteFn } }, [
                _c("span", { staticClass: "iconfont icon-shanchu" }),
                _c("span", { staticClass: "text" }, [_vm._v("删除")]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }