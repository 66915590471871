<template>
  <div :class="{ 'section-item-wrapper': true, active: item.showInfoCard}"
       :style="itemStyle">
    <div :class="sectionClass"
         ref="item">
      <div class="section-item-sname left"
           ref="item"
           @click="toggleInfoCard(item)">
        {{item.simpleName}}
      </div>
      <div :class="itemInfoCardClass"
           v-show="item.showInfoCard">
        <!-- 子级 -->
        <div v-if="item.children"
             class="has-child-item-icon"
             @click="toggleChildItem(item)">
          <i :class="icon"></i>
        </div>
        <!-- 节点全名 -->
        <div class="section-item-fname"
             @click="openForm">{{item.nodeName}}</div>
        <!-- 头像 -->
        <div class="section-item-avatar"
             v-if="item.avatar">
          <img :src="item.avatar" />
        </div>
      </div>
      <div class="section-item-sname right"
           ref="item">
        {{item.simpleName}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import visibilityMixin from '~mixins/visibility'
import getNodePosTimeMixin from '~mixins/getNodePosTime'

export default {
  mixins: [visibilityMixin, getNodePosTimeMixin],
  name: 'section-item',
  props: {
    item: Object
  },
  data () {
    return {
      showBox: false,
      showChild: false
    }
  },
  computed: {
    ...mapState('caseDetail', {
      today: state => state.today,
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    icon () {
      if (this.showChild) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    translateX () {
      const { start } = this.getTLRangeStamp
      const nodePosTime = this.getNodePosTime(this.item)
      const left = (nodePosTime - start) / this.getTLRangeCrossNumber * this.timelineWidth
      return left
    },
    itemStyle () {
      const { start } = this.getTLRangeStamp
      const _start = this.getTimeStamp(this.item.beginTime)
      const left =
        (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
      const width = (this.item.endTime - this.item.beginTime) / this.getTLRangeCrossNumber * this.timelineWidth
      const transform = `translate3d(${left}px, 15px, 0)`
      const style = {
        transform,
        width: `${width}px`
      }
      return style
    },
    sectionClass () {
      return 'section-item default'
    },
    connectorStyle () {
      return ''
    },
    nodeItemPopperClass () {
      return 'casedetail-nodeitem-tooltip'
    },
    itemInfoCardClass () {
      const { beginTime } = this.item
      const isOutdated = this.today - beginTime
      if (isOutdated > 0) {
        return 'section-item-info-card gray'
      } else {
        const styleCode = this.item.style
        return `section-item-info-card ${styleCode}`
      }
    },
    hintTimeBegin () {
      const date = window.moment(this.item.beginTime).format('M月D日')
      return date
    },
    hintTimeEnd () {
      const date = window.moment(this.item.endTime).format('M月D日')
      return date
    }
  },
  methods: {
    ...mapMutations('caseDetail', [
      'setShowTopItemConnector',
      'setTopItemConnnectorPos',
      'setShowHintLineSimple',
      'setSimpleHintlineYData'
    ]),
    // 显示辅助线
    showHintLine () {
      this.setSimpleHintlineYData(this.item)
      this.setShowHintLineSimple(true)
    },
    // 隐藏辅助线
    hideHintLine () {
      this.setSimpleHintlineYData({})
      this.setShowHintLineSimple(false)
    },
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    toggleInfoCard (item) {
      const payload = {}
      payload.item = item
      payload.nodeType = 'ROUND'
      this.setShowTopItemConnector(false)
      // 通知父组件改变显示信息卡片状态
      if (item.showInfoCard) {
        payload.action = 'hide'
        this.$emit('showInfoCard', payload)
      } else {
        payload.action = 'show'
        this.$emit('showInfoCard', payload)
      }
      // 更改子级展开状态
      const showChildpayload = {}
      showChildpayload.action = 'hide'
      showChildpayload.item = item
      showChildpayload.nodeType = 'ROUND'
      // 改变状态
      this.showChild = false
      // 通知父组件
      this.$emit('showChildItem', showChildpayload)
    },
    toggleTearItemBox (item) {
      if (this.showBox) {
        const payload = {}
        payload.action = 'hide'
        payload.item = item
        payload.nodeType = 'ROUND'
        // 改变状态
        this.showChild = false
        // 通知父组件
        this.$emit('showChildItem', payload)
      }
      this.showBox = !this.showBox
    },
    toggleChildItem (item) {
      this.showChild = !this.showChild
      if (this.showChild) {
        this.setShowTopItemConnector(true)
        this.setTopItemConnnectorPos(this.getNodePosTime(item))
      } else {
        this.setShowTopItemConnector(false)
        this.setTopItemConnnectorPos(0)
      }
      const payload = {}
      payload.nodeType = 'ROUND'
      payload.action = this.showChild ? 'show' : 'hide'
      payload.item = item
      this.$emit('showChildItem', payload)
    },
    resetShowChildItem () {
      this.showChild = false
    },
    openForm (e) {
      const caseId = this.$route.params.caseId
      const startDate = this.getNodePosTime(this.item)
      const { nodeId, nodeFormType, nodeName: title } = this.item
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    },
    getTimeString (time) {
      return window.moment(time).format('YYYY.MM.DD')
    }
  },
  created () {
    if (this.item.expand && this.item.showInfoCard && this.item.children) {
      this.toggleChildItem(this.item)
    }
  }
}
</script>

<style lang="stylus">
$default-color = #21A9F0
$orange-color = #F6B536
$gray-color = #666
.section-item-wrapper
  position absolute
  background transparent
  height 22px
  z-index 1
  cursor pointer
  border-bottom 2px solid #f6b536
  .section-item
    position relative
    display flex
    padding-bottom 1px
    .section-item-sname
      width 22px
      height 22px
      font-size 16px
      text-align center
      line-height 22px
      border-radius 50%
      background #ffffff
      color rgba(246, 181, 54, 1)
      border 1px solid rgba(246, 181, 54, 1)
      z-index 3
      user-select none
    .section-item-info-card
      display flex
      background rgba(246, 181, 54, 1)
      height 22px
      line-height 24px
      user-select none
      color #666
      transform translate3d(-22px, 0, 0)
      z-index 1
      padding-left 11px
      .has-child-item-icon
        padding 0 4px
    .left
      left 0
      transform translate3d(-50%, 0, 0)
    .right
      position absolute
      right 0
      transform translate3d(50%, 0, 0)
      text-align center
</style>
