<template>
  <div class="intervalDate">
    <span>
      <span class="red">*</span>
      <span>间隔</span>
    </span>
    <span class="border-bottom intervalValue"
          contenteditable="true"
          ref="intervalValue"
          @input="changeIntervalValue">{{$store.state.nodeform.dateDelay}}</span>
    <span class="selectType"
          @click="showSelect=true">
      <span class="timeType"
            ref="timeType">{{$store.state.nodeform.dateType}}</span>
      <van-icon name="arrow-down" />
    </span>
    <div class="timePiker border"
         @click="showEndTime=true">
      <span>{{endTime}}</span>
      <i class="iconfont icon-calendar"></i>
    </div>
    <van-popup v-model="showSelect"
               position="bottom">
      <van-picker :columns="columns"
                  :show-toolbar="true"
                  :item-height="pickCellheight"
                  @confirm="changeType"
                  @cancel="showSelect = false" />
    </van-popup>
    <van-popup v-model="showEndTime"
               position="bottom">
      <van-datetime-picker v-model="dateTime"
                           :type="$store.state.nodeform.dateKind"
                           :item-height="pickCellheight"
                           @confirm="confirmDateHandle"
                           @cancel="cancelDateHandle"
                           @change="changeEndTime"
                           :cancel-button-text="$store.state.nodeform.dateKind=='date'?'切换为时间':'切换为日期'" />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'intervalDate',
  computed: {
    pickCellheight () {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    endTime () {
      const a = new Date(this.$store.state.nodeform.deadline)
      let time = ''
      time += a.getFullYear() + '/'
      time += (a.getMonth() + 1) > 9 ? (a.getMonth() + 1) : '0' + (a.getMonth() + 1)
      time += '/'
      time += a.getDate() > 9 ? a.getDate() : ('0' + a.getDate())
      return time
    },
    dateTime: {
      get () {
        return new Date(this.$store.state.nodeform.deadline)
      },
      set (value) {
        this.$store.state.nodeform.deadline = value.getTime()
      }
    },
    load () {
      return this.$store.state.nodeform.loadingFinish
    }
  },
  data () {
    return {
      showSelect: false,
      columns: [
        '年',
        '月',
        '日'
      ],
      showEndTime: false
    }
  },
  methods: {
    changeIntervalValue () {
      if (/^[0-9]{1,5}$/.test(this.$refs.intervalValue.innerText) || this.$refs.intervalValue.innerText === '') {
        if (this.$refs.intervalValue.innerText === '') {
          this.$store.state.nodeform.dateDelay = 0
        } else {
          this.$store.state.nodeform.dateDelay = parseInt(this.$refs.intervalValue.innerText)
        }
        this.delayHandle()
      } else {
        this.$refs.intervalValue.innerText = '0'
        this.$toast('间隔只能填写0-99999之间的整数')
        this.delayHandle()
      }
    },
    changeType (value, index) {
      this.$refs.timeType.innerText = value
      this.$store.state.nodeform.dateType = value
      this.delayHandle()
      this.showSelect = false
    },
    confirmDateHandle (value) {
      this.$store.state.nodeform.deadline = value.getTime()
      this.showEndTime = false
    },
    cancelDateHandle () {
      if (this.$store.state.nodeform.dateKind === 'date') {
        this.$store.state.nodeform.dateKind = 'datetime'
      } else {
        this.$store.state.nodeform.dateKind = 'date'
      }
    },
    changeEndTime () {
      // 转换开始时间和结束时间的格式
      var startTime = new Date(this.$store.state.nodeform.startDate)
      var s = {}
      s.year = startTime.getFullYear()
      s.month = startTime.getMonth() + 1
      s.day = startTime.getDate()
      var endTime = new Date(this.$store.state.nodeform.deadline)
      var e = {}
      e.year = endTime.getFullYear()
      e.month = endTime.getMonth() + 1
      e.day = endTime.getDate()
      // 计算间隔时间
      this.$store.state.nodeform.dateDelay = this.endHandle(s, e)
    },

    // 根据开始时间和间隔时间计算截止日期
    delayHandle () {
      const time = new Date(this.$store.state.nodeform.startDate)
      const a = {}
      a.year = time.getFullYear()
      a.month = time.getMonth() + 1
      a.day = time.getDate()
      a.hour = time.getHours()
      a.minute = time.getMinutes()
      if (this.$store.state.nodeform.dateType === '日' && this.$store.state.nodeform.dateDelay) {
        this.calcDateByDay(a, parseInt(this.$store.state.nodeform.dateDelay))
      } else if (this.$store.state.nodeform.dateType === '月' && this.$store.state.nodeform.dateDelay) {
        this.calcDateByMonth(a, parseInt(this.$store.state.nodeform.dateDelay))
      } else if (this.$store.state.nodeform.dateType === '年' && this.$store.state.nodeform.dateDelay) {
        this.calcDateByYear(a, parseInt(this.$store.state.nodeform.dateDelay))
      }
      this.$store.state.nodeform.deadline = new Date(`${a.year}-${a.month}-${a.day} ${a.hour}:${a.minute}`).getTime()
    },
    // 按天计算
    calcDateByDay (a, delay) {
      var oldday = new Date(`${a.year}-${a.month}-${a.day}`).getTime()
      var newday = oldday + delay * 1000 * 60 * 60 * 24
      var time = new Date(newday)
      a.year = time.getFullYear()
      a.month = time.getMonth() + 1
      a.day = time.getDate()
    },
    // 按月计算
    calcDateByMonth (a, delay) {
      a.year = Math.floor((a.month + delay) / 12) + a.year
      a.month = (a.month + delay) % 12
      if (a.month === 0) {
        a.month = 12
      }
      var maxDay = this.calcMaxDay(a)
      if (a.day > maxDay) {
        a.day = maxDay
      }
    },
    // 按年计算
    calcDateByYear (a, delay) {
      a.year = a.year + delay
      if (a.month === 2 && a.day === 29 && !((a.year % 100 === 0 && a.year % 400 === 0) || (a.year % 100 !== 0 && a.year % 4 === 0))) {
        a.day = 28
      }
    },
    // 计算一个月最大天
    calcMaxDay (a) {
      var maxDay
      if (a.month === 2) {
        if ((a.year % 100 === 0 && a.year % 400 === 0) || (a.year % 100 !== 0 && a.year % 4 === 0)) {
          // console.log('闰年')
          maxDay = 29
        } else {
          // console.log('不是闰年')
          maxDay = 28
        }
      } else if (a.month === 4 || a.month === 6 || a.month === 9 || a.month === 11) {
        // console.log('小月')
        maxDay = 30
      } else {
        // console.log('大月')
        maxDay = 31
      }
      return maxDay
    },

    // 根据截止时间和开始时间计算间隔是时间
    endHandle (s, e) {
      if (this.$store.state.nodeform.dateType === '日') {
        var startTime = new Date(`${s.year}-${s.month}-${s.day}`).getTime()
        var endTime = new Date(`${e.year}-${e.month}-${e.day}`).getTime()
        return Math.round((endTime - startTime) / (1000 * 60 * 60 * 24))
      } else if (this.$store.state.nodeform.dateType === '月') {
        return (e.year - s.year) * 12 + e.month - s.month
      } else if (this.$store.state.nodeform.dateType === '年') {
        return e.year - s.year
      }
    }
  },
  watch: {
    load () {
      if (this.$store.state.nodeform.deadline) { // 如果有截止日期，算出间隔时间
        this.$store.state.nodeform.dateType = '日'
        this.changeEndTime()
      } else { // 没有就算出截止时间，用默认间隔
        this.$store.state.nodeform.dateType = '年'
        this.$store.state.nodeform.dateDelay = 1
        this.delayHandle()
      }
    }
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
.intervalDate
  margin-top 15px
  display flex
  justify-content space-between
  align-items center
  .red
    color red
    margin-right 5px
  .intervalValue
    display inline-block
    padding 1px 3px
    max-width 50px
  .selectType
    display flex
    align-items center
    .timeType
      margin-right 5px
  .timePiker
    width 50%
    padding 5px 15px
    border-color $borderColor
    border-radius 10px
    display flex
    align-items center
    justify-content space-between
    .iconfont
      color $color
      // font-size 1.3em
      transform scale(1.5)
</style>
