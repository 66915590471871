<template>
  <div class="searchCooperation-box">
    <div class="searchCooperation">
      <div class="searchCooperation-title">
        添加协作人
      </div>
      <div class="searchCooperation-search">
        <label
          for="searchCooperation-search-input"
          class="iconfont icon-search"
          @click="search"
        ></label>
        <input
          type="text"
          id="searchCooperation-search-input"
          placeholder="搜索"
          v-model="inputValue"
          @focus="getfocus"
          @blur="blur"
        />
        <div class="searchCooperation-select">
          <select v-model="searchType">
            <option value="1">律所</option>
            <option value="3">用户名</option>
            <option value="2">手机号</option>
          </select>
        </div>
        <ul class="searchlist" v-if="numlost">
          <li
            v-for="(item, index) in getlist"
            :key="index"
            @click="sea_per(item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div
        class="searchCooperation-container"
        ref="scrollContainer"
        @scroll="scroll"
      >
        <label
          class="searchCooperation-cell"
          v-for="(item, index) in array"
          :key="index"
          :for="item.id"
        >
          <input
            type="checkbox"
            :id="item.id"
            v-model="choosedArray"
            :value="item"
          />
          <div class="searchCooperation-cell-left">
            <img :src="item.avatarUrl" v-if="item.avatarUrl" />
            <i class="iconfont icon-user" v-else></i>
            <span>{{ item.nickName || item.lawyerName }}</span>
          </div>
          <span class="iconfont icon-check-2"></span>
        </label>
      </div>
      <div class="searchCooperation-button-box">
        <button @click="cancel">取消</button>
        <button @click="confirm" :disabled="num">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'searchCooperation',
  props: ['addCooperation', 'propCaseId'], // 表单位置
  data() {
    return {
      searchType: '1', // 搜索类型
      inputValue: '', // 搜索类容
      array: [], // 渲染数组
      choosedArray: [], // 选中的人
      page: 1, // 页码
      searchParams: '', // 下拉更多搜索地址的缓存
      fainalPage: false, // 是否到最后一页
      limitNum: 20, // 一页的页数
      num: false,
      getlist: [],
      numlost: false
    }
  },
  computed: {
    ...mapState('user', ['organizationId'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    sea_per(val) {
      // 查协作人
      this.inputValue = val
      this.$axios
        .get(
          `${caseBase}/lts/nodeShare/find?pageNum=1&limit=20&parameterType=1&parameter=${val}`
        )
        .then(res => {
          if (res.data.data.total <= 20) {
            this.array = res.data.data.items
          } else {
            this.$axios
              .get(
                `${caseBase}/lts/nodeShare/find?pageNum=1&limit=${
                  res.data.data.total
                }&parameterType=1&parameter=${val}`
              )
              .then(res => {
                this.array = res.data.data.items
              })
          }
        })
    },
    blur() {
      setTimeout(() => {
        this.numlost = false
      }, 300)
    },
    getfocus() {
      this.numlost = true
      if (this.inputValue.trim().length === 0) {
        this.getlist = []
        this.getsearch()
      }
    },
    getsearch() {
      this.$axios
        .get(`${caseBase}/lts/Lawfirm/queryLawfirm?name=`)
        .then(res => {
          this.getlist = res.data.data.items
        })
    },
    // 确定
    confirm() {
      this.num = true
      if (this.addCooperation) {
        var paramsValue = {}
        paramsValue.nodeShareEntity = {}
        paramsValue.userIds = []
        paramsValue.nodeShareEntity.caseId =
          this.$store.state.nodeform.caseId || this.propCaseId
        // 如果有nodeId则设置参数值
        const nodeId = this.$store.state.nodeform.nodeId
        if (nodeId) {
          paramsValue.nodeShareEntity.nodeId = nodeId.toString()
        }
        this.choosedArray.forEach(item => {
          const id = item.id || item.userId
          paramsValue.userIds.push(id)
        })
        this.$axios
          .post(`${caseBase}/lts/nodeShare/add`, paramsValue)
          .then(res => {
            this.num = false
            this.$emit('added', this.choosedArray)
            this.cancel()
            this.$notify({
              message: '添加成功',
              background: '#67C23A'
            })
          })
          .catch(err => {
            Promise.reject(err)
          })
      } else {
        // 文丽接口
        const paramsValue = {}
        paramsValue.nodeShareEntity = {}
        paramsValue.userIds = []
        paramsValue.nodeShareEntity.caseId = this.$route.params.caseId
        this.choosedArray.forEach(item => {
          const id = item.id || item.userId
          paramsValue.userIds.push(id)
        })
        this.$axios
          .post(`${caseBase}/lts/nodeShare/add`, paramsValue)
          .then(res => {
            this.num = false
            // console.log(res)
            this.$emit('added', this.choosedArray)
            this.cancel()
          })
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    search() {
      // 重置设置信息
      this.choosedArray = []
      this.page = 1
      this.fainalPage = false
      this.searchParams = ''
      // 发请求
      this.$axios
        .get(`${caseBase}/lts/nodeShare/find`, {
          params: {
            pageNum: this.page,
            limit: this.limitNum,
            parameterType: this.searchType,
            parameter: this.inputValue
          }
        })
        .then(res => {
          this.array = res.data.data.items
          var value = `${caseBase}/lts/nodeShare/find?`
          value += `limit=${this.limitNum}&parameterType=${
            this.searchType
          }&parameter=${this.inputValue}&pageNum=`
          this.searchParams = value
          if (res.data.data.total <= this.limitNum * this.page) {
            this.fainalPage = true
          }
          this.page++
        })
    },
    scroll(event) {
      if (this.fainalPage) return
      if (
        this.$refs.scrollContainer.scrollTop ===
        this.$refs.scrollContainer.scrollHeight -
          this.$refs.scrollContainer.offsetHeight
      ) {
        this.$axios.get(this.searchParams + (this.page - 1)).then(res => {
          var newArray = this.array.concat(res.data.data.content)
          this.array = newArray
          if (res.data.data.totalElements <= this.array.length) {
            this.fainalPage = true
          } else {
            this.page++
          }
        })
      }
    },
    // 获取组织id
    async getId() {
      await this.getOrganizationId()
    },
    queryColleague() {
      this.$axios
        .get(
          `${caseBase}/management/organization/${
            this.organizationId
          }/users?size=${this.limitNum}&page=${this.page -
            1}&status=&key=&sort=`
        )
        .then(res => {
          this.array = res.data.data.content
          var value = `${caseBase}/management/organization/${
            this.organizationId
          }/users?`
          value += `size=${this.limitNum}&status=&key=&sort=&page=`
          this.searchParams = value
          if (res.data.data.totalElements <= this.array.length) {
            this.fainalPage = true
          }
          this.page++
        })
    }
  },
  watch: {
    inputValue(val) {
      if (val.trim().length === 0) {
        setTimeout(() => {
          this.getsearch()
        })
      } else {
        this.$axios
          .get(`${caseBase}/lts/Lawfirm/queryLawfirm?name=${this.inputValue}`)
          .then(res => {
            this.getlist = res.data.data.items
          })
      }
    }
  },
  mounted() {},
  async created() {
    await this.getId()
    this.queryColleague()
  }
}
</script>
<style scoped>
.searchlist {
  position: absolute;
  width: 110%;
  height: 200px;
  overflow: auto;
  background: #fff;
  z-index: 999;
  top: 35px;
  left: -5.5%;
  font-size: 14px;
  color: #333;
}
.searchlist li {
  width: 100%;
  line-height: 25px;
  text-indent: 10px;
}
.searchCooperation-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.searchCooperation {
  width: 100%;
  /* height: 288px; */
  background-color: white;
  margin: 0 auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #666666;
}
.searchCooperation-title {
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
.searchCooperation-search {
  width: 90%;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 21px;
}
.searchCooperation-search > input {
  border: none;
  width: 100px;
  flex-grow: 1;
  padding: 0 10px;
}
.searchCooperation-select {
  border-left: 1px solid #999999;
  height: 15px;
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.searchCooperation-select > select {
  border: none;
}
.searchCooperation-container {
  max-height: 250px;
  min-height: 170px;
  overflow-y: scroll;
  padding-left: 21px;
  padding-right: 15px;
}
.searchCooperation-cell {
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  position: relative;
}
.searchCooperation-cell > input[type='checkbox'] {
  visibility: hidden;
  position: absolute;
}
.searchCooperation-cell > .iconfont {
  color: #3eb3f0;
  visibility: hidden;
}
.searchCooperation-cell > input[type='checkbox']:checked ~ span.iconfont {
  visibility: visible;
}
.searchCooperation-cell
  > input[type='checkbox']:checked
  + .searchCooperation-cell-left
  > span {
  color: #3eb3f0;
}
.searchCooperation-cell-left > img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.searchCooperation-cell-left > .icon-user {
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  font-size: 14px;
}
.searchCooperation-button-box {
  border-top: 1px solid #999999;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.searchCooperation-button-box > button {
  flex-grow: 1;
  text-align: center;
  border: none;
  outline: none;
  background: none;
}
.searchCooperation-button-box > button:nth-of-type(n + 2) {
  border-left: 1px solid #999999;
}
</style>
