<template>
  <!-- <component :is="$store.state.nodeform.formType"
             :type="type"></component> -->
  <form-container />
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { caseBase } from '~api-config'
// import lian from '~components/nodeform/lian'
// import zhencha from '~components/nodeform/zhencha'
// import pizhun from '~components/nodeform/pizhun'
import formContainer from './formContainer'
export default {
  name: 'nodeform',
  components: {
    // lian,
    // zhencha,
    // pizhun,
    formContainer
  },
  data() {
    return {
      type: 0
    }
  },
  computed: {
    ...mapState('nodeform', [
      'caseId',
      'nodeId',
      'nodeFormType',
      'formType',
      'textArray'
    ])
  },
  methods: {
    ...mapMutations('nodeform', [
      'setFormType',
      'setDataKind',
      'setDateType',
      'setDateDelay',
      'setStartDate',
      'setFormStatus',
      'setConstractor',
      'setTextArray',
      'setCourt',
      'setIsNull',
      'setFayuanName',
      'setDeadline',
      'setFormId',
      'setCooperation',
      'setLoadingFinish',
      'setFormKV'
    ]),
    // 添加协作人表单显示
    addcooperationHandle() {
      this.showAddCooperation = true
    },
    // 关闭添加协作人表单
    closecooperation() {
      this.showAddCooperation = false
    }
  },
  mounted() {
    /**  判断表单类型
     ** FILING(5, "立案"),
     ** DATETIME_CONTENT(6, "日期时间/内容"),
     ** DATE_CONTENT(7, "日期/内容"),
     ** INTERVAL_DATE_CONTENT(8, "1年日期间隔/内容"),
     ** DATE(9, "日期")
     ** CONTENT(10, "内容"),
     */
    if (this.nodeFormType === 'FILING') {
      // 立案
      this.setFormType('lian')
    } else if (this.nodeFormType === 'INTERVAL_DATE_CONTENT') {
      // 批准 默认时间一年
      this.setFormType('pizhun')
    } else {
      this.setFormType('zhencha')
      if (this.nodeFormType === 'DATE') {
        // 日期
        this.type = 1
      } else if (this.nodeFormType === 'CONTENT') {
        // 内容
        this.type = 2
      } else {
        // 日期类容
        if (this.nodeFormType === 'DATETIME_CONTENT') {
          this.setDataKind('datetime')
          // replace(/-/g, '/')这样处理是为了兼容iOS
          const full = this.$store.state.nodeform.startDate.replace(/-/g, '/')
          const time = `${full.getFullYear()}-${full.getMonth() +
            1}-${full.getDate()} 09:00`
          this.setStartDate(new Date(time))
        }
        this.type = 3
      }
    }

    if (this.caseId && this.nodeId) {
      this.$axios(
        `${caseBase}/lts/nodeForm/get/${this.caseId}/${
          this.nodeId
        }/?nodeName=证据交换,答辩,开庭/再次开庭`
      ).then(res => {
        // 如果响应成功就取消加载状态
        if (res.data.code === '200') {
          this.loading = false
          this.setLoadingFinish(true)
        }
        const { NodeForm, nodeIdAndName } = res.data.data
        // 如果有值传过来
        if (NodeForm) {
          const data = NodeForm
          // 开始时间
          if (data.setTime && this.$store.state.nodeform.formStatus !== 0) {
            this.setStartDate(data.setTime)
          }
          // 表单状态
          this.setFormStatus(data.formStatus)
          // 承办人
          this.setConstractor(data.initiateName)
          // 数组
          if (data.formContents) {
            // 存在数组取数组
            this.setTextArray(data.formContents)
          } else {
            // 不存在取默认值
            this.$axios
              .get(
                `${caseBase}/lts/criterion/c/${this.caseId}/n/${this.nodeId}`
              )
              .then(res => {
                // console.log('默认多行输入框', res, res.data.data.length)
                if (res.data.data.length) {
                  const arr = res.data.data.map(item => {
                    return {
                      isChecked: 0,
                      content: item,
                      status: 1
                    }
                  })
                  this.setTextArray(arr)
                  if (
                    this.textArray.length === 1 &&
                    this.textArray[0].content === ''
                  ) {
                    // console.log('数据只有一条切为空')
                    this.setIsNull(true)
                  } else {
                    this.setIsNull(false)
                  }
                }
              })
              .catch(err => {
                Promise.reject(err)
              })
          }

          // 协作人
          if (data.nodeShares && data.nodeShares.length) {
            this.setCooperation(data.nodeShares)
          }

          // 法院
          this.setCourt(data.lawfirmName)
          // 如果没有存入法院则采用
          if (!this.$store.state.nodeform.fayuanName) {
            if (this.formType === 'lian' || this.formType === 'pizhun') {
              // this.$axios.get(`${caseBase}/lts/user/queryCourt`)
              //   .then((res) => {
              //     // console.log('默认法院', res)
              //     // 默认法院名称
              //     this['setFayuanName'](res.data.data.name)
              //     this['setCourt'](res.data.data.id)
              //   })
            }
          } else {
            // 如果有法院id
            // console.log('有法院id')
            this.$axios
              .get(
                `${caseBase}/lts/court/queryCourtById?id=` +
                  this.$store.state.nodeform.court
              )
              .then(res => {
                // console.log('id获取法院', res)
                this.setFayuanName(res.data.data.name)
              })
          }
          // 截止日期
          if (data.endTime !== null) {
            // 存在截止日期
            this.setDeadline(data.endTime)
            this.setDateType('日')
          } else {
            // 没有截止日期 默认一年
            this.setDateType('年')
            this.setDateDelay(1)
          }

          // formId
          if (data.formId) {
            this.setFormId(data.formId)
          }

          // 保存日期类型
          if (data.dateType && data.dateType !== '0') {
            try {
              this.setDateKind(data.dateType)
            } catch {}
          }
        } else {
          this.$axios.get(`${caseBase}.com/lts/user/queryCourt`).then(res => {
            // console.log('默认法院', res)
            // 默认法院名称
            this.setFayuanName(res.data.data.fullName)
          })
        }
        if (nodeIdAndName && nodeIdAndName.length) {
          this.setFormKV({
            key: 'nodeIdAndName',
            val: nodeIdAndName
          })
        }
      })
    }
  }
}
</script>
<style>
/* #nodeform {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.47);
  z-index: 9999;
  text-align: center;
}
#nodeform::before {
  content: '';
  display: inline-block;
  height: 100vh;
  vertical-align: middle;
}
.nodeform-form {
  text-align: left;
  width: 345px;
  background-color: #dff1f9;
  margin: 0 auto;
  padding: 15px;
  z-index: 10000;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}
.nodeform-header {
  height: 20px;
  padding: 2px;
  margin-bottom: 13px;
  color: #169bd5;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
}
.nodeform-header > .icon-clear {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
}
.nodeform-header > .icon-question-mark {
  font-size: 16px;
}
.nodeform-header > span:nth-of-type(1) {
  margin-right: 10px;
}
.nodeform-input-box {
  height: 45px;
  width: 315px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;
}
.nodeform-input-box > label {
  padding-left: 18px;
  line-height: 45px;
  font-size: 15px;
}
.nodeform-input-box > .icon-calendar {
  font-size: 30px;
  margin-right: 16px;
  color: #169bd5;
}
.nodeform-input-box > .icon-search {
  margin-right: 15px;
  font-size: 23px;
  color: #169bd5;
}
.nodeform-input {
  height: 45px;
  border: none;
  padding-left: 18px;
  padding-right: 10px;
  font-size: 15px;
  flex-grow: 1;
}
.nodeform-textarea-box {
  width: 315px;
  height: 80px;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.nodeform-textarea {
  resize: none;
  width: 315px;
  height: 80px;
  border: none;
  padding: 15px 16px;
  font-size: 15px;
}
.nodeform-button-box {
  height: 32px;
  display: flex;
  justify-content: space-around;
}
.nodeform-button {
  width: 115px;
  height: 32px;
  background-color: #169bd5;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 18px;
}
.nodeform-cooperation-box {
  background-color: white;
  margin-top: 15px;
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
  color: #169bd5;
}
.nodeform-cooperation {
  margin-top: 9px;
  display: flex;
  align-items: center;
}
.nodeform-cooperation-detail {
  width: 42px;
  height: 42px;
  border-radius: 21px;
  margin-right: 10px;
}
.nodeform-cooperation-box > .icon-tianjia {
  margin-left: 10px;
}
.nodeform-document-box {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 15px;
  background-color: white;
  color: #169bd5;
}
.nodeform-document-box > .icon-child-off {
  float: right;
  font-size: 20px;
}
.nodeform-document {
  margin-top: 6px;
}
.nodeform-document-cell {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.nodeform-document-cell .icon-download {
  font-size: 14px;
}
.nodeform-document-cell:nth-of-type(n + 2) {
  margin-top: 10px;
}
.nodeform-document-left {
  color: #333;
}
.nodeform-document-left > span:nth-of-type(1) {
  margin-right: 10px;
}
.nodeform-document-right {
  color: #999;
}
.icon-edit {
  color: #169bd5;
  margin-right: 0 !important;
}
.nodeform-approve {
  margin-bottom: 15px;
  height: 45px;
  width: 315px;
  display: flex;
}
.nodeform-left {
  width: 40%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 10px;
}
.nodeform-left > input {
  width: 30px;
  text-align: center;
}
.nodeform-right {
  width: 60%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  align-items: center;
}
.nodeform-right > input {
  flex-grow: 1;
  max-width: 143px;
  border: none;
  padding: 0 0 0 10px;
  font-size: 15px;
}
.nodeform-right > span {
  font-size: 30px;
  margin-right: 16px;
  color: #169bd5;
}
#nodeform .question-title,
.nodeform-form .nodeform-header > span:nth-of-type(1) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>
