<template>
  <div class="constractor">
    <span>
      <span class="red">*</span>
      <span>承办人</span>
    </span>
    <div class="inputBox border-bottom"
         @click="clickHandle">
      <input type="text"
             class="input"
             ref="input"
             v-model="$store.state.nodeform.constractor">
    </div>
  </div>
</template>
<script>
export default {
  name: 'constractor',
  methods: {
    clickHandle () {
      this.$refs.input.focus()
    }
  }
}
</script>
<style scoped lang="stylus">
$fontColor = #000000
.constractor
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    color red
    margin-right 5px
  .inputBox
    width 70%
    padding 5px 15px
    border-color $borderColor
    border-radius 10px
    display flex
    align-items stretch
    justify-content space-between
    .input
      flex-grow 1
      border none
      outline none
      color $fontColor
</style>
