var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "shengji",
      attrs: {
        position: "top",
        "overlay-style": { background: "rgba(0,0,0,0)" },
      },
      on: { "click-overlay": _vm.ww },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [_c("div", { on: { click: _vm.upJiedian } }, [_vm._v("升级为节点")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }