var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "finance-tab" },
    [
      _c("loading", { attrs: { showflag: _vm.flag } }),
      _vm.runbit
        ? _c("ul", { staticClass: "finance" }, [
            _c("li", [
              _c("div", [_vm._v("代理方式")]),
              _c("div", { on: { click: _vm.agency } }, [
                _c("p", [_vm._v(_vm._s(_vm.getFB(_vm.runbit)[_vm.comic]))]),
              ]),
            ]),
            _c("li", [
              _c("div", [_vm._v("诉讼标的")]),
              _c("div", [
                _c("p", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.litigationPrice,
                        expression: "litigationPrice",
                      },
                    ],
                    attrs: {
                      type: "Number",
                      placeholder: "请输入金额",
                      min: "0",
                      onkeydown: "if(event.keyCode === 69){return false}",
                    },
                    domProps: { value: _vm.litigationPrice },
                    on: {
                      blur: _vm.chance,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.litigationPrice = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._m(0),
              ]),
            ]),
            _c("li", [
              _c("div", [_vm._v("受理费(全额)")]),
              _c("div", [
                _c("p", [
                  this.acceptance.costMin !== this.acceptance.costMax
                    ? _c("span", [
                        _vm._v(_vm._s(this.acceptance.costMin) + "元-"),
                      ])
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(this.acceptance.costMax) + "元")]),
                ]),
                _c(
                  "p",
                  {
                    style: {
                      transition: "all .5s",
                      transform: _vm.pricetab
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont icon-gengduo",
                      on: { click: _vm.globe },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm.pricetab && this.litigationPrice > 0
              ? _c(
                  "table",
                  { staticClass: "tableprice", attrs: { border: "1" } },
                  _vm._l(
                    _vm.TablePrice.legalCaseCostStandards,
                    function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [
                            _vm._v(_vm._s(item.involvingPropertyMin)),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.involvingPropertyMax === -1
                                  ? ""
                                  : "-" + item.involvingPropertyMax
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.involvingPropertyMax === -1
                                  ? "以上部分"
                                  : "元部分"
                              )
                            ),
                          ]),
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              _vm._s((item.ratioMin * 100).toFixed(1)) + "%"
                            ),
                          ]),
                          item.ratioMin != item.ratioMax
                            ? _c("span", [
                                _vm._v(
                                  "-" +
                                    _vm._s((item.ratioMax * 100).toFixed(1)) +
                                    "%"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.ratioMin === 0
                                  ? item.costMin
                                  : parseInt(
                                      item.involvingPropertyMin * item.ratioMin
                                    )
                              )
                            ),
                          ]),
                          item.ratioMin !== 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.involvingPropertyMax === -1
                                      ? ""
                                      : item.ratioMin === 0
                                      ? -item.costMax
                                      : -parseInt(
                                          item.involvingPropertyMax *
                                            item.ratioMax
                                        )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c("span", [_vm._v("元")]),
                          item.involvingPropertyMax === -1
                            ? _c("span", [_vm._v("以上")])
                            : _vm._e(),
                        ]),
                      ])
                    }
                  ),
                  0
                )
              : _vm._e(),
            _c("li", [
              _c("div", [_vm._v("减半收取")]),
              _c("div", [
                _c("p", [
                  this.acceptance.costMin !== this.acceptance.costMax
                    ? _c("span", [
                        _vm._v(_vm._s(this.acceptance.costMin / 2) + "元-"),
                      ])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(_vm._s(this.acceptance.costMax / 2) + "元"),
                  ]),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", [_vm._v("律师代理费")]),
              _c("div", [
                _c("p", [
                  !this.lawer.code
                    ? _c("span", [
                        _vm._v(_vm._s(this.lawer.chargeAmountMin) + "元-"),
                      ])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      _vm._s(this.lawer.chargeAmountMax) +
                        _vm._s(this.lawer.chargeAmountMax ? "元" : "无")
                    ),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    style: {
                      transition: "all .5s",
                      transform: _vm.lawertab
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont icon-gengduo",
                      on: { click: _vm.global },
                    }),
                  ]
                ),
              ]),
            ]),
            !this.lawer.chargeAmountMax
              ? _c("p", { staticClass: "case" }, [
                  _vm._v("系统暂未收集到该地区的标准数据"),
                ])
              : _vm._e(),
            _vm.lawertab && this.litigationPrice > 0
              ? _c(
                  "table",
                  { staticClass: "tableprice", attrs: { border: "1" } },
                  _vm._l(
                    _vm.TablePriceTwo.lawyerServiceFeeStandards,
                    function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(item.propertyAmountMin))]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.propertyAmountMax === -1
                                  ? ""
                                  : "-" + item.propertyAmountMax
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.propertyAmountMax === -1
                                  ? "以上部分"
                                  : "元部分"
                              )
                            ),
                          ]),
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              _vm._s((item.chargeRatioMin * 100).toFixed(1)) +
                                "%"
                            ),
                          ]),
                          item.chargeRatioMin != item.chargeRatioMax
                            ? _c("span", [
                                _vm._v(
                                  "-" +
                                    _vm._s(
                                      (item.chargeRatioMax * 100).toFixed(1)
                                    ) +
                                    "%"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.chargeRatioMin === 0
                                  ? item.chargeAmountMin
                                  : parseInt(
                                      item.propertyAmountMin *
                                        item.chargeRatioMin
                                    )
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.propertyAmountMax === -1
                                  ? ""
                                  : item.chargeRatioMin === 0
                                  ? -item.chargeAmountMax
                                  : -parseInt(
                                      item.propertyAmountMax *
                                        item.chargeRatioMax
                                    )
                              )
                            ),
                          ]),
                          _c("span", [_vm._v("元")]),
                          item.propertyAmountMax === -1
                            ? _c("span", [_vm._v("以上")])
                            : _vm._e(),
                        ]),
                      ])
                    }
                  ),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [_vm._v("元")]),
      _c("i", { staticClass: "iconfont icon-gengduo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }