var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: _vm.tabWrapperStyleUsingTransfrom,
      attrs: { id: "scroll-menu" },
      on: {
        "&touchstart": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchstart.apply(null, arguments)
        },
        "&touchmove": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchmove.apply(null, arguments)
        },
        "&touchend": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchend.apply(null, arguments)
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tabHeight === 0,
            expression: "tabHeight === 0",
          },
        ],
        staticClass: "bottom-click-trigger pull-trigger",
        on: { click: _vm.addTabHeight },
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRectMenu,
            expression: "showRectMenu",
          },
        ],
        staticClass: "menu-mask",
        on: { click: _vm.toggleRectMenu },
      }),
      _c(
        "div",
        {
          staticClass: "team-avatar pull-trigger",
          style: _vm.teamAvatarStyle,
          on: { click: _vm.startChat },
        },
        [_c("cooperation-user", { attrs: { avatarUrl: _vm.coopAvatarUrl } })],
        1
      ),
      _vm.showLeftMenu
        ? _c(
            "div",
            { staticClass: "left-menu", on: { click: _vm.toggleLeftMenu } },
            [_c("i", { staticClass: "iconfont icon-menu-s" })]
          )
        : _vm._e(),
      _vm.showRightMenu
        ? _c(
            "div",
            {
              staticClass: "rect-menu-trigger pull-trigger",
              on: { click: _vm.toggleRectMenu },
            },
            [_c("i", { staticClass: "iconfont icon-leimu pull-trigger" })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRectMenu,
              expression: "showRectMenu",
            },
          ],
          staticClass: "rect-menu-box",
        },
        _vm._l(_vm.hiddenTabs, function (menu, index) {
          return _c(
            "div",
            {
              key: menu.name,
              staticClass: "menu",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectTab(index)
                },
              },
            },
            [
              _c("div", { staticClass: "menu-item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("i", { class: "iconfont icon-" + menu.icon }),
                ]),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(menu.name))]),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "tabs-wrapper" },
        [
          _vm.renderTab || (_vm.tabs[_vm.active] !== "Caselaw" && _vm.otherTab)
            ? _c(
                "van-tabs",
                {
                  class: {
                    "case-nav-tabs-left-menu": _vm.showLeftMenu,
                    "case-nav-tabs-right-menu": _vm.showRightMenu,
                    "pull-trigger": true,
                  },
                  attrs: {
                    "line-height": 0,
                    "line-width": 0,
                    animated: "",
                    duration: 0.2,
                    swipeable: _vm.canSwipe,
                  },
                  on: { change: _vm.onTabChange },
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "van-tab",
                    {
                      key: tab.componentName,
                      staticClass: "dynamic-height-tab",
                      style: _vm.tabWrapperStyle,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tab-title pull-trigger",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("div", { staticClass: "tab-name pull-trigger" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === index,
                                  expression: "active === index",
                                },
                              ],
                              class: "iconfont icon-" + tab.icon,
                            }),
                            _vm._v(_vm._s(tab.name) + " "),
                          ]),
                        ]
                      ),
                      _vm.active === index
                        ? _c(
                            "div",
                            [
                              _c(
                                "van-pull-refresh",
                                {
                                  ref: "pullRefresh",
                                  refInFor: true,
                                  staticClass: "outter-pull-wrapper",
                                  attrs: {
                                    "pulling-text": "下拉收起面板",
                                    "loosing-text": "释放收起面板",
                                    "loading-text": " ",
                                    "animation-duration": 80,
                                  },
                                  on: { refresh: _vm.onPullDown },
                                  model: {
                                    value: _vm.inPullDown,
                                    callback: function ($$v) {
                                      _vm.inPullDown = $$v
                                    },
                                    expression: "inPullDown",
                                  },
                                },
                                [
                                  _c(tab.componentName, {
                                    ref: tab.componentName,
                                    refInFor: true,
                                    tag: "component",
                                    staticClass:
                                      "casedetail-tab-content-wrapper",
                                    style: _vm.tabContentWrapperStyle,
                                    attrs: {
                                      is_load: true,
                                      active: _vm.active,
                                    },
                                    on: { sliderSide: _vm.handleSideSwipe },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }