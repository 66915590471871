var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("ul", { ref: "nodelist" }, [
      _c("li", { staticClass: "jiedianlist" }, [
        _c("span", {
          staticClass: "iconfont",
          class:
            _vm.item.progress === "FINISHED"
              ? "icon-xuanzhong"
              : "icon-kongbai",
          on: {
            click: function ($event) {
              return _vm.change(_vm.item)
            },
          },
        }),
        _c("div", { staticClass: "list border-bottom" }, [
          _c(
            "div",
            { staticClass: "van-cell-group" },
            [
              _c("van-field", {
                ref: "input",
                staticClass: "van-field__body",
                class: _vm.item.progress === "FINISHED" ? "delete-line" : "",
                attrs: {
                  type: "textarea",
                  rows: "1",
                  border: "",
                  autosize: "",
                },
                on: {
                  blur: function ($event) {
                    return _vm.saveHeadline(_vm.item)
                  },
                },
                model: {
                  value: _vm.item.headline,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "headline", $$v)
                  },
                  expression: "item.headline",
                },
              }),
              _c(
                "div",
                { staticClass: "avatars" },
                _vm._l(_vm.item.user, function (j, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "avatar",
                      class: "avatar" + _vm.num,
                      on: {
                        click: function ($event) {
                          return _vm.chooseImg(index, $event)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: j.avatarUrl, alt: "" } })]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }