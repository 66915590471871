<template>
  <div class="chart-cell" :class="{ right: index % 2 }">
    <div
      style="width:100%;height:30px;line-height:30px;text-align:center;font-weight:bold"
    >
      {{ title }}
    </div>
    <ve-pie
      :height="height"
      :data="chartData"
      :settings="chartSetting"
      :series="series"
      class="ve-pie"
    />
  </div>
</template>
<script>
import VePie from 'v-charts/lib/pie.common'
export default {
  name: 'pie',
  props: ['title', 'series', 'index', 'chartSetting', 'chartData'],
  components: {
    VePie
  },
  data() {
    return {
      height: '0'
    }
  },
  methods: {
    getPosition() {
      const width = this.$el.clientWidth * 1.1
      this.height = width * 1.1 + 'px'
    }
  },
  mounted() {
    this.getPosition()
  }
}
</script>
<style scoped>
.chart-cell {
  /* height: 160px; */
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-bottom: 15px;
}
.ve-pie {
  margin-top: 10px;
}
</style>
