export default {
  methods: {
    getNodePosTime (nodeData) {
      const timeSetter = { hour: 0, minute: 0, second: 0, millisecond: 0 }
      const time = new Date()
      const today = new Date(window.moment(time).set(timeSetter)).getTime()
      // 节点数据
      const node = nodeData
      // 节点状态（也就是节点类型）
      const { nodeStatus, completeDateDisType } = node
      /*
        用来确定位置的时间 不同状态的节点选取的字段不一样
        落轴DOWN - downTime
        完成COMPLETE - completeTime
        漂浮FLOW - （ beginTime + endTime ）/ 2
      */
      // 水滴偏移至中午 也就是加12小时
      // const halfDayMS = 43200000
      let nodePosTime = 0
      if (completeDateDisType === 'BEGIN_AND_END') {
        nodePosTime = node.beginTime
      } else {
        if (nodeStatus === 'DOWN') {
          nodePosTime = node.downTime
        } else if (nodeStatus === 'COMPLETE') {
          nodePosTime = node.beginTime
        } else if (nodeStatus === 'FLOW') {
          // TODO 根据是否与today相交来确定时间
          if (node.beginTime <= today && node.endTime >= today) {
            nodePosTime = today
          } else {
            nodePosTime = node.beginTime
          }
        } else {
          nodePosTime += node.beginTime
        }
      }

      return nodePosTime
    }
  }
}
