<template>
  <div class="task-tab">
    <!-- 加载 -->
    <div class="task-loading-wrapper" v-show="showLoading">
      <van-loading />
    </div>

    <!-- 节点列表 -->
    <node
      v-for="val in jiedianList"
      :val="val"
      :key="val.nodeId"
      :isshow="isshow"
      :taskjiedian="taskJiedian"
      @showDeleteOverBuilt="shownode"
      @closeDeleteOverBuilt="closenode"
      :jiedianList="jiedianList"
      ref="node"
    ></node>
    <nonode
      v-for="(item, index) in task"
      :key="index"
      :item="item"
      :in="index"
      @DeleteOverBuilt="showDeleteOverBuilt"
      ref="nodelist"
    ></nonode>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
    </van-list>
    <div class="task-kongbai"></div>
  </div>
</template>
<script>
import node from './components/node'
import nonode from './components/nonode'
import { caseBase } from '~api-config'
import { setTimeout } from 'timers'
export default {
  name: 'task-tab',
  components: {
    node,
    nonode
  },
  data() {
    return {
      changes: null,
      taskList: [],
      taskJiedian: [], // 存储有时间的列表
      noJiedian: [], // 存储没有时间的列表
      showLoading: true, // 加载页面
      loading: false, // 加载列表数据
      isLoading: false, // 下拉刷新
      finished: false, // 结束加载列表数据
      showNode: false,
      showCell: true,
      changeBtn: null,
      container: () => document.body,
      show: false,
      listLength: 0,
      nowtime: null, // 当前时间为毫秒数
      isshow: false, // 判断节点列表是否全部显示
      task: [],
      jiedianList: []
    }
  },
  computed: {
    changeButton() {
      if (
        this.$store.state.caseDetail.currrentTabName === 'TaskTab' &&
        this.$store.state.caseDetail.bottomTabMode !== 'MIN'
      ) {
        return true
      } else {
        return false
      }
    },
    changess() {
      return this.$store.state.caseDetail.watchButton
    }
  },
  methods: {
    // 获取日程列表
    gettaskList() {
      this.showLoading = true
      const caseId = this.$route.params.caseId
      // const userId = this.$store.state.user.userInfo.id

      this.$axios
        .get(`${caseBase}/management/legal/case/${caseId}/task`)
        .then(res => {
          if (res.status === 200) {
            if (this.taskJiedian.length === 0) {
              for (const k of res.data.data) {
                if (k.nodeId !== null) {
                  this.taskJiedian.push(k)
                } else {
                  this.noJiedian.push(k)
                }
              }
              // console.log(this.taskJiedian)
              // console.log(this.noJiedian[0].taskViews)
            } else {
              this.taskJiedian.splice(0, this.taskJiedian.length)
              this.noJiedian.splice(0, this.noJiedian.length)
              for (const i of res.data.data) {
                if (i.nodeId !== null) {
                  this.taskJiedian.push(i)
                } else {
                  this.noJiedian.push(i)
                }
              }
            }
            this.$nextTick(() => {
              this.rank(this.taskJiedian)
            })
            // console.log('taskJiedian======', this.taskJiedian)
            // console.log(this.noJiedian[0].taskViews)
            this.task = this.noJiedian[0].taskViews
            this.listLength = res.data.data.length
            this.showLoading = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 对日程列表进行排序
    rank(arr) {
      var len = arr.length
      const jiedianList = [...arr]
      // console.log(len)
      for (var i = 0; i < len; i++) {
        for (var j = 0; j < len - 1 - i; j++) {
          // 第一个数的completeTime不为空
          if (jiedianList[j].legalCaseNode.completeTime !== null) {
            // 第二个数的completeTime不为空
            if (jiedianList[j + 1].legalCaseNode.completeTime !== null) {
              if (
                jiedianList[j].legalCaseNode.completeTime >
                jiedianList[j + 1].legalCaseNode.completeTime
              ) {
                const temp = jiedianList[j + 1]
                jiedianList[j + 1] = jiedianList[j]
                jiedianList[j] = temp
              }
            } else {
              // 第二个数的completeTime为空但是beginTime不为空
              if (jiedianList[j + 1].legalCaseNode.beginTime !== null) {
                if (
                  jiedianList[j].legalCaseNode.completeTime >
                  jiedianList[j + 1].legalCaseNode.beginTime
                ) {
                  const temp = jiedianList[j + 1]
                  jiedianList[j + 1] = jiedianList[j]
                  jiedianList[j] = temp
                }
              } else {
                // 第二个数的completeTime为空但是beginTime为空但是endTime不为空
                if (jiedianList[j + 1].legalCaseNode.endTime !== null) {
                  if (
                    jiedianList[j].legalCaseNode.completeTime >
                    jiedianList[j + 1].legalCaseNode.endTime
                  ) {
                    const temp = jiedianList[j + 1]
                    jiedianList[j + 1] = jiedianList[j]
                    jiedianList[j] = temp
                  }
                }
              }
            }
          } else {
            // 第一个数的completeTime为空但是beginTime不为空
            if (jiedianList[j].legalCaseNode.beginTime !== null) {
              // 第二个数的completeTime不为空
              if (jiedianList[j + 1].legalCaseNode.completeTime !== null) {
                if (
                  jiedianList[j].legalCaseNode.beginTime >
                  jiedianList[j + 1].legalCaseNode.completeTime
                ) {
                  const temp = jiedianList[j + 1]
                  jiedianList[j + 1] = jiedianList[j]
                  jiedianList[j] = temp
                }
              } else {
                // 第二个数的completeTime为空但是beginTime不为空
                if (jiedianList[j + 1].legalCaseNode.beginTime !== null) {
                  if (
                    jiedianList[j].legalCaseNode.beginTime >
                    jiedianList[j + 1].legalCaseNode.beginTime
                  ) {
                    const temp = jiedianList[j + 1]
                    jiedianList[j + 1] = jiedianList[j]
                    jiedianList[j] = temp
                  }
                } else {
                  // 第二个数的completeTime为空但是beginTime为空但是endTime不为空
                  if (jiedianList[j + 1].legalCaseNode.endTime !== null) {
                    if (
                      jiedianList[j].legalCaseNode.beginTime >
                      jiedianList[j + 1].legalCaseNode.endTime
                    ) {
                      const temp = jiedianList[j + 1]
                      jiedianList[j + 1] = jiedianList[j]
                      jiedianList[j] = temp
                    }
                  }
                }
              }
            } else {
              // 第一个数的completeTime为空且beginTime为空但是endTime不为空
              if (jiedianList[j].legalCaseNode.endTime !== null) {
                // 第二个数的completeTime不为空
                if (jiedianList[j + 1].legalCaseNode.completeTime !== null) {
                  if (
                    jiedianList[j].legalCaseNode.endTime >
                    jiedianList[j + 1].legalCaseNode.completeTime
                  ) {
                    const temp = jiedianList[j + 1]
                    jiedianList[j + 1] = jiedianList[j]
                    jiedianList[j] = temp
                  }
                } else {
                  // 第二个数的completeTime为空但是beginTime不为空
                  if (jiedianList[j + 1].legalCaseNode.beginTime !== null) {
                    if (
                      jiedianList[j].legalCaseNode.endTime >
                      jiedianList[j + 1].legalCaseNode.beginTime
                    ) {
                      const temp = jiedianList[j + 1]
                      jiedianList[j + 1] = jiedianList[j]
                      jiedianList[j] = temp
                    }
                  } else {
                    // 第二个数的completeTime为空但是beginTime为空但是endTime不为空
                    if (jiedianList[j + 1].legalCaseNode.endTime !== null) {
                      if (
                        jiedianList[j].legalCaseNode.endTime >
                        jiedianList[j + 1].legalCaseNode.endTime
                      ) {
                        const temp = jiedianList[j + 1]
                        jiedianList[j + 1] = jiedianList[j]
                        jiedianList[j] = temp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // this.$nextTick(() => {
      this.jiedianList = jiedianList
      console.log(jiedianList)
      // })
    },
    onLoad() {
      setTimeout(() => {
        this.loading = false
        if (
          this.noJiedian.length + this.taskJiedian.length >=
          this.listLength
        ) {
          this.finished = true
        }
      }, 500)
    },

    // 键盘事件
    keydownHandle(event, index, item) {
      const caseId = this.$route.params.caseId
      if (event.keyCode === 13) {
        // 新增任务
        const params = {
          caseId: caseId,
          createTime: '',
          displayOrder: 0,
          isChecked: 0,
          taskContent: '',
          id: '',
          status: 1
        }
        this.taskList.splice(index + 1, 0, params)
        this.$nextTick(() => {
          this.$refs.input[index + 1].focus()
        })
        event.preventDefault()
      } else if (
        event.keyCode === 8 &&
        this.taskList[index].id === '' &&
        event.target.value === ''
      ) {
        // 删除没有taskContent的任务
        this.taskList.splice(index, 1)
        var key = index === 0 ? index : index - 1
        this.$refs.input[key].focus()

        event.preventDefault()
      } else if (
        event.keyCode === 8 &&
        event.target.value === '' &&
        this.taskList[index].id !== ''
      ) {
        const taskId = this.taskList[index].id
        // 删除有taskContent的任务
        this.$axios
          .post(`${caseBase} / lts / case_task /delete /${taskId}`)
          .then(res => {
            if (res.status === 200) {
              this.taskList.splice(index, 1)
              // console.log('success')
              var key = index === 0 ? index : index - 1
              this.$refs.input[key].focus()
            }
          })
          .catch(err => {
            console.log(err)
          })
        event.preventDefault()
      }
    },

    toggle(item) {
      if (item.isChecked === 0) {
        item.isChecked = 1
      } else if (item.isChecked === 1) {
        item.isChecked = 0
      }
    },
    createMenu() {
      // 切换
      this.changeBtn = document.createElement('div')
      this.changeBtn.innerHTML = '历史'
      this.changeBtn.classList.add('taskTabButton')
      this.changeBtn.addEventListener('click', this.showCellOrNode)
      this.content.appendChild(this.changeBtn)
    },
    mounteMenu() {
      this.createMenu()
    },
    destroyMenu() {
      if (this.changeBtn) {
        this.content.removeChild(this.changeBtn)
        this.changeBtn = null
      }
    },
    showCellOrNode() {
      // 显示/隐藏节点列表
      // console.log(this.$store.state.caseDetail.watchButton)
      this.isshow = !this.isshow
      // 切换
      this.showNode = !this.showNode
      this.showCell = !this.showCell

      if (this.showCell && this.checkTasklength) {
        this.show = true
      } else if (this.checkjiedianlength && this.showNode) {
        this.show = true
      } else {
        this.show = false
      }
    },
    showDeleteOverBuilt() {
      // 通过选中图标 控制bottombar的显示或隐藏
      if (this.checkTasklength) {
        this.show = true
      } else {
        this.show = false
      }
    },
    shownode() {
      // 节点中 选中图标时 bottombar显示
      if (this.checkjiedianlength) {
        this.show = true
      }
    },
    closenode() {
      // 节点中 未选中图标时 bottombar隐藏
      if (this.checkTasklength) {
        this.show = true
      } else if (!this.checkjiedianlength) {
        this.show = false
      }
    },
    closeBottomBar() {
      // 不在taskTab下 bottombar隐藏
      this.show = false
    }
  },
  mounted() {
    setTimeout(() => {
      this.content = document.querySelector('.fb-page')
      this.gettaskList()
      this.createMenu()
    }, 500)
  },

  destroyed() {
    this.destroyMenu()
  },
  watch: {
    changeButton(value) {
      if (value) {
        this.mounteMenu()
        this.showDeleteOverBuilt()
        this.shownode()
      } else {
        this.destroyMenu()
        this.closeBottomBar()
      }
    },

    changess(val) {
      location.reload()
      // this.gettaskList()
    }
  }
}
</script>
<style lang="stylus">
.task-loading-wrapper
  display flex
  justify-content center
  align-items center
.taskTabButton
  width 50px
  height 30px
  background #4899FC
  z-index 105
  position fixed
  bottom 50px
  right 0
  border-top-left-radius 15px
  border-bottom-left-radius 15px
  text-align center
  padding-top 5 px
  font-size 14px
  font-family PingFangSC
  color #fff
.task-kongbai
  height 48px
.deleteandoverandbuilt
  width 100%
  height 52px
  background-color #fff
  border-top 1px solid rgba(238, 238, 238, 1)
  position fixed
  .taskwrap
    display flex
    justify-content space-between
    margin 10px 52px 0 52px
    & li
      display flex
      flex-direction column
      align-items center
      color #333
      .text
        font-size 11px
      .iconfont
        font-size 20px
</style>
