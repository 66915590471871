var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showComponent,
          expression: "showComponent",
        },
      ],
      staticClass: "ana-summary",
      style: _vm.wrapperStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "summary",
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        },
        [
          _c("van-field", {
            attrs: {
              type: "textarea",
              placeholder: "案情概要",
              rows: "2",
              disabled: "",
              autosize: "",
            },
            model: {
              value: _vm.$store.state.caseDetail.tabAnaSummary,
              callback: function ($$v) {
                _vm.$set(_vm.$store.state.caseDetail, "tabAnaSummary", $$v)
              },
              expression: "$store.state.caseDetail.tabAnaSummary",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "textProp",
          attrs: { "get-container": "body" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "conatiner" }, [
            _c(
              "div",
              { staticClass: "textEditer", on: { click: _vm.editerFocus } },
              [
                _c("van-field", {
                  ref: "editerArea",
                  staticClass: "editerArea",
                  attrs: {
                    type: "textarea",
                    placeholder: "案情概要",
                    autosize: "",
                  },
                  model: {
                    value: _vm.$store.state.caseDetail.tabAnaSummary,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$store.state.caseDetail,
                        "tabAnaSummary",
                        $$v
                      )
                    },
                    expression: "$store.state.caseDetail.tabAnaSummary",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "save" }, [
              _c("span", { on: { click: _vm.save } }, [_vm._v("重新检索")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }