<template>
  <div id="timeline-toolbar">
    <div class="zoom-in"
         @click="zoomIn"><i class="iconfont icon-minus"></i></div>
    <div class="zoom-out"
         @click="zoomOut"><i class="iconfont icon-add"></i></div>
  </div>
</template>

<script>
export default {
  name: 'timeline-toolbar',
  methods: {
    zoomIn () {
      window.TLDetail.zoomOut(0.2, { animation: { duration: 400, easingFunction: 'easeInOutQuad' } })
    },
    zoomOut () {
      window.TLDetail.zoomIn(0.2, { animation: { duration: 400, easingFunction: 'easeInOutQuad' } })
    }
  }
}
</script>

<style lang="stylus">
#timeline-toolbar
  position absolute
  right 8px
  transform translate3d(0, 2px, 0)
  height 18px
  width 80px
  z-index 3
  display flex
  justify-content space-around
  box-sizing border-box
  .zoom-in, .zoom-out
    background #4998FC
    width 50%
    text-align center
    height 18px
    color #fff
    border-radius 10px 0 0 10px
    border-right 1px solid #fff
    font-size 18px
    display flex
    justify-content center
    align-items center
  .zoom-out
    border-right none
    border-radius 0 10px 10px 0
    font-size 18px
</style>
