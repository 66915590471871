<template>
  <div class="moveEvidence">
    <div class="movEvidenceTop">
      <i class="iconfont icon-back"
         @click="backChoice"></i>
      <span class="movEvidence-text">移动</span>
      <br><br>
      <input type="radio"
             name="moveSrc"
             id=""
             value="当前目录（预设）"
             v-model="choosedFileNew"
             class="radio"><span class="movEvidence-text">当前目录</span>
      <div class="line"></div>
      <div class="movEvidenceContent">
        <div class="moEmpty hidden">
          <img src="./static/imgs/moempty.png"
               alt="">
        </div>
        <p v-for="(item,index) in randerArray"
           :key="index"
           class="fileEach">
          <input type="radio"
                 name="moveSrc"
                 id=""
                 :value="item"
                 v-model="choosedFileNew"
                 class="radio"><span @click="fileInit(item)"
                class="movEvidence-text">{{item.fileName}}</span>
        </p>
      </div>
      <div class="line"></div>
      <!-- <div>
        <i class="iconfont icon-add"></i>
        <span class="movEvidence-text" style="color:#478eee">自定义</span>
      </div> -->
      <p class='select-btn'>
        <span class="movEvidence-text" style="color:#478eee" @click="clickBack">取消</span>
        <span class="movEvidence-text" style="color:#478eee" @click="moveDown">确定</span>
      </p>
    </div>
    <!-- <fb-header slot="header"
               title="移动"
               fixed
               style="">
      <fb-button slot="left"
                 icon="back"
                 @click="clickBack"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header> -->
    <!-- 文件夹better-scroll容器 -->
    <!-- <div class="evidence"
         :class="{bordernone:!randerArray.length}"
         ref="scrollArea"> -->
    <!-- 文件夹container -->
    <!-- <div class="evidence-cell-container"
           ref="evidenceContainer"> -->
    <!-- 是否有上一级 -->
    <!-- <div class="evidence-src">
          <div class="evidence-src-scroll">
            <span class="evidence-src-ex"
                  v-for="(item,index) in srcArray"
                  :key="index">{{item}}<span class="evidence-src-ex-border"></span><span class="evidence-src-ex-border-bottom"></span>
            </span>
          </div>
        </div>
        <div class="evidence-cell"
             v-if="positionArray.length"
             @click="fileBack">
          ......
        </div> -->
    <!-- v-for列表渲染 -->
    <!-- <div class="evidence-cell"
             v-for="(item,index) in randerArray"
             :key="index"
             @click="fileInit(item)">
          <span class="evidence-left">
            <span class="iconfont icon-folder"></span>
            <span>{{item.fileName}}</span>
          </span>
          <span class="evidence-right"
                @click.prevent.stop="chooseRadioHandle(item)">
            <input type="radio"
                   name="file"
                   :value="item"
                   v-model="choosedFile"
                   :id="item.id"
                   class="inputcheckbox">
            <label class="iconfont icon-selected"
                   :for="item.id"></label>
          </span>

        </div>

      </div>

    </div> -->
    <!-- <div class="moveConfirm">
      <span @click="moveDown">移动</span>
    </div> -->
  </div>
</template>
<script>
// import BScroll from 'better-scroll'
import { caseBase } from '~api-config'
import { mapState } from 'vuex'
// import FbHeader from '~components/header'
// import FbButton from '~components/button'
export default {
  name: 'moveEvidence',
  components: {
    // FbHeader,
    // FbButton
  },
  props: ['choosedArray', 'choosedType', 'kangqianye'],
  data () {
    return {
      srcArray: ['根目录'],
      array: [], // 渲染数组
      positionArray: [], // 文件位置
      choosedFileNew: '当前目录（预设）',
      evidence: { id: null } // 证据页
    }
  },
  computed: {
    randerArray () {
      var newArray = this.array.filter(element => {
        return (element.isDir && this.isSelf(element.id))
      })
      return newArray
    },
    ...mapState('caseDetail', {
      evidenceId: state => state.evidenceId
    }),
    ...mapState('user', {
      userId: state => state.userInfo.id
    }),
    caseId () {
      return this.$route.params.caseId
    }
  },
  methods: {
    // 返回（为完成）
    clickBack () {
      this.$emit('goback')
    },
    // // 点击选中文件取消选中状态
    // chooseRadioHandle (item) {
    //   if (this.choosedFile && item.id === this.choosedFile.id) {
    //     this.choosedFile = null
    //   } else {
    //     this.choosedFile = item
    //   }
    // },
    isSelf (id) {
      var a = true
      this.choosedArray.forEach(element => {
        if (element.id === id) {
          a = false
        }
      })
      return a
    },
    // 点击进入文件夹下层
    fileInit (item) {
      // console.log('fileInit')
      this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { pId: item.id, creater: this.userId } })
        .then((res) => {
          this.array = res.data.data.file
          this.positionArray.push(item)
          this.srcArray.push(item.fileName)
          // console.log(res)
        })
      this.choosedFileNew = '当前目录（预设）'
    },
    // 返回上层文件夹
    fileBack () {
      var srcLength = this.srcArray.length
      this.srcArray.splice(srcLength - 1, 1)
      if (this.positionArray.length > 1) { // 有上层pid
        // console.log('回到上层')
        this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { pId: this.positionArray[this.positionArray.length - 1].pId, creater: this.userId } })
          .then((res) => {
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      } else { // 没有上层pid请求证据页页面
        // console.log('回到证据页')
        this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { caseId: this.caseId, pId: this.evidence.id, creater: this.userId } })
          .then((res) => {
            // console.log(res)
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      }
      this.choosedFileNew = '当前目录（预设）'
    },
    // 完成移动
    moveDown () {
      // console.log(this.choosedFile)
      const loading = this.$toast.loading({
        message: '移动中...'
      })
      if (this.choosedFileNew !== '当前目录（预设）') { // 如果选中文件夹，则移动到文件夹中
        if (this.kangqianye === this.choosedFileNew.id) {
          this.clickBack()
          return
        }
        this.choosedArray.forEach((element, index) => {
          // 修改pId
          element.pId = this.choosedFileNew.id
          // 传入参数
          var paramValue = `id=${element.id}&pId=${element.pId}&creater=${this.userId}`
          // if (this.choosedType === 0) {//在证据页的文件要移动，需要把caseId和type给改掉
          //   paramValue += `&casseId=0&type=0`;
          // }
          this.$axios.put(`${caseBase}/design/fileManage/update?` + paramValue)
            .then((res) => {
            })
          if (this.choosedArray.length - 1 === index) {
            // 最后
            this.$emit('moved', this.choosedFileNew.id, loading)
          }
        })
      } else { // 如果没有选中文件，则移动到当前页
        if (this.positionArray.length) { // 不在证据页
          if (this.kangqianye === this.positionArray[this.positionArray.length - 1].id) {
            this.clickBack()
            return
          }
          this.choosedArray.forEach((element, index) => {
            element.pId = this.positionArray[this.positionArray.length - 1].id
            var paramValue = `id=${element.id}&pId=${element.pId}&creater=${this.userId}`
            // if (this.choosedType === 0) {//在证据页的文件要移动，需要把caseId和type给改掉
            //   paramValue += `&casseId=0&type=0`;
            // }
            this.$axios.put(`${caseBase}/design/fileManage/update?` + paramValue)
              .then((res) => {
                // console.log(res)
              })
            if (this.choosedArray.length - 1 === index) {
              // 最后
              this.$emit('moved', this.positionArray[this.positionArray.length - 1].id, loading)
            }
          })
        } else { // 在证据页
          if (this.kangqianye === this.evidence.id) {
            this.clickBack()
            return
          }
          this.choosedArray.forEach((element, index) => {
            element.pId = this.evidence.id
            this.$axios.put(`${caseBase}/design/fileManage/update?` + `id=${element.id}&caseId=${this.caseId}&pId=${element.pId}&creater=${this.userId}`)
              .then((res) => {
                // console.log(res)
              })
            if (this.choosedArray.length - 1 === index) {
              // 最后
              this.$emit('moved', this.evidence.id, loading)
            }
          })
        }
      }
    },
    backChoice () {
      if (this.positionArray.length === 0) {
        return this.clickBack()
      } else {
        return this.fileBack()
      }
    }
  },
  mounted () {
    // // this.scrollwidth = document.getElementsByClassName('evidence-src')[0].scrollLeft
    // this.qiniuUrl = qiniuBase
    // // betterscroll设置
    // this.scroll = new BScroll(this.$refs.scrollArea, { click: true })
    // 挂载信息
    this.evidence.id = this.evidenceId
    // console.log('证据id', this.evidenceId, this.userId, this.evidence.id)
    if (!this.evidence.id) {
      this.$toast.loading({
        message: '加载失败...'
      })
      return
    }
    const loading = this.$toast.loading({
      message: '加载中...'
    })
    this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { caseId: this.caseId, creater: this.userId, pId: this.evidence.id } })
      .then(res => {
        // console.log('证据查询结果', res.data)
        this.array = res.data.data.file
        // console.log(this.array)
        loading.clear()
      })
  },
  watch: {
    randerArray () {
      var length = this.randerArray.length
      if (length === 0) {
        // console.log(11111)
        document.getElementsByClassName('moEmpty')[0].classList.remove('hidden')
      } else {
        document.getElementsByClassName('moEmpty')[0].classList.add('hidden')
      }
    },
    positionArray () {
      if (this.positionArray.length > 0) {
        document.getElementsByClassName('movEvidence-text')[0].innerText = this.positionArray[this.positionArray.length - 1].fileName
      } else {
        document.getElementsByClassName('movEvidence-text')[0].innerText = '移动'
      }
    }
  }
}
</script>
<style scoped>
.moveEvidence {
  width: 345px;
  height: 477px;
  background-color: white;
}
.movEvidenceTop {
  width: 295px;
  height: 20px;
  line-height: 20px;
  margin: 0 auto;
  margin-top: 25px;
}
.movEvidence-text {
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 20px;
  margin-left: 15px;
}
.radio {
  width: 15px;
  height: 15px;
  position: relative;
  top: -2px;
}
.line {
  width: 295px;
  height: 1px;
  background: rgba(204, 204, 204, 1);
  margin: 20px auto;
}
.fileEach {
  margin-top: 20px;
}
.fileEach:nth-of-type(1) {
  margin-top: 0;
}
.moEmpty {
  width: 100%;
  height: auto;
  text-align: center;
}
.hidden {
  display: none;
}
.moEmpty > img {
  width: 220px;
  height: auto;
  margin: 0 auto;
}
.movEvidenceContent {
  height: 265px;
  overflow-y: scroll;
}
.icon-add::before{
  width: 17px;
  height: 17px;
  color: white;
  background-color: #478eee;
  border-radius: 50%;
}
.select-btn{
  text-align: right;
}
.select-btn>span:nth-of-type(2){
  margin-left: 40px;
}
.evidence {
  width: 345px;
  margin: 15px auto;
  box-shadow: 0px 1px 8px 1px rgba(64, 180, 233, 0.23);
  color: #666666;
  padding: 0 0px;
  overflow-y: hidden;
  overflow-x: scroll;
  max-height: calc(100vh - 137px);
  background-color: white;
}
.evidence-top {
  height: 44px;
  border-bottom: 1px #d2d2d2 solid;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #169bd5;
}
.evidence-top .iconfont {
  margin-left: 20px;
  font-size: 25px;
  color: #169bd5;
}
.inputcheckbox {
  position: absolute;
  opacity: 0;
}
.dialoged-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.moveConfirm {
  padding-right: 50px;
  text-align: right;
  font-size: 20px;
  color: #478eee;
}
</style>
