<template>
  <div class="gongGaoZuJian">

    <div class="cell"
         v-for="(item,index) in array"
         :key="index">
      <span>
        <span class="red">*</span>
        <span>{{item.name}}</span>
      </span>
      <div class="time border-bottom">
        <div class="left">
          <span>{{item.interval}}</span>
          <span>日至</span>
        </div>
        <div class="right"
             @click="showTimePicker(item)">
          <span class="endDate">{{calcDate(item)}}</span>
          <i class="iconfont icon-calendar"></i>
        </div>
      </div>
      <van-popup v-model="item.showTimer"
                 position="bottom">
        <van-datetime-picker v-model="$store.state.nodeform[item.date]"
                             :type="$store.state.nodeform.dateKind"
                             :item-height="pickCellheight"
                             :min-date="minDate"
                             @confirm="confirmDateHandle(item)"
                             @cancel="cancelDateHandle"
                             :cancel-button-text="$store.state.nodeform.dateKind=='date'?'切换为时间':'切换为日期'" />
      </van-popup>
    </div>

  </div>
</template>
<script>
export default {
  name: 'gongGaoZuJian',
  computed: {
    pickCellheight () {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    minDate () {
      return new Date(this.$store.state.nodeform.startDate)
    }
  },
  data () {
    return {
      array: [
        {
          name: '送达',
          showTimer: false,
          date: 'juZhengEndDate',
          interval: 60
        },
        {
          name: '答辩',
          showTimer: false,
          date: 'daBianEndDate',
          interval: 15
        },
        {
          name: '举证',
          showTimer: false,
          date: 'juZhengEndDate',
          interval: 30
        },
        {
          name: '开庭',
          showTimer: false,
          date: 'kaiTingEndDate',
          interval: 3
        }
      ]
    }
  },
  methods: {
    calcDate (item) {
      let a
      if (item.name === '送达') {
        a = new Date(this.$store.state.nodeform.startDate + 1000 * 60 * 60 * 24 * 60)
      } else {
        a = new Date(this.$store.state.nodeform[item.date])
      }
      let time = ''
      time += a.getFullYear() + '/'
      time += (a.getMonth() + 1) > 9 ? (a.getMonth() + 1) : '0' + (a.getMonth() + 1)
      time += '/'
      time += a.getDate() > 9 ? a.getDate() : ('0' + a.getDate())
      return time
    },
    showTimePicker (item) {
      if (item.name !== '送达') {
        item.showTimer = true
      }
    },
    confirmDateHandle (item) {
      item.showTimer = false
    },
    cancelDateHandle () {
      if (this.$store.state.nodeform.dateKind === 'date') {
        this.$store.state.nodeform.dateKind = 'datetime'
      } else {
        this.$store.state.nodeform.dateKind = 'date'
      }
    }
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
.cell
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    opacity 0
    margin-right 5px
  .time
    width 70%
    padding 5px 15px
    display flex
    align-items center
    justify-content space-between
    .left
      flex-grow 1
      display flex
      justify-content space-between
      align-items center
    .right
      margin-left 10px
      display flex
      align-items center
      justify-content space-between
      .endDate
        margin-right 10px
      i
        color $color
        transform scale(1.5)
</style>
