var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("li", { staticClass: "wrap" }, [
        _c("span", {
          staticClass: "iconfont",
          class: _vm.calIcon,
          attrs: { id: "icon" },
          on: { click: _vm.changeIcon },
        }),
        _c("div", { staticClass: "lists" }, [
          _c("div", { staticClass: "lists-left" }, [
            _c("span", { staticClass: "item-name", on: { click: _vm.info } }, [
              _vm._v(" " + _vm._s(_vm.item.documentName) + " "),
              _c("span", { staticClass: "item-count" }, [
                _vm._v(_vm._s(_vm.item.count ? `(${_vm.item.count})` : "")),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "lists-right" }, [
            _c("span", { staticClass: "item-editor" }, [
              _vm._v(_vm._s(_vm.item.editor)),
            ]),
            _c("em", { staticClass: "item-time" }, [
              _vm._v(_vm._s(_vm.item.type === null ? "" : _vm.calTime)),
            ]),
            _c("span", {
              staticClass: "iconfont icon-more",
              class: _vm.Bgcolor ? "color2" : "color1",
              on: { click: _vm.changeBgcolor },
            }),
          ]),
        ]),
      ]),
      _vm._l(_vm.sonNodeHistory, function (val, index) {
        return _c("historylist", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHistory,
              expression: "showHistory",
            },
          ],
          key: index,
          ref: "history",
          refInFor: true,
          attrs: { historyData: { val, count: _vm.item.count - index - 1 } },
          on: { showBottomBar: _vm.checkHistory },
        })
      }),
      _c("message", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showMessage,
            expression: "showMessage",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }