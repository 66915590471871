<template>
  <div class="startTime">
    <div class="start">
      <span>
        <span class="red">*</span>
        <span>{{
          propsData && propsData.timeName ? propsData.timeName : '日期'
        }}</span>
      </span>
      <div class="timePiker border-bottom" @click="showStartTimer = true">
        <span :style="{ color: timeExist ? '#bbb' : '#000' }">{{
          startTimes
        }}</span>
        <i class="iconfont icon-calendar"></i>
      </div>
    </div>
    <van-popup v-model="showStartTimer" position="bottom" get-container="body">
      <van-datetime-picker
        v-model="dateTime"
        :type="$store.state.nodeform.dateKind"
        :min-date="minDate"
        :item-height="pickCellheight"
        @confirm="confirmDateHandle"
        @cancel="cancelDateHandle"
        @change="changeHandle"
        :cancel-button-text="
          $store.state.nodeform.dateKind === 'date'
            ? '切换为时间'
            : '切换为日期'
        "
      />
    </van-popup>
    <!-- 时间间隔 -->
    <interval-date v-if="propsData.showEndTimePicker" ref="interval" />
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import intervalDate from './intervalDate'
export default {
  name: 'startTime',
  components: {
    intervalDate
  },
  computed: {
    pickCellheight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    startTimes() {
      const a = new Date(this.$store.state.nodeform.startDate)
      if (a) {
        if (a.getHours() === 0 && a.getMinutes() === 0) {
          return window.moment(a).format('YYYY/MM/DD')
        } else {
          return window.moment(a).format('YYYY/MM/DD h:mm')
        }
      } else {
        const now = new Date()
        return window.moment(now).format('YYYY/MM/DD h:mm')
      }
    },
    // minDate() {
    //   return new Date(
    //     this.$store.state.nodeform.startDate - 1000 * 60 * 60 * 24 * 365 * 5
    //   )
    // },
    dateTime: {
      get() {
        return new Date(this.$store.state.nodeform.startDate)
      },
      set(value) {
        this.setFormKV({ key: 'startDate', val: value.getTime() })
      }
    }
  },
  props: ['propsData'],
  data() {
    return {
      showStartTimer: false,
      minDate: null,
      timeExist: true
    }
  },
  methods: {
    ...mapMutations('nodeform', ['setFormKV']),
    confirmDateHandle(value) {
      this.timeExist = false
      this.setFormKV({ key: 'startDate', val: value.getTime() })
      this.showStartTimer = false
    },
    cancelDateHandle() {
      if (this.$store.state.nodeform.dateKind === 'date') {
        this.setFormKV({ key: 'dateKind', val: 'datetime' })
      } else {
        this.setFormKV({ key: 'dateKind', val: 'date' })
      }
    },
    changeHandle() {
      if (this.propsData.showEndTimePicker) {
        this.$refs.interval.delayHandle()
      }
    }
  },
  beforeMount() {
    this.minDate = new Date(
      this.$store.state.nodeform.startDate - 1000 * 60 * 60 * 24 * 365 * 5
    )
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
$borderColor = #D3D5D6
.startTime
  margin 15px 0
  color $fontColor
  .red
    color red
    margin-right 5px
  .start
    display flex
    align-items center
    justify-content space-between
    .timePiker
      width 70%
      padding 5px 15px
      border-color $borderColor
      border-radius 10px
      display flex
      align-items center
      justify-content space-between
      .iconfont
        color $color
        // font-size 1.3em
        transform scale(1.5)
</style>
