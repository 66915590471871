var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "court" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "search border-bottom", on: { click: _vm.inputFuguan } },
        [
          _c("span", { staticClass: "courtName" }, [
            _vm._v(_vm._s(_vm.courtName)),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": false, position: "right" },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _vm.showPopup
            ? _c("search-court", {
                on: { clickback: _vm.clickback, choosed: _vm.choosed },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _c("span", [_vm._v("法院")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }