<template>
  <div>
    <li :class="this.historyData.val.status?'history':'delete'">
      <div class="shanchu">
        <elCheckBox v-model="historyData.val.flag"
                    style="vertical-align:-25px"></elCheckBox>
      </div>
      <div class="history-list">
        <div class="history-left">
          <span class="history-name"
                @touchend="goEditorPage"
                @click="desktop(historyData.val.documentId,historyData.val.groupId,historyData.val.id,historyData.val.modelId,historyData.val.caseId)">{{historyData.val.documentName}}>{{historyData.val.documentName}}</span>
        </div>
        <div class="history-right">
          <span class="name">{{historyData.val.editor}}</span>
          <em class="history-newtime">{{calTimes}}</em>
        </div>
      </div>
    </li>
  </div>
</template>
<script>
// import { caseBase } from '~api-config'
import elCheckBox from '@/pages/precedentDetail/tabs/checkBox'
export default {
  name: 'historyList',
  props: ['historyData'],
  components: { elCheckBox },
  data () {
    return {
      active: false// 点击 更改图标类名
    }
  },
  computed: {
    calTimes () {
      const times = new Date(this.historyData.val.createTime)
      const Year = times.getFullYear()
      let Month = times.getMonth() + 1
      let Days = times.getDate()
      Month = Month < 10 ? ('0' + Month) : Month
      Days = Days < 10 ? ('0' + Days) : Days
      return Year + '.' + Month + '.' + Days
    }
  },
  methods: {
    desktop (documentId, groupId, id, modelId, caseId) {
      const path = `/editor/${caseId}?caseDocumentId=${documentId}&groupId=${groupId || null}&modelId=${modelId || null}&documentRecordId=${id}&caseId=${caseId}`
      this.$router.push({ path })
    },
    goEditorPage () {
      const caseId = this.$route.params.caseId
      const path = `/editor/${caseId}?caseDocumentId=${this.historyData.val.documentId}`
      this.$router.push({ path })
    },
    change () {
      this.active = !this.active
      this.historyid = this.historyData.val.myDocumentId
      this.$emit('showBottomBar', this.historyid, this.active)
    }
  }
}
</script>
<style lang="stylus" scoped>
.shanchu
  margin 0 14px 0 44px
  font-size 20px
  /deep/ .van-icon
    border-radius 0%
.delete
  display none
.history
  display flex
  align-items center
  #history-icon1
    margin 0 14px 0 44px
    font-size 20px
  .icon-xuanzhong
    color #4B9EFB
  .history-list
    border-bottom 1px solid #F3F4F7
    display flex
    justify-content space-between
    align-items center
    padding-bottom 14px
    margin 14px 19px 0 0
    width 100%
    position relative
    .history-left
      display flex
      flex 1
      align-items center
      color #333
      font-size 14px
      font-family PingFangSC
      .history-name
        margin-right 10px
    .history-right
      display flex
      align-items center
      font-size 13px
      color #999
      .name
        margin-right 11px
</style>
