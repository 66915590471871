var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("li", { staticClass: "wrap border-bottom" }, [
        _c("div", { staticClass: "left", on: { click: _vm.openNodeForm } }, [
          _c(
            "span",
            {
              class:
                _vm.val.caseNodeStatus === "FLOW" ? "val-name" : "val-name2",
            },
            [_vm._v(_vm._s(_vm.val.nodeName))]
          ),
          _c(
            "span",
            {
              staticClass: "val-num",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showptPopup.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.checkNum))]
          ),
          _vm.val.caseNodeStatus === "COMPLETE"
            ? _c(
                "div",
                { staticClass: "val-time", on: { click: _vm.openNodeForm } },
                [
                  _vm.val.completeDate !== null
                    ? _c("div", [
                        _vm.val.nodeFormType === "DATETIME_CONTENT" ||
                        _vm.val.nodeFormType === "COURT_SESSION" ||
                        _vm.val.nodeFormType === "FILING"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("DateTime")(_vm.val.completeDate))
                              ),
                            ])
                          : _vm._e(),
                        _vm.val.nodeFormType === "DATE_CONTENT" ||
                        _vm.val.nodeFormType === "NO_FORM"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("GetDate")(_vm.val.completeDate))
                              ),
                            ])
                          : _vm._e(),
                        _vm.val.nodeFormType === "INTERVAL_DATE_CONTENT"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("GetDate")(_vm.val.beginDate))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "right", on: { click: _vm.change } }, [
          _c("span", { ref: "icon", staticClass: "iconfont icon-gengduo" }),
        ]),
      ]),
      _vm._l(_vm.SonNodelist, function (item, index) {
        return _c("sonnode", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNodeList,
              expression: "showNodeList",
            },
          ],
          key: index,
          ref: "sonnode",
          refInFor: true,
          attrs: { item: item },
          on: {
            showsonNodeHistory: _vm.showsonNodeHistory,
            closesonNodeHistory: _vm.closesonNodeHistory,
            sonnode: _vm.showsonnode,
            nodefunct: _vm.nodefunct,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }