<template>
  <div class="nodeform-search">
    <fb-header slot="header"
               title="检索法院"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="clickBack"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <div class="nodeformsearch-body">
      <div class="nodeform-search-cell"
           :key="'norepead'">
        <input type="text"
               v-model="name"
               ref="nodeformsearch"
               @input="inputHandle"
               placeholder="输入法院名称">
      </div>
      <div class="nodeform-search-cell"
           v-for="(item,index) in array"
           :key="index"
           @click="chooseHandle(item)">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
export default {
  name: 'searchCourt',
  methods: {
    clickBack () {
      this.$emit('clickback')
    },
    inputHandle (event) {
      // 要写入接口
      this.searchCourt()
    },
    searchCourt () {
      if (this.time !== null) {
        clearTimeout(this.time)
      }
      this.time = setTimeout(() => {
        this.$axios.get(`${caseBase}/lts/court/queryCourtByName?name=` + this.name)
          .then((res) => {
            // console.log('检索法院', res)
            this.array = res.data.items
          })
      }, 300)
    },
    chooseHandle (item) {
      this.$emit('choosed', item)
    }
  },
  mounted () {
    // this.$refs.nodeformsearch.value = this.$store.state.nodeform.court;
    // this.$refs.nodeformsearch.value = '';
    this.name = this.$store.state.nodeform.fayuanName
    this.$refs.nodeformsearch.focus()
  },
  data () {
    return {
      // timeout返回值
      time: null,
      array: [

      ],
      // 法院名字
      name: ''
    }
  }
}
</script>
<style scoped>
.nodeform-search {
  width: 100vw;
  min-height: 100vh;
  padding: 60px 15px 20px 15px;
}
.nodeformsearch-body {
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  padding-left: 10px;
  border: 1px solid #999;
}
.nodeform-search-cell {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #bfbfbf;
}
.nodeform-search-cell > input {
  flex-grow: 1;
  height: 100%;
  border: none;
  padding: 0 10px;
}
.nodeform-search-cell:nth-of-type(1) {
  margin-left: -10px;
}
.nodeform-search-cell:nth-last-of-type(1) {
  border: none;
}
</style>
