<template>
  <ul class="caselaw">
    <!-- 原告法人或者是非法人公司 -->
    <loading :showflag="flag"></loading>
    <li
      class="currson"
      v-for="(item, index) in adult.litigantList"
      :key="index"
      v-show="
        item.category !== 'NATURAL_PERSON' &&
          item.procedureSubject !== 'DEFENDANT'
      "
    >
      <p @click="subsequent(item.name)">{{ item.name }}</p>
      <i class="iconfont icon-pingzhuangtu"></i>
    </li>
    <!-- 被告法人或者是非法人公司 -->
    <li
      class="currson accused"
      v-for="(item, index) in advertisement"
      :key="item.name"
      :li="index"
      v-show="
        item.category !== 'NATURAL_PERSON' &&
          item.procedureSubject !== 'PLAINTIFF'
      "
    >
      <p @click="subsequent(item.name)">{{ item.name }}</p>
      <i class="iconfont icon-pingzhuangtu"></i>
    </li>
    <!-- 选择的法院 -->
    <li class="count">
      <div class="titele">
        <span>{{ talent.courtName }}</span>
        <i class="iconfont icon-pingzhuangtu"></i>
      </div>
      <!-- 选择的法官 -->
      <div class="adult advertise" v-if="talent">
        <div
          v-for="(item, index) in getFB(talent.judge)"
          :key="index"
          v-show="item.length > 0"
        >
          <span
            @click="
              portion(
                item,
                agency[Object.keys(agency)[0]][0].name,
                Object.keys(agency)[0],
                talent.courtName
              )
            "
            >{{ item }}</span
          >
          <i class="iconfont icon-pingzhuangtu"></i>
        </div>
      </div>
    </li>
    <!-- 律师律所 -->
    <!-- 原告-->

    <li class="count" v-for="(item, index) in agency" :key="item.name">
      <div class="titele">{{ index }}</div>
      <div class="adult" v-for="(itemr, indexr) in item" :key="indexr">
        <div>
          <span @click="scandal(itemr.name, index, itemr.id)">{{
            itemr.name
          }}</span>
          <span v-if="itemr.daili === 'YES'">代理人</span>
          <i class="iconfont icon-pingzhuangtu"></i>
        </div>
      </div>
    </li>
    <!-- 被告 -->
    <li class="count" v-for="(item, index) in debt" :key="index">
      <div class="titele">{{ index }}</div>
      <div class="adult" v-for="(itemr, inder) in item" :key="inder">
        <div>
          <span @click="scandal(itemr.name, index, itemr.id)">{{
            itemr.name
          }}</span>
          <span v-if="itemr.daili === 'YES'">代理人</span>
          <i class="iconfont icon-pingzhuangtu"></i>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { caseBase, pydataBase } from '~api-config'
import loading from '@/pages/Search/compoments/loading'
import { mapMutations } from 'vuex'
import { Promise } from 'q'
export default {
  props: ['active'],
  components: {
    loading
  },
  data() {
    return {
      flag: 1,
      adult: {
        litigantList: []
      },
      advertisement: [],
      agency: {},
      debt: {},
      case: '',
      judgenames: [],
      talent: {}
    }
  },
  methods: {
    ...mapMutations('caseDetail', ['setTabIndex']),
    // 法官选择进入 法官报表
    portion(name, lvshi, lvsuo, countname) {
      this.setTabIndex(this.active)
      this.$store.state.caseDetail.adult = {
        fbname: name
      }
      this.$router.push({
        name: 'fayuanInfo',
        query: {
          is: 0,
          caseId: this.$route.params.caseId
        }
      })
    },
    // 原告,被告进入律师报表
    scandal(name, index, id) {
      this.setTabIndex(this.active)
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      /* ?name=${name}&office=${index} */
      this.$axios
        .post(`${pydataBase}/api/lawyer/card`, {
          name: name,
          office: index
        })
        .then(res => {
          totast.clear()
          if (res.data.result.data.data[0].data[0].graph.datas.length === 0) {
            this.$notify('暂未查询到历史案件')
          } else {
            this.$router.push({
              path: '/lawer',
              query: {
                name: name,
                lawFirmName: index,
                lawyerId: id
              }
            })
          }
        })
        .catch(err => {
          totast.clear()
          Promise.reject(err)
        })
    },
    // 原告被告进入当事人列表
    subsequent(name) {
      this.setTabIndex(this.active)
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      /* ?name=${name} */
      this.$axios
        .post(`${pydataBase}/api/party/card`, {
          name: name
        })
        .then(res => {
          totast.clear()
          if (res.data.result.data[0].data[0].graph.datas.length === 0) {
            this.$notify('暂未查询到历史案件')
            return false
          }
          this.$router.push({
            path: '/currson',
            query: {
              name: name
            }
          })
        })
    },
    getFB(fblist) {
      if (fblist) {
        let str = fblist.slice(1, fblist.length - 1)
        str += ','
        str = str.split(',')
        str.splice(str.length - 1, 1)
        return str
      }
    }
  },
  created() {
    this.$axios
      .get(
        `${caseBase}/lts/inf/infOverview?caseId=${this.$route.params.caseId}`
      )
      .then(res => {
        this.flag = 2
        res.data.data.litigantList.forEach(item => {
          this.advertisement.push(item)
        })
        res.data.data.litigantList.forEach(item => {
          this.adult.litigantList.push(item)
        })

        this.case = res.data.data.essentialInf.caseReasonName
        this.talent = res.data.data.officesInf
        if (res.data.data.officesInf.judge) {
          this.judgenames = res.data.data.officesInf.judge
            .slice(1, -1)
            .split(',')
            .map(function(item) {
              return item.trim()
            })
        } else {
          res.data.data.officesInf.judge = '[]'
        }
        // 被告
        for (let i = 0; i < this.adult.litigantList.length; i++) {
          const item = this.adult.litigantList[i]
          // 删除数组中为被告的item
          if (item.procedureSubject === 'DEFENDANT') {
            this.adult.litigantList.splice(i, 1).forEach((item, index) => {
              item.agentList.forEach((itemr, num) => {
                if (this.debt[item.lawFirmName]) {
                  this.debt[itemr.lawFirmName].push({
                    name: itemr.name,
                    id: itemr.lawyerId,
                    daili: item.displayClient
                  })
                } else {
                  this.debt[itemr.lawFirmName] = [
                    {
                      name: itemr.name,
                      id: itemr.lawyerId,
                      daili: item.displayClient
                    }
                  ]
                }
              })
            })
            i--
            continue
          }
          // 原告
          if (item.agentList) {
            item.agentList.forEach((itemr, indexr) => {
              if (this.agency[itemr.lawFirmName]) {
                this.agency[itemr.lawFirmName].push({
                  name: itemr.name,
                  id: itemr.lawyerId,
                  daili: item.displayClient
                })
              } else {
                this.agency[itemr.lawFirmName] = [
                  {
                    name: itemr.name,
                    id: itemr.lawyerId,
                    daili: item.displayClient
                  }
                ]
              }
            })
          }
        }
        // this.decade = this.adult.litigantList[0].displayClient
        // this.global = this.adult.litigantList[1].displayClient
        // 被告
        // 删除一样的律师
        for (const item in this.agency) {
          if (this.agency[item].length === 1) {
            return
          } else {
            const list = []
            this.agency[item].forEach((itemr, indexr) => {
              if (list.length === 0) {
                list.push(itemr)
                return false
              }
              for (let i = 0; i < list.length; i++) {
                if (itemr.name === list[i].name) {
                  return false
                }
                if (i === list.length - 1) {
                  list.push(itemr)
                }
              }
            })
            this.agency[item] = list
          }
        }
      })
  }
}
</script>
<style lang="stylus">
.caselaw
  width 100%
  margin-top 10px
  li
    margin-left 19px
    span
      padding-right 5px
    i
      color #4B9EFB
  .accused
    margin-top 10px
  .currson
    width 100%
    height 20px
    line-height 20px
    color #333
    font-size 15px
    margin-top 10px
    p
      display inline-block
  .count
    width 100%
    overflow hidden
    margin-top 10px
    .titele
      color #333
      font-size 15px
    .adult
      color #666
      font-size 14px
      div
        float left
        margin-top 5px
    .advertise
      div:nth-child(2)
        margin-left 5px
      div:nth-child(3)
        margin-left 5px
</style>
