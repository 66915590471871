var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("li", { class: this.historyData.val.status ? "history" : "delete" }, [
      _c(
        "div",
        { staticClass: "shanchu" },
        [
          _c("elCheckBox", {
            staticStyle: { "vertical-align": "-25px" },
            model: {
              value: _vm.historyData.val.flag,
              callback: function ($$v) {
                _vm.$set(_vm.historyData.val, "flag", $$v)
              },
              expression: "historyData.val.flag",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "history-list" }, [
        _c("div", { staticClass: "history-left" }, [
          _c(
            "span",
            {
              staticClass: "history-name",
              on: {
                touchend: _vm.goEditorPage,
                click: function ($event) {
                  return _vm.desktop(
                    _vm.historyData.val.documentId,
                    _vm.historyData.val.groupId,
                    _vm.historyData.val.id,
                    _vm.historyData.val.modelId,
                    _vm.historyData.val.caseId
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.historyData.val.documentName) +
                  ">" +
                  _vm._s(_vm.historyData.val.documentName)
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "history-right" }, [
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.historyData.val.editor)),
          ]),
          _c("em", { staticClass: "history-newtime" }, [
            _vm._v(_vm._s(_vm.calTimes)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }