<template>

  <div ref="tearwp"
       :class="{ 'svg-wrapper': true, active: item.showInfoCard}"
       :style="itemStyle"
       @mouseover="showHintLine"
       @mouseout="hideHintLine"
       v-show="isVisible(item)">
    <svg viewBox="0 0 30 42"
         :class="tearClass"
         ref="item"
         @click="toggleTearCard(item)">
      <path d="M15 3
                Q16.5 6.8 25 18
                A12.8 12.8 0 1 1 5 18
                Q13.5 6.8 15 3z" />
      <text x="15"
            y="26"
            fill="white">{{item.simpleName}}</text>
    </svg>
    <div :class="itemInfoCardClass"
         v-show="item.showInfoCard">
      <!-- 子级 -->
      <div v-if="item.children"
           class="has-child-item-icon"
           @click="toggleChildItem(item)">
        <i :class="icon"></i>
      </div>
      <!-- 节点全名 -->
      <div class="tear-item-fname"
           @click="openForm">{{item.nodeName}}</div>
      <!-- 头像 -->
      <div class="tear-item-avatar"
           v-if="item.avatar">
        <img :src="item.avatar" />
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import visibilityMixin from '~mixins/visibility'
import getNodePosTimeMixin from '~mixins/getNodePosTime'

export default {
  mixins: [visibilityMixin, getNodePosTimeMixin],
  name: 'tear-item',
  props: {
    item: Object
  },
  data () {
    return {
      showBox: false,
      showChild: false
    }
  },
  computed: {
    ...mapState('caseDetail', {
      today: state => state.today,
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    icon () {
      if (this.showChild) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    isFlowNode () {
      if (this.item.nodeStatus === 'FLOW') {
        return true
      } else {
        return false
      }
    },
    tearPosTime () {
      // 如果是漂浮节点
      // 漂浮的节点有两个时间，开始和结束，用这两个时间和今天比较，开始大于今天，取开始，开始小于今天且结束大于今天，取今天，结束小于今天取结束
      if (this.item.nodeStatus === 'FLOW') {
        const { beginTime, endTime } = this.item
        if (endTime < this.today) {
          return endTime
        } else if (beginTime > this.today) {
          return beginTime
        } else {
          return this.today
        }
      } else {
        return this.item.completeTime
      }
    },
    itemStyle () {
      const { start } = this.getTLRangeStamp
      const _start = this.tearPosTime
      const itemEl = this.$refs.item
      let itemWidth = 0
      if (itemEl) {
        itemWidth = parseFloat(window.getComputedStyle(itemEl).width.replace(/px/g, ''))
      } else {
        itemWidth = 28
      }
      const left = ((_start - start) / this.getTLRangeCrossNumber) * this.timelineWidth - itemWidth / 2

      let transformY = -5
      if (this.isFlowNode) {
        transformY = 5
      }
      const transform = `translate3d(${left}px, ${transformY}px, 0)`
      const style = {
        transform
      }
      return style
    },
    tearClass () {
      const completeTime = this.tearPosTime
      const isOutdated = this.today - completeTime
      if (isOutdated > 0 || this.isFlowNode) {
        return 'tear-item gray'
      } else {
        const styleCode = this.item.style
        return `tear-item ${styleCode}`
      }
    },
    itemInfoCardClass () {
      const completeTime = this.tearPosTime
      const isOutdated = this.today - completeTime
      if (isOutdated > 0) {
        return 'item-info-card gray'
      } else {
        const styleCode = this.item.style
        return `item-info-card ${styleCode}`
      }
    },
    nodeItemPopperClass () {
      return 'casedetail-nodeitem-tooltip'
    },
    hintTime () {
      const date = window.moment(this.tearPosTime).format('M月D日')
      return date
    }
  },
  methods: {
    ...mapMutations('caseDetail', [
      'setShowTopItemConnector',
      'setTopItemConnnectorPos',
      'setShowHintLineSimple',
      'setSimpleHintlineYData'
    ]),
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    // 显示辅助线
    showHintLine () {
      this.setSimpleHintlineYData(this.item)
      this.setShowHintLineSimple(true)
    },
    // 隐藏辅助线
    hideHintLine () {
      this.setSimpleHintlineYData({})
      this.setShowHintLineSimple(false)
    },
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    toggleTearCard (item) {
      const payload = {}
      payload.item = item
      payload.nodeType = 'TEAR'
      this.setShowTopItemConnector(false)
      // 通知父组件改变显示信息卡片状态
      if (item.showInfoCard) {
        payload.action = 'hide'
        this.$emit('showInfoCard', payload)
      } else {
        payload.action = 'show'
        this.$emit('showInfoCard', payload)
      }
      // 更改子级展开状态
      const showChildpayload = {}
      showChildpayload.action = 'hide'
      showChildpayload.item = item
      showChildpayload.nodeType = 'TEAR'
      // 改变状态
      this.showChild = false
      // 通知父组件
      this.$emit('showChildItem', showChildpayload)
    },
    toggleChildItem (item) {
      this.showChild = !this.showChild
      if (this.showChild && this.isFlowNode) {
        this.setShowTopItemConnector(true)
        this.setTopItemConnnectorPos(this.getNodePosTime(item))
      } else {
        this.setShowTopItemConnector(false)
        this.setTopItemConnnectorPos(0)
      }
      const payload = {}
      payload.action = this.showChild ? 'show' : 'hide'
      payload.item = item
      this.$emit('showChildItem', payload)
    },
    resetShowChildItem () {
      this.showChild = false
    },
    openForm (e) {
      const caseId = this.$route.params.caseId
      const startDate = this.getNodePosTime(this.item)
      const { nodeId, nodeFormType, nodeName: title } = this.item
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    }
  },
  created () {
    if (this.item.expand && this.item.showInfoCard && this.item.children) {
      this.toggleChildItem(this.item)
    }
  }
}
</script>

<style lang="stylus">
.svg-wrapper
  position absolute
  background transparent
  height 42px
  display flex
  z-index 2
  cursor pointer
  &.active
    z-index 3
  .item-info-card
    background rgba(255, 255, 255, 0.9)
    border-radius 2px
    margin 9px 0 0 0
    height 28px
    line-height 28px
    display flex
    box-shadow 0px 0px 10px rgba(163, 163, 163, 0.7)
    // color #fff
    font-size 16px
    .has-child-item-icon
      color #666
      padding 0 0 0 6px
      i
        font-size 20px
        line-height 32px
    .tear-item-fname
      margin 0 6px
      white-space nowrap
    .tear-item-avatar
      margin 0 6px 0 0
      height 32px
      width 32px
      img
        border-radius 50%
        width 100%
        height 100%
  svg
    width 30px
    text
      text-anchor middle
      dominant-baseline middle
      font-size initial
</style>
