<template>
  <div class="finance-tab">
    <loading :showflag="flag"></loading>
    <ul class="finance"
        v-if="runbit">
      <li>
        <div>代理方式</div>
        <div @click="agency">
          <p>{{getFB(runbit)[comic]}}</p>
          <!-- <p>
            <i class="iconfont icon-gengduo"></i>
          </p>
          <div class="adult"
               v-show="advertise">
            <p v-for="(item,index) in getFB(runbit)"
               @click="change(index)"
               :style="{'display':comic === index?'none':''}"
               :key="index"> {{item}}</p>
          </div> -->

        </div>
      </li>
      <li>
        <div>诉讼标的</div>
        <div>
          <p><input type="Number"
                   placeholder="请输入金额"
                   min=0
                   onkeydown='if(event.keyCode === 69){return false}'
                   v-model='litigationPrice'
                   @blur="chance"></p>
          <p>
            <span>元</span>
            <i class="iconfont icon-gengduo"></i>
          </p>
        </div>
      </li>
      <li>
        <div>受理费(全额)</div>
        <div>
          <p>
            <span v-if="this.acceptance.costMin!==this.acceptance.costMax">{{this.acceptance.costMin}}元-</span>
            <span>{{this.acceptance.costMax}}元</span>
          </p>
          <p :style="{'transition':'all .5s','transform':pricetab?'rotate(90deg)':'rotate(0deg)'}">
            <i class="iconfont icon-gengduo"
               @click="globe"></i>
          </p>
        </div>
      </li>
      <table class="tableprice"
             border="1"
             v-if="pricetab&&this.litigationPrice>0">
        <tr v-for="(item,index) in TablePrice.legalCaseCostStandards"
            :key="index">
          <!--  元部分-->
          <td>
            <span>{{item.involvingPropertyMin}}</span>
            <span>{{item.involvingPropertyMax===-1?'':'-'+item.involvingPropertyMax}}</span>
            <span>{{item.involvingPropertyMax===-1?'以上部分':'元部分'}}</span>
          </td>
          <!--  机率 -->
          <td>
            <span>{{(item.ratioMin*100).toFixed(1)}}%</span>
            <span v-if="item.ratioMin!=item.ratioMax">-{{(item.ratioMax*100).toFixed(1)}}%</span>
          </td>
          <!-- 第三部分  -->
          <td>
            <span>{{item.ratioMin===0?item.costMin:parseInt(item.involvingPropertyMin*item.ratioMin)}}</span>
            <span v-if="item.ratioMin!==0">{{item.involvingPropertyMax===-1?"":item.ratioMin===0?-item.costMax:-parseInt(item.involvingPropertyMax*item.ratioMax)}}</span>
            <span>元</span>
            <span v-if="item.involvingPropertyMax===-1">以上</span>
          </td>
        </tr>
      </table>
      <!--     <li>
        <div>减半收取</div>
        <div>
          <p>5900元</p>
          <p>
            <i class="iconfont icon-gengduo"></i>
          </p>
        </div>

      </li>
      <li>
        <div>财产保全费</div>
        <div>
          <p>4520元</p>
          <p>
            <i class="iconfont icon-gengduo"></i>
          </p>
        </div>
      </li>
      <li>
        <div>保全保证金</div>
        <div>
          <p>2400000元</p>
          <p>
            <i class="iconfont icon-gengduo"></i>
          </p>
        </div>
      </li>
      <li>
        <div>执行费</div>
        <div>
          <p>10400元</p>
          <p>
            <i class="iconfont icon-gengduo"></i>
          </p>
        </div>
      </li> -->
      <li>
        <div>减半收取</div>
        <div>
          <p>
            <span v-if="this.acceptance.costMin!==this.acceptance.costMax">{{this.acceptance.costMin/2}}元-</span>
            <span>{{this.acceptance.costMax/2}}元</span>
          </p>
        </div>
      </li>
      <li>
        <div>律师代理费</div>
        <div>
          <p>
            <span v-if="!this.lawer.code">{{this.lawer.chargeAmountMin}}元-</span>
            <span>{{this.lawer.chargeAmountMax}}{{this.lawer.chargeAmountMax?'元':'无'}}</span>
          </p>
          <p :style="{'transition':'all .5s','transform':lawertab?'rotate(90deg)':'rotate(0deg)'}">
            <i class="iconfont icon-gengduo"
               @click="global"></i>
          </p>
        </div>
      </li>
      <p class="case"
         v-if="!this.lawer.chargeAmountMax">系统暂未收集到该地区的标准数据</p>
      <table class="tableprice"
             border="1"
             v-if="lawertab&&this.litigationPrice>0">
        <tr v-for="(item,index) in TablePriceTwo.lawyerServiceFeeStandards"
            :key="index">
          <!-- 元部分 -->
          <td>
            <span>{{item.propertyAmountMin}}</span>
            <span>{{item.propertyAmountMax===-1?'':'-'+item.propertyAmountMax}}</span>
            <span>{{item.propertyAmountMax===-1?'以上部分':'元部分'}}</span>
          </td>
          <!-- 机率 -->
          <td>
            <span>{{(item.chargeRatioMin*100).toFixed(1)}}%</span>
            <span v-if="item.chargeRatioMin!=item.chargeRatioMax">-{{(item.chargeRatioMax*100).toFixed(1)}}%</span>
          </td>
          <!-- 第三部分 -->
          <td>
            <span>{{item.chargeRatioMin===0?item.chargeAmountMin:parseInt(item.propertyAmountMin*item.chargeRatioMin)}}</span>
            <span>{{item.propertyAmountMax===-1?"":item.chargeRatioMin===0?-item.chargeAmountMax:-parseInt(item.propertyAmountMax*item.chargeRatioMax)}}</span>
            <span>元</span>
            <span v-if="item.propertyAmountMax===-1">以上</span>
          </td>
        </tr>
      </table>
    </ul>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import loading from '@/pages/Search/compoments/loading'
export default {
  name: 'finance-tab',
  components: {
    loading
  },
  data() {
    return {
      flag: 1,
      litigationPrice: '',
      TablePrice: [],
      TablePriceTwo: [],
      lawer: {},
      acceptance: {},
      res: '',
      lawertab: false,
      pricetab: false,
      advertise: false,
      forbid: '一般代理',
      debate: '特别代理',
      runbit: '',
      comic: 0,
      list: false,
      style: ''
    }
  },
  watch: {
    litigationPrice(val) {
      if (val < 0) {
        this.litigationPrice = ''
        return
      }
      this.litigationPrice = parseInt(val)
    },
    comic(val) {}
  },
  created() {
    this.style = document.getElementById('scroll-menu').style.transform
    this.$axios
      .get(
        `${caseBase}/lts/case/${this.$route.params.caseId}/caseNodesByCaseId`
      )
      .then(res => {
        this.flag = 2
        this.runbit = res.data.data.caseBasicInfo.clientType
      })
  },
  async mounted() {
    let res = await this.get_data(0)
    if (res.data.caseTargetAmount) {
      this.litigationPrice = res.data.caseTargetAmount
    } else {
      this.litigationPrice = 0
    }
    // 律师费
    res = await this.get_data(1)
    this.lawer = res.data
    this.TablePriceTwo = res.data
    // 受理费
    res = await this.get_data(2)
    this.acceptance = res.data
    this.TablePrice = res.data
    let style = null
    const _self = this
    this.blur = () => {
      document.getElementById('scroll-menu').style.transform = style
      document.removeEventListener('blur', _self.blur)
    }
    this.test = () => {
      style = document.getElementById('scroll-menu').style.transform
      if (document.activeElement.tagName.toLowerCase() === 'input') {
        if (style !== this.style) {
          document.getElementById('scroll-menu').style.transform = this.style
          document.activeElement.addEventListener('blur', this.blur)
        }
      }
    }
    document.body.addEventListener('click', this.test, false)
  },
  destroyed() {
    document.body.removeEventListener('click', this.test)
  },
  methods: {
    change(index) {
      this.comic = index
    },
    getFB(fblist) {
      if (fblist) {
        let str = fblist.slice(1, fblist.length - 1)
        str += ','
        str = str.split(',')
        str.splice(str.length - 1, 1)
        return str
      }
    },
    globe() {
      if (this.litigationPrice === 0) {
        return
      }
      this.pricetab = !this.pricetab
    },
    global() {
      if (this.litigationPrice === 0) {
        return
      }
      this.lawertab = !this.lawertab
    },
    agency() {
      this.advertise = !this.advertise
    },
    get_data(name, id) {
      let res = null
      switch (name) {
        case 0:
          res = this.$axios.get(
            `${caseBase}/management/case/${this.$route.params.caseId}/account`
          )
          break
        case 1:
          res = this.$axios.get(
            `${caseBase}/management/case/${this.$route.params.caseId}/service-fee/standard`
          )
          break
        case 2:
          res = this.$axios.get(
            `${caseBase}/management/legal/case/${this.$route.params.caseId}/cost/standard`
          )
          break
        case 3:
          res = this.$axios.put(
            `${caseBase}/management/case/${this.$route.params.caseId}/account/${id}`,
            { caseTargetAmount: this.litigationPrice }
          )
          break
      }
      return new Promise((resolve, reject) => {
        resolve(res)
      })
    },
    async chance() {
      let res = await this.get_data(0)
      res = await this.get_data(3, res.data.caseAccountId)
      // 律师费
      res = await this.get_data(1)
      if (res.data.code === 404) {
        this.list = true
      }
      this.lawer = res.data
      this.TablePriceTwo = res.data
      //  受理费
      res = await this.get_data(2)
      this.acceptance = res.data
      this.TablePrice = res.data
    }
  }
}
</script>
<style lang="stylus">
.finance-tab
  width 100%
  height 100%
  .finance
    width 100%
    height 100%
    margin-top 5px
    overflow auto
    .case
      color red
      margin-left 15px
    li
      display flex
      justify-content space-between
      font-size 15px
      border-bottom 1px solid #F3F4F7
      height 44px
      line-height 44px
      div:nth-child(1)
        color #000000
        margin-left 20px
      div:nth-child(2)
        color #333333
        margin-right 15px
        text-align right
        position relative
        p
          float left
        p:nth-child(1)
          input
            display inline-block
            height 40px
            line-height 40px
            text-align right
            margin-right 5px
            outline none
            border 1px solid transparent
            border-top none
        .adult
          display block
          width 100%
          top 44px
          left 0px
          position absolute
          line-height 44px
          z-index 99
          background #ffffff
          box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
          border-radius 5px
          text-align center
          p
            width 100%
            height 44px
            line-height 44px
            text-align center
    .tableprice
      width 95%
      height 20px
      border 1px solid #f2f2f2
      border none
      margin 0px auto 0px
      line-height 20px
      td
        border-bottom 1px solid #f2f2f2
        border-right 1px solid #f2f2f2
        height 21px
</style>
