var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "scrollYRef", staticClass: "analyse-tab" },
    [
      _c(
        "van-list",
        {
          staticClass: "topOffset",
          attrs: { finished: _vm.finished, "finished-text": _vm.finishedText },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageInfo.page === "ana",
                  expression: "pageInfo.page === 'ana'",
                },
              ],
              ref: "scrollDocument",
              staticClass: "container",
            },
            [
              _c("analysis", {
                ref: "analysis",
                attrs: {
                  step: _vm.step,
                  pageInfo: _vm.pageInfo,
                  scrollDocument: _vm.$refs.scrollYRef,
                  inScrollMenu: true,
                },
                on: {
                  "update:step": function ($event) {
                    _vm.step = $event
                  },
                  resetFinished: _vm.resetFinished,
                  resetLoading: _vm.resetLoading,
                  resetFinishedText: _vm.resetFinishedText,
                  sliderSide: _vm.sliderSide,
                },
              }),
            ],
            1
          ),
          _vm.pageInfo.page === "detail" ||
          _vm.pageInfo.page === "detailInTopCase"
            ? _c(
                "div",
                { ref: "detailContainer", staticClass: "container" },
                [
                  _c("detail", {
                    attrs: {
                      pageInfo: _vm.pageInfo,
                      title: _vm.title,
                      scrollDocument: _vm.$refs.scrollYRef,
                      inScrollMenu: true,
                    },
                    on: {
                      "update:title": function ($event) {
                        _vm.title = $event
                      },
                      resetFinished: _vm.resetFinished,
                      resetLoading: _vm.resetLoading,
                      clickBack: _vm.clickBack,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.pageInfo.page === "topCase" ||
          _vm.pageInfo.page === "detailInTopCase"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageInfo.page !== "detailInTopCase",
                      expression: "pageInfo.page !== 'detailInTopCase'",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c("top-case", {
                    attrs: { pageInfo: _vm.pageInfo },
                    on: {
                      resetFinished: _vm.resetFinished,
                      resetLoading: _vm.resetLoading,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }