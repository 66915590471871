var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "evidence-tab" },
    [
      _c("van-loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isloading,
            expression: "isloading",
          },
        ],
        staticStyle: { margin: "0 auto" },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isloading,
              expression: "!isloading",
            },
          ],
          staticClass: "evidence-all",
        },
        [
          _c("div", { staticClass: "evidence-tab-top" }, [
            _c("span", {
              staticClass: "iconfont icon-back",
              style: _vm.positionArray.length > 0 ? "" : "color:white",
              on: {
                click: function ($event) {
                  return _vm.fileBack($event)
                },
              },
            }),
            _c(
              "span",
              { staticClass: "evidence-tab-src" },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.positionArray.length > 0 ? true : false,
                        expression: "positionArray.length > 0 ? true : false",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.fileBack($event)
                      },
                    },
                  },
                  [_vm._v("... /")]
                ),
                _vm._l(_vm.srcArray, function (item, index) {
                  return _c("span", { key: index }, [
                    _vm._v(" " + _vm._s(item) + " /"),
                  ])
                }),
              ],
              2
            ),
            _c(
              "span",
              {
                staticClass: "iconfont evidenceIcon",
                on: {
                  click: function ($event) {
                    return _vm.takePhoto()
                  },
                },
              },
              [_vm._v("")]
            ),
            _c(
              "span",
              {
                staticClass: "iconfont evidenceIcon",
                on: {
                  click: function ($event) {
                    return _vm.addfile()
                  },
                },
              },
              [_vm._v("")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "evidence-content" },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText,
                    "immediate-check": false,
                    offset: 0,
                  },
                  on: {
                    load: function ($event) {
                      return _vm.onLoad(_vm.params)
                    },
                  },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.array, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "evidence-example border-bottom",
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont evidence-example-icon",
                        class:
                          item.type != null ? "icon-kongbai" : "icon-daiziji",
                        attrs: { "data-clicked": "false" },
                        on: {
                          click: function ($event) {
                            return _vm.select(index, item)
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "evidence-example-file",
                          on: {
                            click: function ($event) {
                              _vm.choosedArray.length === 0
                                ? _vm.fileInit(_vm.page, item, $event)
                                : _vm.noUse
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.fileName))]
                      ),
                      _c("span", {
                        staticClass: "iconfont icon-more",
                        on: {
                          click: function ($event) {
                            _vm.choosedArray.length === 0
                              ? _vm.showEdit(index)
                              : _vm.noUse
                          },
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "showEdit hidden",
                          on: {
                            click: function ($event) {
                              return _vm.evidenceExDel(item, index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._m(0),
              _c(
                "van-popup",
                {
                  staticClass: "deleteandbuilt",
                  attrs: {
                    position: "bottom",
                    overlay: false,
                    "get-container": _vm.container,
                  },
                  model: {
                    value: _vm.isShowBottom,
                    callback: function ($$v) {
                      _vm.isShowBottom = $$v
                    },
                    expression: "isShowBottom",
                  },
                },
                [
                  _c("div", { staticClass: "evidence-bottom border" }, [
                    _c(
                      "div",
                      {
                        staticClass: "evidence-bottom-ex",
                        on: { click: _vm.movehandle },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-move-folder" }),
                        _c("p", [_vm._v("移动")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "evidence-bottom-ex",
                        on: { click: _vm.chooseImg },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-pdf" }),
                        _c("p", [_vm._v("生成PDF")]),
                      ]
                    ),
                    _c("div", { staticClass: "evidence-bottom-ex" }, [
                      _c("i", {
                        staticClass: "iconfont icon-delete",
                        on: { click: _vm.deleteHandle },
                      }),
                      _c("p", [_vm._v("删除")]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "van-dialog",
            {
              attrs: { title: "请输入文件夹名称", "show-cancel-button": true },
              on: { confirm: _vm.confirmAddFile },
              model: {
                value: _vm.dialoged,
                callback: function ($$v) {
                  _vm.dialoged = $$v
                },
                expression: "dialoged",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialoged-input" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入文件夹名称" },
                    model: {
                      value: _vm.addfileName,
                      callback: function ($$v) {
                        _vm.addfileName = $$v
                      },
                      expression: "addfileName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "van-dialog",
            {
              attrs: {
                title: "请输入生成pdf的名称",
                "show-cancel-button": true,
              },
              on: { confirm: _vm.generatePdf, cancel: _vm.cancelGeneratePdf },
              model: {
                value: _vm.generate,
                callback: function ($$v) {
                  _vm.generate = $$v
                },
                expression: "generate",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialoged-input" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入生成pdf的名称" },
                    model: {
                      value: _vm.generatePdfName,
                      callback: function ($$v) {
                        _vm.generatePdfName = $$v
                      },
                      expression: "generatePdfName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("van-dialog", {
            attrs: { message: _vm.message },
            model: {
              value: _vm.showMessage,
              callback: function ($$v) {
                _vm.showMessage = $$v
              },
              expression: "showMessage",
            },
          }),
          _c(
            "van-popup",
            {
              on: { "click-overlay": _vm.closePdf },
              model: {
                value: _vm.showPdf,
                callback: function ($$v) {
                  _vm.showPdf = $$v
                },
                expression: "showPdf",
              },
            },
            [
              _vm.pdfSrc
                ? _c("iframe", {
                    staticClass: "iframeClass",
                    attrs: { src: _vm.pdfSrc, frameborder: "0" },
                  })
                : _vm._e(),
            ]
          ),
          _c(
            "van-popup",
            {
              staticClass: "mov-pop",
              model: {
                value: _vm.showMove,
                callback: function ($$v) {
                  _vm.showMove = $$v
                },
                expression: "showMove",
              },
            },
            [
              _vm.showMove
                ? _c("move-evidence", {
                    attrs: {
                      choosedArray: _vm.choosedArray,
                      choosedType: _vm.positionArray.length,
                      kangqianye: _vm.kangqianye,
                    },
                    on: { goback: _vm.goback, moved: _vm.moved },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "meng hidden" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty hidden" }, [
      _c("img", {
        attrs: { src: require("./static/imgs/moempty.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }