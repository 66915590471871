<template>
  <div>

    <ul v-if="value">

      <li class='jiedianlist'>
        <span class="iconfont"
              :class="value.progress==='FINISHED'?'icon-xuanzhong' : 'icon-kongbai'"
              @click="chan(value)"></span>
        <div class="list border-bottom">
          <van-cell-group class="van-ce-group ">
            <van-field v-model="value.headline"
                       :class="value.progress==='FINISHED'?'delete-line':''"
                       type="textarea"
                       rows="1"
                       autosize
                       ref="input"
                       class="van-field__body"
                       aria-placeholder="请输入"
                       @keydown="keydown"
                       @blur="saveHeadline(value)">
            </van-field>
          </van-cell-group>
          <span class="iconfont icon-more"
                @click="changeBgcolor"
                :class="value.progress==='FINISHED'?'color1':'color2'"
                ref="icon">
            <Edit :showdia='showdialog'
                  :content='value.content'
                  @showPopup="showPopup"
                  class="edit"></Edit>
          </span>
        </div>
      </li>
    </ul>

    <ul v-if="item">
      <li class='jiedianlist'>
        <span class="iconfont"
              :class="item.isChecked?'icon-xuanzhong' : 'icon-kongbai'"
              @click="change"></span>
        <div class="list border-bottom">
          <!-- <van-cell-group class="van-ce-group "> -->
          <van-field v-model="item.content"
                     :class="item.isChecked?'delete-line':''"
                     type="textarea"
                     rows="1"
                     autosize
                     ref="input"
                     class="van-field__body"
                     aria-placeholder="请输入"
                     @keydown="keydown"
                     @blur.stop="save(item)">
          </van-field>
          <!-- </van-cell-group> -->
          <span class="iconfont icon-more"
                @click="changeBgcolor"
                :class="item.isChecked?'color1':'color2'"
                ref="icon"
                id="icon-more">
            <Dialog :content='item.content'
                    :showdia='showdialog'
                    @showPopup="showPopup"></Dialog>
          </span>
        </div>
      </li>
    </ul>
    <!-- 点击 "升级为节点" 函数 -->
    <!-- <van-popup v-model="show"
               :get-container="container">

    </van-popup> -->
  </div>
</template>
<script>
import Dialog from './dialog'
import Edit from './edit'
import { caseBase } from '~api-config'

export default {
  name: 'jiedianlist',
  props: ['item', 'canshu', 'value', 'NodeForm', 'index', 'oldlist'],
  components: {
    Edit,
    Dialog
  },
  filters: {
    // 开始时间格式化   年：月：日  时：分
    DateTime: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      const hours = beginTime.getHours()
      const minutes = beginTime.getMinutes()
      return year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day) + ' ' + (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes)
    },
    // 年：月：日
    GetDate: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day)
    },
    NumberFormat: function (value) {
      return (value * 100).toFixed(2) + '%'
    }

  },
  data () {
    return {
      active: false,
      Bgcolor: false,
      showdialog: false,
      show: false,
      showgg: false,
      arr: [],
      NodeForms: {
        nodeFormEntity: {},
        formContentEntity: []
      },
      par: {
        ids: [],
        progress: null
      },
      container: () => document.body
    }
  },
  mounted () {
    this.ss()
  },
  methods: {
    // 头像去重
    ss () {
      if (this.value) {
        this.arr = this.value.user
        for (var i = 0; i < this.arr.length; i++) {
          for (var j = i + 1; j < this.arr.length; j++) {
            if (this.arr[i].id === this.arr[j].id) { // 第一个等同于第二个，splice方法删除第二个
              this.arr.splice(j, 1)
              j--
            }
          }
        }
      }
    },
    keydown (event) {
      if (this.value) {
        const params = {
          event,
          nodename: this.value.node.nodeName
        }
        this.$emit('keydownHan', params)
      } else {
        const params = {
          event
        }
        this.$emit('keydownHandle', params)
      }
    },

    chan (value) {
      if (value.progress === 'READY') {
        value.progress = 'FINISHED'
      } else if (value.progress === 'FINISHED') {
        value.progress = 'READY'
      }
      this.$nextTick(() => {
        const userId = this.$store.state.user.userInfo.id
        this.par.ids = [value.id]
        this.par.progress = value.progress

        this.$axios.post(`${caseBase}/management/user/${userId}/task/progress`, this.par).then(res => {
          if (res.status === 200) {
            console.log('保存成功')
          }
        })
      })
    },
    focus () {
      this.$refs.input.focus()
    },
    save (item) {
      // 修改任务
      // console.log('uuuuuuuu')
      this.showdialog = false
      if (this.showdialog) {
        this.$refs.icon.style = 'color:#4B9EFB'
      } else {
        this.$refs.icon.style = 'color:#ccc'
      }
      const caseId = this.$route.params.caseId
      const nodeId = this.canshu
      // const formContentEntity = [this.item]
      const formContentEntity = []
      const nodeFormEntity = { caseId, nodeId }

      if (this.item) {
        for (const j of this.oldlist) {
          if (j.content) {
            formContentEntity.push(j)
          }
        }
        console.log(formContentEntity)
        if (this.item.content) {
          this.$axios.post(`${caseBase}/lts/nodeForm/saveContent`, { formContentEntity, nodeFormEntity }).then(res => {
            if (res.status === 200) {
              console.log('保存成功')
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('content没有内容')
        }
      }
    },
    saveHeadline (value) {
      if (this.value) {
        this.$nextTick(() => {
          const userId = this.$store.state.user.userInfo.id
          this.par.ids = [value.id]
          this.par.progress = value.progress
          this.$axios.put(`${caseBase}/management/user/${userId}/task/${value.id}`, value).then(res => {
            if (res.status === 200) {
              console.log('保存成功', res)
            }
          })
        })
        // }
      }
    },
    change () {
      this.NodeForms.formContentEntity = this.NodeForm.formContents
      // this.NodeForms.nodeFormEntity.formStatus = this.NodeForm.formStatus// 状态
      this.NodeForms.nodeFormEntity.formStatus = 2// 状态
      this.NodeForms.nodeFormEntity.setTime = this.NodeForm.setTime ? this.NodeForm.setTime : new Date().getTime()// 开始时间
      this.NodeForms.nodeFormEntity.caseId = this.NodeForm.caseId// caseId
      this.NodeForms.nodeFormEntity.formId = this.NodeForm.formId// formId
      this.NodeForms.nodeFormEntity.nodeId = this.NodeForm.nodeId// nodefId
      // this.NodeForms.nodeFormEntity.dateType = this.NodeForm.dateType// 日期类型 date datetime
      this.NodeForms.nodeFormEntity.dateType = 'data'// 日期类型 date datetime
      // 以下注释为一开始传参
      // if (this.item.isChecked === 0) {
      //   this.item.isChecked = 1
      // } else if (this.item.isChecked === 1) {
      //   this.item.isChecked = 0
      // }
      if (!this.item.isChecked || this.item.isChecked === '0') {
        this.item.isChecked = 1
      } else {
        this.item.isChecked = 0
      }
      this.$axios.post(`${caseBase}/lts/nodeForm/saveOrUpdate`, this.NodeForms).then(res => {
        console.log('成功111')
      })
      this.$emit('DeleteOverBuilt')
    },
    changeBgcolor () {
      this.showdialog = !this.showdialog
      if (this.showdialog) {
        this.$refs.icon.style = 'color:#4B9EFB'
      } else {
        this.$refs.icon.style = 'color:#ccc'
      }
    },
    showPopup () {
      this.show = true
    }
  },
  computed: {
    isCheckedTask () {
      return this.taskList.filter((val, index) => {
        return this.$refs.input[index].active
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.title
  display flex
  align-items center
  .group
    display flex
    align-items center
    width 90%
    .icon-kongbai
      font-size 20px
      margin 0 10px 0 15px
    .icon-xuanzhong
      font-size 20px
      margin 0px 10px 0 15px
      color #CCCCCC
    .van-area
      width 90%
      padding 0
      margin 0
    .delete-line
      text-decoration line-through
.delete
  display none
.jiedianlist
  display flex
  align-items center
  .icon-kongbai
    font-size 20px
    margin 0 10px 0 15px
  .icon-xuanzhong
    font-size 20px
    margin 0px 10px 0 15px
    color #CCCCCC
  .border-bottom
    border-color #F3F4F7
  .list
    display flex
    align-items center
    padding-bottom 11px
    margin 11px 19px 0 0
    font-family PingFangSC
    width 100%
    .van-group
      width 100%
      align-items center
      .van-title
        width 100%
        box-sizing border-box
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        margin-right 10px
        span
          width 100%
      .van-right
        width 100%
        box-sizing border-box
        display flex
        align-items center
        .starttime
          width 50%
    .van-body
      border 1px solid #ccc
      box-sizing border-box
      margin 0 2rem
      line-clamp 5
    .van-ce-group
      width 100%
      display flex
      align-items center
      .van-field__body
        box-sizing border-box
    .delete-line
      text-decoration line-through
    #icon-more
      position relative
      .edit
        position absolute
    .van-cell
      padding 0
    .color1
      color #ccc
      font-size 16px
      visibility hidden
    .color2
      color #ccc
      display block
      font-size 16px
</style>
