<template>
  <div
    id="scroll-menu"
    @touchstart.passive.stop="ontouchstart"
    @touchmove.passive.stop="ontouchmove"
    @touchend.passive.stop="ontouchend"
    :style="tabWrapperStyleUsingTransfrom"
  >
    <div
      class="bottom-click-trigger pull-trigger"
      v-show="tabHeight === 0"
      @click="addTabHeight"
    ></div>
    <div class="menu-mask" v-show="showRectMenu" @click="toggleRectMenu"></div>
    <div class="team-avatar pull-trigger" :style="teamAvatarStyle" @click="startChat">
      <!-- 协作人 -->
      <cooperation-user :avatarUrl="coopAvatarUrl" />
    </div>
    <div class="left-menu" v-if="showLeftMenu" @click="toggleLeftMenu">
      <i class="iconfont icon-menu-s"></i>
    </div>
    <div
      class="rect-menu-trigger pull-trigger"
      @click="toggleRectMenu"
      v-if="showRightMenu"
    >
      <i class="iconfont icon-leimu pull-trigger"></i>
    </div>
    <div class="rect-menu-box" v-show="showRectMenu">
      <!-- <div class="grid"></div> -->
      <div
        class="menu"
        v-for="(menu, index) in hiddenTabs"
        :key="menu.name"
        @click.stop="selectTab(index)"
      >
        <div class="menu-item">
          <div class="icon"><i :class="'iconfont icon-' + menu.icon"></i></div>
          <div class="name">{{ menu.name }}</div>
        </div>
      </div>
    </div>
    <div class="tabs-wrapper">
      <van-tabs
        v-model="active"
        :line-height="0"
        v-if="renderTab || (tabs[active] !== 'Caselaw' && otherTab)"
        :line-width="0"
        :class="{
          'case-nav-tabs-left-menu': showLeftMenu,
          'case-nav-tabs-right-menu': showRightMenu,
          'pull-trigger': true,
        }"
        animated
        :duration="0.2"
        @change="onTabChange"
        :swipeable="canSwipe"
      >
        <van-tab
          v-for="(tab, index) in tabs"
          :key="tab.componentName"
          :style="tabWrapperStyle"
          class="dynamic-height-tab"
        >
          <div slot="title" class="tab-title pull-trigger">
            <div class="tab-name pull-trigger">
              <i :class="'iconfont icon-' + tab.icon" v-show="active === index"></i
              >{{ tab.name }}
            </div>
          </div>
          <div v-if="active === index">
            <van-pull-refresh
              v-model="inPullDown"
              class="outter-pull-wrapper"
              pulling-text="下拉收起面板"
              loosing-text="释放收起面板"
              loading-text=" "
              :animation-duration="80"
              @refresh="onPullDown"
              ref="pullRefresh"
            >
              <component
                v-bind:is="tab.componentName"
                @sliderSide="handleSideSwipe"
                :style="tabContentWrapperStyle"
                class="casedetail-tab-content-wrapper"
                :is_load="true"
                :active="active"
                :ref="tab.componentName"
              />
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { caseBase } from '@api-config'
import touchMixin from '@mixins/touchHandler'
import RecordTab from './components/recordTab'
import DropboxTab from './components/dropboxTab'
import DocumentTab from './components/documentTab'
import TaskTab from './components/taskTab'
import EvidenceTab from './components/evidenceTab'
import InfoTab from './components/infoTab'
import WikiTab from './components/wikiTab'
import FinanceTab from './components/financeTab'
import AnalyseTab from './components/analyseTab'
import NoteTab from './components/noteTab'
import PrecedentTab from './components/precedentTab'
import PreservationTab from './components/preservationTab'
import RegisterTab from './components/registerTab'
import CooperationUser from './components/cooperationUser'
import Caselaw from './components/caselaw'

export default {
  name: 'scroll-menu',
  components: {
    DocumentTab,
    TaskTab,
    EvidenceTab,
    InfoTab,
    WikiTab,
    FinanceTab,
    AnalyseTab,
    NoteTab,
    PrecedentTab,
    PreservationTab,
    RegisterTab,
    CooperationUser,
    DropboxTab,
    RecordTab,
    Caselaw,
  },
  props: {
    // 是否恢复到上次打开的tab页
    resumeTab: Boolean,
    // 显示左侧菜单按钮
    showLeftMenu: Boolean,
    // 显示右侧方形菜单
    showRightMenu: Boolean,
    // 初始化模式（高度）
    initialHeight: String,
    // 群聊id
    tid: String,
  },
  mixins: [touchMixin],
  data() {
    return {
      renderTab: false,
      otherTab: true,
      coopAvatarUrl: '',
      tabs: [
        {
          name: '文书',
          componentName: 'DocumentTab',
          icon: 'file',
        },
        // {
        //   name: '日程',
        //   componentName: 'TaskTab',
        //   icon: 'renwu'
        // },
        {
          name: '知识',
          componentName: 'WikiTab',
          icon: 'zhishi2',
        },
        {
          name: '分析',
          componentName: 'AnalyseTab',
          icon: 'fenxi2',
        },
        {
          name: '信息',
          componentName: 'InfoTab',
          icon: 'jibenxinxi',
        },
      ],
      hiddenTabs: [
        // {
        //   name: '档案',
        //   componentName: 'PrecedentTab',
        //   icon: 'icon-test1'
        // },
        // {
        //   name: '证据',
        //   componentName: 'EvidenceTab',
        //   icon: 'wenjian'
        // },
        {
          name: '网盘',
          componentName: 'DropboxTab',
          icon: 'clouddisk',
        },
        {
          name: '财务',
          componentName: 'FinanceTab',
          icon: 'caiwu',
        },
        // {
        //   name: '笔记',
        //   componentName: 'NoteTab',
        //   icon: 'note'
        // },
        // {
        //   name: '立案',
        //   componentName: 'RegisterTab',
        //   icon: 'lian'
        // },
        // {
        //   name: '保全',
        //   componentName: 'PreservationTab',
        //   icon: 'baoquan'
        // },
        // {
        //   name: '历史',
        //   componentName: 'Caselaw',
        //   icon: 'panli'
        // }
      ],
      tabHeight: 0,
      active: 0,
      showRectMenu: false,
      // 是否在触摸状态
      isTouching: false,
      startTime: null,
      startPoint: { x: 0, y: 0 },
      currentPoint: { x: 0, y: 0 },
      canChangeTabHeight: true,
      offsetY: 0,
      inPullDown: false,
      // 强制开启滑动
      forceSwipe: false,
      isrefresh: true,
      list: 0,
    }
  },
  computed: {
    ...mapState('caseDetail', ['currrentTabName', 'tabIndex']),
    caseId() {
      return this.$route.params.caseId
    },
    canSwipe() {
      /**
       * *  [需求描述]- 当前tab为分析时 禁用外层左右滑动
       **/
      const activeTab = this.tabs[this.active].componentName
      // const isMaxMode = this.tabHeight === this.maxHeight
      if (activeTab === 'AnalyseTab') {
        return false
      } else {
        return true
      }
    },
    tabWrapperStyle() {
      return {
        height: `${this.maxHeight}px`,
        minHeight: `${this.maxHeight}px`,
      }
    },
    tabWrapperStyleUsingTransfrom() {
      const translateY = this.maxHeight - this.tabHeight
      // 案件档案需要一个高度值 先用全局变量赋值使用
      window.__translateY__ = translateY
      if (this.tabHeight === this.maxHeight) {
        return {
          height: `${this.TLHeight}px`,
          transform: `translate3d(0, ${translateY}px, 0)`,
          boxShadow: 'none',
        }
      } else {
        return {
          height: `${this.TLHeight}px`,
          transform: `translate3d(0, ${translateY}px, 0)`,
        }
      }
    },
    normalHeight() {
      return (this.TLHeight / 3) * 2
    },
    TLHeight() {
      return document.documentElement.clientHeight - (44 / 37.5) * this.rootFontSize
    },
    rootFontSize() {
      return document.documentElement.style.fontSize.replace(/px/g, '')
    },
    maxHeight() {
      const tabTopHeight = 1.173333 * this.rootFontSize
      return this.TLHeight - tabTopHeight
    },
    teamAvatarStyle() {
      let oY = (18 / 37.5) * this.rootFontSize
      if (this.tabHeight === this.maxHeight) {
        if (this.showLeftMenu && !this.showRightMenu) {
          return {
            display: 'none',
          }
        } else {
          const size = 26
          const width = size / 37.5
          oY = ((size + (44 - size) / 2) / 37.5) * this.rootFontSize
          return {
            transform: `translate3d(0, -${oY}px, 0)`,
            boxShadow: 'none',
            width: `${width}rem`,
            height: `${width}rem`,
          }
        }
      } else {
        return {
          transform: `translate3d(0, -${oY}px, 0)`,
        }
      }
    },
    tabContentWrapperStyle() {
      return {
        minHeight: `${this.maxHeight - 1}px`,
      }
    },
    anaTabIndex() {
      const index = this.tabs.findIndex((tab) => {
        return tab.componentName === 'AnalyseTab'
      })
      return index
    },
  },
  methods: {
    ...mapMutations('caseDetail', ['setTabIndex']),
    ...mapMutations('caseDetail', ['setBottomTabMode', 'setCurrentTabName']),
    onPullDown() {
      this.minusTabHeight()
      setTimeout(() => {
        this.inPullDown = false
      }, 100)
    },
    addTabHeight() {
      if (this.tabHeight === this.maxHeight) {
        return null
      } else if (this.tabHeight >= this.normalHeight && this.tabHeight < this.maxHeight) {
        this.tabHeight = this.maxHeight
      } else if (this.tabHeight >= 0) {
        this.tabHeight = this.normalHeight
      }
    },
    minusTabHeight() {
      if (this.tabHeight === this.maxHeight) {
        this.tabHeight = this.normalHeight
      } else if (this.tabHeight <= this.normalHeight) {
        this.tabHeight = 0
      }
    },
    showMainContent() {
      this.tabHeight = 0
    },
    // 右上角方形菜单点击事件
    selectTab(index) {
      const tab = this.hiddenTabs[index]
      this.otherTab = false
      setTimeout(() => {
        const firstFiveTabs = this.tabs.filter((item, i) => {
          return i < 5
        })
        const remainTabs = this.tabs.length < 5 ? firstFiveTabs : this.tabs
        let _index = this.tabs.findIndex((t) => {
          return t.componentName === tab.componentName
        })
        if (_index > -1) {
          setTimeout(() => {
            this.renderTab = true
            this.showRectMenu = false
            this.otherTab = true
            this.active = _index
          })
          return
        }
        this.tabs = [...remainTabs, tab]
        this.renderTab = true
        _index = this.tabs.findIndex((t) => {
          return t.componentName === tab.componentName
        })
        setTimeout(() => {
          this.active = _index
        })
        this.showRectMenu = false
        this.otherTab = true
      }, 10)
    },
    toggleRectMenu() {
      this.showRectMenu = !this.showRectMenu
    },
    onTabChange(index) {
      const tabName = this.tabs[index].componentName
      // location.reload(this['setCurrentTabName'](tabName))
      this.setCurrentTabName(tabName)
    },
    reduceAction() {
      setTimeout(() => {
        this.canChangeTabHeight = true
      }, 100)
    },
    // 触摸事件 --start--
    ontouchstart(e) {
      this.isTouching = true
      this.startTime = new Date()
      var t = e.touches ? e.touches[0] : e
      this.startPoint = { x: t.pageX, y: t.pageY }
    },
    ontouchmove(e) {
      const childHasTrigger = e.target.innerHTML.indexOf('pull-trigger') !== -1
      console.log('aaaa ontouch move')
      const isTrigger = e.target.className.indexOf('pull-trigger') !== -1
      if (this.isTouching) {
        if (this.canChangeTabHeight) {
          const t = e.touches ? e.touches[0] : e
          const p = { x: t.pageX, y: t.pageY }
          this.currentPoint = p
          const x1 = this.startPoint.x
          const x2 = this.currentPoint.x
          const y1 = this.startPoint.y
          const y2 = this.currentPoint.y
          if (Math.abs(x1 - x2) > 2 || Math.abs(y1 - y2) > 2) {
            // 判断滑动方向
            const direction = this.getSwipeDirection(x1, x2, y1, y2)
            if (direction === 'up' && this.tabHeight !== this.maxHeight) {
              this.canChangeTabHeight = false
              this.debounce(this.addTabHeight(), 700)
              this.reduceAction()
            } else if (
              direction === 'down' &&
              (isTrigger || childHasTrigger) &&
              this.tabHeight !== 0
            ) {
              this.canChangeTabHeight = false
              this.debounce(this.minusTabHeight(), 700)
              this.reduceAction()
            }
          }
        }
      }
    },
    ontouchend(e) {
      this.isTouching = false
    },
    // 触摸事件 --end--
    // 处理分析边缘滑动
    handleSideSwipe(payload) {
      if (payload === 'LEFT') {
        setTimeout(() => {
          this.active = parseInt(this.active - 1)
        }, 100)
      } else if (payload === 'RIGHT') {
        setTimeout(() => {
          this.active = parseInt(this.active + 1)
        }, 100)
      }
    },
    // 点击左侧菜单
    toggleLeftMenu() {
      this.$emit('toggleLeftMenu', 'left menu clicked!')
    },
    startChat() {
      if (this.tid) {
        const caseId = parseInt(this.caseId, 0)
        const iOSUA = 'fb-iOS-Webview'
        const AndroidUA = 'fb-Android-Webview'
        const flutterUA = 'fb-flutter-Webview'

        const ua = navigator.userAgent
        if (ua === AndroidUA) {
          try {
            // eslint-disable-next-line
            AND2JS.startChatSession('Team', this.tid.toString(), caseId, -1, -1)
          } catch (err) {
            console.log(err)
            this.$notify('非原生APP环境无法使用联系协作人功能')
          }
        } else if (ua === iOSUA) {
          try {
            window.webkit.messageHandlers.startChatSession.postMessage(
              JSON.parse(this.tid)
            )
          } catch (err) {
            console.log(err)
            this.$notify('非原生APP环境无法使用联系协作人功能')
          }
        } else if (ua === flutterUA) {
          try {
            window.startChatSession.postMessage(this.tid)
          } catch (err) {
            console.log('startChatSession.postMessage', err)
            this.$notify('非原生APP环境无法使用联系协作人功能')
          }
        } else {
          this.$notify('非原生APP环境无法使用联系协作人功能')
        }
      } else {
        // TODO 显示添加协作人提示
        this.$emit('addCoop')
      }
    },
    // 获取群聊id
    getCoopAvatar() {
      this.$axios
        .get(`${caseBase}/management/legal/case/${this.caseId}/group/${this.tid}`)
        .then((res) => {
          if (res.data && res.data.icon) {
            this.coopAvatarUrl = res.data.icon
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.$store.state.caseDetail.tabIndex) {
      const lishi = this.hiddenTabs[this.$store.state.caseDetail.tabIndex - 2]
      this.tabs.push(lishi)
      this.renderTab = false
      setTimeout(() => {
        const firstFiveTabs = this.tabs.filter((item, i) => {
          return i <= 4
        })
        const remainTabs = this.tabs.length <= 5 ? this.tabs : firstFiveTabs
        this.tabs = [...remainTabs, lishi]
        this.renderTab = true
        const index = this.tabs.findIndex((t) => {
          return t.componentName === lishi.componentName
        })
        this.active = index
        this.showRectMenu = false
      }, 10)
      this.setTabIndex(null)
    }
    // 根据情况恢复到上次打开的tab页
    const index = this.tabs.findIndex((tab) => {
      return tab.componentName === this.currrentTabName
    })
    if (this.resumeTab && index) {
      this.active = index
    }
    this.$nextTick(() => {
      if (this.initialHeight === 'MIN') {
        this.tabHeight = 0
        this.setBottomTabMode('MIN')
      } else if (this.initialHeight === 'MAX') {
        this.tabHeight = this.maxHeight
        this.setBottomTabMode('MAX')
      } else {
        this.tabHeight = this.normalHeight
        this.setBottomTabMode('NORMAL')
      }
    })
  },
  watch: {
    tid(nv, ov) {
      if (nv && nv !== '') {
        this.getCoopAvatar()
      }
    },
    tabHeight(nv, ov) {
      let mode = 'NORMAL'
      if (this.tabHeight === this.maxHeight) {
        mode = 'MAX'
      } else if (this.tabHeight === 0) {
        mode = 'MIN'
      }
      this.setBottomTabMode(mode)
    },
  },
}
</script>

<style lang="stylus">
.dynamic-height-tab.van-tab__pane
  background #ffffff
  overflow-y auto
  -webkit-overflow-scrolling touch
.casedetail-tab-content-wrapper
  height 100%
& .van-hairline--top-bottom
  &::after
    display none
& .van-tabs__wrap
  & .van-tabs__nav
    width calc(100% - 40px)
    .van-tab>div
      div.tab-title
        div.tab-name
          font-size 14px
          font-size 14px
          font-weight 200
          font-family PingFangSC
    .van-tab.van-tab--active
      & div.tab-title
        & div.tab-name
          display inline
          padding 2px 6px
          background linear-gradient(90deg, rgba(75, 158, 251, 1), rgba(25, 131, 251, 1))
          border-radius 15px
          color #ffffff
          box-shadow 0px 1px 5px 1px rgba(101, 172, 251, 0.6)
        & i
          width auto
          font-size 14px
          font-weight 200
          font-family PingFangSC
          margin-right 2px
.case-nav-tabs-left-menu.van-tabs
  padding-top 43px
  & .van-hairline--top-bottom
    &::after
      display none
  .van-tabs__wrap
    .van-tabs__nav
      width calc(100% - 40px)
      left 40px
#scroll-menu
  position absolute
  pointer-events auto
  background #ffffff
  width 100%
  z-index 103
  will-change transform
  transition transform 0.38s ease-in-out
  box-shadow 0px -1px 12px 3px rgba(213, 213, 213, 0.58)
  .menu-mask
    position fixed
    height calc(100% - 44px)
    width 100%
    top 44px
    background transparent
    z-index 104
  .bottom-click-trigger
    position fixed
    height 44px
    width 100%
    z-index 106
  .tabs-wrapper
    height 100%
    & .van-tabs__content--animated
      overflow-y scroll
  .team-avatar
    position fixed
    right 38px
    height 30px
    width 30px
    z-index 104
    background #ffffff
    box-shadow 0px 0px 12px 0px rgba(204, 204, 204, 0.78)
    border-radius 50%
    transform translate3d(0, -18px, 0)
    will-change transform
    display flex
    justify-content center
    align-items center
    i
      color #4B9EFB
      font-size 18px
  .left-menu
    position fixed
    height 44px
    width 40px
    z-index 107
    background #ffffff
    display flex
    justify-content center
    align-items center
    cursor pointer
    left 0
    i
      color #666
  .drage-area, .rect-menu-trigger
    position fixed
    height 44px
    width 40px
    z-index 105
    background #ffffff
    display flex
    justify-content center
    align-items center
    cursor pointer
    .iconfont
      font-size 20px
      color #666
  .rect-menu-trigger
    right 0
  .drage-area
    left 0
  .rect-menu-box
    width 200px
    height 240px
    border-radius 10px
    position fixed
    margin-top 45px
    z-index 106
    background #ffffff
    box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
    right 10px
    .menu
      width 33.3%
      height 33.3%
      border none
      float left
      display inline-flex
      justify-content center
      align-items center
      &:active
        background #f3f4f7
      .menu-item
        .icon
          display flex
          justify-content center
          align-items center
          i
            font-size 20px
            padding-bottom 4px
            color #444444
        .name
          color #444444
          text-align center
</style>
