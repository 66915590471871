var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addCooperationInForm" },
    [
      _c(
        "div",
        {
          staticClass: "add-top",
          on: {
            click: function ($event) {
              _vm.showSearch = true
            },
          },
        },
        [_vm._m(0), _c("i", { staticClass: "iconfont icon-add1" })]
      ),
      _c(
        "div",
        { staticClass: "nodeform-cooperation" },
        [
          _vm.noCooperator ? _c("div", [_vm._v("暂时没有协作人")]) : _vm._e(),
          _vm._l(_vm.renderArray, function (item, index) {
            return _c("img", {
              key: index,
              staticClass: "nodeform-cooperation-detail",
              attrs: { src: item.avatarUrl },
            })
          }),
          _vm.moreThan5 ? _c("div", [_vm._v("....")]) : _vm._e(),
        ],
        2
      ),
      _vm.showSearch
        ? _c("search-cooperation", {
            attrs: { addCooperation: "1" },
            on: {
              cancel: function ($event) {
                _vm.showSearch = false
              },
              added: _vm.added,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "iconfont icon-mine" }),
      _c("span", [_vm._v("协作人")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }