<template>
  <img :src="avatarUrl"
       v-if="hasAvatar"
       class="coop-avatar-img" />
  <i class="iconfont icon-user"
     v-else></i>
</template>

<script>
export default {
  name: 'cooperation-user',
  props: {
    avatarUrl: String
  },
  computed: {
    hasAvatar () {
      if (this.avatarUrl && this.avatarUrl !== '') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang='stylus' scoped>
.coop-avatar-img
  border-radius 50%
</style>
