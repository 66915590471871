<template>
  <div class="proofPeriod">
    <span>
      <span class="red">*</span>
      <span>举证期</span>
    </span>
    <div class="inputBox border-bottom">
      <span class="center"
            @click="add(15,checkedFif)"
            :class="{isCkecked:checkedFif}">15</span>
      <span class="center"
            @click="add(30,checkedThi)"
            :class="{isCkecked:checkedThi}">30</span>
      <div class="time"
           @click="showTimer = true">
        <span>{{calcDate}}</span>
        <i class="iconfont icon-calendar"></i>
      </div>
    </div>
    <van-popup v-model="showTimer"
               position="bottom">
      <van-datetime-picker v-model="$store.state.nodeform.juZhengEndDate"
                           :type="$store.state.nodeform.dateKind"
                           :item-height="pickCellheight"
                           :min-date="minDate"
                           @confirm="confirmDateHandle"
                           @cancel="cancelDateHandle"
                           :cancel-button-text="$store.state.nodeform.dateKind=='date'?'切换为时间':'切换为日期'" />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'proofPeriod',
  computed: {
    pickCellheight () {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    checkedFif () {
      if (this.$store.state.nodeform.juZhengEndDate === null) {
        return ''
      } else {
        const endNum = this.getTimeNum(this.$store.state.nodeform.juZhengEndDate)
        const startNum = this.getTimeNum(new Date(this.$store.state.nodeform.startDate))
        if ((endNum - startNum) / (1000 * 60 * 60 * 24) === 15) {
          return true
        } else {
          return false
        }
      }
    },
    checkedThi () {
      if (this.$store.state.nodeform.juZhengEndDate === null) {
        return ''
      } else {
        const endNum = this.getTimeNum(this.$store.state.nodeform.juZhengEndDate)
        const startNum = this.getTimeNum(new Date(this.$store.state.nodeform.startDate))
        if ((endNum - startNum) / (1000 * 60 * 60 * 24) === 30) {
          return true
        } else {
          return false
        }
      }
    },
    minDate () {
      return new Date(this.$store.state.nodeform.startDate)
    },
    calcDate () {
      if (this.$store.state.nodeform.juZhengEndDate === null) {
        return ''
      } else {
        var a = this.$store.state.nodeform.juZhengEndDate
        var time = ''
        time += a.getFullYear() + '/'
        time += (a.getMonth() + 1) > 9 ? (a.getMonth() + 1) : '0' + (a.getMonth() + 1)
        time += '/'
        time += a.getDate() > 9 ? a.getDate() : ('0' + a.getDate())
        return time
      }
    },
    load () {
      return this.$store.state.nodeform.loadingFinish
    }
  },
  data () {
    return {
      endDate: null,
      showTimer: false,
      selecters: [
        {
          count: 15,
          isCkecked: false
        },
        {
          count: 30,
          isCkecked: false
        }
      ]
    }
  },
  methods: {
    confirmDateHandle () {
      this.showTimer = false
    },
    cancelDateHandle () {
      if (this.$store.state.nodeform.dateKind === 'date') {
        this.$store.state.nodeform.dateKind = 'datetime'
      } else {
        this.$store.state.nodeform.dateKind = 'date'
      }
    },
    add (days, checked) {
      if (checked) {
        this.$store.state.nodeform.juZhengEndDate = null
      } else {
        this.$store.state.nodeform.juZhengEndDate = new Date(this.$store.state.nodeform.startDate + 1000 * 60 * 60 * 24 * days)
      }
    },
    getTimeNum (date) {
      var a = date
      var time = ''
      time += a.getFullYear() + '-'
      time += (a.getMonth() + 1) > 9 ? (a.getMonth() + 1) : '0' + (a.getMonth() + 1)
      time += '-'
      time += a.getDate() > 9 ? a.getDate() : ('0' + a.getDate())
      time += ' 00:00'
      return new Date(time).getTime()
    }
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
$selecedColor = #CCCCCC
.proofPeriod
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    opacity 0
    margin-right 5px
  .inputBox
    width 70%
    border-color $borderColor
    border-radius 10px
    display flex
    align-items center
    justify-content space-between
    .time
      flex-grow 1
      padding 5px 15px 5px 0
      margin-left 5px
      display flex
      align-items center
      justify-content space-between
      .iconfont
        color $color
        transform scale(1.5)
    .center
      padding 0 5px
      margin-right 5px
      display flex
      align-items center
      justify-content center
      &:nth-of-type(1)
        margin-left 15px
    .isCkecked
      background-color $selecedColor
      color white
      border-radius 15px
</style>
