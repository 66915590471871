var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "timeline-toolbar" } }, [
    _c("div", { staticClass: "zoom-in", on: { click: _vm.zoomIn } }, [
      _c("i", { staticClass: "iconfont icon-minus" }),
    ]),
    _c("div", { staticClass: "zoom-out", on: { click: _vm.zoomOut } }, [
      _c("i", { staticClass: "iconfont icon-add" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }