<template>
  <div>
    <!-- <ul class="wrap"> -->
    <ul class="wrap">
      <!-- 节点列表 -->
      <li class="jiedians"
          :class="nodelist.nodeStatus==='COMPLETE'?'border-bottom':''"
          v-show="isshowjiedian||isshow">
        <div :class="(nodelist.nodeStatus==='FLOw' || nodelist.completeTime===null || nodelist.beginDate===null)?'form-left':'left'">
          <span class="val-name"
                @click="openForm(nodelist.nodeId,nodelist.nodeName)">{{nodelist.nodeName}}</span>
          <span class="val-num"
                @click="showptPopup">{{nodelist.probability | NumberFormat}}</span>
        </div>
        <div class="right">
          <div></div>
          <div class="val-time"
               @click="openForm(nodelist.nodeId,nodelist.nodeName)"
               v-if='nodelist.nodeStatus==="COMPLETE"'>
            <!-- <span v-if="nodelist"></span> -->
            <div v-if="nodelist.completeTime!==null">

              <span v-if="nodelist.nodeFormType==='DATETIME_CONTENT' || nodelist.nodeFormType==='COURT_SESSION' ||nodelist.nodeFormType==='FILING'">{{nodelist.completeTime | DateTime}}</span>
              <span v-if="nodelist.nodeFormType==='DATE_CONTENT' || nodelist.nodeFormType==='NO_FORM'">{{nodelist.completeTime | GetDate}}</span>
              <span v-if="nodelist.nodeFormType==='INTERVAL_DATE_CONTENT'">{{nodelist.completeTime | GetDate}}</span>
            </div>

          </div>

          <span class="iconfont icon-gengduo"
                ref="icon"
                @click="change"></span>
        </div>

      </li>

      <!-- 任务列表组件 -->
      <nodelist v-for="(value,index) in   newlist"
                :key="value.id"
                :value="value"
                :canshu="value.nodeId"
                :NodeForm='NodeForm'
                v-show="showJiedianList"
                @DeleteOverBuilt='showDeleteOverBuilt'
                @keydownHan="keydownHan($event,value, index)"
                ref="nodelists"></nodelist>
      <nodelist v-for="(item,index) in oldlist"
                :key="item.contentId"
                :item="item"
                :index='index'
                :canshu="val.nodeId"
                :NodeForm='NodeForm'
                :oldlist='oldlist'
                v-show="showJiedianList"
                @DeleteOverBuilt='showDeleteOverBuilt'
                @keydownHandle="keydownHandle($event, index)"
                ref="nodelist"
                class="itemnodelist"></nodelist>
    </ul>
  </div>
</template>
<script>
import nodelist from './nodelist'
import { caseBase } from '~api-config'
import getNodePosTimeMixin from '~mixins/getNodePosTime'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'jiedian',
  props: ['val', 'isshow', 'taskjiedian', 'jiedianList'],
  mixins: [getNodePosTimeMixin],
  components: {
    nodelist
  },

  filters: {
    // 开始时间格式化   年：月：日  时：分
    DateTime: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      const hours = beginTime.getHours()
      const minutes = beginTime.getMinutes()
      return year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day) + ' ' + (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes)
    },
    // 年：月：日
    GetDate: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day)
    },
    NumberFormat: function (value) {
      return (value * 100).toFixed(2) + '%'
    }

  },
  data () {
    return {
      oldlist: [],
      newlist: [],
      showJiedianList: false,
      show: false,
      check: false,
      time: null,
      avatarUrl: null,
      nodelist: null,
      nowTime: null, // 当前时间为毫秒数
      isshowjiedian: false,
      NodeForm: {
        nodeFormEntity: {},
        formContentEntity: []
      },

      container: () => document.body

    }
  },

  created () {
    // console.log(this.val)
    this.nodelist = this.val.legalCaseNode
    // completeTime不为空
    var nowtime = new Date().getTime()

    if (this.nodelist.completeTime !== null) {
      if (this.nodelist.completeTime < nowtime) {
        this.isshowjiedian = false
        console.log('completeTime', this.val.name, this.isshowjiedian, this.nodelist)
      } else {
        this.isshowjiedian = true
        console.log('completeTime', this.val.name, this.isshowjiedian, this.nodelist)
      }
    } else
    // beginTime不为空
    if (this.nodelist.beginTime !== null) {
      if ((this.nodelist.beginTime) < (nowtime)) {
        this.isshowjiedian = false
        console.log('beginTime', this.val.name, this.isshowjiedian, this.nodelist)
      } else {
        this.isshowjiedian = true
        console.log('beginTime', this.val.name, this.isshowjiedian, this.nodelist)
      }
    } else {
      if (this.nodelist.endTime !== null) {
        // endTime不为空
        if ((this.nodelist.endTime < (nowtime))) {
          this.isshowjiedian = false
          console.log('endTime', this.val.name, this.isshowjiedian, this.nodelist)
        } else {
          this.isshowjiedian = true
          console.log('endTime', this.val.name, this.isshowjiedian, this.nodelist)
        }
      }
    }
  },
  mounted () {
    // 显示当前时间
    this.getNowTime()
  },
  computed: {
    ...mapState('caseDetail', ['items']),
    nodelistlength () {
      // 通过选中节点图标 控制bottombar的显示或隐藏
      return this.newlist.filter((item, index) => {
        return this.$refs.nodelist[index].active
      }).length
    },
    checkNum () {
      if (this.val.probability) {
        return (this.val.probability * 100).toFixed(2) + '%'
      } else {
        return ''
      }
    }

  },
  methods: {
    ...mapMutations('nodeform', [
      'showNodeForm',
      'addmore'
    ]),
    // 时间格式化
    getdate (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day)
    },
    change (e) {
      e.preventDefault()

      const caseId = this.$route.params.caseId

      this.newlist = this.val.taskViews
      this.showJiedianList = !this.showJiedianList
      if (this.showJiedianList) {
        this.$axios.get(`${caseBase}/lts/nodeForm/get/${caseId}/${this.val.nodeId}`,
          { params: { nodeName: this.nodelist.nodeName } }).then(res => {
          if (res.data.data.NodeForm.formContents !== null) {
            // this.showJiedianList = !this.showJiedianList
            if (res.status === 200 && res.data.data.NodeForm.formContents.length >= 1) {
              this.NodeForm = res.data.data.NodeForm
              this.oldlist = res.data.data.NodeForm.formContents

              for (const i in this.oldlist) {
                // if (i !== 0) {
                if (this.oldlist[i].content === '') {
                  this.oldlist.splice(i, 1)
                }
                // }
              }
              console.log('00000000000')
              this.$nextTick(console.log(this.oldlist))
            } else if (this.newlist.length === 0 && (this.oldlist.length === 0 || this.oldlist === '[]')) {
              const params = {
                isChecked: 0,
                content: '',
                status: 1
              }
              this.oldlist.splice(0, 0, params)
              this.$nextTick(() => {
                console.log(this.oldlist)
              })
            }
          } else {
            // const nodeId = this.val.nodeId
            // const nodename = this.val.name
            // this.openForm(nodeId, nodename)
            // this.showJiedianList = !this.showJiedianList
            if (this.oldlist.length === 0) {
              const params = {
                isChecked: 0,
                content: '',
                status: 1
              }
              this.oldlist.splice(0, 0, params)
              this.$nextTick(() => {
                console.log(this.oldlist)
              })
            }
          }
        })
      }

      return false
    },
    // change (e) {
    //   e.preventDefault()

    //   const caseId = this.$route.params.caseId

    //   this.newlist = this.val.taskViews
    //   this.$axios.get(`${caseBase}/lts/nodeForm/get/${caseId}/${this.val.nodeId}`).then(res => {
    //     if (res.data.data.NodeForm.formContents !== null) {
    //       this.showJiedianList = !this.showJiedianList

    //       if (res.status === 200 && res.data.data.NodeForm.formContents.length >= 1) {
    //         this.NodeForm = res.data.data.NodeForm
    //         this.oldlist = res.data.data.NodeForm.formContents

    //         for (let i in this.oldlist) {
    //           // if (i !== 0) {
    //           if (this.oldlist[i].content === '') {
    //             this.oldlist.splice(i, 1)
    //           }
    //           // }
    //         }
    //         console.log('00000000000')
    //         this.$nextTick(console.log(this.oldlist))
    //       } else if (this.newlist.length === 0 && (this.oldlist.length === 0 || this.oldlist === '[]')) {
    //         const params = {
    //           isChecked: 0,
    //           content: '',
    //           status: 1
    //         }
    //         this.oldlist.splice(0, 0, params)
    //         this.$nextTick(() => {
    //           console.log(this.oldlist)
    //         })
    //       }
    //     } else {
    //       const nodeId = this.val.nodeId
    //       const nodename = this.val.name
    //       this.openForm(nodeId, nodename)
    //     }
    //   })

    //   return false
    // },
    // 获取节点列表
    getList () {
      const caseId = this.$route.params.caseId
      this.newlist = this.val.taskViews
      this.$axios.get(`${caseBase}/lts/nodeForm/get/${caseId}/${this.val.nodeId}`).then(res => {
        // if (res.data.data.NodeForm.formContents !== null) {
        if (res.status === 200) {
          this.NodeForm = res.data.data.NodeForm
          this.oldlist = res.data.data.NodeForm.formContents

          // for (let i in this.oldlist) {
          //   if (this.oldlist[i].content === '') {
          //     this.oldlist.splice(i, 1)
          //   }
          // }
          // console.log('00000000000')
          // this.$nextTick(console.log(this.oldlist))
          // } else if (this.newlist.length === 0 && (this.oldlist.length === 0 || this.oldlist === '[]')) {
          //   const params = {
          //     isChecked: 0,
          //     content: '',
          //     status: 1
          //   }
          //   this.oldlist.splice(0, 0, params)
          //   this.$nextTick(() => {
          //     console.log(this.oldlist)
          //   })
        }
        // } else {
        //   if (this.newlist.length === 0) {
        //     const params = {
        //       isChecked: 0,
        //       content: '',
        //       status: 1
        //     }
        //     this.oldlist.splice(0, 0, params)
        //     this.$nextTick(() => {
        //       console.log(this.oldlist)
        //     })
        //   }
        // }
      })
    },
    showDeleteOverBuilt () {
      if (this.nodelistlength) {
        this.check = true
        this.$emit('showDeleteOverBuilt')
      } else {
        this.check = false
        this.$emit('closeDeleteOverBuilt')
      }
    },
    showptPopup () {
      const caseId = this.$route.params.caseId
      const nodeId = this.nodelist.nodeId
      const startDate = this.nodelist.beginDate
      const title = this.nodelist.nodeName
      const formDate = {
        type: this.val.nodeFormType,
        startDate,
        caseId,
        nodeId,
        title,
        showQuestion: true
      }
      this.$store.commit('nodeform/showNodeForm', formDate)
    },
    // 键盘事件
    keydownHan (event, value, index) {
      if (event.event.keyCode === 13) {
        // 新增节点内容
        const params = {
          isChecked: 0,
          content: '',
          status: 1
        }
        // var arr = []
        if (event.nodename) {
          this.oldlist.unshift(params)
          this.$nextTick(() => {
            this.$refs.nodelist[0].focus()
          })
        }

        event.event.preventDefault()
      } else if (event.event.keyCode === 8 && event.event.target.value === '') {
        // 删除节点内容
        const length = this.newlist.length + this.oldlist.length
        if (length >= 2) {
          this.newlist[index].status = 0
          const formContentEntity = value
          const userId = this.$store.state.user.userInfo.id
          this.$axios.put(`${caseBase}/management/user/${userId}/task/${value.id}`, { formContentEntity }).then(res => {
            if (res.status === 200) {
              this.newlist.splice(index, 1)
              var key = (index === 0 ? index : (index - 1))
              this.$refs.nodelist[key].focus()
            }
          })
        }

        event.event.preventDefault()
      }
    },
    // 键盘事件
    keydownHandle (event, index) {
      const caseId = this.$route.params.caseId
      const nodeId = this.val.nodeId
      const nodeFormEntity = { caseId, nodeId }
      if (event.event.keyCode === 13) {
        // 新增节点内容
        const params = {
          isChecked: 0,
          content: '',
          status: 1
        }
        if (event.nodename) {
          this.oldlist.unshift(params)
          this.$nextTick(() => {
            this.$refs.nodelist[0].focus()
            console.log(this.oldlist)
          })
        } else {
          this.oldlist.splice(index + 1, 0, params)
          const formContentEntity = this.oldlist
          this.$axios.post(`${caseBase}/lts/nodeForm/saveContent`, { formContentEntity, nodeFormEntity }).then(res => {
            if (res.status === 200) {
              // this.getList()
              // this.showJiedianList = true

              this.$refs.nodelist[index + 1].focus()
              console.log(this.oldlist)
            }
          })
        }

        event.event.preventDefault()
      } else if (event.event.keyCode === 8 && event.event.target.value === '') {
        // 删除节点内容
        const length = this.newlist.length + this.oldlist.length

        if (length > 1) {
          this.oldlist[index].status = 0
          const formContentEntity = this.oldlist
          this.$axios.post(`${caseBase}/lts/nodeForm/saveContent`, { formContentEntity, nodeFormEntity }).then(res => {
            if (res.status === 200) {
              this.oldlist.splice(index, 1)
              var key = (index === 0 ? index : (index - 1))
              this.$refs.nodelist[key].focus()
            }
          })
        }

        event.event.preventDefault()
      }
    },
    // 获取当前时间
    getNowTime () {
      this.nowTime = new Date().getTime()
    },
    // 点击节点名称后弹出对应框
    openForm (nodeId, nodename) {
      const caseId = this.$route.params.caseId

      const startDate = this.nodelist.endTime

      const title = nodename
      const nodeFormType = this.nodelist.nodeFormType
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    }
  }

}
</script>
<style lang="stylus" scoped>
.jiedians::before
  border-color #fff
.border-bottom
  font-weight 700
.jiedians
  display flex
  justify-content space-between
  align-items center
  padding 15px 19px 13px 15px
  background-color #F8F8F8
  margin-bottom 1px
  .left
    display flex
    align-items center
    width 58%
    .val-name
      font-size 14px
      font-family PingFangSC
      color #000
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      width 40%
    .val-num
      margin-left 30px
      font-size 13px
      font-family PingFangSC
      color #4B9EFB
  .form-left
    display flex
    align-items center
    width 96%
    .val-name
      font-size 14px
      font-family PingFangSC
      color #000
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
    .val-num
      margin-left 30px
      font-size 13px
      font-family PingFangSC
      color #4B9EFB
  .right
    width 52%
    display flex
    justify-content space-between
    align-items center
  .val-time
    font-size 14px
  .icon-gengduo
    color #ccc
    font-size 14px
  .icon-gengduo2
    color #ccc
    font-size 14px
.BottomBar
  width 100%
  height 52px
  background-color #fff
  border-top 1px solid rgba(238, 238, 238, 1)
  position fixed
  .nodewrap
    display flex
    justify-content space-between
    margin 10px 52px 0 52px
    & li
      display flex
      flex-direction column
      align-items center
      color #333
      .text
        font-size 11px
      .iconfont
        font-size 20px
</style>
