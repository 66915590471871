<template>
  <div class="drop-box"
       ref="box">
    <skydrive ref="skyDrive"></skydrive>
  </div>
</template>

<script>
import skydrive from '../../../../../skyDrive/index'
export default {
  components: { skydrive },
  name: 'drop-box',
  mounted () {
    setTimeout(() => {
      if (document.getElementById('scroll-menu').style.boxShadow === 'none') {
        this.$refs.skyDrive.$el.style.height = '100%'
        this.$refs.box.style.height = (document.documentElement.offsetHeight - 42 * 2) + 'px'
      } else {
        this.$refs.skyDrive.$el.style.height = '77%'
        this.$refs.box.style.height = '100px'
      }
      const _self = this
      // eslint-disable-next-line accessor-pairs
      Object.defineProperty(document.getElementById('scroll-menu').style, 'boxShadow', {
        set (val) {
          if (val) {
            _self.$refs.skyDrive.$el.style.height = '100%'
            _self.$refs.box.style.height = (document.documentElement.offsetHeight - 42 * 2) + 'px'
          } else {
            _self.$refs.skyDrive.$el.style.height = '77%'
            _self.$refs.box.style.height = '100px'
          }
        }
      })
    })
  }
}
</script>
<style lang="stylus" scoped>
*
  margin 0px
  padding 0px
  list-style none
.drop-box
  overflow auto
</style>
