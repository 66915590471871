<template>
  <div class="register-tab">
    <under-dev />
  </div>
</template>

<script>
export default {
  name: 'register-tab'
}
</script>

<style>
</style>
