<template>
  <ul>
    <li class="wrap border-bottom">
      <div class="left" @click="openNodeForm">
        <!-- <span class="val-name">{{val.nodeName}}</span> -->
        <span
          :class="val.caseNodeStatus === 'FLOW' ? 'val-name' : 'val-name2'"
          >{{ val.nodeName }}</span
        >
        <span class="val-num" @click.stop="showptPopup">{{ checkNum }}</span>
        <div
          class="val-time"
          @click="openNodeForm"
          v-if="val.caseNodeStatus === 'COMPLETE'"
        >
          <div v-if="val.completeDate !== null">
            <span
              v-if="
                val.nodeFormType === 'DATETIME_CONTENT' ||
                  val.nodeFormType === 'COURT_SESSION' ||
                  val.nodeFormType === 'FILING'
              "
              >{{ val.completeDate | DateTime }}</span
            >
            <span
              v-if="
                val.nodeFormType === 'DATE_CONTENT' ||
                  val.nodeFormType === 'NO_FORM'
              "
              >{{ val.completeDate | GetDate }}</span
            >
            <span v-if="val.nodeFormType === 'INTERVAL_DATE_CONTENT'">{{
              val.beginDate | GetDate
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="
                  right"
        @click="change"
      >
        <!-- <div></div> -->
        <span class="iconfont icon-gengduo" ref="icon"></span>
      </div>
    </li>
    <sonnode
      v-for="(item, index) in SonNodelist"
      :key="index"
      v-show="showNodeList"
      :item="item"
      ref="sonnode"
      @showsonNodeHistory="showsonNodeHistory"
      @closesonNodeHistory="closesonNodeHistory"
      @sonnode="showsonnode"
      @nodefunct="nodefunct"
    ></sonnode>
  </ul>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

import sonnode from './sonnode'
import { caseBase } from '~api-config'
export default {
  name: 'node',
  props: ['val'],
  components: {
    sonnode
  },
  filters: {
    // 开始时间格式化   年：月：日  时：分
    DateTime: function(value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      const hours = beginTime.getHours()
      const minutes = beginTime.getMinutes()
      return (
        year +
        '.' +
        (month > 9 ? month : '0' + month) +
        '.' +
        (day > 9 ? day : '0' + day) +
        ' ' +
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes)
      )
    },
    // 年：月：日
    GetDate: function(value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return (
        year +
        '.' +
        (month > 9 ? month : '0' + month) +
        '.' +
        (day > 9 ? day : '0' + day)
      )
    },
    NumberFormat: function(value) {
      return (value * 100).toFixed(2) + '%'
    }
  },
  data() {
    return {
      showNodeList: false,
      check: false,
      SonNodelist: []
    }
  },
  computed: {
    ...mapState('caseDetail', ['watchButton']),
    checkSonNodelength() {
      // 节点模式下 根据选中图标 控制bottombar的显示或隐藏
      return this.SonNodelist.filter((item, index) => {
        return this.$refs.sonnode[index].active
      }).length
    },
    checkSonNodeHistorylength() {
      // 节点模式下 根据选中图标 控制bottombar的显示或隐藏
      return this.SonNodelist.filter((item, index) => {
        return this.$refs.sonnode[index].check
      }).length
    },
    checkNum() {
      if (this.val.probability) {
        return (this.val.probability * 100).toFixed(2) + '%'
      } else {
        return '0.00%'
      }
    }
  },
  methods: {
    nodefunct(val) {
      this.$emit('nodefunct', val)
    },
    ...mapMutations('nodeform', ['showNodeForm']),
    change() {
      this.showNodeList = !this.showNodeList
      this.getSonNodeList()
    },
    showsonnode(id, e) {
      if (this.checkSonNodelength) {
        this.check = true
        this.$emit('shownode', id, e)
      } else {
        this.check = false
        this.$emit('nonenode', id, e)
      }
    },
    showsonNodeHistory(id, e) {
      if (this.checkSonNodeHistorylength) {
        this.check = true
        this.$emit('shownode', id, e)
      }
    },
    closesonNodeHistory(id, e) {
      if (!this.checkSonNodeHistorylength) {
        this.check = false
        this.$emit('nonenode', id, e)
      }
    },
    // 根据节点查询文书
    getSonNodeList() {
      const caseId = this.$route.params.caseId
      // this.$axios.get(`${caseBase}/new-design/modals/getAllDataByPointId?caseRecordId=${caseId}&pointId=${this.val.nodeId}&page=1`)
      this.$axios
        .get(
          `${caseBase}/document/basicDocuments/getDocumentsByNodeId?caseId=${caseId}&nodeId=${
            this.val.nodeId
          }`
        )
        .then(res => {
          if (res.status === 200 && res.data.data) {
            this.SonNodelist = res.data.data
            this.SonNodelist.forEach(item => {
              setTimeout(() => {
                Array.from(
                  document.querySelectorAll('.lists-right .item-editor')
                ).forEach(itemlist => {
                  // console.log(itemlist.innerText)
                  // console.log(item.editor)
                  if (itemlist.innerText === item.editor) {
                    itemlist.style.fontWeight = '700'
                    itemlist.style.color = '#000000'
                    itemlist.parentElement.children[1].style.color = '#000000'
                    itemlist.parentElement.children[1].style.fontWeight = '700'
                    itemlist.parentElement.previousSibling.children[0].style.fontWeight =
                      '700'
                    itemlist.parentElement.previousSibling.children[0].style.color =
                      '#000000'
                  }
                })
              })
            })
          } else {
            this.$notify('此节点暂无数据')
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    },
    showptPopup() {
      // console.log(this.val)
      const caseId = this.$route.params.caseId
      const nodeId = this.val.nodeId
      const startDate = this.val.beginDate
      const title = this.val.nodeName
      const formDate = {
        type: this.val.nodeFormType,
        startDate,
        caseId,
        nodeId,
        title,
        showQuestion: true
      }
      // console.log(formDate)
      this.showNodeForm(formDate)
    },
    openNodeForm() {
      const caseId = this.$route.params.caseId
      const {
        beginDate: startDate,
        nodeId,
        nodeFormType,
        nodeName: title
      } = this.val
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    }
  },
  watch: {
    watchButton(bool) {
      // this.$parent.getNodeList()
      this.$emit('getNodeList')
    }
  }
}
</script>
<style lang="stylus" scoped>
.wrap::before
  border-color #fff
.wrap
  display flex
  align-items center
  background-color #F8F8F8
  justify-content space-between
  padding 14px 0px 13px 15px
  margin-bottom 1px
  padding-bottom 14px
  .left
    display flex
    flex 1
    align-items center
    .val-name
      font-size 14px
      color #333
      font-family PingFangSC
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
    .val-name2
      font-size 14px
      color #333
      font-family PingFangSC
      font-weight 700
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
    .val-num
      // margin-left 30px
      margin-left 20px
      font-size 13px
      font-family PingFangSC
      color #4B9EFB
    .val-time
      font-size 14px
      margin-left 30px
      font-weight 700
  .right
    width 50px
    display flex
    justify-content center
    align-items center
  .icon-gengduo
    color #ccc
    font-size 14px
</style>
