var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("li", [
        _c("i", {
          ref: "active",
          staticClass: "iconfont",
          class: _vm.calIcon,
          on: { click: _vm.changeIcon },
        }),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "list-left" }, [
            _c(
              "span",
              { staticClass: "item-name", on: { touchend: _vm.goEditorPage } },
              [_vm._v(_vm._s(_vm.item.documentName))]
            ),
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(_vm.item.count === null ? "" : `(${_vm.item.count})`)
              ),
            ]),
          ]),
          _c("div", { staticClass: "list-right" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.item.editor)),
            ]),
            _c("em", { staticClass: "item-newtime" }, [
              _vm._v(_vm._s(_vm.item.type === null ? "" : _vm.calTime)),
            ]),
            _c("i", {
              staticClass: "iconfont icon-more",
              class: _vm.bgColor ? "color2" : "color1",
              on: { click: _vm.change },
            }),
          ]),
        ]),
      ]),
      _vm._l(_vm.HistoryLists, function (val, index) {
        return _c("HistoryList", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHistory,
              expression: "showHistory",
            },
          ],
          key: index,
          ref: "history",
          refInFor: true,
          attrs: { historyData: { val, count: _vm.item.count - index - 1 } },
          on: { showBottomBar: _vm.showBottomBar },
        })
      }),
      _c("Message", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showMessage,
            expression: "showMessage",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }