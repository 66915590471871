<template>
  <div class="precedent-tab">
    <AnJianDangAn />
  </div>
</template>

<script>
import AnJianDangAn from '../../../../pages/Information/zhishishu/views/anjiandangan'
export default {
  name: 'precedent-tab',
  components: {
    AnJianDangAn
  }
}
</script>
