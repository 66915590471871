<template>
  <div class="programType">
    <span>
      <span class="red">*</span>
      <span>程序类型</span>
    </span>
    <div class="inputBox border-bottom">
      <span v-if="$store.state.nodeform.procedureType">{{$store.state.nodeform.procedureType.name}}</span>
      <span v-else></span>
      <van-icon name="arrow-down"
                class="selColor"
                @click="showPopup = true" />
    </div>
    <van-popup v-model="showPopup"
               position="bottom">
      <van-picker :columns="columns"
                  :show-toolbar="true"
                  value-key="name"
                  :item-height="pickCellheight"
                  @confirm="changeType"
                  @cancel="showPopup = false" />
    </van-popup>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
export default {
  name: 'programType',
  data () {
    return {
      showPopup: false,
      columns: []
    }
  },
  computed: {
    pickCellheight () {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    }
  },
  methods: {
    changeType (item) {
      this.$store.state.nodeform.procedureType = item
      this.showPopup = false
    }
  },
  mounted () {
    this.$axios.get(`${caseBase}/lts/case/getProcedureType?caseId=${this.$route.params.caseId}`)
      .then(res => {
        this.columns = res.data.data
        this.$store.state.nodeform.procedureType = this.columns[0]
      })
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
.programType
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    color red
    margin-right 5px
  .inputBox
    width 70%
    padding 5px 15px
    border-color $borderColor
    border-radius 10px
    display flex
    align-items center
    justify-content space-between
    .selColor
      color $color
</style>
