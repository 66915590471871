var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "constractor" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "inputBox border-bottom", on: { click: _vm.clickHandle } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$store.state.nodeform.constractor,
              expression: "$store.state.nodeform.constractor",
            },
          ],
          ref: "input",
          staticClass: "input",
          attrs: { type: "text" },
          domProps: { value: _vm.$store.state.nodeform.constractor },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.$store.state.nodeform,
                "constractor",
                $event.target.value
              )
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _c("span", [_vm._v("承办人")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }