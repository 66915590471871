<template>
  <div>
    <div class="view" ref="view">
      <ul class="item" v-for="(item, index) in data.length > 0 ? data : this.$store.state.search.knowledge" :key="index" @click="JumpToOtherUrl(item.url, item.source)">
        <li class="title" v-html="item.title"></li>
        <p class="text" v-html="item.hits"></p>
        <li class="last">
          <span>{{ item.origin }}</span
          ><span>&nbsp;-&nbsp;{{ item.publisher }}</span
          ><span>&nbsp;-&nbsp;{{ item.pub_date }}</span>
        </li>
      </ul>
    </div>

    <div class="loading" v-if="showtype === 0 && is_load">
      <span>加载中</span>
      <van-loading />
    </div>
    <div class="loading" v-if="showtype === 1">
      <span>没有更多了</span>
    </div>
  </div>
</template>
<script>
import { caseBase, pydataBase } from '~src/api/config'
export default {
  name: 'knowledge',
  props: ['is_load', 'tj'],
  data() {
    return {
      showtype: 0,
      data: [],
      next: true,
      t: null,
      sentence: '',
      summary: '',
    }
  },
  methods: {
    createElement(url, source) {
      // eslint-disable-next-line no-undef
      AND2JS.showNews(url)
      //     this.dv = document.createElement('div')
      //     this.dv.setAttribute('style', ` width:100%;
      // height:100%;
      // position:fixed;
      // left:0px;
      // top:0px;
      // background:black;
      // opacity:0.5;
      // z-index:100000;`)
      //     this.iframe = document.createElement('iframe')
      //     this.iframe.src = source === 'local' ? '' : url
      //     this.iframe.setAttribute('style', ` width:90%;
      // height:80%;
      // top:5%;
      // left:5%;
      // position:fixed;
      // border-radius:5px;
      // background:#fff;
      // z-index:100001;`)
      //     let Data = null
      //     if (source === 'local') {
      //       Data = document.createElement('ul')
      //       this.$axios.get(url).then((data) => {
      //         data.data.result.forEach((item) => {
      //           let li = document.createElement('li')
      //           li.style.marginTop = '20px'
      //           li.style.fontSize = '20px'
      //           li.innerText = item
      //           Data.appendChild(li)
      //         })
      //         this.iframe.contentWindow.document.body.appendChild(Data)
      //       })
      //     }
      //     this.dv.addEventListener('click', () => {
      //       this.dv.remove()
      //       this.iframe.remove()
      //       this.dv = null
      //       this.iframe = null
      //     }, false)
      //     document.body.appendChild(this.iframe)
      //     document.body.appendChild(this.dv)
    },
    JumpToOtherUrl(url, source) {
      if (this.dv || this.iframe) {
        return false
      }
      this.createElement(url, source)
    },
    req(pagenum = 1) {
      this.$axios
        .post(`${pydataBase}/api/nvi/ledgeSearch`, {
          // user_id: this.$store.state.user.userInfo.userId,
          case_id: this.$route.params.caseId,
          offset: pagenum,
          sort_type: 0,
          // sentence: this.sentence || '',
          summary: this.summary || '',
        })
        .then((res) => {
          if (res.data.data.data.length === 0) {
            this.showtype = 1
            this.next = false
            return
          } else {
            this.showtype = 0
            this.next = true
          }
          this.data = this.data.concat(res.data.data.data)
        })
    },
  },
  mounted() {
    // if (this.is_load) {
    //   setTimeout(() => {
    //     this.t = setInterval(() => {
    //       if (this.next) {
    //         this.req(num)
    //         num++
    //       }
    //     }, 3800)
    //   }, 3000)
    // }
    this.$axios
      .all([
        this.$axios.get(`${caseBase}/new-design/caseUserDocument/queryByCaseId/${this.$route.params.caseId}`),
        this.$axios.get(`${caseBase}/lts/case/${this.$route.params.caseId}/caseNodesByCaseId`),
      ])
      .then((res) => {
        if (res[0].data.data.caseUserDocument.length > 0) {
          this.sentence = JSON.parse(res[0].data.data.caseUserDocument[0].documentData)[0].suSongQingQiu_1 || ''
          this.sentence += JSON.parse(res[0].data.data.caseUserDocument[0].documentData)[0].shiShiLiYou_1 || ''
        }
        this.summary = res[1].data.data.caseBasicInfo.causeName
        // console.log(this.sentence, 'sentence------this.summary', this.summary)
        if (!this.tj) {
          this.req()
          let num = 2
          const _self = this
          this.$refs.view.parentNode.parentNode.parentNode.parentNode.addEventListener(
            'scroll',
            function () {
              if (this.scrollTop === this.scrollHeight - this.offsetHeight) {
                _self.req(num)
                num++
              }
            },
            false
          )
        }
      })
  },
  destroyed() {
    clearInterval(this.t)
  },
}
</script>
<style scoped lang="stylus">
.view
  width 100%
  box-sizing border-box
  overflow scroll
  padding 15px
  .item
    border-bottom 1px solid #eaeaea
    box-sizing border-box
    padding-bottom 10px
    margin-bottom 10px
    .title
      font-size 16px
      font-weight bold
      color #000000
      margin-bottom 6px
    .text
      font-size 14px
      color #333333
      font-weight 600
      margin-bottom 6px
    .last
      color #999999
      font-size 14px
      span
        display inline-block
        max-width 40%
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
.loading
  width 100%
  display flex
  justify-content center
  span
    vertical-align middle
    display inline-block
    line-height 30px
    color #c3c3c3
    font-size 14px
</style>
