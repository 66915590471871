var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-range", on: { click: _vm.move } }, [
    _c(
      "div",
      { staticClass: "track" },
      [
        _vm.timeRange.nodeSegmentations
          ? _c(
              "div",
              { staticClass: "segmentations" },
              _vm._l(_vm.sortedSegmentations, function (segment) {
                return _c(
                  "div",
                  {
                    key: segment.id,
                    staticClass: "segment",
                    style: _vm.segmentStyle(segment),
                  },
                  [_vm._v(" " + _vm._s(segment.quantity) + " ")]
                )
              }),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "range", style: { width: _vm.percentage } }),
        _c(
          "div",
          {
            staticClass: "thumb",
            style: { left: _vm.percentage },
            on: {
              touchstart: _vm.onTouchStart,
              touchmove: _vm.onTouchMove,
              touchend: _vm.onTouchEnd,
            },
          },
          [_vm._v(_vm._s(_vm.timeRange.simpleName))]
        ),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }