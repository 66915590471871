var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "judge" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "inputBox border-bottom" },
      [
        _vm._l(_vm.judge, function (item, index) {
          return _c("span", { key: index, on: { click: _vm.showSearch } }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        _c("div", { staticStyle: { height: "40px" } }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _c("span", [_vm._v("法官")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }