<template>
  <div :class="stageClass"
       :style="stageStyle"
       :title="stage.nodeName"
       v-show="isVisible(stage)">
    <div class="stage-name">{{stage.nodeName}}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import visibilityMixin from '~mixins/visibility'
export default {
  mixins: [visibilityMixin],
  name: 'stage-item',
  props: {
    stage: Object
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    stageBorder () {
      if (this.stage.nodeStatus === 'FLOW') {
        return '1px dashed #aaa'
      } else {
        return 'none'
      }
    },
    stageClass () {
      const status = this.stage.nodeStatus
      if (status === 'FLOW') {
        return 'stage-item default'
      } else {
        const styleCode = this.stage.style
        return `stage-item ${styleCode}`
      }
    },
    stageStyle () {
      const { start, end } = this.getTLRangeStamp
      const _start = this.getTimeStamp(this.stage.beginTime)
      const _end = this.getTimeStamp(this.stage.endTime)
      const timeCross = _end >= _start ? _end - _start : 0
      const pr = timeCross / this.getTLRangeCrossNumber
      const width = this.timelineWidth * pr
      const left = (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
      // 处理bar可见 bar的起始时间在range的end之前 结束时间在end之后
      const isSBeforeEnd = end - _start
      const isEAfterEnd = _end - end
      const styleCode = this.stage.style + ''
      const styleSheet = {
        default: '#60c3f6',
        orange: '#F6B536',
        gray: '#666',
        unset: '#eee'
      }
      const translateY = 0
      const bg = styleSheet[styleCode] || '#3a8ee6'
      const background = this.stage.nodeStatus === 'FLOW' ? 'rgba(0, 168, 255, 0.8)' : bg
      let style = {}
      if (isSBeforeEnd >= 0 && isEAfterEnd >= 0) {
        style = {
          width: `${this.timelineWidth - left - 1}px`,
          transform: `translate3d(${left}px,${translateY},0)`,
          border: this.stageBorder
        }
      } else {
        style = {
          width: `${width}px`,
          transform: `translate3d(${left}px,${translateY},0)`,
          border: this.stageBorder
        }
      }
      if (this.stage.nodeStatus === 'FLOW') {
        style.background = background
      }
      return style
    }

  },
  methods: {
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    }
  }
}
</script>

<style lang="stylus">
.stage-item
  position absolute
  background #3a8ee6
  color #fff
  height 100%
  box-sizing border-box
  .stage-name
    text-align left
    font-size 14px
    overflow hidden
    white-space nowrap
</style>
