var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "timeline" } },
    [
      _c("scroll-menu", {
        ref: "scrollmenu",
        style: { width: "100%" },
        attrs: {
          showLeftMenu: false,
          showRightMenu: true,
          initialHeight: "NORMAL",
          resumeTab: true,
          tid: _vm.tid,
        },
        on: { addCoop: _vm.openAddCoopPopup },
      }),
      _c("fb-loading", { attrs: { loading: _vm.isLoading } }),
      _c(
        "van-popup",
        {
          attrs: { position: "top", overlay: false },
          model: {
            value: _vm.showAddCoop,
            callback: function ($$v) {
              _vm.showAddCoop = $$v
            },
            expression: "showAddCoop",
          },
        },
        [
          _c("div", { staticClass: "top-notify-with-action" }, [
            _c("div", { staticClass: "notice" }, [
              _vm._v(" 该案件未添加协作人 "),
              _c(
                "span",
                { staticClass: "tap-text", on: { click: _vm.openAddCoopForm } },
                [_vm._v("点击添加")]
              ),
            ]),
          ]),
        ]
      ),
      _c("ana-summary"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.timelineInitialized,
              expression: "timelineInitialized",
            },
          ],
          style: _vm.backToTodayBtnStyle,
          attrs: { id: "backToTodayBtnDetail" },
          on: { click: _vm.backToToday },
        },
        [_vm._v("今天")]
      ),
      _c("tool-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.timelineInitialized,
            expression: "timelineInitialized",
          },
        ],
      }),
      _c(
        "div",
        {
          attrs: { id: "items-area" },
          on: {
            "&touchstart": function ($event) {
              return _vm.onTouchStart.apply(null, arguments)
            },
            "&touchmove": function ($event) {
              return _vm.onTouchMove.apply(null, arguments)
            },
            "&touchend": function ($event) {
              return _vm.onTouchEnd.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "stages-wrapper" },
            [
              _vm.showTopItemConnector
                ? _c("div", {
                    staticClass: "top-item-connector-top",
                    style: _vm.topItemConnectorTopStyle,
                  })
                : _vm._e(),
              _vm._l(_vm.stageNodeList, function (stage) {
                return _c("stage-item", {
                  key: stage.caseNodeId,
                  ref: "stageItem",
                  refInFor: true,
                  attrs: { stage: stage },
                })
              }),
              _vm._l(_vm.sectionNodeList, function (item) {
                return _c("section-item", {
                  key: item.caseNodeId,
                  ref: "sectionItem",
                  refInFor: true,
                  attrs: { item: item },
                  on: {
                    showChildItem: _vm.handleShowChildItem,
                    showInfoCard: _vm.handleShowInfoCard,
                  },
                })
              }),
              _vm._l(_vm.roundNodeList, function (item) {
                return _c("round-item", {
                  key: item.nodeId,
                  ref: "roundItem",
                  refInFor: true,
                  attrs: { item: item },
                  on: {
                    showChildItem: _vm.handleShowChildItem,
                    showInfoCard: _vm.handleShowInfoCard,
                  },
                })
              }),
              _vm._l(_vm.topTearNodeList, function (tear) {
                return _c("tear-item", {
                  key: tear.caseNodeId,
                  attrs: { item: tear },
                  on: {
                    showChildItem: _vm.handleShowChildItem,
                    showInfoCard: _vm.handleShowInfoCard,
                  },
                })
              }),
              _vm._l(_vm.remindList, function (remind, i) {
                return _c("remind-item", {
                  key: i,
                  attrs: { data: remind },
                  on: { openRemind: _vm.handleOpenRemindForm },
                })
              }),
            ],
            2
          ),
          _c("div", { ref: "itemsArea", staticClass: "scroll-wrapper" }, [
            _c("div", { staticClass: "scroll-content" }, [
              _vm.showTopItemConnector
                ? _c("div", {
                    staticClass: "top-item-connector-bottom",
                    style: _vm.topItemConnectorBottomStyle,
                  })
                : _vm._e(),
              _vm.hasTearItem
                ? _c(
                    "div",
                    { staticClass: "tears-wrapper" },
                    _vm._l(_vm.tearNodeList, function (tear) {
                      return _c("tear-item", {
                        key: tear.nodeId,
                        ref: "tearItem",
                        refInFor: true,
                        attrs: { item: tear },
                        on: {
                          showChildItem: _vm.handleShowChildItem,
                          showInfoCard: _vm.handleShowInfoCard,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "children-wrapper" },
                [
                  _c("linked-item", {
                    ref: "linkedItem",
                    attrs: {
                      itemList: _vm.topLinkedList,
                      parentItem: _vm.topParentItem,
                    },
                    on: { toggleChild: _vm.handleToggleLinkedChild },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flow-items-wrapper" },
                _vm._l(_vm.flowNodeList, function (item) {
                  return _c("flow-item", {
                    key: item.nodeId,
                    attrs: { item: item },
                  })
                }),
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm.$store.state.nodeform.nodeform ? _c("nodeform") : _vm._e(),
      _vm.$store.state.remindForm.remind ? _c("remind-form") : _vm._e(),
      _c(
        "van-popup",
        {
          staticClass: "add-coop-popup",
          model: {
            value: _vm.showAddCoopForm,
            callback: function ($$v) {
              _vm.showAddCoopForm = $$v
            },
            expression: "showAddCoopForm",
          },
        },
        [
          _c("search-cooperation", {
            attrs: { addCooperation: "1", propCaseId: _vm.caseId },
            on: {
              added: _vm.onAddCoopSuccess,
              cancel: function ($event) {
                _vm.showAddCoopForm = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }