<template>
  <div class="nodeform-document-box">
    <div class="documentHeader">
      <span>文书</span>
      <span class="iconfont icon-child-off"></span>
    </div>
    <div class="nodeform-document">
      <div
        class="nodeform-document-cell"
        v-for="(item, index) in array"
        :key="index"
      >
        <span class="nodeform-document-left" @click="showDocument(item)">
          <span>{{ item.documentName }}</span>
          <!-- <span v-if="item.displayComplete"
                class="iconfont icon-download"></span> -->
        </span>
        <span v-if="item.completeTime" class="nodeform-document-right">{{
          getTime(item.completeTime)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import { mapState } from 'vuex'
export default {
  name: 'documentList',
  data() {
    return {
      array: []
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    })
  },
  methods: {
    // 计算时间
    getTime(value) {
      const a = new Date(value)
      return `${a.getFullYear()}.${a.getMonth() + 1}.${a.getDate()}`
    },
    // 文书展示
    showDocument(item) {
      const caseId = this.$route.params.caseId
      const query = {
        caseDocumentId: item.documentId,
        caseId,
        litigantId: item.litigantId,
        groupId: item.groupId
      }
      this.$router.push({ path: `/editor/${caseId}`, query })
    }
  },
  mounted() {
    this.$axios
      .get(
        `${caseBase}/document/basicDocuments/getDocumentsByNodeId?caseId=${
          this.$store.state.nodeform.caseId
        }&nodeId=${this.$store.state.nodeform.nodeId}`
      )
      .then(res => {
        if (res.data.data) {
          this.array = res.data.data
        } else {
          this.array = []
        }
      })
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
.nodeform-document-box
  color $fontColor
.documentHeader
  display flex
  align-items center
  justify-content space-between
.nodeform-document
  margin 15px 0
  .nodeform-document-cell
    padding 2px 0
    display flex
    justify-content space-between
    align-items center
</style>
