var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.value
      ? _c("ul", [
          _c("li", { staticClass: "jiedianlist" }, [
            _c("span", {
              staticClass: "iconfont",
              class:
                _vm.value.progress === "FINISHED"
                  ? "icon-xuanzhong"
                  : "icon-kongbai",
              on: {
                click: function ($event) {
                  return _vm.chan(_vm.value)
                },
              },
            }),
            _c(
              "div",
              { staticClass: "list border-bottom" },
              [
                _c(
                  "van-cell-group",
                  { staticClass: "van-ce-group" },
                  [
                    _c("van-field", {
                      ref: "input",
                      staticClass: "van-field__body",
                      class:
                        _vm.value.progress === "FINISHED" ? "delete-line" : "",
                      attrs: {
                        type: "textarea",
                        rows: "1",
                        autosize: "",
                        "aria-placeholder": "请输入",
                      },
                      on: {
                        keydown: _vm.keydown,
                        blur: function ($event) {
                          return _vm.saveHeadline(_vm.value)
                        },
                      },
                      model: {
                        value: _vm.value.headline,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "headline", $$v)
                        },
                        expression: "value.headline",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    ref: "icon",
                    staticClass: "iconfont icon-more",
                    class:
                      _vm.value.progress === "FINISHED" ? "color1" : "color2",
                    on: { click: _vm.changeBgcolor },
                  },
                  [
                    _c("Edit", {
                      staticClass: "edit",
                      attrs: {
                        showdia: _vm.showdialog,
                        content: _vm.value.content,
                      },
                      on: { showPopup: _vm.showPopup },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item
      ? _c("ul", [
          _c("li", { staticClass: "jiedianlist" }, [
            _c("span", {
              staticClass: "iconfont",
              class: _vm.item.isChecked ? "icon-xuanzhong" : "icon-kongbai",
              on: { click: _vm.change },
            }),
            _c(
              "div",
              { staticClass: "list border-bottom" },
              [
                _c("van-field", {
                  ref: "input",
                  staticClass: "van-field__body",
                  class: _vm.item.isChecked ? "delete-line" : "",
                  attrs: {
                    type: "textarea",
                    rows: "1",
                    autosize: "",
                    "aria-placeholder": "请输入",
                  },
                  on: {
                    keydown: _vm.keydown,
                    blur: function ($event) {
                      $event.stopPropagation()
                      return _vm.save(_vm.item)
                    },
                  },
                  model: {
                    value: _vm.item.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "content", $$v)
                    },
                    expression: "item.content",
                  },
                }),
                _c(
                  "span",
                  {
                    ref: "icon",
                    staticClass: "iconfont icon-more",
                    class: _vm.item.isChecked ? "color1" : "color2",
                    attrs: { id: "icon-more" },
                    on: { click: _vm.changeBgcolor },
                  },
                  [
                    _c("Dialog", {
                      attrs: {
                        content: _vm.item.content,
                        showdia: _vm.showdialog,
                      },
                      on: { showPopup: _vm.showPopup },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }