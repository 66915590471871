<template>
  <div class="chart-cell" :class="{ right: index % 2 }">
    <div
      style="width:100%;height:30px;line-height:30px;text-align:center;font-weight:bold"
    >
      {{ title }}
    </div>
    <ve-bar
      :height="height"
      :series="series"
      :xAxis="xAxis"
      :yAxis="yAxis"
      :data="chartData"
      :legend="legend"
      :settings="chartSetting"
      :tooltip="tooltip"
    />
  </div>
</template>
<script>
import VeBar from 'v-charts/lib/bar.common'
export default {
  name: 'bar',
  components: {
    VeBar
  },
  props: [
    'series',
    'xAxis',
    'yAxis',
    'title',
    'index',
    'chartSetting',
    'chartData'
  ],
  data() {
    return {
      // eslint-disable-next-line
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      height: '0',
      legend: { show: false }
    }
  },
  methods: {
    getPosition() {
      const width = this.$el.clientWidth * 1.1
      this.height = width * 1.1 + 'px'
    }
  },
  mounted() {
    this.getPosition()
  }
}
</script>
<style scoped>
.chart-cell {
  /* height: 160px; */
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-bottom: 15px;
}
.chart-cell .ve-bar {
  margin-top: 10px;
}
</style>
