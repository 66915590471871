var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { ref: "view", staticClass: "view" },
      _vm._l(
        _vm.data.length > 0 ? _vm.data : this.$store.state.search.knowledge,
        function (item, index) {
          return _c(
            "ul",
            {
              key: index,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.JumpToOtherUrl(item.url, item.source)
                },
              },
            },
            [
              _c("li", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(item.title) },
              }),
              _c("p", {
                staticClass: "text",
                domProps: { innerHTML: _vm._s(item.hits) },
              }),
              _c("li", { staticClass: "last" }, [
                _c("span", [_vm._v(_vm._s(item.origin))]),
                _c("span", [_vm._v(" - " + _vm._s(item.publisher))]),
                _c("span", [_vm._v(" - " + _vm._s(item.pub_date))]),
              ]),
            ]
          )
        }
      ),
      0
    ),
    _vm.showtype === 0 && _vm.is_load
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("span", [_vm._v("加载中")]), _c("van-loading")],
          1
        )
      : _vm._e(),
    _vm.showtype === 1
      ? _c("div", { staticClass: "loading" }, [
          _c("span", [_vm._v("没有更多了")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }