<template>
  <div class="record-box">
    <under-dev />
  </div>
</template>

<script>
export default {
  name: 'record-box'
}
</script>
