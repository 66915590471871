<template>
  <div>
    <li>
      <i :class="calIcon" class="iconfont" @click="changeIcon" ref="active"></i>
      <div class="list">
        <div class="list-left">
          <span class="item-name" @touchend="goEditorPage">{{
            item.documentName
          }}</span>
          <span class="count">{{
            item.count === null ? '' : `(${item.count})`
          }}</span>
        </div>
        <div class="list-right">
          <span class="name">{{ item.editor }}</span>
          <!-- <span class="name">12{{item.completeTime}}</span> -->
          <em class="item-newtime">{{ item.type === null ? '' : calTime }}</em>
          <i
            class="iconfont icon-more"
            @click="change"
            :class="bgColor ? 'color2' : 'color1'"
          ></i>
        </div>
      </div>
    </li>
    <!-- 有历史版本 -->
    <HistoryList
      v-for="(val, index) in HistoryLists"
      :key="index"
      v-show="showHistory"
      ref="history"
      @showBottomBar="showBottomBar"
      :historyData="{ val, count: item.count - index - 1 }"
    ></HistoryList>
    <!-- 没有历史版本 -->
    <Message v-show="showMessage"></Message>
  </div>
</template>
<script>
import HistoryList from './historylist'
import Message from './message'
import { caseBase } from '~api-config'
export default {
  name: 'cell',
  props: ['item'],
  components: {
    HistoryList,
    Message
  },
  data() {
    return {
      bgColor: false, // 点击更改图标颜色
      showHistory: false, // 展示历史版本
      showMessage: false, // 没有历史版本 提示语
      HistoryLists: [],
      checked: false, // 点击 更改图标类名
      historyCheck: false,
      myDocumentId: ''
    }
  },
  computed: {
    chekednodeDoc() {
      return this.$refs.history.filter((item, index) => {
        return item.active
      })
    },
    calTime() {
      // const times = this.item.updateTime.split(" ")[0]
      // console.log(times)
      // return times.split("-").join(".")
      let a
      // if (this.item.createTime) {
      if (this.item.completeTime) {
        a = parseInt(this.item.completeTime)
      } else {
        a = 0
      }
      const times = new Date(a)
      const Year = times.getFullYear()
      let Month = times.getMonth() + 1
      let Days = times.getDate()
      Month = Month < 10 ? '0' + Month : Month
      Days = Days < 10 ? '0' + Days : Days
      return Year + '.' + Month + '.' + Days
    },
    calIcon() {
      if (this.checked) {
        return 'icon-xuanzhong'
      } else {
        if (this.item.count === null) {
          return 'icon-kongbai'
        } else {
          return 'icon-daiziji'
        }
      }
    },
    checkHistory() {
      return this.HistoryLists.filter((item, index) => {
        return this.$refs.history[index].active
      }).length
    }
  },
  watch: {
    HistoryLists: {
      deep: true,
      handler(val) {
        this.searcharr = []
        val.forEach(item => {
          if (item.flag) {
            this.searcharr.push(item.id)
          }
        })
        this.$emit('establish', this.searcharr)
      }
    }
  },
  methods: {
    canclee() {
      this.chekednodeDoc.forEach(item => {
        item.active = false
      })
    },

    // 获取历史版本接口
    getHistoryList() {
      const caseId = this.$route.params.caseId
      // const userId = this.$store.state.user.userInfo.id
      // this.$axios.get(`${caseBase}/new-design/catalog/historicalRecords?userId=${userId}&caseId=${caseId}&documentId=${this.item.documentId}&id=${this.item.catalogId}`)
      this.$axios
        .get(
          `${caseBase}/document/basicDocuments/historicalRecords?caseId=${caseId}&documentId=${this
            .item.documentId || ''}&myDocumentId=${this.item.myDocumentId ||
            ''}&limit=${50}&pageNum=${1}`
        )
        .then(res => {
          if (res.data.code === '200') {
            // this.$message.error(res.data.message)
          } else {
            this.$toast(res.data.message)
          }
          if (res.data.data.items.length > 0) {
            res.data.data.items.forEach(item => {
              item.flag = false
            })
            this.showHistory = !this.showHistory
          } else {
            this.showMessage = !this.showMessage
          }
          if (res.status === 200) {
            this.HistoryLists = res.data.data.items.map(item => {
              item.status = 1
              return item
            })
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    },
    change() {
      // 点击切换图标颜色
      this.bgColor = !this.bgColor
      // this.showHistory = !this.showHistory
      // this.getHistoryList()
      // console.log(this.item)
      // 根据count判断显示
      // if (this.item.count === null) {
      //   this.showMessage = !this.showMessage
      // } else {
      //   this.showHistory = !this.showHistory
      //   this.getHistoryList()
      // }
      this.getHistoryList()
    },
    changeIcon() {
      this.checked = !this.checked
      this.myDocumentId = this.item.myDocumentId
        ? this.item.myDocumentId
        : this.item.documentId
      // console.log(this.item, '---changeIcon')
      this.$emit('DeleteandBuilt', this.myDocumentId, this.checked)
    },
    showBottomBar(id, e) {
      if (this.checkHistory) {
        this.historyCheck = true
        this.$emit('showHistoryBottomBar', id, e)
      } else {
        this.historyCheck = false
        this.$emit('closeHistoryBottomBar', id, e)
      }
    },
    // 进入编辑器页面
    goEditorPage() {
      const caseId = this.$route.params.caseId
      const path = `/editor/${caseId}?caseDocumentId=${
        this.item.documentId
      }&myDocumentId=${this.item.myDocumentId || null}&groupId=${this.item
        .groupId || null}&caseId=${caseId}`
      this.$router.push({ path })
    }
  }
}
</script>
<style lang="stylus" scoped>
.document-tab li
  display flex
  align-items center
  .icon-kongbai
    font-size 20px
    margin 0 14px 0 15px
  .icon-daiziji
    font-size 20px
    margin 0 14px 0 15px
  .icon-xuanzhong
    font-size 20px
    margin 0 14px 0 15px
    color #4B9EFB
  .list
    border-bottom 1px solid #F3F4F7
    display flex
    justify-content space-between
    align-items center
    padding-bottom 14px
    margin 14px 19px 0 0
    width 100%
    .list-left
      display flex
      align-items center
      flex-wrap wrap
      flex 1
      font-size 14px
      color #333
      font-family PingFangSC
      .item-name
        margin-right 10px
    .list-right
      display flex
      align-items center
      font-size 13px
      color #999
      .item-newtime
        margin 0 16px 0 11px
      .color1
        color #ccc
      .color2
        color #4899FC
</style>
