<template>
  <div class="addCooperationInForm">
    <div class="add-top" @click="showSearch = true">
      <span>
        <i class="iconfont icon-mine"></i>
        <span>协作人</span>
      </span>
      <i class="iconfont icon-add1"></i>
    </div>
    <div class="nodeform-cooperation">
      <div v-if="noCooperator">暂时没有协作人</div>
      <img
        :src="item.avatarUrl"
        class="nodeform-cooperation-detail"
        v-for="(item, index) in renderArray"
        :key="index"
      />
      <div v-if="moreThan5">....</div>
    </div>
    <search-cooperation
      v-if="showSearch"
      addCooperation="1"
      @cancel="showSearch = false"
      @added="added"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import searchCooperation from '../searchCooperation'
export default {
  name: 'addCooperationInForm',
  components: {
    searchCooperation
  },
  computed: {
    ...mapState('nodeform', ['cooperation']),
    renderArray() {
      if (this.cooperation && this.cooperation.length) {
        // console.log('computed ------------> ', this.cooperation)
        return this.cooperation.filter((item, index) => {
          return index <= 4
        })
      } else {
        return []
      }
    },
    noCooperator() {
      if (this.cooperation === null) {
        return true
      } else {
        if (this.cooperation.length) {
          return false
        } else {
          return true
        }
      }
    },
    moreThan5() {
      if (this.cooperation) {
        if (this.cooperation.length > 5) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  data() {
    return {
      showSearch: false
    }
  },
  methods: {
    ...mapMutations('nodeform', ['setCooperation']),
    added(choosedArray) {
      var addNewCooperation = choosedArray.filter(element => {
        var a = true
        this.$store.state.nodeform.cooperation.forEach(item => {
          // 如果2个id相同 则为false
          if (item.id === element.id) {
            a = false
            // setTimeout(() => {
            //   this.$notify('您已添加过一个或多个用户')
            // }, 200)
          }
        })
        return a
      })
      this.setCooperation(this.cooperation.concat(addNewCooperation))

      this.showSearch = false
    }
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #A3A3A3
.addCooperationInForm
  margin 15px 0
  color $fontColor
  .add-top
    color $fontColor
    display flex
    align-items center
    justify-content space-between
    .iconfont
      margin-right 5px
  .addcolor
    color $color
  .nodeform-cooperation-box
    background-color white
    margin-top 15px
    border-radius 5px
    padding 15px
    font-size 15px
    color #169bd5
  .nodeform-cooperation
    margin-top 9px
    display flex
    align-items center
  .nodeform-cooperation-detail
    width 42px
    height 42px
    border-radius 21px
    margin-right 10px
  .nodeform-cooperation-box > .icon-tianjia
    margin-left 10px
</style>
