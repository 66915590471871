var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      { staticClass: "wrap" },
      [
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isshowjiedian || _vm.isshow,
                expression: "isshowjiedian||isshow",
              },
            ],
            staticClass: "jiedians",
            class:
              _vm.nodelist.nodeStatus === "COMPLETE" ? "border-bottom" : "",
          },
          [
            _c(
              "div",
              {
                class:
                  _vm.nodelist.nodeStatus === "FLOw" ||
                  _vm.nodelist.completeTime === null ||
                  _vm.nodelist.beginDate === null
                    ? "form-left"
                    : "left",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "val-name",
                    on: {
                      click: function ($event) {
                        return _vm.openForm(
                          _vm.nodelist.nodeId,
                          _vm.nodelist.nodeName
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.nodelist.nodeName))]
                ),
                _c(
                  "span",
                  { staticClass: "val-num", on: { click: _vm.showptPopup } },
                  [
                    _vm._v(
                      _vm._s(_vm._f("NumberFormat")(_vm.nodelist.probability))
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "right" }, [
              _c("div"),
              _vm.nodelist.nodeStatus === "COMPLETE"
                ? _c(
                    "div",
                    {
                      staticClass: "val-time",
                      on: {
                        click: function ($event) {
                          return _vm.openForm(
                            _vm.nodelist.nodeId,
                            _vm.nodelist.nodeName
                          )
                        },
                      },
                    },
                    [
                      _vm.nodelist.completeTime !== null
                        ? _c("div", [
                            _vm.nodelist.nodeFormType === "DATETIME_CONTENT" ||
                            _vm.nodelist.nodeFormType === "COURT_SESSION" ||
                            _vm.nodelist.nodeFormType === "FILING"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("DateTime")(
                                        _vm.nodelist.completeTime
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.nodelist.nodeFormType === "DATE_CONTENT" ||
                            _vm.nodelist.nodeFormType === "NO_FORM"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("GetDate")(
                                        _vm.nodelist.completeTime
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.nodelist.nodeFormType ===
                            "INTERVAL_DATE_CONTENT"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("GetDate")(
                                        _vm.nodelist.completeTime
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c("span", {
                ref: "icon",
                staticClass: "iconfont icon-gengduo",
                on: { click: _vm.change },
              }),
            ]),
          ]
        ),
        _vm._l(_vm.newlist, function (value, index) {
          return _c("nodelist", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJiedianList,
                expression: "showJiedianList",
              },
            ],
            key: value.id,
            ref: "nodelists",
            refInFor: true,
            attrs: {
              value: value,
              canshu: value.nodeId,
              NodeForm: _vm.NodeForm,
            },
            on: {
              DeleteOverBuilt: _vm.showDeleteOverBuilt,
              keydownHan: function ($event) {
                return _vm.keydownHan($event, value, index)
              },
            },
          })
        }),
        _vm._l(_vm.oldlist, function (item, index) {
          return _c("nodelist", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJiedianList,
                expression: "showJiedianList",
              },
            ],
            key: item.contentId,
            ref: "nodelist",
            refInFor: true,
            staticClass: "itemnodelist",
            attrs: {
              item: item,
              index: index,
              canshu: _vm.val.nodeId,
              NodeForm: _vm.NodeForm,
              oldlist: _vm.oldlist,
            },
            on: {
              DeleteOverBuilt: _vm.showDeleteOverBuilt,
              keydownHandle: function ($event) {
                return _vm.keydownHandle($event, index)
              },
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }