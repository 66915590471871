<template>
  <div v-if="isSection">
    <div class="remind-section-casedetail"
         :style="remindScetionStyle">
      <div class="left"
           @click="handleClick">{{simpleName}}</div>
      <div class="right"
           @click="handleClick">{{simpleName}}</div>
    </div>
  </div>
  <div v-else
       class="remind-container-casedetail"
       :style="tearContainerStyle"
       v-show="isShow">
    <div ref="remindItem"
         :class="remindItemClass"
         @click="handleClick">{{simpleName}}</div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'remind-item',
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState('caseDetail', {
      today: state => state.today,
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    translateX () {
      const { start } = this.getTLRangeStamp
      const nodePosTime = this.getNodePosTime(this.data)
      const left = (nodePosTime - start) / this.getTLRangeCrossNumber * this.timelineWidth
      return left
    },
    tearContainerStyle () {
      const { start } = this.getTLRangeStamp
      const nodePosTime = this.getNodePosTime(this.data)
      // 元素宽度的一半
      const itemEl = this.$refs.tearItem || this.$refs.remindItem
      let itemWidth = 0
      if (itemEl) {
        itemWidth = window.getComputedStyle(itemEl).width.replace(/px/g, '')
      } else {
        itemWidth = 8
      }
      const left = (nodePosTime - start) / this.getTLRangeCrossNumber * this.timelineWidth - itemWidth / 2
      const style = {
        transform: `translate3d(${left}px,0.3rem,0)`
      }
      return style
    },
    isShow () {
      const { start, end } = this.getTLRangeStamp
      const _start = this.getNodePosTime(this.data)
      const isAfterStart = _start - start
      const isBeforeEnd = end - _start
      if (isAfterStart >= 0 && isBeforeEnd >= 0) {
        return true
      } else {
        return false
      }
    },
    tearClass () {
      const downTime = this.data.downTime
      const isOutdated = this.today - downTime
      if (isOutdated > 0) {
        return 'tear-item gray'
      } else {
        const styleCode = this.data.style
        return `tear-item ${styleCode}`
      }
    },
    remindItemClass () {
      const nodePosTime = this.getNodePosTime(this.data)
      const isOutdated = this.today - nodePosTime
      if (isOutdated > 0) {
        return 'remind-item gray'
      } else {
        // const styleCode = this.data.style
        return 'remind-item default'
      }
    },
    isSection () {
      if (this.data.endDate !== null && this.data.beginDate !== null) {
        return true
      } else {
        return false
      }
    },
    remindScetionStyle () {
      const { beginDate, endDate } = this.data
      const { start } = this.getTLRangeStamp
      const halfElem = 11
      const left = (beginDate - start) / this.getTLRangeCrossNumber * this.timelineWidth - halfElem
      const width = (endDate - beginDate) / this.getTLRangeCrossNumber * this.timelineWidth
      return {
        transform: `translate3d(${left}px,0.3rem,0)`,
        width: `${width}px`
      }
    },
    simpleName () {
      const { remindContent } = this.data
      if (remindContent && remindContent !== '') {
        return this.data.remindContent.substring(0, 1)
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapMutations('caseList', ['setShowHintLineSimple', 'setSimpleHintlineYPos']),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    getNodePosTime (item) {
      return item.remindDate || item.completeDate || item.beginDate
    },
    showHintLine () {
      const pos = this.translateX
      this.setSimpleHintlineYPos(pos)
      this.setShowHintLineSimple(true)
    },
    hideHintLine () {
      this.setSimpleHintlineYPos(-1)
      this.setShowHintLineSimple(false)
    },
    handleClick () {
      this.$emit('openRemind', this.data)
    }
  }
}
</script>

<style lang="stylus">
$default-color = #21A9F0
$orange-color = #F6B536
$gray-color = #666
.remind-container-casedetail
  position absolute
  z-index 2
  cursor pointer
  box-sizing border-box
  .remind-item
    border-radius 50%
    text-align center
    width 22px
    height 22px
    line-height 22px
    font-size 16px
    border 0.026667rem solid #50a6ff
    border-radius 50%
    color #50a6ff
    background rgba(255, 255, 255, 0.9)
    user-select none
    cursor pointer
.remind-section-casedetail
  height 22px
  border-bottom 0.026667rem solid #50a6ff
  position absolute
  box-sizing border-box
  .left, .right
    position absolute
    width 22px
    height 22px
    line-height 22px
    font-size 16px
    border 1px solid #50a6ff
    border-radius 50%
    color #50a6ff
    background rgba(255, 255, 255, 0.9)
    user-select none
    cursor pointer
    text-align center
  .left
    transform translate3d(-50%, 0px, 0)
    border-radius 50%
    left 0
  .right
    transform translate3d(50%, 0px, 0)
    border-radius 50%
    right 0
</style>
