<template>
  <ul>
    <li class="wrap">
      <span class="iconfont"
            :class="calIcon"
            @click="changeIcon"
            id="icon"></span>
      <div class="lists">
        <div class="lists-left">
          <span class="item-name"
                @click="info">
            {{item.documentName}}
            <span class="item-count">{{item.count? `(${item.count})`:''}}</span>
          </span>

        </div>
        <div class="lists-right">
          <span class="item-editor">{{item.editor}}</span>
          <!-- <span class="item-time">{{item.count?calTime:''}}</span> -->
          <em class="item-time">{{item.type === null ? '' : calTime}}</em>
          <span class="iconfont icon-more"
                @click="changeBgcolor"
                :class="Bgcolor?'color2' : 'color1'"></span>
        </div>
      </div>
    </li>
    <!-- 历史版本 -->
    <historylist v-for="(val,index) in sonNodeHistory"
                 :key="index"
                 v-show="showHistory"
                 ref="history"
                 @showBottomBar="checkHistory"
                 :historyData="{val,count:item.count-index-1}">
    </historylist>
    <!-- 没有历史版本 -->
    <message v-show="showMessage"></message>
  </ul>
</template>
<script>
import { caseBase } from '~api-config'
import historylist from './historylist'
import message from './message'
export default {
  name: 'nodeList',
  props: ['item'],
  components: {
    historylist,
    message
  },
  data () {
    return {
      Bgcolor: false,
      active: false,
      check: false,
      showHistory: false,
      showMessage: false,
      sonNodeHistory: []
    }
  },
  watch: {
    sonNodeHistory: {
      deep: true,
      handler (val) {
        this.nodearr = []
        val.forEach(item => {
          if (item.flag) {
            this.nodearr.push(item.id)
          }
        })
        this.$emit('nodefunct', this.nodearr)
      }
    }
  },
  computed: {
    calIcon () {
      if (this.active) {
        return 'icon-xuanzhong'
      } else {
        if (this.item.count === null) {
          return 'icon-kongbai'
        } else {
          return 'icon-daiziji'
        }
      }
    },
    calTime () {
      let a
      if (this.item.completeTime) {
        a = parseInt(this.item.completeTime)
      } else {
        a = 0
      }
      const times = new Date(a)
      const Year = times.getFullYear()
      let Month = times.getMonth() + 1
      let Days = times.getDate()
      Month = Month < 10 ? ('0' + Month) : Month
      Days = Days < 10 ? ('0' + Days) : Days
      return Year + '.' + Month + '.' + Days
    },
    checkHistorylength () {
      return this.sonNodeHistory.filter((item, index) => {
        return this.$refs.history[index].active
      }).length
    }
  },
  methods: {
    // 点击节点文书 ，进入编辑器页面
    info () {
      const caseId = this.$route.params.caseId
      const path = `/editor/${caseId}?caseDocumentId=${this.item.documentId}&myDocumentId=${this.item.myDocumentId || null}&groupId=${this.item.groupId || null}&caseId=${caseId}`
      this.$router.push({ path })
    },
    changeBgcolor () {
      // console.log(this.item)
      this.Bgcolor = !this.Bgcolor
      // this.showHistory = !this.showHistory
      // this.getsonNodeHistory()
      // if (this.item.count) {
      //   this.showHistory = !this.showHistory
      //   this.getsonNodeHistory()
      // } else {
      //   this.showMessage = !this.showMessage
      // }
      this.getsonNodeHistory()
    },
    changeIcon () {
      this.active = !this.active
      this.nodemyDocumentId = this.item.myDocumentId
      this.$emit('sonnode', this.nodemyDocumentId, this.active)
    },
    checkHistory (id, e) {
      if (this.checkHistorylength) {
        this.check = true
        this.$emit('showsonNodeHistory', id, e)
      } else {
        this.check = false
        this.$emit('closesonNodeHistory', id, e)
      }
    },
    getsonNodeHistory () {
      // 获取节点下历史版本接口
      const caseId = this.$route.params.caseId
      // const userId = this.$store.state.user.userInfo.id
      // console.log(this.item)
      this.$axios.get(`${caseBase}/document/basicDocuments/historicalRecords?caseId=${caseId}&documentId=${this.item.documentId || ''}&myDocumentId=${this.item.myDocumentId || ''}&limit=${10}&pageNum=${1}`).then(res => {
        if (res.data.code === '200') {
          // console.log(res)
        } else {
          this.$toast(res.data.message)
        }
        if (res.data.data.items.length === 0) {
          this.showMessage = !this.showMessage
        } else {
          this.showHistory = !this.showHistory
        }
        if (res.status === 200) {
          // console.log(res)
          this.sonNodeHistory = res.data.data.items.map(item => {
            item.status = 1
            return item
          })
        }
      }).catch(err => {
        Promise.reject(err)
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.wrap
  display flex
  align-items center
  #icon
    margin 0 10px 0 15px
    font-size 20px
  .icon-xuanzhong
    color #4B9EFB
  .lists
    margin 14px 19px 0 0
    padding-bottom 14px
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #F3F4F7
    width 100%
    .lists-left
      font-size 14px
      font-family PingFangSC
      color #333
      display flex
      flex 1
      .item-name
        padding-right 10px
    .lists-right
      display flex
      align-items center
      font-size 13px
      color #999
      .item-time
        margin 0 16px 0 11px
      .color1
        color #ccc
      .color2
        color #4899FC
</style>
