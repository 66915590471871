// 当事人列表的身份判别
export const agencytity = type => {
  switch (type) {
    case 'LAWYER':
      return '律师'
    case 'OTHER_NATURAL_PEOPLE':
      return '其他自然人'
    case 'LEGAL_WORKER':
      return '法律工作者'
    case 'LEGAL_AGENT':
      return '法定代理人'
    case 'LEGAL_REPRESENTATIVE':
      return '法定代表人'
    case 'PERSON_IN_CHARGE':
      return '负责人（机构）'
    default:
      return '未匹配到'
  }
}
