<template>
  <div ref="tearwp"
       :class="{ 'round-item-wrapper': true, active: item.showInfoCard , 'show-connector': showChild && item.showInfoCard}"
       :style="itemStyle"
       v-show="isVisible(item)">
    <div :class="roundClass"
         @click="toggleInfoCard(item)"
         ref="item">
      {{item.simpleName}}
    </div>
    <div :class="itemInfoCardClass"
         v-show="item.showInfoCard">
      <!-- 子级 -->
      <div v-if="item.children"
           class="has-child-item-icon"
           @click="toggleChildItem(item)">
        <i :class="icon"></i>
      </div>
      <!-- 节点全名 -->
      <div class="tear-item-fname"
           @click="openForm">{{item.nodeName}}</div>
      <!-- 头像 -->
      <div class="tear-item-avatar"
           v-if="item.avatar">
        <img :src="item.avatar" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import visibilityMixin from '~mixins/visibility'
import getNodePosTimeMixin from '~mixins/getNodePosTime'

export default {
  mixins: [visibilityMixin, getNodePosTimeMixin],
  name: 'round-item',
  props: {
    item: Object
  },
  data () {
    return {
      showBox: false,
      showChild: false
    }
  },

  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    icon () {
      if (this.showChild) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    itemStyle () {
      const { start } = this.getTLRangeStamp
      const _start = this.getTimeStamp(this.item.completeTime)
      const itemEl = this.$refs.item
      let itemWidth = 0
      if (itemEl) {
        itemWidth = window.getComputedStyle(itemEl).width.replace(/px/g, '')
      }
      const left =
        (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth - itemWidth / 2
      const transform = `translate3d(${left}px, 0.25rem, 0)`
      const style = {
        transform
      }
      return style
    },
    roundClass () {
      return 'round-item gray'
    },
    itemInfoCardClass () {
      return 'item-info-card gray'
    }
  },
  methods: {
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    ...mapMutations('caseDetail', [
      'setShowTopItemConnector',
      'setTopItemConnnectorPos',
      'setShowHintLineSimple',
      'setSimpleHintlineYData'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    toggleInfoCard (item) {
      const payload = {}
      payload.item = item
      payload.nodeType = 'ROUND'
      this.setShowTopItemConnector(false)
      // 通知父组件改变显示信息卡片状态
      if (item.showInfoCard) {
        payload.action = 'hide'
        this.$emit('showInfoCard', payload)
      } else {
        payload.action = 'show'
        this.$emit('showInfoCard', payload)
      }
      // 更改子级展开状态
      const showChildpayload = {}
      showChildpayload.action = 'hide'
      showChildpayload.item = item
      showChildpayload.nodeType = 'ROUND'
      // 改变状态
      this.showChild = false
      // 通知父组件
      this.$emit('showChildItem', showChildpayload)
    },
    toggleTearItemBox (item) {
      if (this.showBox) {
        const payload = {}
        payload.action = 'hide'
        payload.item = item
        payload.nodeType = 'ROUND'
        // 改变状态
        this.showChild = false
        // 通知父组件
        this.$emit('showChildItem', payload)
      }
      this.showBox = !this.showBox
    },
    toggleChildItem (item) {
      this.showChild = !this.showChild
      if (this.showChild) {
        this.setShowTopItemConnector(true)
        this.setTopItemConnnectorPos(this.getNodePosTime(item))
      } else {
        this.setShowTopItemConnector(false)
        this.setTopItemConnnectorPos(0)
      }
      const payload = {}
      payload.nodeType = 'ROUND'
      payload.action = this.showChild ? 'show' : 'hide'
      payload.item = item
      this.$emit('showChildItem', payload)
    },
    resetShowChildItem () {
      this.showChild = false
    },
    openForm (e) {
      const caseId = this.$route.params.caseId
      const startDate = this.getNodePosTime(this.item)

      const { nodeId, nodeFormType, nodeName: title } = this.item
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    }
  },
  created () {
    if (this.item.expand && this.item.showInfoCard && this.item.children) {
      this.toggleChildItem(this.item)
    }
  }
}
</script>

<style lang="stylus">
$default-color = #21A9F0
$orange-color = #F6B536
$gray-color = #666
.round-item-wrapper
  position absolute
  background transparent
  height 28px
  display flex
  z-index 2
  &>.round-item
    width 26px
    height 26px
    line-height 26px
    text-align center
    border-radius 50%
    font-size 16px
  &.active
    z-index 3
  .item-info-card
    background rgba(255, 255, 255, 0.9)
    box-shadow 0px 0px 10px rgba(163, 163, 163, 0.7)
    border-radius 2px
    margin 1px 0 0 0
    height 26px
    line-height 26px
    display flex
    width auto
    // color #fff
    font-size 16px
    .has-child-item-icon
      color #666
      padding 0 0 0 6px
      i
        font-size 20px
    .tear-item-fname
      margin 0 6px
      line-height 26px
      white-space nowrap
      word-break keep-all
      overflow visible
    .tear-item-avatar
      margin 0 6px 0 0
      height 26px
      width 26px
      img
        border-radius 50%
        width 100%
        height 100%
</style>
