<template>
  <div id="nodeform"
       @click="closeForm">
    <div class="form"
         v-if="!$store.state.nodeform.showQuestion"
         @click.stop>
      <!-- 头部 -->

      <div class="nodeform-header">
        <!-- 节点名称 -->
        <span>{{$store.state.nodeform.title}}</span>
        <!-- 图标 -->
        <span class="iconfont icon-question-mark"
              @click="$store.state.nodeform.showQuestion = true"></span>
      </div>
      <div class="container">
        <!-- 表单类容 -->

        <component v-for="(item,index) in calcArray"
                   :key="index"
                   :is="item.componentName"
                   :propsData="item.propsData"></component>
      </div>
      <!-- 保存 -->
      <save-button />
    </div>
    <div class="question"
         v-if="$store.state.nodeform.showQuestion"
         @click.stop>
      <question-form v-if="$store.state.nodeform.showQuestion">
        <div class="nodeform-header">
          <van-icon name="arrow-left"
                    @click="$store.state.nodeform.showQuestion = false" />
          <span @click="$store.state.nodeform.showQuestion = false">{{$store.state.nodeform.title}}</span>
        </div>
      </question-form>
    </div>
  </div>
</template>
<script>
// 问号表单
import questionForm from './questionForm'
// 开始时间组件
import startTime from './components/startTime'
// 程序类型
import programType from './components/programType'
// 举证期
import proofPeriod from './components/proofPeriod'
// 承办人
import constractor from './components/constractor'
// 法院
import court from './components/court'
// 多行输入狂
// import textNode from './components/textNode'
// 按钮
import saveButton from './components/saveButton'
// 协作人
import cooperation from './components/cooperation'
// 文书
import documentList from './components/documentList'
// 法官
import judge from './components/judge'
// 公告的组件
import gongGaoZuJian from './components/gongGaoZuJian'
export default {
  name: 'formContainer',
  components: {
    questionForm,
    startTime,
    constractor,
    court,
    // textNode,
    saveButton,
    cooperation,
    documentList,
    programType,
    proofPeriod,
    judge,
    gongGaoZuJian
  },
  computed: {
    calcArray () {
      if (this.$store.state.nodeform.nodeFormType === 'FILING') {
        if (this.$store.state.nodeform.formStatus) {
          return this.oldLian
        } else {
          return this.lian
        }
      } else if (this.$store.state.nodeform.nodeFormType === 'INTERVAL_DATE_CONTENT') {
        return this.interval
      } else if (this.$store.state.nodeform.nodeFormType === 'DATE') {
        return this.date
      } else if (this.$store.state.nodeform.nodeFormType === 'CONTENT') {
        return this.content
      } else if (this.$store.state.nodeform.nodeFormType === 'DATETIME_CONTENT') {
        return this.dateTimeContent
      } else if (this.$store.state.nodeform.nodeFormType === 'DATE_CONTENT') {
        return this.dateContent
      } else if (this.$store.state.nodeform.nodeFormType === 'ANNOUNCEMENT') {
        return this.announcement
      } else if (this.$store.state.nodeform.nodeFormType === 'COURT_SESSION') {
        return this.courtSession
      } else if (this.$store.state.nodeform.nodeFormType === 'NO_FORM') {
        return this.dateTimeContent
      } else {
        return []
      }
    }
  },
  data () {
    return {
      // FILING
      // 新立案
      lian: [
        {
          componentName: 'startTime',
          propsData: {
            timeName: '立案日期'
          }
        },
        {
          componentName: 'programType',
          propsData: {}
        },
        {
          componentName: 'proofPeriod',
          propsData: {}
        },
        {
          componentName: 'court',
          propsData: {}
        },
        {
          componentName: 'judge',
          propsData: {}
        },
        {
          componentName: 'cooperation',
          propsData: {}
        }
      ],
      // 老立案
      oldLian: [
        {
          componentName: 'startTime',
          propsData: {
            timeName: '立案日期'
          }
        },
        {
          componentName: 'constractor',
          propsData: {}
        },
        {
          componentName: 'court',
          propsData: {}
        },
        // {
        //   componentName: 'textNode',
        //   propsData: {}
        // },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      // INTERVAL_DATE_CONTENT
      interval: [
        {
          componentName: 'startTime',
          propsData: {
            showEndTimePicker: true
          }
        },
        // {
        //   componentName: 'textNode',
        //   propsData: {}
        // },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      // DATE
      date: [
        {
          componentName: 'startTime',
          propsData: {}
        },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      // CONTENT
      content: [
        // {
        //   componentName: 'textNode',
        //   propsData: {}
        // },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      // DATETIME_CONTENT
      dateTimeContent: [
        {
          componentName: 'startTime',
          propsData: {}
        },
        // {
        //   componentName: 'textNode',
        //   propsData: {}
        // },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      dateContent: [
        {
          componentName: 'startTime',
          propsData: {}
        },
        // {
        //   componentName: 'textNode',
        //   propsData: {}
        // },
        {
          componentName: 'cooperation',
          propsData: {}
        },
        {
          componentName: 'documentList',
          propsData: {}
        }
      ],
      // ANNOUNCEMENT 公告
      announcement: [
        {
          componentName: 'startTime',
          propsData: {
            timeName: '公告日'
          }
        },
        {
          componentName: 'gongGaoZuJian',
          propsData: {}
        },
        {
          componentName: 'cooperation',
          propsData: {}
        }
      ],
      // COURT_SESSION
      courtSession: [
        {
          componentName: 'startTime',
          propsData: {
            timeName: '开庭日期'
          }
        },
        {
          componentName: 'judge',
          propsData: {}
        },
        {
          componentName: 'cooperation',
          propsData: {}
        }
      ]
    }
  },
  methods: {
    closeForm () {
      this.$store.commit('nodeform/nodeformReset')
    }
  }
}
</script>
<style scoped lang="stylus">
$fontSize = 15px
$color = #333333
#nodeform
  position fixed
  background-color rgba(0, 0, 0, 0.47)
  z-index 110
  top 0
  bottom 0
  left 0
  right 0
  padding 15px
  display flex
  align-items center
  justify-content center
  font-size $fontSize
  .form
    background-color white
    max-height 90%
    min-height 70%
    width 100%
    padding 15px 0
    box-sizing border-box
    display flex
    flex-direction column
    border-radius 10px
    .container
      flex-grow 1
      overflow-y auto
      padding 0 25px
    .nodeform-header
      margin 7.5px 0
      padding-left 25px
      span
        margin-right 10px
        color $color
  .question
    width 100%
    .nodeform-header
      display flex
      align-items center
      color $color
      margin-bottom 15px
</style>
