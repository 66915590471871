<template>
  <div class="evidence-tab">
    <van-loading v-show="isloading" style="margin: 0 auto" />
    <div class="evidence-all" v-show="!isloading">
      <div class="evidence-tab-top">
        <span class="iconfont icon-back" @click="fileBack($event)" :style="positionArray.length > 0 ? '' : 'color:white'"> </span>
        <span class="evidence-tab-src">
          <span v-show="positionArray.length > 0 ? true : false" @click="fileBack($event)">... /</span>
          <span v-for="(item, index) in srcArray" :key="index"> {{ item }} /</span>
        </span>
        <span class="iconfont evidenceIcon" @click="takePhoto()">&#xe60d;</span>
        <span class="iconfont evidenceIcon" @click="addfile()">&#xe71d;</span>
      </div>
      <div class="evidence-content">
        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" :immediate-check="false" :offset="0" @load="onLoad(params)">
          <div class="evidence-example border-bottom" v-for="(item, index) in array" :key="index">
            <span class="iconfont evidence-example-icon" :class="item.type != null ? 'icon-kongbai' : 'icon-daiziji'" data-clicked="false" @click="select(index, item)"></span>
            <span @click="choosedArray.length === 0 ? fileInit(page, item, $event) : noUse" class="evidence-example-file">{{ item.fileName }}</span>
            <span class="iconfont icon-more" @click="choosedArray.length === 0 ? showEdit(index) : noUse"></span>
            <p class="showEdit hidden" @click="evidenceExDel(item, index)">删除</p>
          </div>
        </van-list>
        <div class="empty hidden">
          <img src="./static/imgs/moempty.png" alt="" />
        </div>
        <van-popup v-model="isShowBottom" position="bottom" :overlay="false" class="deleteandbuilt" :get-container="container">
          <div class="evidence-bottom border">
            <div class="evidence-bottom-ex" @click="movehandle">
              <i class="iconfont icon-move-folder"></i>
              <p>移动</p>
            </div>
            <div class="evidence-bottom-ex" @click="chooseImg">
              <i class="iconfont icon-pdf"></i>
              <p>生成PDF</p>
            </div>
            <div class="evidence-bottom-ex">
              <i class="iconfont icon-delete" @click="deleteHandle"></i>
              <p>删除</p>
            </div>
          </div>
        </van-popup>
      </div>
      <!--  添加文件夹弹出框 -->
      <van-dialog v-model="dialoged" title="请输入文件夹名称" :show-cancel-button="true" @confirm="confirmAddFile">
        <div class="dialoged-input">
          <van-field placeholder="请输入文件夹名称" v-model="addfileName" />
        </div>
      </van-dialog>
      <!--  生成pdf弹出框 -->
      <!--  -->
      <van-dialog v-model="generate" title="请输入生成pdf的名称" :show-cancel-button="true" @confirm="generatePdf" @cancel="cancelGeneratePdf">
        <div class="dialoged-input">
          <van-field placeholder="请输入生成pdf的名称" v-model="generatePdfName" />
        </div>
      </van-dialog>
      <!-- 提示信息弹出框 -->
      <van-dialog v-model="showMessage" :message="message" />
      <!-- 图片弹出框 -->
      <van-popup v-model="showPdf" @click-overlay="closePdf">
        <iframe v-if="pdfSrc" :src="pdfSrc" frameborder="0" class="iframeClass"></iframe>
      </van-popup>
      <!-- 移动文件夹弹出框 -->
      <van-popup v-model="showMove" class="mov-pop">
        <move-evidence v-if="showMove" :choosedArray="choosedArray" :choosedType="positionArray.length" :kangqianye="kangqianye" @goback="goback" @moved="moved" />
      </van-popup>
      <!-- 编辑栏 -->
      <div class="meng hidden"></div>
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import { mapState } from 'vuex'
import moveEvidence from './moveEvidence'
export default {
  name: 'evidence-tab',
  components: {
    moveEvidence,
  },
  data() {
    return {
      container: () => document.body,
      isloading: true,
      params: {
        caseId: null,
        creater: null,
        pId: null,
        page: null,
        size: 10,
      },
      isShowBottom: false,
      pagebiaoji: [],
      finishedText: '没有更多了',
      list: [],
      refreshing: false,
      loading: false,
      finished: false,
      x: 0,
      biaoji: 0,
      isShowEdit: false,
      lengthArray: [43],
      scrollwidth: 0,
      srcArray: [],
      array: [],
      arrayNew: [],
      page: 1,
      evidence: {}, // 证据页面数据
      showImg: false, // 预览图片的开关
      imgSrc: '', // 预览图片的路径  http://ovucajwy3.bkt.clouddn.com/FsN-WrNZMo6leQ5mM6FIKq9EfNFw
      showPdf: false, // pdf预览
      pdfSrc: '', // pdf预览路径
      dialoged: false, // 弹框开关，添加文件夹的弹框
      addfileName: '', // 添加文件夹的文件夹名称
      showMessage: false, // 弹框开关，提示信息弹框
      message: '', // 提示信息类容
      showMove: false, // 开始移动
      editored: false, // 编辑状态的开关
      choosedArray: [], // 选中文件
      positionArray: [], // 位置文件
      choosedImgs: [], // 选中图片
      dirArray: [], // 选中文件夹
      generate: false, // 生成pdf开关
      generatePdfName: '', // 生成pdf的文件名
      bottomLoading: false,
      show: false,
    }
  },
  computed: {
    isshow() {
      const currrentTabName = this.$store.state.caseDetail.currrentTabName
      const bottomTabMode = this.$store.state.caseDetail.bottomTabMode
      if (bottomTabMode !== 'MIN' && currrentTabName === 'EvidenceTab' && this.choosedArray.length > 0) {
        return true
      } else {
        return false
      }
    },
    // 当前页pId
    kangqianye() {
      if (this.positionArray.length === 0) {
        // 证据页
        // console.log(this.evidence.id)
        return this.evidence.id
      } else {
        // 在证据页当前pid为0，不在证据页就返回当前页pid
        return this.positionArray[this.positionArray.length - 1].id
      }
    },
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    ...mapState('caseDetail', {
      evidenceId: (state) => state.evidenceId,
    }),
    caseId() {
      return this.$route.params.caseId
    },
  },
  created() {
    window.addEvidenceResult = this.addEvidenceResultCallback
    window.refreshEvidence = this.refreshEvidence
    const currrentTabName = this.$store.state.caseDetail.currrentTabName
    console.log(currrentTabName)
  },
  methods: {
    onLoad(params) {
      this.bottomLoading = true
      if (this.page === 0) {
        this.page = 1
      }
      this.page += 1
      params.page = this.page
      this.$axios
        .get(`${caseBase}/design/fileManage/list`, { params })
        .then((res) => {
          res.data.data.file.forEach((item) => {
            this.array.push(item)
          })
          if (res.data.data.file.length < 10) {
            this.finished = true
          }
          this.loading = false
          this.bottomLoading = false
        })
        .catch(() => {
          this.bottomLoading = false
        })
    },
    showEdit(index, event) {
      var length = this.array.length
      event = event || window.event
      event.stopPropagation()
      for (var i = 0; i < length; i++) {
        document.getElementsByClassName('showEdit')[i].classList.add('hidden')
      }
      document.getElementsByClassName('showEdit')[index].classList.remove('hidden')
      document.getElementsByClassName('meng')[0].classList.remove('hidden')
    },
    // // 刷新
    refreshEvidence() {
      this.params.pId = this.kangqianye
      this.params.page = 1
      var params = this.params
      this.$axios.get(`${caseBase}/design/fileManage/list`, { params }).then((res) => {
        this.array = res.data.data.file
      })
    },
    select(index, item) {
      var type = item.type
      var dom = document.getElementsByClassName('evidence-example-icon')[index]
      var selected = dom.getAttribute('data-clicked')
      // console.log(selected)
      if (selected === 'false') {
        var class1 = dom.getAttribute('class')
        if (class1.indexOf('icon-kongbai') !== -1) {
          dom.classList.remove('icon-kongbai')
          dom.classList.add('icon-xuanzhong')
          dom.setAttribute('data-clicked', 'true')
        } else {
          dom.classList.remove('icon-daiziji')
          dom.classList.add('icon-xuanzhong')
          dom.setAttribute('data-clicked', 'true')
        }
        this.choosedArray.push(item)
      } else {
        dom.classList.remove('icon-xuanzhong')
        dom.setAttribute('data-clicked', 'false')
        if (type != null) {
          dom.classList.add('icon-kongbai')
        } else {
          dom.classList.add('icon-daiziji')
        }
        for (var i = 0; i < this.choosedArray.length; i++) {
          // console.log(this.choosedArray[i].id, item.id)
          if (this.choosedArray[i].id === item.id) {
            this.choosedArray.splice(i, 1)
          }
        }
      }
    },
    // // 接收 使用原生相机拍照回调信息
    addEvidenceResultCallback(data) {
      /*
       * code 0-失败 1-成功
       * message 消息
       */
      // console.log('拍照结束返回值', data)
      // ios返回目前是json格式的字符串等待解决中
      if (typeof data === 'string') {
        data = JSON.parse(data)
        // console.log(data)
      }
      if (data.code) {
        this.array.push(...data.data.files)
      } else {
        // console.log('拍照失败')
      }
    },
    // 拍照
    takePhoto() {
      // 将当前路径传给安卓端，等安卓拍照上传完成之后再跳转回来
      const caseId = parseInt(this.$route.params.caseId)
      const pId = parseInt(this.kangqianye)
      // console.log('给案件 %s 添加证据', caseId, 'pid', pId)
      try {
        // window.webkit.messageHandlers.showCamera.postMessage({})
        // AND2JS.showCamera(caseId, pId)
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showCamera(caseId, pId)
        } else if (u === 'fb-flutter-Webview') {
          window.showCamera.postMessage(
            JSON.stringify({
              caseId: caseId,
              pId: pId,
            })
          )
        } else if (u === 'fb-iOS-Webview') {
          // eslint-disable-next-line
          window.webkit.messageHandlers.showCamera.postMessage({ caseId, pId })
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 进入下一级和点击文件的处理(进入下一级完成)
    fileInit(page, item, event) {
      if (this.bottomLoading) {
        return
      }
      document.getElementsByClassName('evidence-content')[0].scrollTop = 0
      this.pagebiaoji.push(page - 1)
      // console.log(this.pagebiaoji)
      this.page = 1
      this.params.page = 1
      this.finished = false
      event.preventDefault()
      console.log(item, '====>>> 接口返参07')
      // console.log(item.fileName)
      if (item.isDir) {
        // 是文件夹
        // console.log(item.id)
        // 发请求
        var thisNew = this
        setTimeout(() => {
          for (var i = 0; i < thisNew.srcArray.length; i++) {
            var lengthEach = document.getElementsByClassName('evidence-tab-src')[0].getElementsByTagName('span')[i + 1].offsetWidth
            if (lengthEach !== 0) {
              thisNew.lengthArray.push(lengthEach)
            }
          }
          var newArr
          function unique(arr) {
            newArr = [arr[0]]
            for (var i = 1; i < arr.length; i++) {
              if (newArr.indexOf(arr[i]) === -1) {
                newArr.push(arr[i])
              }
            }
            return newArr
          }
          var lengthAll = 0
          thisNew.lengthArray = unique(thisNew.lengthArray)
          // console.log(thisNew.lengthArray)
          thisNew.lengthArray.forEach((item) => {
            if (item === undefined) {
              lengthAll += 0
            } else {
              lengthAll += item
            }
          })
          var lengthWide = document.getElementsByClassName('evidence-tab-src')[0].clientWidth
          if (this.array.length >= 1) {
            if (lengthWide - lengthAll < 150) {
              lengthAll -= this.lengthArray[this.srcArray.length - this.x - 1]
              // console.log(lengthAll, lengthWide)
              document.getElementsByClassName('evidence-tab-src')[0].getElementsByTagName('span')[this.x + 1].classList.add('hidden')
              this.x++
            }
          } else {
            lengthAll -= this.lengthArray[this.srcArray.length - this.x - 1]
            document.getElementsByClassName('evidence-tab-src')[0].getElementsByTagName('span')[this.x + 1].classList.add('hidden')
          }
        }, 1)
        this.params.pId = item.id
        var params = this.params
        this.$axios
          .get(`${caseBase}/design/fileManage/list`, { params })
          .then((res) => {
            this.array = res.data.data.file
            // console.log(this.array)
            this.positionArray.push(item)
            this.srcArray.push(item.fileName)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        // 文件状态的处理（为完成）
        // console.log('文件类型')
        if (item.type !== null) {
          // console.log('是图片')
          // 这里图片路径要用哈希值
          const imgArray = this.array.filter((element) => {
            if (!element.isDir && item.type !== null) {
              return true
            } else {
              return false
            }
          })
          let selectedIndex = null
          imgArray.forEach((element, index) => {
            if (element.id === item.id) {
              selectedIndex = index
            }
          })
          const mapedArray = imgArray.map((element) => {
            const listItem = {}
            listItem.previewFileUrl = element.filePath
            listItem.previewFileName = element.fileName
            listItem.previewFileId = element.id
            return listItem
          })
          const string = JSON.stringify({ data: mapedArray, index: selectedIndex })
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // eslint-disable-next-line no-undef
              AND2JS.showPreviewPhotos(string)
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-linetodo0927
              try {
                window.showPreviewPhotos.postMessage(string)
              } catch (error) {
                console.log(error, 'showPreviewPhotos.postMessage')
              }
            } else if (u === 'fb-iOS-Webview') {
              window.webkit.messageHandlers.showEvidencePhotoPreview.postMessage({
                params: string,
              })
            } else {
              console.log('环境不对')
            }
          } catch (err) {
            console.log('环境不对', err)
          }
        } else {
          // console.log('不是图片（word，pdf，excel...）')
          // AND2JS
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.showFilePreview(item.filePath, item.fileName)
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-linetodo0927
              try {
                // 找不到位置
                // 之前的方法
                // window.fbDownloadFile.postMessage(item.filePath, item.fileName)
                // 新改之后的
                window.showFilePreview.postMessage(
                  JSON.stringify({
                    filePath: item.filePath,
                    fileName: item.fileName,
                  })
                )
              } catch (error) {
                console.log(error, 'fbDownloadFile.postMessage')
              }
            } else if (u === 'fb-iOS-Webview') {
              // eslint-disable-next-line
              window.webkit.messageHandlers.showFilePreview.postMessage({
                filePath: item.filePath,
                fileName: item.fileName,
              })
            } else {
              console.log('环境不对')
            }
          } catch (err) {
            console.log('环境不对', err)
          }
        }
      }
    },
    noUse() {
      return true
    },
    // // 回到上层(完成)
    fileBack(event) {
      var srcLength = this.srcArray.length
      this.page = 1
      var minLength = this.lengthArray.length - srcLength
      // console.log(this.page)
      if (this.lengthArray.length > 1) {
        this.lengthArray.splice(this.lengthArray.length - 1, minLength)
        if (this.x > 0) {
          document.getElementsByClassName('evidence-tab-src')[0].getElementsByTagName('span')[this.x].classList.remove('hidden')
          this.x--
        }
      }
      // console.log(this.positionArray.length >= 1)
      if (this.positionArray.length >= 1) {
        // 有上层pid
        // console.log('回到上层')
        this.params.pId = this.positionArray[this.positionArray.length - 1].pId
        this.params.page = 1
        this.finished = false
        var params = this.params
        this.$axios.get(`${caseBase}/design/fileManage/list`, { params }).then((res) => {
          this.array = res.data.data.file
          this.positionArray.pop()
          this.pagebiaoji.splice(this.pagebiaoji.length - 1, 1)
          // console.log(this.pagebiaoji)
          this.srcArray.splice(srcLength - 1, 1)
        })
      } else {
        // 没有上层pid请求证据页页面
        // console.log('回到证据页')
        // this.params.pId = this.evidence.id
        // var params2 = this.params
        // this.$axios.get(`${caseBase}/design/fileManage/list`, { params2 })
        //   .then((res) => {
        //     // console.log(res)
        //     this.array = res.data.data.file
        //     this.positionArray.pop()
        //   })
        return
      }
      this.editored = false
      this.choosedArray = []
    },

    // 点击添加文件夹(完成)
    addfile() {
      this.addfileName = ''
      this.dialoged = true
      // console.log(this.dialoged)
    },
    // 添加文件夹弹框(完成)
    confirmAddFile() {
      if (this.addfileName.trim() === '') {
        // console.log('不能为空')
        this.message = '文件夹名称不能为空'
        this.showMessage = true
        return
      }
      if (this.positionArray.length === 0) {
        // 证据根目录添加文件
        this.$axios
          .post(`${caseBase}/design/fileManage/add`, {
            isDir: 1,
            caseId: this.caseId,
            fileName: this.addfileName,
            creater: this.userId,
            pId: this.evidence.id,
          })
          .then((res) => {
            this.array.push(res.data.data.file)
            this.addfileName = ''
          })
      } else {
        // 不在根目录中是有pId的，所以用pId
        this.$axios
          .post(`${caseBase}/design/fileManage/add`, {
            pId: this.positionArray[this.positionArray.length - 1].id,
            isDir: 1,
            fileName: this.addfileName,
            creater: this.userId,
            caseId: this.caseId,
          })
          .then((res) => {
            // console.log(res)
            this.array.push(res.data.data.file)
            this.addfileName = ''
          })
      }
    },
    // 删除(完成)
    deleteHandle() {
      // const loading = this.$toast.loading({
      //   message: '正在删除...'
      // })
      // loading.clear()
      // console.log(this.choosedArray)
      // console.log(this.array)
      this.choosedArray.forEach((element, index) => {
        // 发请求删除文件
        this.$axios.delete(`${caseBase}/design/fileManage/delete/` + element.id).then((res) => {
          // console.log(111)
          this.array.forEach((item, index) => {
            if (item === element) {
              // console.log(index)
              this.array.splice(index, 1)
            }
          })
        })
      })
      this.choosedArray = []
      for (var i = 0; i < this.array.length; i++) {
        document.getElementsByClassName('evidence-example-icon')[i].classList.remove('icon-xuanzhong')
        document.getElementsByClassName('evidence-example-icon')[i].setAttribute('data-clicked', 'false')
        if (this.array[i].type != null) {
          document.getElementsByClassName('evidence-example-icon')[i].classList.add('icon-kongbai')
        } else {
          document.getElementsByClassName('evidence-example-icon')[i].classList.add('icon-daiziji')
        }
      }
    },
    evidenceExDel(item, index) {
      // const loading = this.$toast.loading({
      //   message: '正在删除...'
      // })
      this.$axios.delete(`${caseBase}/design/fileManage/delete/` + item.id).then((res) => {
        this.array.splice(index, 1)
        // loading.clear()
        document.getElementsByClassName('showEdit')[index].classList.add('hidden')
        document.getElementsByClassName('meng')[0].classList.add('hidden')
      })
    },
    // 移动文件夹
    movehandle() {
      if (!this.choosedArray.length) {
        // console.log('没有选中文件夹移动')
        this.message = '至少要选中一个文件或文件夹'
        this.showMessage = true
        return
      }
      // console.log('move', this.choosedArray)
      this.showMove = true
    },
    // 从移动状态返回
    goback() {
      this.showMove = false
    },
    // 移动结束
    moved(pId, loading) {
      var choosedArray = this.choosedArray.sort((a, b) => {
        return a.index < b.index
      })
      choosedArray.forEach((item) => {
        this.array.splice(item.index, 1)
      })
      loading.clear()
      this.goback()
      this.choosedArray = []
    },
    // 关闭渲染pdf蒙层 重置src
    closePdf() {
      this.pdfSrc = ''
      this.editored = false
    },
    // 生成pdf=>选择要生成pdf的img图片
    chooseImg() {
      // console.log('chooseImg', this.choosedArray)
      if (!this.choosedArray.length) {
        // console.log('没有选中文件生成pdf')
        this.message = '至少要选中一个图片或者包含图片的文件夹'
        this.showMessage = true
        return
      }
      // const loading = this.$toast.loading({
      //   message: '准备生成pdf...'
      // })
      // 初始化存放图片数组和选中文件数组
      this.choosedImgs = []
      this.dirArray = []
      // 初始选中的的类容数组
      var beginArray = this.choosedArray.map((element) => {
        // console.log(element)
        return element
      })
      // console.log(beginArray)
      beginArray.forEach((element) => {
        // console.log(element)
        if (element.isDir) {
          this.dirArray.push(element)
        } else {
          if (this.fileType(element.fileName) === 'icon-picture') {
            this.choosedImgs.push(element)
            // console.log('push-begin')
          }
        }
      })
      // console.log('dirs', this.dirArray)
      // 文件夹向下查询
      if (this.dirArray[0]) {
        // 有下级
        this.findImgInDir(this.dirArray[0], 0)
      } else {
        // 没有下级 结束
        // console.log('查询图片结束')
        // loading.clear()
        if (!this.choosedImgs.length) {
          this.message = '至少要选中一个图片或者包含图片的文件夹'
          this.showMessage = true
          return
        }
        this.generate = true
        // 查询完所有图片
      }
    },
    findImgInDir(dir, index, loading) {
      this.$axios
        .get(`${caseBase}/design/fileManage/list`, {
          params: { pId: dir.id, creater: this.userId },
        })
        .then((res) => {
          res.data.data.file.forEach((element) => {
            if (element.isDir) {
              this.dirArray.push(element)
            } else {
              if (this.fileType(element.fileName) === 'icon-picture') {
                this.choosedImgs.push(element)
              }
            }
          })
          // 如果文件没查完向下继续查
          if (this.dirArray[index + 1]) {
            this.findImgInDir(this.dirArray[index + 1], index + 1, loading)
          } else {
            // console.log('查询图片结束')
            if (!this.choosedImgs.length) {
              this.message = '至少要选中一个图片或者包含图片的文件夹'
              this.showMessage = true
              return
            }
            loading.clear()
            this.generate = true
            // 查询完所有图片
          }
        })
    },
    // 生成pdf
    generatePdf(name) {
      // console.log('准备生成pdf', this.choosedImgs)
      // const loading = this.$toast.loading({
      //   message: '正在生成pdf...'
      // })
      const hashs = this.choosedImgs.map((item) => {
        const houzuiArray = item.fileName.split('.')
        return item.filePath + '.' + houzuiArray[houzuiArray.length - 1]
      })
      const paramsHashs = hashs.toString()
      if (this.generatePdfName.trim() === '') {
        // console.log('不能为空')
        this.message = 'pdf名称不能为空'
        this.showMessage = true
        return
      }
      let pdfName
      if (/\.pdf$/.test(this.generatePdfName)) {
        pdfName = this.generatePdfName
      } else {
        pdfName = this.generatePdfName + '.pdf'
      }
      this.$axios.post(`${caseBase}/design/catalog/convert2Pdf?userId=${this.userId}&caseId=${this.caseId}&fileName=${pdfName}&hashs=${paramsHashs}`).then((res) => {
        // console.log('生成pdf的结果', res)
        // loading.clear()
        this.message = 'pdf文件已生成在我的文档中'
        this.showMessage = true
        this.choosedArray = []
      })
    },
    // 取消pdf
    cancelGeneratePdf() {
      // console.log('取消pdf')
    },
  },
  mounted() {
    // // betterscroll设置
    // this.scroll = new BScroll(this.$refs.scrollArea, { click: true })
    // 挂载信息
    this.evidence.id = this.evidenceId
    if (!this.evidence.id) {
      // this.$toast.loading({
      //   message: '加载失败...'
      // })
      return
    }
    // const loading = this.$toast.loading({
    //   message: '加载中...'
    // })
    this.params = {
      caseId: this.caseId,
      creater: this.userId,
      pId: this.evidence.id,
      page: 1,
      size: 10,
    }
    var params = this.params
    this.$axios
      .get(`${caseBase}/design/fileManage/list`, { params })
      .then((res) => {
        // console.log('证据查询结果', res.data)
        this.array = res.data.data.file
        this.isloading = false
        // loading.clear()
      })
      .then(() => {
        var length = this.array.length
        for (var i = 0; i < length; i++) {
          document.getElementsByClassName('showEdit')[i].classList.add('hidden')
        }
      })
  },
  watch: {
    array() {
      var length = this.array.length
      var clickFunc = function () {
        document.getElementsByClassName('meng')[0].classList.add('hidden')
        for (var i = 0; i < length; i++) {
          document.getElementsByClassName('showEdit')[i].classList.add('hidden')
        }
        event.stopPropagation()
      }
      if (length === 0) {
        // console.log(11111)
        document.getElementsByClassName('meng')[0].removeEventListener('click', clickFunc, false)
        document.getElementsByClassName('meng')[0].classList.add('hidden')
        document.getElementsByClassName('empty')[0].classList.remove('hidden')
      } else {
        document.getElementsByClassName('meng')[0].addEventListener('click', clickFunc, false)
        document.getElementsByClassName('empty')[0].classList.add('hidden')
      }
    },
    choosedArray() {
      // console.log(this.choosedArray)
      if (this.choosedArray.length > 0) {
        this.show = true
      } else {
        this.show = false
      }
    },
    srcArray() {},
    positionArray() {
      if (this.positionArray.length === 0) {
        this.lengthArray = [43]
      }
    },
    isshow(value) {
      this.isShowBottom = value
    },
  },
  destroyed() {
    console.log('destory')
    this.choosedArray = []
  },
}
</script>

<style scoped>
.evidence-tab {
  width: 100%;
  height: 100%;
  line-height: 20px;
  padding: 15px 13px;
}
.evidence-all {
  width: 100%;
  height: 100%;
}
.evidence-tab-top {
  line-height: 20px;
}
.evidence-tab-src {
  display: inline-block;
  flex-wrap: nowrap;
  height: 20px;
  width: 60%;
  overflow: hidden;
  font-size: 15px;
  color: #666666;
  margin-left: 20px;
  position: relative;
  top: 3px;
}
.evidenceIcon {
  font-size: 20px;
  color: #4b9efb;
  margin-left: 25px;
  float: right;
}
.evidence-content {
  width: auto;
  height: calc(100% - 80px);
  margin-top: 10px;
  overflow-y: scroll;
}
.evidence-example {
  width: 100%;
  height: 44px;
  border-color: rgba(243, 244, 247, 1);
  margin: 0 auto;
  font-size: 15px;
  line-height: 44px;
  position: relative;
}
.evidence-example-icon {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 2px;
  margin-right: 15px;
}
.icon-more {
  float: right;
  color: #cccccc;
}
.evidence.evidencebottom {
  margin-bottom: 65px;
}
.showEdit {
  width: 150px;
  height: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 3px rgba(240, 240, 240, 1);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  left: calc(100% - 153px);
  top: 27px;
  z-index: 250;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.empty {
  width: 100%;
  height: auto;
  text-align: center;
}
.disflex {
  display: flex;
}
.empty > img {
  width: 240px;
  height: auto;
  margin: 0px auto;
}
.icon-xuanzhong {
  color: #4b9efb;
}
.evidence-bottom {
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  left: 0;
  border-color: rgba(238, 238, 238, 1);
  padding-top: 10px;
}
.meng {
  width: 100%;
  height: calc(100% - 45px);
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 200;
}
.mov-pop {
  /* box-shadow: 0px 0px 1px 0px rgba(240, 240, 240, 1); */
  border-radius: 13px;
}
.evidence-bottom-ex {
  width: 20%;
  height: auto;
  text-align: center;
  z-index: 99;
}
.evidence-bottom-ex > i::before {
  font-size: 30px;
  color: black;
  text-align: center;
}
.evidence-bottom-ex:nth-of-type(1) > i::before {
  font-weight: 600;
}
.evidence-bottom-ex > p {
  font-size: 9px;
  font-family: PingFangSC-Light;
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
}
/*不是文件夹的情况*/
.fileicon {
  vertical-align: middle;
  margin-right: 11px;
}
.dialoged-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.evidencetool {
  width: 100%;
  background-color: white;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 9px;
  color: #999999;
}
.evidencetool > span {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evidencetool > span > span.iconfont {
  font-size: 25px;
  color: #169bd5;
}
.iframeClass {
  width: 85%;
  height: 550px;
}
</style>
