var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "infoMain" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.zairu,
            expression: "!zairu",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "info-top" },
          [
            _c(
              "div",
              {
                staticClass: "case-info border-bottom",
                on: { click: _vm.anjianinfo },
              },
              [
                _c("div", { staticClass: "info-top-content" }, [
                  _c("span", { staticClass: "info-top-content-left" }, [
                    _vm._v(_vm._s(_vm.essentialInf.caseReasonName)),
                  ]),
                  _c("span", { staticClass: "right_content" }, [
                    _vm._v(_vm._s(_vm.acceptDate) + "受理"),
                  ]),
                ]),
                _c("i", {
                  staticClass: "iconfont icon-gengduo",
                  on: { click: _vm.anjianinfo },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "court-info border-bottom",
                on: { click: _vm.fayuan },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.officesInf.displayDefaultCourt === "NO"
                            ? true
                            : false,
                        expression:
                          "officesInf.displayDefaultCourt === 'NO' ? true : false",
                      },
                    ],
                    staticClass: "info-top-content",
                  },
                  [
                    _c("span", { staticClass: "info-top-content-left" }, [
                      _vm._v(_vm._s(_vm.officesInf.courtName)),
                    ]),
                    _c("span", { staticClass: "right_content" }, [
                      _vm._v(_vm._s(_vm.officesInf.courtTelephone)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.officesInf.displayDefaultCourt === "NO"
                            ? false
                            : true,
                        expression:
                          "officesInf.displayDefaultCourt === 'NO' ? false : true",
                      },
                    ],
                    staticClass: "info-top-content",
                  },
                  [
                    _c("span", [_vm._v("法院")]),
                    _c(
                      "span",
                      {
                        staticClass: "info-top-content-left",
                        staticStyle: {
                          color: "#4b9efb",
                          width: "75%",
                          "text-align": "right",
                        },
                      },
                      [
                        _vm._v(_vm._s(_vm.officesInf.courtName)),
                        _c("span", { staticClass: "small" }, [
                          _vm._v("（律呗预测）"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("i", {
                  staticClass: "iconfont icon-gengduo",
                  on: { click: _vm.fayuan },
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.faguanArray.length === 0 ? true : false,
                    expression: "faguanArray.length === 0 ? true : false",
                  },
                ],
                staticClass: "judge-info border-bottom",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "info-top-content",
                    on: { click: _vm.fayuan },
                  },
                  [
                    _c("span", [_vm._v("法官")]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.faguanBiaoji,
                            expression: "faguanBiaoji",
                          },
                        ],
                        staticStyle: {
                          width: "70%",
                          "text-align": "right",
                          color: "#4b9efb",
                        },
                      },
                      [
                        _vm._v(_vm._s(_vm.faguanChushi)),
                        _c("span", { staticClass: "small" }, [
                          _vm._v("（律呗预测）"),
                        ]),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.faguanBiaoji,
                            expression: "!faguanBiaoji",
                          },
                        ],
                      },
                      [_vm._v("暂无推荐数据，点击添加")]
                    ),
                  ]
                ),
              ]
            ),
            _vm._l(_vm.faguanArray, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "judge-info border-bottom" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "info-top-content",
                      on: { click: _vm.fayuan },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(`法官${_vm.kongge}${item}`),
                        },
                      }),
                      _c("span"),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "bigLine" }),
        _c(
          "div",
          { staticClass: "info-content" },
          _vm._l(_vm.typeArrayEn, function (type, index) {
            return _c("div", { key: index, staticClass: "info-example" }, [
              _c("p", { staticClass: "info-example-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.typeArray[index]))]),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addInfo(type, true)
                      },
                    },
                  },
                  [_vm._v("添加")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "info-example-content" },
                _vm._l(_vm.ligitantArrayNew[type], function (item, index2) {
                  return _c(
                    "div",
                    {
                      key: index2,
                      staticClass: "info-example-content-example",
                    },
                    [
                      _c("div", { staticClass: "border-bottom" }, [
                        item.category !== "NATURAL_PERSON"
                          ? _c("i", {
                              staticClass: "iconfont icon-qiye",
                              on: {
                                click: function ($event) {
                                  return _vm.gongshang(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            class:
                              item.category === "NATURAL_PERSON"
                                ? "special"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.addInfo(
                                  type,
                                  item,
                                  _vm.ligitantArrayNew[type].length
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "info-top-content-left",
                                on: {
                                  click: function ($event) {
                                    return _vm.addInfo(
                                      type,
                                      item,
                                      _vm.ligitantArrayNew[type].length
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "info-left-text" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.displayClient === "YES",
                                        expression:
                                          "item.displayClient === 'YES'",
                                      },
                                    ],
                                    staticClass: "tips",
                                  },
                                  [_vm._v("委")]
                                ),
                              ]
                            ),
                            item.category !== "NATURAL_PERSON"
                              ? _c("i", {
                                  staticClass: "iconfont icon-panli",
                                  on: {
                                    click: function ($event) {
                                      return _vm.currsonPerson(
                                        $event,
                                        item.name
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            item.phone
                              ? _c("i", {
                                  staticClass: "iconfont icon-dianhua1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.Plaintiff(item.phone, $event)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c("i", {
                          staticClass: "iconfont icon-gengduo",
                          on: {
                            click: function ($event) {
                              return _vm.addInfo(
                                type,
                                item,
                                _vm.ligitantArrayNew[type].length
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._l(item.agentList, function (element, index3) {
                        return _c(
                          "div",
                          {
                            key: index3,
                            staticClass:
                              "info-example-content-example-agentList border-bottom",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "info-top-content-left" },
                              [
                                _vm._v(
                                  _vm._s(element.agencyType) +
                                    " " +
                                    _vm._s(element.name)
                                ),
                              ]
                            ),
                            _c("i", {
                              staticClass: "iconfont icon-panli",
                              on: {
                                click: function ($event) {
                                  return _vm.lawyer(
                                    element.name,
                                    element.lawFirmName,
                                    element.lawyerId
                                  )
                                },
                              },
                            }),
                            element.phone
                              ? _c("i", {
                                  staticClass: "iconfont icon-dianhua1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.Defendant(element.phone)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
              _c("div", { staticClass: "bigLine" }),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "info-bottom" }, [
          _c("div", { staticClass: "qingqiu" }, [
            _c("div", { staticClass: "case-info" }, [
              _c(
                "div",
                { staticClass: "info-top-content", on: { click: _vm.qingqiu } },
                [_c("span", [_vm._v("诉讼请求")])]
              ),
              _c("i", {
                staticClass: "iconfont icon-gengduo",
                on: { click: _vm.qingqiu },
              }),
            ]),
            _c(
              "pre",
              { staticClass: "qingqiu-content", on: { click: _vm.qingqiu } },
              [_vm._v(_vm._s(_vm.textinfos.claims.content))]
            ),
          ]),
          _c("div", { staticClass: "bigLine" }),
          _c("div", { staticClass: "qingqiu" }, [
            _c("div", { staticClass: "case-info" }, [
              _c(
                "div",
                { staticClass: "info-top-content", on: { click: _vm.jianjie } },
                [_c("span", [_vm._v("案情简要")])]
              ),
              _c("i", {
                staticClass: "iconfont icon-gengduo",
                on: { click: _vm.jianjie },
              }),
            ]),
            _c(
              "pre",
              {
                staticClass: "qingqiu-content jianjie-content",
                on: { click: _vm.jianjie },
              },
              [_vm._v(_vm._s(_vm.textinfos.briefs.content))]
            ),
          ]),
          _c("div", { staticClass: "bigLine" }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.zairu,
            expression: "zairu",
          },
        ],
        staticClass: "loading-all",
      },
      [_c("van-loading", { staticClass: "lonading-logo" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }