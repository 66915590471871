var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nodeform-document-box" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "nodeform-document" },
      _vm._l(_vm.array, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "nodeform-document-cell" },
          [
            _c(
              "span",
              {
                staticClass: "nodeform-document-left",
                on: {
                  click: function ($event) {
                    return _vm.showDocument(item)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(item.documentName))])]
            ),
            item.completeTime
              ? _c("span", { staticClass: "nodeform-document-right" }, [
                  _vm._v(_vm._s(_vm.getTime(item.completeTime))),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "documentHeader" }, [
      _c("span", [_vm._v("文书")]),
      _c("span", { staticClass: "iconfont icon-child-off" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }