<template>
  <div class="time-picker">
    <div class="track">
      <div class="slider-item"
           :style="{left: percentage}">
        <div v-if="timeRange.children"
             class="expand-icon"
             @click="notifyToggleChildren">
          <i :class="expandIcon"></i>
        </div>
        <div class="slider-name"
             @click.stop="openForm">{{timeRange.nodeName}}
          <span class="probability">{{probability}}</span>
        </div>
      </div>
      <div class="range"
           :style="{width: percentage}"
           @click="move"></div>
      <div class="thumb"
           :style="{left: percentage}"
           @touchstart="onTouchStart"
           @touchmove="onTouchMove"
           @touchend="onTouchEnd">{{timeRange.simpleName}}</div>
      <!-- <div class="value" :style="{left: percentage}">{{ valFilter(val) }}</div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'time-picker',
  props: {
    timeRange: Object,
    expand: Boolean
  },
  data () {
    return {
      step: 2,
      val: 0,
      offset: 0,
      touch: {
        startX: 0,
        lastVal: 0
      }
    }
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching,
      timelineCanMove: state => state.TLCanMove
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    max () {
      const { beginTime, endTime } = this.timeRange
      const timeDiffInDays =
        Math.floor((this.getTimeStamp(endTime) - this.getTimeStamp(beginTime)) / 3600 / 24 / 1000)
      return timeDiffInDays
    },
    min () {
      return 0
    },
    precision () {
      return (this.step.toString().split('.')[1] || []).length
    },
    percentage () {
      const pr = this.val * (3600 * 1000 * 24) / (this.timeRange.endTime - this.timeRange.beginTime) * 100
      return `${pr}%`
    },
    wrapperWidth () {
      const WPleft = this.getOffsetLeft(this.timeRange)
      const vItem = {}
      vItem.beginTime = this.timeRange.endTime
      const vLeft = this.getOffsetLeft(vItem)
      const width = vLeft - WPleft
      return width
    },
    expandIcon () {
      if (this.expand) {
        return 'iconfont icon-child-on'
      } else {
        return 'iconfont icon-child-off'
      }
    },
    probability () {
      const probability = this.timeRange.probability || 0
      const pr = parseFloat(probability * 100).toFixed(2)
      const prString = `${pr}%`
      return prString
    }
  },
  methods: {
    ...mapMutations('caseDetail', [
      'setTLRange',
      'setTLWidth',
      'setTLHeight',
      'setTLMode',
      'setTLTouching',
      'setTLCanMove'
    ]),
    ...mapMutations('nodeform', [
      'showNodeForm'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    getOffsetLeft (item) {
      const { start } = this.getTLRangeStamp
      if (item && item.beginTime) {
        const _start = this.getTimeStamp(item.beginTime)
        const left =
          (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
        return left
      } else {
        return 0
      }
    },
    onTouchStart (e) {
      this.setTLCanMove(false)
      this.touch.startX = e.targetTouches[0].clientX
      this.touch.lastVal = parseInt(this.val)
    },
    onTouchMove (e) {
      const range = this.max - this.min
      const pageX = e.targetTouches[0].clientX
      const diffX = pageX - this.touch.startX
      if (pageX <= this.timelineWidth) {
        if (diffX !== 0) {
          const delta = Math.floor(diffX / this.wrapperWidth * range)
          const newVal = parseInt(this.touch.lastVal + delta)
          if (newVal >= this.max) {
            this.val = this.max
          } else if (newVal <= 0) {
            this.val = 0
          } else {
            this.val = newVal
          }
          const pr = this._getPercentage(this.val)
          this.notifyParent(this.val, pr)
        }
      }
    },
    onTouchEnd () {
      this.setTLCanMove(true)
    },
    move (e) {
      const me = this
      const WPleft = this.getOffsetLeft(this.timeRange)
      const left = e.pageX - me.offset - WPleft
      const range = this.max - this.min
      const delta = Math.floor(left / this.wrapperWidth * range)
      const newVal = parseInt(delta)
      if (newVal >= this.max) {
        this.val = this.max
      } else if (newVal <= 0) {
        this.val = 0
      } else {
        this.val = newVal
      }
      const pr = this._getPercentage(this.val)
      this.notifyParent(this.val, pr)
    },
    _getWholeWidth () {
      this.wholeWidth = this.$el.querySelector('.track').offsetWidth
    },
    _getPercentage (value) {
      const pr = value * (3600 * 1000 * 24) / (this.timeRange.endTime - this.timeRange.beginTime) * 100
      return `${pr}%`
    },
    valFilter (val) {
      this.val = parseFloat(val).toFixed(this.precision)
      return this.val
    },
    notifyParent (days = 0, pr = 0.0) {
      const payload = {}
      payload.days = days
      payload.pr = pr
      this.$emit('sliderChange', payload)
    },
    notifyToggleChildren () {
      this.$emit('toggleChildren', this.timeRange.id)
    },
    openForm (e) {
      const caseId = this.$route.params.caseId
      const startDate = (this.val) * 3600 * 24 * 1000 + this.timeRange.beginTime
      const { nodeId, nodeFormType, nodeName: title } = this.timeRange
      const formData = {
        type: nodeFormType,
        startDate,
        caseId,
        nodeId,
        title
      }
      this.showNodeForm(formData)
    },
    setDefaultVal () {
      const { beginTime, endTime } = this.timeRange
      if (beginTime < this.today && endTime > this.today) {
        this.val = Math.floor((this.today - beginTime) / (3600 * 24 * 1000))
        const pr = this._getPercentage(this.val)
        this.notifyParent(this.val, pr)
      }
    }
  },
  mounted () {
    this.setDefaultVal()
  }
}
</script>

<style lang="stylus">
$primary-color = #60c3f6
$range-size = 15px
$thumb-size = 20px
$thumb-color = white
$box-shadow-color = #aaa
.time-picker
  user-select none
  cursor pointer
  width 100%
  margin $thumb-size * 0.5 0 0 0
  color $primary-color
  .track
    position relative
    height $range-size
    background lighten($primary-color, 30%)
    // border-radius: 2px;
    .range
      background $primary-color
      // border-radius: 2px;
      height 100%
    .slider-item
      height 100%
      position absolute
      padding 0 0 0 14px
      max-width 120px
      line-height 1
      display flex
      color #666
      white-space nowrap
      word-break keep-all
      overflow visible
      .expand-icon
        padding 0 2px 0 2px
      .slider-name
        line-height $range-size
        .probability
          margin-left 10px
    .thumb
      position relative
      text-align center
      line-height $thumb-size
      background $thumb-color
      box-shadow 0 0 10px -2px $box-shadow-color
      width $thumb-size
      height $thumb-size
      border-radius $thumb-size
      transform translate3D((- $thumb-size / 2), -25px, 0)
      &:after
        content ' '
        position absolute
        width 6px
        height 6px
        left 50%
        top 100%
        background #fff
        margin-left -3px
        margin-top -4px
        transform rotate(-45deg)
    .value
      position absolute
      top - $thumb-size * 1.5
      transform translateX(-50%)
      background $thumb-color
      box-shadow 0 0 10px -2px $box-shadow-color
      border-radius 4px
      line-height 20px
      padding 0 10px
      white-space nowrap
      &:before
        content ' '
        position absolute
        width 6px
        height 6px
        left 50%
        top 100%
        background #fff
        margin-left -3px
        margin-top -4px
        transform rotate(-45deg)
</style>
