var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible(_vm.item),
          expression: "isVisible(item)",
        },
      ],
      ref: "tearwp",
      class: {
        "round-item-wrapper": true,
        active: _vm.item.showInfoCard,
        "show-connector": _vm.showChild && _vm.item.showInfoCard,
      },
      style: _vm.itemStyle,
    },
    [
      _c(
        "div",
        {
          ref: "item",
          class: _vm.roundClass,
          on: {
            click: function ($event) {
              return _vm.toggleInfoCard(_vm.item)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.item.simpleName) + " ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.item.showInfoCard,
              expression: "item.showInfoCard",
            },
          ],
          class: _vm.itemInfoCardClass,
        },
        [
          _vm.item.children
            ? _c(
                "div",
                {
                  staticClass: "has-child-item-icon",
                  on: {
                    click: function ($event) {
                      return _vm.toggleChildItem(_vm.item)
                    },
                  },
                },
                [_c("i", { class: _vm.icon })]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tear-item-fname", on: { click: _vm.openForm } },
            [_vm._v(_vm._s(_vm.item.nodeName))]
          ),
          _vm.item.avatar
            ? _c("div", { staticClass: "tear-item-avatar" }, [
                _c("img", { attrs: { src: _vm.item.avatar } }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }