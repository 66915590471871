<template>
  <div class="infoMain">
    <div v-show="!zairu">
      <div class="info-top">
        <div class="case-info border-bottom" @click="anjianinfo">
          <div class="info-top-content">
            <span class="info-top-content-left">{{ essentialInf.caseReasonName }}</span>
            <span class="right_content">{{ acceptDate }}受理</span>
          </div>
          <i class="iconfont icon-gengduo" @click="anjianinfo"></i>
        </div>
        <div class="court-info border-bottom" @click="fayuan">
          <div
            class="info-top-content"
            v-show="officesInf.displayDefaultCourt === 'NO' ? true : false"
          >
            <span class="info-top-content-left">{{ officesInf.courtName }}</span>
            <span class="right_content">{{ officesInf.courtTelephone }}</span>
          </div>
          <div
            class="info-top-content"
            v-show="officesInf.displayDefaultCourt === 'NO' ? false : true"
          >
            <span>法院</span>
            <span
              class="info-top-content-left"
              style="color: #4b9efb; width: 75%; text-align: right"
              >{{ officesInf.courtName }}<span class="small">（律呗预测）</span></span
            >
          </div>

          <i class="iconfont icon-gengduo" @click="fayuan"></i>
        </div>
        <div
          class="judge-info border-bottom"
          v-show="faguanArray.length === 0 ? true : false"
        >
          <!--新建案件进来-->
          <div class="info-top-content" @click="fayuan">
            <span>法官</span>
            <span
              v-show="faguanBiaoji"
              style="width: 70%; text-align: right; color: #4b9efb"
              >{{ faguanChushi }}<span class="small">（律呗预测）</span></span
            >
            <span v-show="!faguanBiaoji">暂无推荐数据，点击添加</span>
          </div>
        </div>
        <div
          class="judge-info border-bottom"
          v-for="(item, index) in faguanArray"
          :key="index"
        >
          <!-- 该处调用-->
          <div class="info-top-content" @click="fayuan">
            <span v-html="`法官${kongge}${item}`"></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="bigLine"></div>
      <div class="info-content">
        <div class="info-example" v-for="(type, index) in typeArrayEn" :key="index">
          <p class="info-example-title">
            <span>{{ typeArray[index] }}</span>
            <span @click="addInfo(type, true)">添加</span>
          </p>
          <div class="info-example-content">
            <div
              class="info-example-content-example"
              v-for="(item, index2) in ligitantArrayNew[type]"
              :key="index2"
            >
              <div class="border-bottom">
                <!--icon-qiye 显示工商信息 -->
                <i
                  class="iconfont icon-qiye"
                  @click="gongshang(item)"
                  v-if="item.category !== 'NATURAL_PERSON'"
                ></i>
                <p
                  :class="item.category === 'NATURAL_PERSON' ? 'special' : ''"
                  @click="addInfo(type, item, ligitantArrayNew[type].length)"
                >
                  <span
                    class="info-top-content-left"
                    @click="addInfo(type, item, ligitantArrayNew[type].length)"
                  >
                    <!-- 原告姓名 -->
                    <span class="info-left-text">{{ item.name }}</span>
                    <span class="tips" v-show="item.displayClient === 'YES'">委</span>
                  </span>
                  <!-- 显示法人原告公司当事人报表 -->
                  <i
                    class="iconfont icon-panli"
                    v-if="item.category !== 'NATURAL_PERSON'"
                    @click="currsonPerson($event, item.name)"
                  ></i>
                  <!-- 显示原告手机号码 -->
                  <i
                    class="iconfont icon-dianhua1"
                    v-if="item.phone"
                    @click="Plaintiff(item.phone, $event)"
                  ></i>
                </p>
                <i
                  class="iconfont icon-gengduo"
                  @click="addInfo(type, item, ligitantArrayNew[type].length)"
                ></i>
              </div>
              <!--  显示律师 律师名字 -->
              <div
                class="info-example-content-example-agentList border-bottom"
                v-for="(element, index3) in item.agentList"
                :key="index3"
              >
                <!--  {{item.agentList}}-->
                <span class="info-top-content-left"
                  >{{ element.agencyType }} {{ element.name }}</span
                >
                <i
                  class="iconfont icon-panli"
                  @click="lawyer(element.name, element.lawFirmName, element.lawyerId)"
                ></i>
                <!-- 显示手机号码 -->
                <i
                  class="iconfont icon-dianhua1"
                  v-if="element.phone"
                  @click="Defendant(element.phone)"
                ></i>
                <!-- <i class="iconfont icon-gengduo"></i> -->
              </div>
            </div>
          </div>
          <div class="bigLine"></div>
        </div>
      </div>
      <div class="info-bottom">
        <div class="qingqiu">
          <div class="case-info">
            <div class="info-top-content" @click="qingqiu">
              <span>诉讼请求</span>
            </div>
            <i class="iconfont icon-gengduo" @click="qingqiu"></i>
          </div>
          <pre class="qingqiu-content" @click="qingqiu">{{
            textinfos.claims.content
          }}</pre>
        </div>
        <div class="bigLine"></div>
        <div class="qingqiu">
          <div class="case-info">
            <div class="info-top-content" @click="jianjie">
              <span>案情简要</span>
            </div>
            <i class="iconfont icon-gengduo" @click="jianjie"></i>
          </div>
          <pre class="qingqiu-content jianjie-content" @click="jianjie">{{
            textinfos.briefs.content
          }}</pre>
        </div>
        <div class="bigLine"></div>
      </div>
    </div>
    <div v-show="zairu" class="loading-all">
      <van-loading class="lonading-logo" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { caseBase, pydataBase } from '~api-config'
import { identity } from './js/type'
import { agencytity } from './js/agencyType'
export default {
  name: 'fb-info-summary',
  data() {
    return {
      wancheng: false,
      faguanBiaoji: false,
      faguanChushi: '',
      htmlContent: '',
      kongge: '&#12288',
      faguanArray: [],
      acceptDate: '', // 创建时间
      ligitantArray: [], // 当事人列表
      officesInf: {}, // 法院信息
      essentialInf: {}, // 案件类型
      ligitantArrayNew: {}, // 合并过的当事人列表
      typeArray: [], // 当事人身份列表中文
      typeArrayEn: [], // 当事人身份列表英文
      textinfos: {
        claims: {
          chinese: '诉讼请求',
          content: '',
        },
        briefs: {
          chinese: '案情简要',
          content: '',
        },
      },
      clientNum: null,
    }
  },
  mounted() {},
  created() {
    const caseId = this.$route.params.caseId
    this.$axios
      .get(caseBase + '/lts/inf/infOverview', {
        params: {
          caseId: caseId,
        },
      })
      .then((res) => {
        // 获取当事人列表
        this.ligitantArray = res.data.data.litigantList
        // 合并当事人列表
        this.ligitantArray.forEach((element) => {
          this.ligitantArrayNew[element.procedureSubject] = []
        })
        // 获取委托人个数
        this.clientNum = this.ligitantArray.filter((item) => {
          return item.displayClient === 'YES'
        }).length
        for (var key in this.ligitantArrayNew) {
          this.typeArrayEn.push(key)
          var keyName = identity(key)
          this.typeArray.push(keyName)
          this.ligitantArray.forEach((element) => {
            if (key === element.procedureSubject) {
              element.agentList.forEach((item) => {
                var newtype = agencytity(item.agencyType || item.identity)
                item.agencyType = newtype
              })
              this.ligitantArrayNew[key].push(element)
            }
          })
        }
        // 获取法院信息
        this.officesInf = res.data.data.officesInf
        var faguanString = this.officesInf.judge
        if (faguanString !== null && faguanString !== '[]' && faguanString !== '') {
          let array = []
          if (faguanString.startsWith('[') && faguanString.endsWith(']')) {
            var string = faguanString.slice(1, faguanString.length - 1)
            if (string.indexOf(',', 0) !== -1) {
              array = string.split(',')
              this.faguanArray = array
            } else {
              this.faguanArray.push(string)
            }
          } else if (faguanString.indexOf(',') > 0) {
            array = faguanString.split(',')
            this.faguanArray = array
          } else if (faguanString.trim()) {
            this.faguanArray = [faguanString]
          }
        } else {
          /* ?office=${
                this.officesInf.courtName
              }&name=${this.officesInf.reasonRelation} */
          this.$http
            .post(`${pydataBase}/api/lawyer/court`, {
              office: this.officesInf.courtName,
              name: this.officesInf.reasonRelation,
            })
            .then((res) => {
              this.wancheng = true
              var allFayuan = res.data.result
              this.faguanChushi = ''
              allFayuan.forEach((item) => {
                if (
                  item.label.indexOf(this.officesInf.courtName) !== -1 ||
                  this.officesInf.courtName.indexOf(item.label) !== -1
                ) {
                  this.faguanChushi = item.judge[0]
                  return true
                }
              })
              if (this.faguanChushi === '' || this.faguanChushi === null) {
                this.faguanBiaoji = false
              } else {
                this.faguanBiaoji = true
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
        // 获取案件类型
        this.essentialInf = res.data.data.essentialInf
        // date解析
        var dataNew = new Date(this.essentialInf.acceptCaseTime)
        var year = dataNew.getFullYear()
        var month = dataNew.getMonth() + 1
        var day = dataNew.getDate()
        this.acceptDate = year + '-' + month + '-' + day
      })
    this.$axios
      .get(caseBase + '/new-design/caseUserDocument/queryByCaseId/' + caseId)
      .then((res) => {
        if (res.data.data.caseUserDocument.length !== 0) {
          const textinfos = JSON.parse(res.data.data.caseUserDocument[0].documentData)[0]
          this.textinfos.claims.content = textinfos.suSongQingQiu_1.replace(
            /\^\^\^/g,
            '\n'
          )
          this.textinfos.briefs.content = textinfos.shiShiLiYou_1.replace(/\^\^\^/g, '\n')
          this.isData = true
          this.cudId = res.data.data.caseUserDocument[0].cudId
        } else {
          this.textinfos.claims.content = '无数据'
          this.textinfos.briefs.content = '无数据'
        }
      })
  },
  methods: {
    ...mapMutations('createCase', ['setWtrType', 'setShouldActiveWtrType', 'setBiaoji']),
    gongshang(data) {
      this.$router.push({
        name: 'gongshang',
        params: {
          caseId: this.$route.params.caseId,
          name: data.name,
        },
      })
    },
    currsonPerson(e, name) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .post(`${pydataBase}/api/party/card`, {
          name: name,
        })
        .then((res) => {
          totast.clear()
          if (res.data.result.data[0].data[0].graph.datas.length === 0) {
            this.$notify('暂未查询到历史案件')
          } else {
            this.$router.push({
              path: '/currson',
              query: {
                name: name,
              },
            })
          }
        })
        .catch((err) => {
          totast.clear()
          console.log(err)
        })
      e.stopPropagation()
    },
    lawyer(name, lawFirmName, lawyerId) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0,
      })
      /* ?name=${name}&office=${lawFirmName} */
      this.$axios
        .post(`${pydataBase}/api/lawyer/card`, {
          name: name,
          office: lawFirmName,
        })
        .then((res) => {
          totast.clear()
          if (res.data.result.data.data[0].data[0].graph.datas.length === 0) {
            this.$notify('暂未查询到历史案件')
          } else {
            this.$router.push({
              path: '/lawer',
              query: {
                name: name,
                lawFirmName: lawFirmName,
                lawyerId: lawyerId,
              },
            })
          }
        })
        .catch((err) => {
          totast.clear()
          console.log(err)
        })
    },
    Defendant(phone) {
      this.$dialog
        .alert({
          title: '是否需要拨打电话？',
          message: `<h1 style="color:#4B9EFB;font-size:18px">${phone}</h1>`,
          confirmButtonText: '拨打电话',
          closeOnClickOverlay: true,
        })
        .then(() => {
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // 安卓环境
              // eslint-disable-next-line
              AND2JS.call(phone)
            } else if (u === 'fb-iOS-Webview') {
              // ios环境
              // eslint-disable-next-line
              window.webkit.messageHandlers.backToTool.postMessage({
                takePhoneNumber: phone,
              })
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              window.backToTool.postMessage(
                JSON.stringify({
                  takePhoneNumber: phone,
                })
              )
            } else {
              this.$toast('非原生环境,返回原生指定页')
            }
          } catch (err) {
            console.log('backToTool.postMessage', err)
          }
        })
    },
    Plaintiff(phone, e) {
      this.$dialog
        .alert({
          title: '是否需要拨打电话？',
          message: `<h1 style="color:#4B9EFB;font-size:18px">${phone}</h1>`,
          confirmButtonText: '拨打电话',
          closeOnClickOverlay: true,
        })
        .then(() => {
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // 安卓环境
              // eslint-disable-next-line
              AND2JS.call(phone)
            } else if (u === 'fb-iOS-Webview') {
              // ios环境
              // eslint-disable-next-line
              window.webkit.messageHandlers.backToTool.postMessage({
                takePhoneNumber: phone,
              })
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              window.backToTool.postMessage(
                JSON.stringify({
                  takePhoneNumber: phone,
                })
              )
            } else {
              this.$toast('非原生环境,返回原生指定页')
            }
          } catch (err) {
            console.log('backToTool.postMessage', err)
          }
        })
      e.stopPropagation()
    },

    fayuan() {
      // if (!officesInf.judge) {
      //   officesInf.judge = '[]'
      // }
      // this.$store.state.caseDetail.adult = {
      //   office: office, // 律所 河北百创律师事务所
      //   name: name, // 律所  吴全根
      //   courtId: courtId, // 法院id 2765
      //   caseId: caseId, // 案件id  21651
      //   summary: officesInf, // 案由纠纷
      //   judgename: item, //  孟志刚
      //   fbname: item, // 孟志刚
      //   secure: this.$store.state.caseDetail.adult.secure,
      //   sort: this.$store.state.caseDetail.adult.sort,
      //   portable: this.$store.state.caseDetail.adult.portable,
      //   count: this.$store.state.caseDetail.adult.count
      // }
      // this.$router.push({
      //   name: 'fayuanInfo',
      //   query: {
      //     is: 0
      //   }
      // })
      this.$router.push({
        name: 'fayuanInfo',
        query: {
          is: 0,
          caseId: this.$route.params.caseId,
        },
      })
    },
    qingqiu() {
      this.$router.push({
        name: 'qingqiu',
        params: {
          caseId: this.$route.params.caseId,
        },
      })
    },
    jianjie() {
      this.$router.push({
        name: 'jianjie',
        params: {
          caseId: this.$route.params.caseId,
        },
      })
    },
    anjianinfo() {
      this.$router.push({
        name: 'anjianinfo',
        params: {
          caseId: this.$route.params.caseId,
        },
      })
    },
    addInfo(type, item, length) {
      this.setWtrType(this.officesInf.principal)
      this.setBiaoji(true)
      this.$router.push({
        name: 'addinfo',
        params: {
          caseId: this.$route.params.caseId,
          type: type,
          personData: item === true ? undefined : item,
          length: length,
          clientLength: this.clientNum,
          flag: item,
        },
      })
    },
  },
  computed: {
    zairu() {
      if (this.officesInf && (this.wancheng || this.faguanArray.length > 0)) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    ligitantArrayNew(value) {},
  },
}
</script>

<style scoped>
.right_content {
  width: 40%;
}
.infoMain {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.info-top {
  width: 92%;
  height: auto;
  margin: 0 auto;
}
.info-content {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.bigLine {
  width: 100%;
  height: 5px;
  background: rgba(239, 239, 239, 1);
}
.case-info,
.court-info,
.judge-info {
  width: 100%;
  height: 46px;
  line-height: 46px;
  border-color: rgba(243, 244, 247, 1);
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: space-between;
}
.info-top .iconfont {
  font-size: 20px;
  color: #cccccc;
}
.icon-gengduo {
  font-size: 15px !important;
  color: #cccccc;
}
.info-top-content {
  width: calc(100% - 30px);
  height: 46px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.addBtn {
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(75, 158, 251, 1);
}
.van-dialog__header {
  color: #333333 !important;
}
.category-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
}
.info-top-content-left {
  width: 60%;
  height: 46px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tips {
  display: inline-block;
  position: relative;
  top: -25px;
  left: 3px;
  font-size: 12px;
  color: red;
}
.info-left-text {
  display: inline-block;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.info-top-content-right {
  width: 35%;
  height: 46px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-dianhua1 {
  color: #5dcd43;
}
.icon-panli {
  color: #fb8821;
}
.special {
  width: calc(100% - 30px) !important;
}
.loading-all {
  width: 100%;
  text-align: center;
}
.lonading-logo {
  margin: 15px auto;
}
.Litigation,
.case-briefly {
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 24px;
}
.small {
  font-size: 12px;
  color: #cccccc;
}
.info-example {
  width: 100%;
}
.info-example-title {
  width: 92%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  line-height: 46px;
  margin: 0 auto;
}
.info-example-title > span:nth-of-type(1) {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.info-example-title > span:nth-of-type(2) {
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(75, 158, 251, 1);
}
.info-example-content {
  width: 92%;
  height: auto;
  color: rgba(102, 102, 102, 1);
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  margin: 0 auto;
  border-color: rgba(243, 244, 247, 1);
}
.info-example-content-example > div {
  display: flex;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  line-height: 46px;
  border-color: rgba(243, 244, 247, 1);
}
.info-example-content-example > div > p {
  display: flex;
  width: calc(100% - 65px);
  justify-content: space-between;
}
.icon-qiye {
  font-size: 20px;
  color: #f69731;
}
.info-example-content-example-agentList {
  display: flex;
  justify-content: space-between;
  border-color: rgba(243, 244, 247, 1);
}
.qingqiu {
  width: 92%;
  margin: 0 auto;
  height: auto;
  padding-bottom: 15px;
}
.qingqiu-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  height: auto;
  margin-top: 5px;
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 25px;
  white-space: pre-wrap;
}
.jianjie-content {
  height: 75px;
}
</style>
