var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "nodeform" }, on: { click: _vm.closeForm } },
    [
      !_vm.$store.state.nodeform.showQuestion
        ? _c(
            "div",
            {
              staticClass: "form",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "nodeform-header" }, [
                _c("span", [_vm._v(_vm._s(_vm.$store.state.nodeform.title))]),
                _c("span", {
                  staticClass: "iconfont icon-question-mark",
                  on: {
                    click: function ($event) {
                      _vm.$store.state.nodeform.showQuestion = true
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "container" },
                _vm._l(_vm.calcArray, function (item, index) {
                  return _c(item.componentName, {
                    key: index,
                    tag: "component",
                    attrs: { propsData: item.propsData },
                  })
                }),
                1
              ),
              _c("save-button"),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.nodeform.showQuestion
        ? _c(
            "div",
            {
              staticClass: "question",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm.$store.state.nodeform.showQuestion
                ? _c("question-form", [
                    _c(
                      "div",
                      { staticClass: "nodeform-header" },
                      [
                        _c("van-icon", {
                          attrs: { name: "arrow-left" },
                          on: {
                            click: function ($event) {
                              _vm.$store.state.nodeform.showQuestion = false
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.$store.state.nodeform.showQuestion = false
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$store.state.nodeform.title))]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }