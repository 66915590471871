<template>
  <div id="remindForm"
       @click="closeRemindForm">
    <div class="formContainer"
         @click.stop>
      <span class="iconfont icon-clear"
            @click="closeRemindForm"></span>
      <div class="form-header">
        <span>提醒表单</span>
      </div>
      <div class="formcell content">
        <input type="text"
               placeholder="请输入内容"
               v-model="inputdata">
      </div>
      <div class="formcell starttime">
        <span>开始时间:</span>
        <span @click="showDatePicker = true">{{beginDate}}</span>
      </div>
      <div class="formcell starttime"
           @click="endshowDatePicker = true">
        <span>结束时间:</span>
        <span @click="endshowDatePicker = true">{{endDate}}</span>
      </div>
      <div class="formcell select-type">
        <span>
          设置提醒:
        </span>
        <div @click="timeState=true">
          <van-cell v-model="time_t"></van-cell>
        </div>
        <div @click="timePopup=true">
          <van-cell v-model="time_l"></van-cell>
        </div>
      </div>
      <div class="formcell select-node">
      </div>
      <div class="formcell select-node">
        <div class="button-box"
             v-if="!$store.state.remindForm.status">
          <span class="cancel"
                @click="closeRemindForm">取消</span>
          <span class="save"
                @click="save">添加</span>
        </div>
        <div class="button-box"
             v-if="$store.state.remindForm.status">
          <span class="cancel"
                @click="deleteForm">撤销</span>
          <span class="updata"
                @click="updata">修改</span>
        </div>
      </div>
    </div>
    <p @click.stop>
      <!--开始时间弹出框 -->
      <van-popup v-model="showDatePicker"
                 position="bottom">
        <van-datetime-picker v-model="pickerTime"
                             :type="'datetime'"
                             @confirm="confirmDateHandle"
                             @cancel="cancelDateHandle" />
      </van-popup>
      <!-- 结束时间选择框 -->
      <van-popup v-model="endshowDatePicker"
                 position="bottom">
        <van-datetime-picker v-model="endpickerTime"
                             :type="'datetime'"
                             @confirm="confirmDateHandle2"
                             @cancel="cancelDateHandle2" />
      </van-popup>
      <!-- 文书可选择框 -->

      <van-popup v-model="showPopup"
                 position="bottom">
        <van-picker :columns="caseNodeList"
                    :show-toolbar="true"
                    value-key="nodeName"
                    @confirm="changeType"
                    @cancel="showPopup = false" />
      </van-popup>
      <!-- 状态选择框 -->
      <van-popup v-model="statePopup"
                 position="bottom">
        <van-picker :columns="status"
                    :show-toolbar="true"
                    value-key="label"
                    @confirm="changeType2"
                    @cancel="statePopup = false" />
      </van-popup>
      <!-- 时间状态选择 -->
      <van-popup v-model="timeState"
                 position="bottom">
        <van-picker :columns="timeType"
                    :show-toolbar="true"
                    value-key="label"
                    @confirm="changeType3"
                    @cancel="timeState = false" />
      </van-popup>
      <!-- 时间长度选择 -->
      <van-popup v-model="timePopup"
                 position="bottom">
        <van-picker :columns="timeValue"
                    :show-toolbar="true"
                    value-key="label"
                    @confirm="changeType4"
                    @cancel="timePopup = false" />
      </van-popup>
    </p>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
export default {
  name: 'remindForm',
  data () {
    return {
      inputdata: '',
      timeState: false,
      time_t: '提醒状态',
      timePopup: false,
      time_l: '选择时间',
      showPopup: false,
      node_t: '选择节点状态',
      statePopup: false,
      state: '选择完成状态',
      // 加载状态
      loading: true,
      // 弹出时间选择器
      showDatePicker: false,
      endshowDatePicker: false,
      pickerTime: 0,
      endpickerTime: 0,
      formData: {
        formRemindEntity: {
          caseId: null,
          // 第一个输入框类容
          remindContent: '',
          // 开始时间 第二个输入框类容
          beginDate: null,
          // 结束时间
          endDate: null,
          // 节点id 第一个选择框
          nodeId: null,
          // 完成状态
          // nodeStatus: 'COMPLET',
          nodeStatus: null,
          // 提前和延迟类型
          // settingType: 'IN_ADVANCE',
          settingType: 'IN_NULL',
          // 提前和延迟的时间值
          // settingTime: 'THIRTY_MINUTES'
          settingTime: 'IN_NULL'
        },
        userIds: []
      },
      // 节点类型
      status: [
        {
          name: 1,
          label: '完成',
          value: 'COMPLETE'
        },
        {
          name: 2,
          label: '消失',
          value: 'REMOVE'
        }
      ],
      // 时间类型
      timeType: [
        {
          name: 1,
          label: '提前',
          value: 'IN_ADVANCE'
        },
        {
          name: 2,
          label: '延后',
          value: 'IN_POSTPONE'
        }
      ],
      // 时间值
      timeValue: [
        {
          name: 1,
          label: '5分钟',
          value: 'FIVE_MINUTES'
        },
        {
          name: 2,
          label: '10分钟',
          value: 'TEN_MINUTES'
        },
        {
          name: 3,
          label: '15分钟',
          value: 'FIFTEEN_MINUTES'
        },
        {
          name: 4,
          label: '30分钟',
          value: 'THIRTY_MINUTES'
        },
        {
          name: 5,
          label: '45分钟',
          value: 'FORTY_FIVE_MINUTES'
        },
        {
          name: 6,
          label: '一小时',
          value: 'AN_HOUR'
        }
      ],
      // node列表
      caseNodeList: []
    }
  },
  methods: {
    // 数据参数转换
    over () {
      this.timeValue.forEach(el => {
        if (el.label === this.time_l) {
          this.formData.formRemindEntity.settingTime = el.value
        }
      })
      this.timeType.forEach(el => {
        if (el.label === this.time_t) {
          this.formData.formRemindEntity.settingType = el.value
        }
      })
      this.status.forEach(el => {
        if (el.label === this.state) {
          this.formData.formRemindEntity.nodeStatus = el.value
        }
      })
      this.caseNodeList.forEach(el => {
        if (el.nodeName === this.node_t) {
          this.formData.formRemindEntity.nodeId = el.nodeId
        }
      })
      this.formData.formRemindEntity.remindContent = this.inputdata
    },
    // 各类选择框确定
    changeType (nodeName) {
      this.node_t = nodeName.nodeName
      this.showPopup = false
    },
    changeType2 (state) {
      this.state = state.label
      this.statePopup = false
    },
    changeType3 (timeType) {
      this.time_t = timeType.label
      this.timeState = false
    },
    changeType4 (timeValue) {
      this.time_l = timeValue.label
      this.timePopup = false
    },
    // 时间选择器确定
    confirmDateHandle (time) {
      this.formData.formRemindEntity.beginDate = time.getTime()
      this.showDatePicker = false
    },
    // 时间学则器取消
    cancelDateHandle () {
      this.pickerTime = new Date(this.formData.formRemindEntity.beginDate)
      this.showDatePicker = false
    },
    // 时间选择器确定2
    confirmDateHandle2 (time) {
      this.formData.formRemindEntity.endDate = time.getTime()
      this.endshowDatePicker = false
    },
    // 时间学则器取消2
    cancelDateHandle2 () {
      this.endpickerTime = new Date(this.formData.formRemindEntity.endDate)
      this.endshowDatePicker = false
    },
    // 计算时间
    calcDateTime (time) {
      if (time === null) {
        return ''
      } else {
        const a = new Date(time)
        const Month = a.getMonth() + 1
        const Days = a.getDate()
        const h = a.getHours()
        const m = a.getMinutes()
        return `${a.getFullYear()}/${Month < 10 ? ('0' + Month) : Month}/${Days < 10 ? ('0' + Days) : Days} ${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`
      }
    },
    // 关闭表单
    closeRemindForm () {
      this.$store.commit('remindForm/closeRemindForm')
    },
    // 添加保存表单
    save () {
      this.over()
      console.log('添加提醒表单参数', this.formData)
      // return false
      if (this.formData.formRemindEntity.remindContent === '') {
        return this.$notify('请填写完整')
      } else {
        const loading = this.$toast.loading({
          mask: true,
          message: '加载中...'
        })
        // console.log('this.formData', this.formData, this.formData.formRemindEntity.beginDate, this.formData.formRemindEntity.endDate)
        const beginTime = this.formData.formRemindEntity.beginDate
        const endTime = this.formData.formRemindEntity.endDate

        if (beginTime - endTime >= 1000 * 60 && new Date(beginTime).getMinutes() !== new Date(endTime).getMinutes()) {
          this.$toast('结束时间应该在开始时间之后')
          return
        } else if (beginTime - endTime >= 1000) {
          this.$toast('结束时间应该在开始时间之后')
          return
        }

        // console.log( new Date(this.formData.formRemindEntity.beginDate).getMinutes(),new Date(this.formData.formRemindEntity.endDate).getMinutes())

        this.$axios.post(`${caseBase}/lts/remind/save`, this.formData)
          .then(res => {
            loading.clear()
            if (res.data.code === '200') {
              this.$toast('添加提醒表单成功')
              this.$store.state.remindForm.remindFormUpdata = Date.now()
              this.closeRemindForm()
            } else if (res.data.code === '410') {
              // this.$toast(res.data.message + this.calcDateTime(res.data.data))
              this.$toast(res.data.message)
            } else {
              this.$toast('发生未知情况')
              this.closeRemindForm()
            }
          })
      }
    },
    // 修改表单
    updata () {
      this.over()
      console.log('修改表单数据' + this.formData)
      const loading = this.$toast.loading({
        mask: true,
        message: '加载中...'
      })
      this.$axios.put(`${caseBase}/lts/remind/${this.formData.formRemindEntity.id}`, this.formData)
        .then(res => {
          loading.clear()
          if (res.data.code === '200') {
            this.$toast('修改提醒表单成功')
            this.$store.state.remindForm.remindFormUpdata = Date.now()
            this.closeRemindForm()
          } else if (res.data.code === '410') {
            this.$toast(res.data.message + this.calcDateTime(res.data.data))
          } else {
            this.$toast('发生未知情况')
            this.closeRemindForm()
          }
        })
    },
    // 撤销表单
    deleteForm () {
      const loading = this.$toast.loading({
        mask: true,
        message: '加载中...'
      })
      this.$axios.put(`${caseBase}/lts/remind/repeal/${this.formData.formRemindEntity.id}`, this.formData)
        .then(res => {
          loading.clear()
          if (res.data.code === '200') {
            this.$toast('删除提醒表单成功')
            this.$store.state.remindForm.remindFormUpdata = Date.now()
            this.closeRemindForm()
          } else {
            this.$toast('撤销失败')
            this.closeRemindForm()
          }
        })
    }
  },
  computed: {
    // 当前时间
    beginDate () {
      return this.calcDateTime(this.formData.formRemindEntity.beginDate)
    },
    endDate () {
      return this.calcDateTime(this.formData.formRemindEntity.endDate)
    },
    // caseId
    caseId () {
      return this.$route.params.caseId
    }

  },
  mounted () {
    console.log('1---' + this.caseNodeList)
    console.log(this.$store.state.remindForm)
    // 判断是查询还是添加
    if (this.$store.state.remindForm.status) {
      // 查询提醒表单
      this.formData.formRemindEntity = this.$store.state.remindForm.remindData
      this.caseNodeList = this.$store.state.remindForm.nodeData.caseNodeList
      this.inputdata = this.$store.state.remindForm.remindData.remindContent || ''
      this.timeDate = this.$store.state.remindForm.remindData.settingType
      this.time_t = this.timeDate === 'IN_ADVANCE' ? '提前' : this.timeDate === 'IN_NULL' ? '选择提醒状态' : '延后'
      this.time_l = this.$store.state.remindForm.remindData.settingTime
      this.timeValue.forEach(el => {
        if (this.time_l === el.value) {
          this.time_l = el.label
        } else {
          this.time_l = '选择时间'
        }
      })
      // this.node_t = this.$store.state.remindForm.remindData.nodeId--暂时不用
      this.state = this.$store.state.remindForm.remindData.nodeStatus === 'COMPLETE' ? '完成' : '消失'
      // console.log('查寻表单数据列表' + this.caseNodeList)
      this.loading = false
    } else {
      // console.log('添加提醒表单', this.$store.state.remindForm.nodeData)
      this.formData.formRemindEntity.beginDate = Date.now()
      this.formData.formRemindEntity.endDate = null
      this.formData.formRemindEntity.caseId = this.caseId
      this.inputdata = this.$store.state.remindForm.remindData || ''
      this.$store.state.remindForm.nodeData.caseNodeList.forEach(el => {
        if (el.nodeType !== 'SPECIAL_NODE') {
          this.caseNodeList.push(el)
        }
      })
      // console.log(this.$store.state.remindForm.nodeData)
      this.loading = false
    }
    // 默认打开timepicer的日期
    this.pickerTime = new Date(this.formData.formRemindEntity.beginDate)
    this.endpickerTime = new Date(this.formData.formRemindEntity.beginDate)
  }
}
</script>
<style lang="stylus" scoped>
$fontSize = 15px
#remindForm
  width 100vw
  height 100vh
  position fixed
  background-color rgba(0, 0, 0, 0.47)
  z-index 9999
  top 0
  // bottom 0
  left 0
  // right 0
  // padding 15px
  display flex
  align-items center
  justify-content center
  font-size $fontSize
.formContainer
  /* width: 283px;
  height: 230px; */
  position relative
  width 88%
  background-color white
  padding 25px 10px
  box-sizing border-box
  display flex
  flex-direction column
  border-radius 10px
.formContainer > span
  position absolute
  top 15px
  right 15px
  color #3eb3f0
.form-header
  text-align center
  font-size 16px
  margin-bottom 15px
  margin-top 15px
.formcell
  font-size 14px
  padding 6px 10px
.content
  border-bottom rgb(242, 242, 242) 1px solid
  // border-radius 3px
.content input
  border-top 1px solid red
  width 100%
  height 100%
  border none
  outline none
.starttime
  margin-top 20px
  display flex
  justify-content space-between
  align-items center
.select-type, .select-node
  margin-top 10px
  display flex
  justify-content space-between
  align-items center
  .left
    width 60%
select
  padding 3px
.nodeIdSelect
  width 140px
.button-box
  width 50%
  display flex
  margin-left 50%
  justify-content space-between
.button-box > span
  color #4B9EFB
  display flex
  // display inline-block
  width 50%
  text-align right
  // border 1px solid red
</style>
