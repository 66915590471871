<template>
  <div class="analyse-tab" ref="scrollYRef">
    <!-- <div @click="clickBack">返回</div> -->
    <!-- <van-pull-refresh v-model="isLoading"
                      @refresh="onRefresh"> -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
      class="topOffset"
    >
      <div
        class="container"
        ref="scrollDocument"
        v-show="pageInfo.page === 'ana'"
      >
        <analysis
          ref="analysis"
          :step.sync="step"
          :pageInfo="pageInfo"
          :scrollDocument="$refs.scrollYRef"
          :inScrollMenu="true"
          @resetFinished="resetFinished"
          @resetLoading="resetLoading"
          @resetFinishedText="resetFinishedText"
          @sliderSide="sliderSide"
        />
      </div>
      <div
        class="container"
        ref="detailContainer"
        v-if="pageInfo.page === 'detail' || pageInfo.page === 'detailInTopCase'"
      >
        <detail
          :pageInfo="pageInfo"
          :title.sync="title"
          :scrollDocument="$refs.scrollYRef"
          :inScrollMenu="true"
          @resetFinished="resetFinished"
          @resetLoading="resetLoading"
          @clickBack="clickBack"
        />
      </div>
      <div
        class="container"
        v-if="
          pageInfo.page === 'topCase' || pageInfo.page === 'detailInTopCase'
        "
        v-show="pageInfo.page !== 'detailInTopCase'"
      >
        <top-case
          :pageInfo="pageInfo"
          @resetFinished="resetFinished"
          @resetLoading="resetLoading"
        />
      </div>
    </van-list>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import analysis from '@/pages/Ana/pages/analysis'
import detail from '@/pages/Ana/pages/detail'
import topCase from '@/pages/Ana/pages/topCase'
// import { caseBase } from '~api-config'
export default {
  name: 'analyse-tab',
  components: {
    analysis,
    detail,
    topCase
  },
  data() {
    return {
      caseIndex: null,
      isLoading: false,
      loading: false,
      finished: false,
      finishedText: 'loading',
      title: '分析',
      step: 0,
      pageInfo: {
        page: 'ana',
        id: null,
        token: null
      }
    }
  },
  computed: {
    changeInfo() {
      return this.$store.state.caseDetail.tabAnaSummaryChange
    }
  },
  methods: {
    clickBack() {
      if (this.pageInfo.page === 'ana') {
        // 在分析页中
        if (this.step >= 2) {
          this.step--
        } else {
          console.log('最前一页了')
        }
      } else if (this.pageInfo.page === 'detailInTopCase') {
        // 如果是从100案列中到详情页面的时候
        this.title = '分析'
        this.pageInfo.id = null
        this.pageInfo.page = 'topCase'
        this.finished = false
      } else {
        // 如果不在分析页，即在100top和原文中
        this.title = '分析'
        this.pageInfo.id = null
        this.pageInfo.token = null
        this.pageInfo.page = 'ana'
        this.finished = false
      }
    },
    async getInfo() {
      // 挂在状态
      if (this.$store.state.caseDetail.tabAnaSummaryChange === 0) {
        return
      }
      const causeName = this.$store.state.ana.caseName
      // console.log(
      //   'window.rawCaseData.caseBasicInfo.causeName',
      //   window.rawCaseData
      // )
      // if (
      //   window.rawCaseData.caseBasicInfo &&
      //   window.rawCaseData.caseBasicInfo.causeName
      // ) {
      //   causeName = window.rawCaseData.caseBasicInfo.causeName
      // }
      console.log('causeName', causeName)
      // if (!causeName) {
      //   return this.$toast('没有解析到相关案由内容。')
      // }
      this.$refs.analysis.analysisData.textData =
        causeName +
        'x' +
        this.$store.state.caseDetail.tabAnaSummary +
        'x' +
        this.$store.state.caseDetail.tabAnaSuSongQingQiu
      await this.$refs.analysis.texAanalysis()
      const info = this.$refs.analysis.analysisData
      this.caseIndex = null
      info.caseList.forEach((element, index) => {
        if (causeName === element.summary_name) {
          this.caseIndex = index
        }
      })
      if (this.caseIndex !== null) {
        this.$nextTick(() => {
          this.$refs.analysis.caseCellClick(this.caseIndex)
        })
      } else {
        this.finishedText = '没有更多了'
        this.finished = true
        this.loading = false
        // this.$toast('没有解析到相关案由内容。')
      }
      // this.loading = false
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    async onLoad() {
      if (this.step === 0) {
        this.getInfo()
      } else if (this.pageInfo.page === 'ana') {
        // 在分析页中
        this.finishedText = '没有更多了'
        if (this.step >= 2) {
          if (
            !this.$refs.analysis.$refs.two.currentTab.selectedArray ||
            !this.$refs.analysis.$refs.two.currentTab.selectedArray.length
          ) {
            return
          }
          const res = await this.$refs.analysis.$refs.two.currentTab.viewMore()
          if (!res) {
            this.finished = true
          }
          this.loading = false
        } else {
          const res = await this.$refs.analysis.$refs.one.viewMore()
          if (!res) {
            this.finished = true
          }
          this.loading = false
        }
      } else if (
        this.pageInfo.page === 'detail' ||
        this.pageInfo.page === 'detailInTopCase'
      ) {
        this.finishedText = ''
        this.finished = true
        this.loading = false
      } else if (this.pageInfo.page === 'topCase') {
        this.finished = true
        this.loading = false
      }
    },
    resetFinished(value) {
      this.finished = value
    },
    resetLoading(value) {
      this.loading = value
    },
    resetFinishedText(value) {
      this.finishedText = value
    },
    sliderSide(type) {
      this.$emit('sliderSide', type)
    }
  },
  mounted() {
    // this.getInfo()
  },
  watch: {
    async changeInfo() {
      this.finished = false
      await this.getInfo()
      this.$nextTick(() => {
        // this.$refs.analysis.$refs.two.getInfo()
        this.$refs.analysis.$refs.two.changeTitle(this.caseIndex, true)
      })
    }
  }
}
</script>

<style lang="stylus">
.analyse-tab
  height 100%
  margin-top 60px
  .topOffset
    transform translateY(-60px)
  // overflow-y auto
  .container
    .van-tabs__wrap
      .van-tabs__nav
        width 100% !important
  .van-tabs__nav--card
    margin 0 !important
</style>
