<template>
  <div class="ana-summary" :style="wrapperStyle" v-show="showComponent">
    <div class="summary" @click="show = true">
      <van-field
        v-model="$store.state.caseDetail.tabAnaSummary"
        type="textarea"
        placeholder="案情概要"
        rows="2"
        disabled
        autosize
      />
    </div>
    <van-popup v-model="show" class="textProp" get-container="body">
      <div class="conatiner">
        <div class="textEditer" @click="editerFocus">
          <van-field
            v-model="$store.state.caseDetail.tabAnaSummary"
            ref="editerArea"
            type="textarea"
            class="editerArea"
            placeholder="案情概要"
            autosize
          />
        </div>
        <div class="save">
          <span @click="save">重新检索</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { caseBase } from '~api-config'
import qs from 'qs'
export default {
  name: 'ana-summary',
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState('caseDetail', [
      'currrentTabName',
      'TLHeight',
      'bottomTabMode',
      'tabAnaSummary'
    ]),
    rootFontSize() {
      return document.documentElement.style.fontSize.replace(/px/g, '')
    },
    TLHeight() {
      return (
        document.documentElement.clientHeight - (44 / 37.5) * this.rootFontSize
      )
    },
    maxHeight() {
      return this.TLHeight - this.tabTopHeight
    },
    tabTopHeight() {
      return 1.173333 * this.rootFontSize
    },
    wrapperHeight() {
      return (74 / 37.5) * this.rootFontSize
    },
    wrapperStyle() {
      const oY = (this.maxHeight * 2) / 3 + this.wrapperHeight
      return {
        transform: `translate3d(0, -${oY}px, 0)`
      }
    },
    showComponent() {
      return (
        this.currrentTabName === 'AnalyseTab' && this.bottomTabMode !== 'MIN'
      )
    }
  },
  methods: {
    editerFocus() {
      this.$refs.editerArea.focus()
    },
    save() {
      this.$axios
        .put(
          `${caseBase}/new-design/caseUserDocument/create`,
          qs.stringify({
            caseId: this.$route.params.caseId,
            claimText: '诉讼请求',
            briefCaseText: this.tabAnaSummary,
            userId: this.$store.state.user.userInfo.id
          })
        )
        .then(res => {
          if (res.data.code === 200) {
            this.show = false
            this.$store.state.ana.caseName = this.$store.state.ana.caseName.split(
              'x'
            )[0]
            this.$store.state.caseDetail.tabAnaSummaryChange = Date.now()
            console.log(Date.now())
            this.$notify({
              message: '修改成功',
              duration: 1000,
              background: '#1989fa'
            })
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    }
  },
  created() {
    this.$axios
      .get(
        `${caseBase}/new-design/caseUserDocument/queryByCaseId/${this.$route.params.caseId}`
      )
      .then(res => {
        const [data] = res.data.data.caseUserDocument
        if (data) {
          const [cellData] = JSON.parse(data.documentData)
          this.$store.state.caseDetail.tabAnaSummary = cellData.shiShiLiYou_1
          this.$store.state.caseDetail.tabAnaSuSongQingQiu =
            cellData.suSongQingQiu_1
          this.$store.state.caseDetail.tabAnaSummaryChange2 = new Date()
        }
      })
      .catch(err => {
        Promise.reject(err)
      })
  }
}
</script>

<style lang="stylus">
.ana-summary
  z-index 10
  position fixed
  bottom 0
  width 100%
  height 74px
  display flex
  justify-content center
  align-items center
  padding 0 5px
  overflow-y auto
  .summary
    background #ffffff
    border-radius 2px
    box-shadow 0px -1px 12px 3px rgba(213, 213, 213, 0.58)
    width 100%
.textProp
  width 90vw
  border-radius 10px
  padding 15px 10px
.conatiner
  height 100%
  min-height 400px
  display flex
  flex-direction column
.textEditer
  box-sizing border-box
  flex-grow 1
.editerArea
  height 100%
.save
  text-align right
  color #4B9EFB
  font-size 15px
  padding-right 15px
</style>
