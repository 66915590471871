var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "programType" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "inputBox border-bottom" },
        [
          _vm.$store.state.nodeform.procedureType
            ? _c("span", [
                _vm._v(_vm._s(_vm.$store.state.nodeform.procedureType.name)),
              ])
            : _c("span"),
          _c("van-icon", {
            staticClass: "selColor",
            attrs: { name: "arrow-down" },
            on: {
              click: function ($event) {
                _vm.showPopup = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.columns,
              "show-toolbar": true,
              "value-key": "name",
              "item-height": _vm.pickCellheight,
            },
            on: {
              confirm: _vm.changeType,
              cancel: function ($event) {
                _vm.showPopup = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _c("span", [_vm._v("程序类型")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }