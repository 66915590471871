<template>
  <div class="court">
    <span>
      <span class="red">*</span>
      <span>法院</span>
    </span>
    <div class="search border-bottom" @click="inputFuguan">
      <span class="courtName">{{ courtName }}</span>
      <!-- <i class="iconfont icon-search"></i> -->
    </div>
    <van-popup
      v-model="showPopup"
      :close-on-click-overlay="false"
      position="right"
    >
      <search-court
        @clickback="clickback"
        v-if="showPopup"
        @choosed="choosed"
      />
    </van-popup>
  </div>
</template>
<script>
import searchCourt from './searchCourt'
import { caseBase } from '~api-config'
import { mapMutations } from 'vuex'
export default {
  name: 'court',
  components: {
    searchCourt
  },
  data() {
    return {
      showPopup: false,
      courtName: ''
    }
  },
  mounted() {
    this.$axios
      .get(`${caseBase}/lts/case_judge/${this.$route.params.caseId}`)
      .then(res => {
        if (res.data.data) {
          this.courtName = res.data.data.courtName
        } else {
          this.$axios.get(`${caseBase}/lts/user/queryCourt`).then(res => {
            this.courtName = res.data.data.name
            this.setFayuanName(res.data.data.name)
            this.setCourt(res.data.data.id)
          })
        }
      })
  },
  methods: {
    ...mapMutations('nodeform', ['setCourt', 'setFayuanName']),
    inputFuguan() {
      // console.log(window)
      // console.log(window.rawCaseData)
      // if (!window.rawCaseData.caseBasicInfo) {
      //   return this.$notify('正在加载数据，请稍后再试')
      // }
      this.$router.push({
        name: 'fayuanInfo',
        query: {
          is: 0,
          caseId: this.$route.params.caseId
        }
      })
    },
    clickback() {
      this.showPopup = false
    },
    choosed(item) {
      this.$store.state.nodeform.court = item.id
      this.$store.state.nodeform.fayuanName = item.name
      this.clickback()
    }
  }
}
</script>
<style scoped lang="stylus">
$fontColor = #000000
$color = #A3A3A3
.court
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    margin-right 5px
    color red
  .search
    width 70%
    padding 5px 15px
    border-color $borderColor
    border-radius 10px
    display flex
    align-items stretch
    justify-content space-between
    .iconfont
      color $color
      transform scale(1.2)
    .courtName
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
</style>
