<template>
  <div>
    没有历史版本
  </div>
</template>
<script>

export default {
  name: 'Message'
}
</script>
<style lang="stylus" scoped>
div
  border-bottom 1px solid #F3F4F7
  padding-bottom 14px
  margin 15px 19px 0 44px
  font-family PingFangSC
  font-size 14px
  color #333
  text-align center
</style>
