var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qestion" },
    [
      _vm._t("default"),
      _c("div", { staticClass: "qestion-form" }, [
        _c(
          "div",
          { staticClass: "charts-container" },
          _vm._l(_vm.chart, function (item, index) {
            return _c(item.type, {
              key: index,
              tag: "component",
              attrs: {
                chartData: item.chartData,
                chartSetting: item.stack,
                xAxis: item.option.xAxis,
                yAxis: item.option.yAxis,
                title: item.title,
                index: index,
              },
            })
          }),
          1
        ),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHtml,
              expression: "showHtml",
            },
          ],
          staticClass: "textCentet",
          staticStyle: { "padding-left": "16.7pt" },
          domProps: { innerHTML: _vm._s(_vm.html) },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }