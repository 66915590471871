var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasAvatar
    ? _c("img", {
        staticClass: "coop-avatar-img",
        attrs: { src: _vm.avatarUrl },
      })
    : _c("i", { staticClass: "iconfont icon-user" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }