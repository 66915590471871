<template>
  <div class="judge">
    <span>
      <span class="red">*</span>
      <span>法官</span>
    </span>
    <div class="inputBox border-bottom">
      <!-- <span v-if="!judge.length">法官</span> -->
      <span v-for="(item, index) in judge" :key="index" @click="showSearch">{{
        item
      }}</span>
      <div style="height:40px"></div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
export default {
  name: 'judge',
  computed: {
    load() {
      return this.$store.state.nodeform.loadingFinish
    }
  },
  data() {
    return {
      judge: []
    }
  },
  methods: {
    showSearch() {
      // if (!window.rawCaseData.caseBasicInfo) {
      //   return this.$notify('正在加载数据，请稍后再试')
      // }
      this.$router.push({
        name: 'fayuanInfo',
        query: {
          is: 0,
          caseId: this.$route.params.caseId
        }
      })
    }
  },
  mounted() {
    this.$axios
      .get(`${caseBase}/lts/case_judge/${this.$route.params.caseId}`)
      .then(res => {
        if (!res.data.data.judge) {
          return
        }
        const string = res.data.data.judge.replace(/\[|\]/g, '')
        // eslint-disable-next-line
        if (string.match(/\,/)) {
          this.judge = string.split(', ')
        } else {
          this.judge = [string]
        }
      })
  }
}
</script>
<style scoped lang="stylus">
$color = #A3A3A3
$fontColor = #000000
.judge
  margin 15px 0
  display flex
  align-items center
  justify-content space-between
  color $fontColor
  .red
    opacity 0
    margin-right 5px
  .inputBox
    width 70%
    padding 5px 15px
    border-color $borderColor
    border-radius 10px
    display flex
    align-items stretch
    justify-content space-between
    .iconfont
      color $color
      transform scale(1.2)
</style>
