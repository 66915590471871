<template>
  <div class="input-range"
       @click="move">
    <div class="track">
      <div v-if="timeRange.nodeSegmentations"
           class="segmentations">
        <div v-for="segment in sortedSegmentations"
             :key="segment.id"
             class="segment"
             :style="segmentStyle(segment)">
          {{segment.quantity}}
        </div>
      </div>
      <div class="range"
           :style="{width: percentage}">
      </div>
      <div class="thumb"
           :style="{left: percentage}"
           @touchstart="onTouchStart"
           @touchmove="onTouchMove"
           @touchend="onTouchEnd">{{timeRange.simpleName}}</div>
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: {
    timeRange: Object,
    groupLevel: Number,
    outterTranslateX: Number
  },
  data () {
    return {
      step: 0,
      val: 0,
      touch: {
        startX: 0,
        lastVal: 0
      }
    }
  },
  computed: {
    ...mapState('caseDetail', {
      timelineRange: state => state.TLRange,
      timelineWidth: state => state.TLWidth,
      timelineHeight: state => state.TLHeight,
      timelineMode: state => state.TLMode,
      timelineTouching: state => state.TLTouching,
      timelineCanMove: state => state.TLCanMove
    }),
    ...mapGetters('caseDetail', ['getTLRangeStamp', 'getTLRangeCrossNumber']),
    max () {
      const { beginTime, endTime } = this.timeRange
      const timeDiffInDays =
        Math.floor((this.getTimeStamp(endTime) - this.getTimeStamp(beginTime)) / 3600 / 24 / 1000)
      return timeDiffInDays
    },
    min () {
      return 0
    },
    precision () {
      return (this.step.toString().split('.')[1] || []).length
    },
    percentage () {
      const pr = this.val * (3600 * 1000 * 24) / (this.timeRange.endTime - this.timeRange.beginTime) * 100
      return `${pr}%`
    },
    wrapperWidth () {
      const WPleft = this.getOffsetLeft(this.timeRange)
      const vItem = {}
      vItem.beginTime = this.timeRange.endTime
      const vLeft = this.getOffsetLeft(vItem)
      const width = vLeft - WPleft
      return width
    },
    sortedSegmentations () {
      const segmentations = this.timeRange.nodeSegmentations
      if (segmentations && segmentations.length) {
        return segmentations.sort(this.sortSegment)
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapMutations('caseDetail', [
      'setTLRange',
      'setTLWidth',
      'setTLHeight',
      'setTLMode',
      'setTLTouching',
      'setTLCanMove'
    ]),
    getTimeStamp (Str) {
      return new Date(Str).getTime()
    },
    getOffsetLeft (item) {
      const { start } = this.getTLRangeStamp
      if (item && item.beginTime) {
        const _start = this.getTimeStamp(item.beginTime)
        const left =
          (_start - start) / this.getTLRangeCrossNumber * this.timelineWidth
        return left
      } else {
        return 0
      }
    },
    sortSegment (obj1, obj2) {
      var val1 = obj1.segmentationSequence
      var val2 = obj2.segmentationSequence
      if (val1 < val2) {
        return -1
      } else if (val1 > val2) {
        return 1
      } else {
        return 0
      }
    },
    onTouchStart (e) {
      this.setTLCanMove(false)
      this.touch.startX = e.targetTouches[0].clientX
      this.touch.lastVal = parseInt(this.val)
    },
    onTouchMove (e) {
      const range = this.max - this.min
      const pageX = e.targetTouches[0].clientX
      const diffX = pageX - this.touch.startX
      if (pageX <= this.timelineWidth) {
        if (diffX !== 0) {
          const delta = Math.floor(diffX / this.wrapperWidth * range)
          const newVal = parseInt(this.touch.lastVal + delta)
          if (newVal >= this.max) {
            this.val = this.max
          } else if (newVal <= 0) {
            this.val = 0
          } else {
            this.val = newVal
          }
          const pr = this._getPercentage(this.val)
          this.notifyParent(this.val, pr)
        }
      }
    },
    onTouchEnd () {
      this.setTLCanMove(true)
    },
    move (e) {
      const me = this
      const WPleft = this.getOffsetLeft(this.timeRange)
      const left = e.pageX - me.offset - WPleft - this.outterTranslateX
      const range = this.max - this.min
      const delta = Math.floor(left / this.wrapperWidth * range)
      const newVal = parseInt(delta)
      if (newVal >= this.max) {
        this.val = this.max
      } else if (newVal <= 0) {
        this.val = 0
      } else {
        this.val = newVal
      }
      const pr = this._getPercentage(this.val)
      this.notifyParent(this.val, pr)
    },
    _getWholeWidth () {
      this.wholeWidth = this.$el.querySelector('.track').offsetWidth
    },
    _getPercentage (value) {
      const pr = value * (3600 * 1000 * 24) / (this.timeRange.endTime - this.timeRange.beginTime) * 100
      return `${pr}%`
    },
    valFilter (val) {
      this.val = parseFloat(val).toFixed(this.precision)
      return this.val
    },
    segmentStyle (segment) {
      const { quantity, quantityUnit } = segment
      let days = 0
      // TODO 判断时间单位计算出天数
      if (quantityUnit === 'DAY') {
        days = quantity
      } else {
        days = quantity
      }
      const pr = this._getPercentage(days)
      return {
        flex: `0 0 ${pr}`
      }
    },
    notifyParent (days = 0, pr = 0.0) {
      const payload = {}
      payload.group = this.groupLevel
      payload.days = days
      payload.pr = pr
      this.$emit('sliderChange', payload)
    },
    setDefaultVal () {
      const level = this.timeRange.level
      const duration = level * 300
      setTimeout(() => {
        const defaultVal = Math.ceil(this.max / 3)
        this.val = defaultVal
        const pr = this._getPercentage(this.val)
        this.notifyParent(this.val, pr)
      }, duration)
    }
  },
  mounted () {
    this.setDefaultVal()
  }

}
</script>

<style lang="stylus">
$primary-color = #f6b536
$range-size = 15px
$thumb-size = 20px
$thumb-color = white
$box-shadow-color = #aaa
.input-range
  user-select none
  cursor pointer
  margin $thumb-size * 0.5 0 0 0
  color $primary-color
  .track
    position relative
    height $range-size
    background lighten($primary-color, 30%)
    .segmentations
      display flex
      width 100%
      height 100%
      left 0
      top 0
      position absolute
      color #fff
      .segment
        flex 0 0 20%
        text-align center
        border-right 1px solid #ffffff
    .range
      background $primary-color
      height 100%
    .thumb
      position relative
      text-align center
      line-height $thumb-size
      background $thumb-color
      box-shadow 0 0 10px -2px $box-shadow-color
      width $thumb-size
      height $thumb-size
      border-radius $thumb-size
      transform translate3D((- $thumb-size / 2), -25px, 0)
      &:after
        content ' '
        position absolute
        width 6px
        height 6px
        left 50%
        top 100%
        background #fff
        margin-left -3px
        margin-top -4px
        transform rotate(-45deg)
    .value
      position absolute
      top - $thumb-size * 1.5
      transform translateX(-50%)
      background $thumb-color
      box-shadow 0 0 10px -2px $box-shadow-color
      border-radius 4px
      line-height 20px
      padding 0 10px
      white-space nowrap
      &:before
        content ' '
        position absolute
        width 6px
        height 6px
        left 50%
        top 100%
        background #fff
        margin-left -3px
        margin-top -4px
        transform rotate(-45deg)
</style>
