<template>
  <!-- <div @click="upJiedian" id="shengji">
    升级为节点
  </div> -->
  <van-popup v-model="show"
             @click-overlay='ww'
             position="top"
             :overlay-style="{background:'rgba(0,0,0,0)'}"
             class="shengji">
    <div @click="upJiedian">升级为节点</div>
  </van-popup>
</template>
<script>
export default {
  name: 'Dialog',
  props: ['content', 'showdia'],
  data () {
    return {
      show: false
    }
  },
  watch: {
    showdia (bool) {
      this.show = bool
    }
  },

  mounted () {
    // console.log(this.show)
  },
  methods: {
    ww () {
      this.show = false
    },
    upJiedian () {
      // 显示背景色
      // this.$emit('showPopup')
      // 进入点击提醒时的页面
      const nodeData = window.rawCaseData
      const remindData = this.content
      console.log(window)
      this.$store.commit('remindForm/openRemindForm', {
        status: false,
        nodeData,
        remindData
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.van-popup
  width 150px
  height 108px
  max-height 285%
  background rgba(255, 255, 255, 1)
  z-index 10
  position absolute
  top 20px
  right 0px
  left -4%
  line-height 1.5rem
  font-size 14px
  color #666
  font-family PingFangSC
  box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
  border-radius 10px
  text-align center
  // padding-top 17px
  box-sizing border-box
  transform translate3d(-90%, 0, 0)
</style>
