import { render, staticRenderFns } from "./proofPeriod.vue?vue&type=template&id=3a56de96&scoped=true"
import script from "./proofPeriod.vue?vue&type=script&lang=js"
export * from "./proofPeriod.vue?vue&type=script&lang=js"
import style0 from "./proofPeriod.vue?vue&type=style&index=0&id=3a56de96&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a56de96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-46129907-392501/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a56de96')) {
      api.createRecord('3a56de96', component.options)
    } else {
      api.reload('3a56de96', component.options)
    }
    module.hot.accept("./proofPeriod.vue?vue&type=template&id=3a56de96&scoped=true", function () {
      api.rerender('3a56de96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/nodeform/components/proofPeriod.vue"
export default component.exports