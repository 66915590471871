<template>
  <div class="saveButton">
    <button v-if="$store.state.nodeform.formStatus===2"
            @click="cancelHandle">撤销</button>
    <button @click="saveHandel"
            :disabled='num'>保存</button>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { caseBase } from '~api-config'
export default {
  name: 'saveButton',
  data () {
    return {
      NodeForm: {
        nodeFormEntity: {},
        formContentEntity: []
      },
      num: false
    }
  },
  computed: {
    ...mapState('nodeform', ['nodeIdAndName'])
  },
  methods: {
    ...mapMutations('nodeform', [
      'nodeformReset',
      'setFormKV'
    ]),
    ...mapMutations('caseDetail', ['setWatchButton']),
    // 保存
    saveHandel () {
      if (this.$store.state.nodeform.nodeFormType === 'FILING' && !this.$store.state.nodeform.formStatus) {
        this.NodeFormValue(2)
        const endTime = this.$store.state.nodeform.juZhengEndDate ? this.$store.state.nodeform.juZhengEndDate.getTime() : null
        const params = {
          nodeForms: [
            this.NodeForm.nodeFormEntity,
            {
              caseId: this.$store.state.nodeform.caseId,
              nodeId: this.getJuZhengNodeId() ? this.getJuZhengNodeId().nodeId : null,
              setTime: endTime,
              formStatus: 2
            }
          ],
          procedureType: this.$store.state.nodeform.procedureType
        }

        const loading = this.$toast.loading({
          message: '正在保存...'
        })
        this.num = true
        this.$axios.post(`${caseBase}/lts/nodeForm/save?caseId=${this.$store.state.nodeform.caseId}`, params)
          .then(res => {
            this.setFormKV({ key: 'dateNow', val: Date.now() })
            this.nodeformReset()
            loading.clear()
            this.setWatchButton(!this.$store.state.caseDetail.watchButton)
          })
      } else {
        this.clickHandle(2)
      }
      this.num = false
    },
    // 取消
    cancelHandle () {
      this.clickHandle(0)
    },
    // 参数赋值
    NodeFormValue (index) {
      if (this.$store.state.nodeform.nodeFormType === 'FILING') {
        // 立案状态下要传入承办人和法院参数的参数
        this.NodeForm.nodeFormEntity.initiateName = this.$store.state.nodeform.constractor// 承办者
        this.NodeForm.nodeFormEntity.lawfirmName = this.$store.state.nodeform.court// 法院
      } else if (this.$store.state.nodeform.nodeFormType === 'INTERVAL_DATE_CONTENT') {
        // 批准状态下传入截止时间参数
        this.NodeForm.nodeFormEntity.endTime = this.$store.state.nodeform.deadline// 截止时间
        this.NodeForm.nodeFormEntity.lawfirmName = this.$store.state.nodeform.court// 法院
      }
      this.NodeForm.formContentEntity = this.$store.state.nodeform.textArray// 内容
      this.NodeForm.nodeFormEntity.formStatus = index// 状态
      this.NodeForm.nodeFormEntity.setTime = this.$store.state.nodeform.startDate// 开始时间
      this.NodeForm.nodeFormEntity.caseId = this.$store.state.nodeform.caseId// caseId
      this.NodeForm.nodeFormEntity.formId = this.$store.state.nodeform.formId// formId
      this.NodeForm.nodeFormEntity.nodeId = this.$store.state.nodeform.nodeId// nodefId
      this.NodeForm.nodeFormEntity.dateType = this.$store.state.nodeform.dateKind// 日期类型 date datetime
    },
    // 发送请求
    clickHandle (index) {
      const loading = this.$toast.loading({
        message: index ? '正在保存...' : '正在撤销...'
      })
      this.NodeFormValue(index)
      this.$axios.post(`${caseBase}/lts/nodeForm/saveOrUpdate`, this.NodeForm).then((res) => {
        if (res.data.code === '409') {
          this.nodeformReset()
          return this.$notify({
            message: res.data.message,
            background: '#E6A23C'
          })
        }
        this.setFormKV({ key: 'dateNow', val: Date.now() })
        this.nodeformReset()
        loading.clear()
        this.setWatchButton(!this.$store.state.caseDetail.watchButton)
      })
    },
    getJuZhengNodeId () {
      let a = null
      if (this.nodeIdAndName) {
        if (this.nodeIdAndName.constructor.name === 'Array') {
          if (this.nodeIdAndName.length) {
            this.nodeIdAndName.forEach(element => {
              if (element.nodeName === '举证/证据交换') {
                a = element
              }
            })
          }
        }
      }
      return a
    }
  }
}
</script>
<style scoped lang="stylus">
$color = #4B9EFB
.saveButton
  margin 15px 0
  display flex
  align-items center
  justify-content flex-end
  padding 0 25px
  button
    color $color
    border none
    background none
    outline none
    &:nth-of-type(2)
      margin-left 40px
</style>
