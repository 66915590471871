<template>
  <div class="qestion">
    <slot />
    <div class="qestion-form">
      <div class="charts-container">
        <component
          v-for="(item, index) in chart"
          :key="index"
          :is="item.type"
          :chartData="item.chartData"
          :chartSetting="item.stack"
          :xAxis="item.option.xAxis"
          :yAxis="item.option.yAxis"
          :title="item.title"
          :index="index"
        ></component>
      </div>
      <div
        class="textCentet"
        v-show="showHtml"
        v-html="html"
        style="padding-left: 16.7pt"
      ></div>
    </div>
  </div>
</template>
<script>
import 'echarts/lib/component/title'
import bar from './formCharts/bar'
import histogram from './formCharts/histogram'
import pie from './formCharts/pie'
import chartLine from './formCharts/line'
import ring from './formCharts/ring'
import { caseBase } from '~api-config'
export default {
  name: 'questionForm',
  components: {
    bar,
    histogram,
    pie,
    chartLine,
    ring
  },
  data() {
    return {
      chart: [],
      html: '',
      showHtml: false
    }
  },
  async mounted() {
    // 获取案件相关信息
    const arr = this.$route.fullPath.split('/')
    const id = arr[arr.length - 2]
    this.$axios
      .get(`${this.$base}/lts/case/getCase?caseId=${id}`)
      .then(result => {
        if (result.data && result.data.code === '200') {
          this.$http
            .post('https://data.farbun.com/api/axis', {
              name: this.$store.state.nodeform.title,
              proceeding: result.data.data.trialGradeValueId
            })
            .then(res => {
              const resArr = res.data.result.result
              resArr.forEach(v => {
                v.option = {}
                if (v.data.length > 0) {
                  v.chartData = {}
                  v.chartData.rows = []
                  v.dataArr = []
                  v.data.forEach(item => {
                    const obj = {}
                    obj.label = item.label
                    obj[v.title] = item.num
                    v.chartData.rows.push(obj)
                    v.dataArr.push(item.num)
                  })
                  v.chartData.columns = []
                  v.chartData.columns.push('label')
                  v.chartData.columns.push(v.title)
                  v.titleArr = v.chartData.rows.map(ite => {
                    return ite.label
                  })
                  v.showLegend = v.titleArr.length > 2
                  if (v.title === '适用案由') {
                    // 柱状图
                    v.type = 'histogram'
                    v.option.xAxis = {
                      type: 'category',
                      data: v.titleArr,
                      axisLabel: {
                        rotate: 60,
                        align: 'right'
                      }
                    }
                    v.option.yAxis = {
                      type: 'value'
                    }
                  } else if (v.title === '承办法院' || v.title === '律师') {
                    v.type = 'bar'
                    v.option.xAxis = {
                      type: 'value'
                    }
                    v.option.yAxis = {
                      type: 'category',
                      data: v.titleArr,
                      axisLabel: {
                        rotate: -60,
                        align: 'right'
                      }
                    }
                  } else if (
                    v.title === '文书性质' ||
                    v.title === '案件类型' ||
                    v.title === '审理程序'
                  ) {
                    v.type = 'ring'
                  } else if (v.title === '审判年份') {
                    v.type = 'chartLine'
                  } else {
                    v.type = 'histogram'
                    v.option.xAxis = {
                      type: 'category',
                      data: v.titleArr,
                      axisLabel: {
                        rotate: 0,
                        align: 'left'
                      }
                    }
                    v.option.yAxis = {
                      type: 'value'
                    }
                  }
                }
              })
              this.chart = resArr
              /* this.chartData = res.data.result.map((item, index) => {
          // 类型判断
          if (
            item.option.series.type === 'bar' ||
            item.option.series[0].type === 'bar'
          ) {
            if (
              item.option.yAxis.type === 'value' ||
              item.option.yAxis[0].type === 'value'
            ) {
              // 是柱状图
              item.type = 'histogram'
            } else {
              // 是条形图
              item.type = 'bar'
            }
          } else if (
            item.option.series.type === 'line' ||
            item.option.series[0].type === 'line'
          ) {
            item.type = 'chartLine'
          } else if (item.option.series.type) {
            item.type = item.option.series.type
          } else {
            item.type = item.option.series[0].type
          }
          // 位置判断
          if (item.type === 'pie') {
            if (item.option.series[0]) {
              // 修改环形图标的位置
              item.option.series[0].center = ['50%', '50%']
              // 把label放到中间
              item.option.series[0].label = {}
              item.option.series[0].label.position = 'center'
              // 把tooltip分左右显示
              item.option.series[0].tooltip = {}
              item.option.series[0].tooltip.position = function(
                point,
                params,
                dom,
                rect,
                size
              ) {
                if (point[0] < size.viewSize[0] / 2) {
                  // 在左侧
                  return [point[0] + 10, point[1] + 10]
                } else {
                  // 在右侧
                  return [point[0] - dom.clientWidth - 10, point[1] + 10]
                }
              }
              // hover放大 如果要关闭就解开下一行注释
              // item.option.series[0].hoverAnimation = false
            } else {
              // 默认series是数组，所以不会走到这里，如果有不是数组的在处理
              item.option.series.center = ['50%', 95]
            }
          } else {
            item.option.title.top = '10px'
          }
          // 数据类型处理
          if (item.type === 'histogram' || item.type === 'chartLine') {
            let w = true
            let k = true
            let axisLabel
            // 判断y轴的数据大小
            item.option.series[0].data.forEach(element => {
              if (element < 10000) {
                w = false
                if (element < 1000) {
                  k = false
                }
              }
            })
            if (w) {
              // 最小值大于10000
              axisLabel = {}
              axisLabel.formatter = function(value, index) {
                return `${value / 10000} 万`
              }
            } else if (k) {
              // 最值在1000-10000之间
              axisLabel = {}
              axisLabel.formatter = function(value, index) {
                return `${value / 1000} k`
              }
            }
            // 判断 yAxis 是数组还是对象
            if (item.option.yAxis[0] && axisLabel) {
              item.option.yAxis[0].axisLabel = axisLabel
            } else if (axisLabel) {
              item.option.yAxis.axisLabel = axisLabel
            }
          } else if (item.type === 'bar') {
            let w = true
            let k = true
            let axisLabel
            // 判断y轴的数据大小
            item.option.series[0].data.forEach(element => {
              if (element < 10000) {
                w = false
                if (element < 1000) {
                  k = false
                }
              }
            })
            if (w) {
              // 最小值大于10000
              axisLabel = {}
              axisLabel.formatter = function(value, index) {
                return `${value / 10000}万`
              }
            } else if (k) {
              // 最值在1000-10000之间
              axisLabel = {}
              axisLabel.formatter = function(value, index) {
                return `${value / 1000}k`
              }
            }
            // 判断 yAxis 是数组还是对象
            if (item.option.xAxis[0] && axisLabel) {
              item.option.xAxis[0].axisLabel = axisLabel
            } else if (axisLabel) {
              item.option.xAxis.axisLabel = axisLabel
            }
          }
          return item
        }) */
            })
        }
      })
      .catch(err => {
        console.log(err)
      })

    this.$axios
      .get(
        `${caseBase}/lts/reminderContent/${this.$store.state.nodeform.nodeId}`
      )
      .then(res => {
        if (res.data.code === '200') {
          this.html = res.data.data.content
          // console.log('this.html', this.html)
          this.showHtml = true
        }
      })
  }
}
</script>
<style scoped lang="stylus">
.qestion
  height 450px
  background-color white
  border-radius 5px
  padding 15px
  display flex
  flex-direction column
.qestion-form
  flex-grow 1
  overflow-y auto
.textCentet
  box-sizing border-box
  border 1px solid #cccccc
  border-radius 3px
  padding 10px
  text-align left
</style>
